import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReportHeads {
    id: number,
    title: string,
    subTitle: string,
    uri: string
}


export interface ReportingState {
    reportHeads: ReportHeads[],
    isLoading: boolean
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface RequestReportHeads { type: 'REQUEST_REPORTHEADS', isLoading: boolean }
export interface RecieveReportHeads { type: 'RECIEVE_REPORTHEADS', reportHeads: ReportHeads[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestReportHeads | RecieveReportHeads;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    FetchHeads: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        fetch(`api/ReportHeads`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
            .then(data => {
                dispatch({
                    type: 'RECIEVE_REPORTHEADS',
                    reportHeads: data
                });
            })
            .catch(error => dispatch({ type: 'REQUEST_REPORTHEADS', isLoading : false }))

        dispatch({ type: 'REQUEST_REPORTHEADS', isLoading: true });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ReportingState> = (state: ReportingState | undefined, incomingAction: Action): ReportingState => {
    if (state === undefined) {
        return { isLoading: false, reportHeads: [] };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_REPORTHEADS':
            return { isLoading: action.isLoading, reportHeads:state.reportHeads };
        case 'RECIEVE_REPORTHEADS':
            return { isLoading:false, reportHeads:action.reportHeads };
        default:
            return state;
    }
};
