import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaHome, FaUpload, FaCalendar, FaUsers } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../feam-logo.png';
import { ApplicationState } from '../store';
import * as DashBoardStore from '../store/DashBoard';
import { connect } from 'react-redux';
import {
    IoHomeOutline, IoCalendarClearOutline, IoCloudUploadOutline
    , IoPeopleOutline, IoGlobeOutline, IoSettingsOutline,
    IoTrendingUpOutline, IoTimeOutline
} from 'react-icons/io5'
import { ScreenValid, IsMenuAllowed } from '../guards/GuardedRoute';

type DashBoardProps =
    DashBoardStore.DashBoardState &
    typeof DashBoardStore.actionCreators &
    RouteComponentProps<{}> &
    IProps;

interface IProps {
    history: any;
    // any other props that come into the component
}

class SideBar extends React.Component<DashBoardProps> {

    unlisten: any = {};
    activePathName: string = '';
    collapsed: boolean = false;
    myRef: any = React.createRef();
    myMenuRef: any = React.createRef();
    handleOutsideClick = (e: { target: any; }) => {
        if (!this.myRef || !this.myRef.current) {
            return;
            //sidebar not rendered yet
        }

        if (!this.myRef.current.contains(e.target)) {
            console.log(e.target);

            this.collapsed = true;
            console.log("click outside hit");

        }
        else {
            if (this.myMenuRef.current.contains(e.target)) {
                return;
            }
            this.collapsed = false;
        }

        this.forceUpdate();
    };


    handleClick() {
        this.collapsed = !this.collapsed;
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location: any, action: any) => {
            console.log("on route change from sidebar");
            this.activePathName = location.pathname;
        });
        document.addEventListener("mousedown", this.handleOutsideClick);
        console.log(this.props.history.location.pathname);
        this.activePathName = this.props.history.location.pathname;
        console.log(this.activePathName);
        this.forceUpdate();
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
        this.unlisten();
    }

    render() {
        return (
            <React.Fragment>

                {((this.activePathName !== '/station') && (this.activePathName !== "/")) &&
                    <ProSidebar ref={this.myRef} breakPoint="sm" className={this.collapsed == true ? 'collapsed smhidden' : 'smhidden'} >
                        <SidebarHeader  >
                            {!this.collapsed &&
                                <div>
                                    <div>
                                        <br />
                                        <br />
                                        <img src={logo} width="234" />
                                    </div>
                                    <div className="welcomeText">
                                        Welcome to  {this.props.selectedStation}!
                            </div>
                                </div>}

                            {this.collapsed &&
                                <div className="welcomeText">
                                    &nbsp;
                            </div>}
                        </SidebarHeader>
                        <SidebarContent>

                        
                            <Menu iconShape="square" ref={this.myMenuRef}>                            
                                <div>
                                {IsMenuAllowed("dashboard") && (this.activePathName === '' || this.activePathName === '/dashboard') &&
                                        <div className={this.activePathName === '' || this.activePathName === '/dashboard' ? 'activeTop' : ''}>
                                            <div className={this.activePathName === '' || this.activePathName === '/dashboard' ? 'activeTopInner' : ''}>&nbsp;</div>

                                        </div>}
                                {IsMenuAllowed("dashboard") && ScreenValid('dashboard') && <MenuItem className={this.activePathName === '' || this.activePathName === '/dashboard' ? 'activeMenu' : 'menuText'}
                                        icon={<IoHomeOutline />}
                                    >
                                        <Link to="/dashboard" />
                                        Dashboard</MenuItem>}
                                {IsMenuAllowed("dashboard") && (this.activePathName === '' || this.activePathName === '/dashboard') && <div className={this.activePathName === '' || this.activePathName === '/dashboard' ? 'activeBottom' : ''}>
                                        <div className={this.activePathName === '' || this.activePathName === '/dashboard' ? 'activeBottomInner' : ''}>&nbsp;</div>

                                    </div>}
                                </div>                            
                            <div>                                
                                {IsMenuAllowed("approval") && (this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval') &&
                                    <div className={this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval' ? 'activeTop' : ''}>
                                    <div className={this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval' ? 'activeTopInner' : ''}>&nbsp;</div>

                                        </div>}
                                {IsMenuAllowed("approval") && ScreenValid('approval') && <MenuItem className={this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval' ? 'activeMenu' : 'menuText'}
                                        icon={<IoTimeOutline />}
                                    >
                                    
                                    <Link to="/approval" />
                                    Time And Labor</MenuItem>}
                               
                                {IsMenuAllowed("timeAndLabor") && ScreenValid('timeAndLabor') && !ScreenValid('approval') && <MenuItem className={this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval' ? 'activeMenu' : 'menuText'}
                                    icon={<IoTimeOutline />}
                                >
                                    <Link to="/timeAndLabor" />
                                    Time And Labor</MenuItem>}
                                {IsMenuAllowed("timeAndLabor") && (this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval') && <div className={this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval'? 'activeBottom' : ''}>
                                    <div className={this.activePathName === '' || this.activePathName.includes('timeAndLabor') || this.activePathName === '/approval' ? 'activeBottomInner' : ''}>&nbsp;</div>

                                    </div>}
                            </div>                            
                                <div>
                                {IsMenuAllowed("planning")&& this.activePathName === '/planning' &&
                                        <div className={this.activePathName === '/planning' ? 'activeTop' : ''}>
                                            <div className={this.activePathName === '/planning' ? 'activeTopInner' : ''}>&nbsp;</div>

                                        </div>}

                                {IsMenuAllowed("planning") && ScreenValid('planning') && <MenuItem className={this.activePathName === '/planning' ? 'activeMenu' : 'menuText'}
                                        icon={<IoCalendarClearOutline />}
                                    >


                                        Planning
                            <Link to="/planning" />
                                    </MenuItem>
                                    }
                                {IsMenuAllowed("planning") && this.activePathName === '/planning' && <div className={this.activePathName === '/planning' ? 'activeBottom' : ''}>
                                        <div className={this.activePathName === '/planning' ? 'activeBottomInner' : ''}>&nbsp;</div>

                                    </div>}
                                </div>


                                <div>
                                {IsMenuAllowed("upload") && this.activePathName === '/traxfile' &&
                                        <div className={this.activePathName === '/traxfile' ? 'activeTop' : ''}>
                                            <div className={this.activePathName === '/traxfile' ? 'activeTopInner' : ''}>&nbsp;</div>

                                        </div>}
                                {IsMenuAllowed("upload") && ScreenValid('traxfile') && <MenuItem className={this.activePathName === '/traxfile' ? 'activeMenu' : 'menuText'}
                                        icon={<IoCloudUploadOutline />}
                                    >

                                        Upload
                            <Link to="/traxfile" />
                                    </MenuItem>}
                                {IsMenuAllowed("upload") && this.activePathName === '/traxfile' && <div className={this.activePathName === '/traxfile' ? 'activeBottom' : ''}>
                                        <div className={this.activePathName === '/traxfile' ? 'activeBottomInner' : ''}>&nbsp;</div>

                                    </div>}
                                </div>

                            <div>

                                {IsMenuAllowed("workload") && (this.activePathName === '/workload' || this.activePathName.includes('worksheet')) &&
                                    <div className={this.activePathName === '/workload' || this.activePathName.includes('worksheet') ? 'activeTop' : ''}>
                                    <div className={this.activePathName === '/workload' || this.activePathName.includes('worksheet') ? 'activeTopInner' : ''}>&nbsp;</div>

                                        </div>}
                                {IsMenuAllowed("workload") && ScreenValid('workload') && <MenuItem className={this.activePathName === '/workload' || this.activePathName.includes('worksheet') ? 'activeMenu' : 'menuText'}
                                    icon={<IoSettingsOutline />}
                                    >

                                        Workload
                            <Link to="/workload" />
                                    </MenuItem>}
                                {IsMenuAllowed("workload") && (this.activePathName === '/workload' || this.activePathName.includes('worksheet')) && <div className={this.activePathName === '/workload' || this.activePathName.includes('worksheet') ? 'activeBottom' : ''}>
                                    <div className={this.activePathName === '/workload' || this.activePathName.includes('worksheet')  ? 'activeBottomInner' : ''}>&nbsp;</div>

                                    </div>}
                                </div>

                                <div>
                                {IsMenuAllowed("users") && (this.activePathName == '/Users' || this.activePathName.includes('UserDetails')) &&
                                    <div className={this.activePathName == '/Users' || this.activePathName.includes('UserDetails')? 'activeTop' : ''}>
                                    <div className={this.activePathName == '/Users' || this.activePathName.includes('UserDetails') ? 'activeTopInner' : ''}>&nbsp;</div>

                                        </div>}
                                {IsMenuAllowed("users") && ScreenValid('users') && <MenuItem className={this.activePathName == '/Users' || this.activePathName.includes('UserDetails') ? 'activeMenu' : 'menuText'}
                                        icon={<IoPeopleOutline />}
                                    >

                                        Users
                            <Link to="/Users" />
                                    </MenuItem>}
                                {IsMenuAllowed("users") && (this.activePathName == '/Users' || this.activePathName.includes('UserDetails')) && <div className={this.activePathName == '/Users' || this.activePathName.includes('UserDetails') ? 'activeBottom' : ''}>
                                    <div className={this.activePathName == '/Users' || this.activePathName.includes('UserDetails') ? 'activeBottomInner' : ''}>&nbsp;</div>

                                    </div>}
                                </div>
                                <div>
                                {IsMenuAllowed("flightboards") && this.activePathName == '/FlightBoards' &&
                                        <div className={this.activePathName == '/FlightBoards' ? 'activeTopDarkModeTheme' : ''}>
                                            <div className={this.activePathName == '/FlightBoards' ? 'activeTopInnerDarkModeTheme' : ''}>&nbsp;</div>

                                        </div>}
                                {IsMenuAllowed("flightboards") && ScreenValid('flightboards') && <MenuItem className={this.activePathName == '/FlightBoards' ? 'activeMenuDarkModeTheme' : 'menuText'}
                                        icon={<IoGlobeOutline />}
                                    >

                                        Maintenance <br />Control
                            <Link to="/FlightBoards" />
                                    </MenuItem>}
                                {IsMenuAllowed("flightboards") && this.activePathName == '/FlightBoards' && <div className={this.activePathName == '/FlightBoards' ? 'activeBottomDarkModeTheme' : ''}>
                                        <div className={this.activePathName == '/FlightBoards' ? 'activeBottomInnerDarkModeTheme' : ''}>&nbsp;</div>

                                    </div>}
                            </div>

                            <div>
                                {IsMenuAllowed("reporting") && (this.activePathName == '/Reporting' || this.activePathName.includes('DeferralReport') || this.activePathName.includes('ManpowerReport')) &&
                                    <div className={this.activePathName == '/Reporting' || this.activePathName.includes('DeferralReport') || this.activePathName.includes('ManpowerReport') ? 'activeTop' : ''}>
                                    <div className={this.activePathName == '/Reporting' || this.activePathName.includes('DeferralReport') || this.activePathName.includes('ManpowerReport') ? 'activeTopInner' : ''}>&nbsp;</div>

                                    </div>}
                                {IsMenuAllowed("reporting") && ScreenValid('reporting') && <MenuItem className={this.activePathName == '/Reporting' || this.activePathName.includes('DeferralReport') || this.activePathName.includes('ManpowerReport') ? 'activeMenu' : 'menuText'}
                                    icon={<IoTrendingUpOutline />}
                                >

                                    Reporting
                            <Link to="/Reporting" />
                                </MenuItem>}
                                {IsMenuAllowed("reporting") && (this.activePathName == '/Reporting' || this.activePathName.includes('DeferralReport') || this.activePathName.includes('ManpowerReport')) && <div className={this.activePathName == '/Reporting' || this.activePathName.includes('DeferralReport') || this.activePathName.includes('ManpowerReport') ? 'activeBottom' : ''}>
                                    <div className={this.activePathName == '/Reporting' || this.activePathName.includes('DeferralReport') || this.activePathName.includes('ManpowerReport') ? 'activeBottomInner' : ''}>&nbsp;</div>

                                </div>}
                            </div>

                            </Menu>
                        </SidebarContent>
                    </ProSidebar>
                }
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.dashBoard,
    DashBoardStore.actionCreators
)(withRouter(SideBar as any));

