import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as UserStore from '../store/UserAccountDetails';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid/dist/styles/ag-grid.css';
import 'ag-grid/dist/styles/ag-theme-balham.css';
import { RowSelectedEvent, RowClickedEvent } from 'ag-grid-community/dist/lib/events';
import { FaPlus } from 'react-icons/fa';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import Select from 'react-select';
import 'react-overlay-loader/styles.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { IoFunnelOutline, IoSearchOutline, IoAddOutline } from 'react-icons/io5';

//@ts-ignore
import _, { debounce } from 'lodash';

type userProps =
    UserStore.UserAccountDetailsState &
    typeof UserStore.actionCreators &
    RouteComponentProps<{}>;


class UserAccountDetails extends React.PureComponent<userProps>
{

    public componentDidMount() {
        this.ensureDataFetched();

    }



    private ensureDataFetched() {
        this.props.getUsers("", "");
    }

    onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    onGridSizeChanged = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    public redirectToAddUser() {
        this.props.history.push("/UserDetails");
    };

    public SelectRole(event: any) {
        this.props.getUsers(this.props.searchFilter, event.value)
    }

    public HandleSearchFilter(e: any) {
        if (this.props.searchFilter.length <= 2) {
            if (e.key === 'Enter') {
                this.props.getUsers(this.props.searchFilter, this.props.selectedRole)
            }
        }
    }
    searchClickHandler = () => {
        this.props.getUsers(this.props.searchFilter, this.props.selectedRole)
    }
    public setSearchFilter(e: any) {
        this.props.SetFilterValue(e.target.value);
        if (e.target.value.length == 0) {
            this.debouncedSearch(e.target.value);
        }
        else if (e.target.value.length > 2) {
            this.debouncedSearch(e.target.value);
        }
        else {
            this.props.SetFilterValue(e.target.value);
        }
    }
    debouncedSearch = debounce((query: any) => {
        this.props.getUsers(this.props.searchFilter, this.props.selectedRole)
    }, 2000);

    public render() {

        return (
            <React.Fragment>
                {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }

                <div className="row">

                    <Button id="mypopover" className="buttonFilter btnFilter leftMargin backGroundWhite"  ><IoFunnelOutline />

                        <span>&nbsp;&nbsp;Filter By</span> </Button>
                    <UncontrolledPopover
                        placement="bottom"

                        target="mypopover"
                        trigger="legacy"

                    >
                        <PopoverHeader>
                            Filter by access level
                        </PopoverHeader>
                        <PopoverBody>
                            <Select
                                className="dropdownSt filterDropdownWidth customSelect"
                                options={this.props.rolesList}
                                onChange={event => { this.SelectRole(event) }} placeholder="Choose access level"
                            />
                        </PopoverBody>
                    </UncontrolledPopover>
                    <span className="input-group input-group-search-filter flex-grow-1">
                        <input type="text" id="quickFilter" className="search-container input-group-text-search-container"
                            onChange={(event) => { this.setSearchFilter(event) }}
                            onKeyDown={(event) => { this.HandleSearchFilter(event) }}
                            placeholder="Search" />
                        <div className="input-group-append">
                            <span className="input-group-text input-group-text-search-filter"> <i><IoSearchOutline className="cursor-pointer" onClick={this.searchClickHandler} /></i></span>
                        </div>
                    </span>

                    <div className="userProfileButton add-user-button-width" onClick={(event) => { this.redirectToAddUser() }}><span className="add-user">ADD USER</span></div>

                </div>
                <br />
                <div className="row">
                    <div
                        className="col-12 ag-theme-balham"
                    >
                        <AgGridReact

                            onGridSizeChanged={this.onGridSizeChanged}
                            onGridReady={this.onGridReady}
                            enableSorting={true}
                            gridAutoHeight={true}
                            rowHeight={45}
                            detailRowHeight={45}
                            enableFilter={true}
                            pagination={false}
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.userRecords}
                            accentedSort={true}
                            onCellClicked={(events) => {
                                if (events && events.column && events.column.getColDef().field === "id") {
                                    console.log(events.value);
                                    console.log(events);
                                    const id = parseInt(events.value);
                                    console.log(id);
                                    const url = `/UserDetails/?id=${id}`;
                                    console.log(url);
                                    this.props.history.push(url);
                                }
                            }
                            }

                        >
                        </AgGridReact>
                    </div>
                </div>

            </React.Fragment>
        );
    }

};


export default connect((state: ApplicationState) => state.userAccountDetails,
    UserStore.actionCreators)(UserAccountDetails as any);