import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as ApprovalStore from '../store/Approval';
import { AgGridReact } from 'ag-grid-react';
import DatePicker from "react-datepicker";
import { FaCaretDown, FaRegCalendar} from 'react-icons/fa';
import { IoLogOutOutline, IoCheckmarkCircleSharp, IoSearchOutline, IoChevronDown} from 'react-icons/io5';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
//@ts-ignore
import _, { debounce } from 'lodash';

// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { ErrorModal } from './Shared/Modal/ErrorModal';
import { ConfirmationModal } from './Shared/Modal/ConfirmationModal';
//@ts-ignore
import JSZip from 'jszip';
//@ts-ignore
import JSZipUtils from 'jszip-utils';
//@ts-ignore
import saveAs from 'save-as';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {IoCloseOutline} from 'react-icons/io5'

type ApprovalProps =
    ApprovalStore.TimesheetApprovalState &
    typeof ApprovalStore.actionCreators &
    RouteComponentProps<{}>;

class Approval extends React.PureComponent<ApprovalProps> {
    gridApi:any
    public tickId: any = {};
    constructor(props: any) {
        super(props)
    }

    private tick() {       
        this.ensureDataFetched();       
    }

    public componentDidMount() {
        this.addAGGridHeaderStyle();
        this.ensureDataFetched();

        this.tickId = setInterval(
            () => this.tick(),
            60000*15
        );        
    }

    public componentWillUnmount() {       
        clearInterval(this.tickId);
    }
   public componentDidUpdate() {
        if (this.props.isReloadTimesheet) {
            this.ensureDataFetched();
        }
    }

    public setSearchFilter(e: any) {
        this.props.setFilterValue(e.target.value);
        if (e.target.value.length == 0) {
            this.debouncedSearch(e.target.value);
        }
        else if (e.target.value.length > 2) {
            this.debouncedSearch(e.target.value);
        }
        else {
            this.props.setFilterValue(e.target.value);
        }
    }
    debouncedSearch = debounce((query: any) => {
        this.ensureDataFetched();
    }, 2000);

    public HandleSearchFilter(e: any) {
        if (this.props.searchFilter.length <= 2) {
            if (e.key === 'Enter') {
                this.ensureDataFetched();
            }
        }
    }

    private ensureDataFetched() {
        this.props.GetApprovalList(this.props.selectedCheckOutDate, this.props.searchFilter);
    }

    AddNewTimesheet = () => {                
        const url = `/timeAndLabor`;        
        this.props.history.push({ pathname: url, state: { id: 0, selectedDate: this.props.selectedCheckOutDate }});
    }

    addAGGridHeaderStyle=()=> {
        let item = document.querySelectorAll('[class="ag-header-cell-text"][role="columnheader"]');
        for (let i = 0; i < item.length; i++) {
            if (item[i].textContent == 'Employee') {
                item[i].classList.add("approval-ag-grid-header-margin");
                break;
            }
        }
    }
    private ApproveTimesheet = () => {
        let approvalSelected = false;

        for (let index = 0; index < this.props.selectedTimeSheetForApproval.length; index++) {
            if (this.props.selectedTimeSheetForApproval[index]["statusId"] == 3) {
                approvalSelected = true;
                break;
            }
        }

        if (approvalSelected) {
            this.props.UpdateApprovedTimesheetSeleted(true);
        }
        else {
            this.props.UpdateApprovedConfirmation(true);            
        }
    }
    private ConfirmApproveTimesheet = () => {
        let timeSheet: any = [];
            this.props.selectedTimeSheetForApproval.forEach(function (selectedRow: any, index: any) {
                timeSheet.push(selectedRow.id);
            });
        console.log("approve timesheet---", timeSheet);
        if (timeSheet.length>0)
            this.props.ApproveTimesheet(timeSheet);
    }

    searchClickHandler = () => {
        this.ensureDataFetched();
    }
    onGridSizeChanged = (params: any) => {
        params.api.sizeColumnsToFit();
    }
    onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    }
    onSelectionChanged = (params: any) => {
        this.gridApi = params.api;
        var selectedRows = params.api.getSelectedRows();
        let timeSheet: any = [];
        selectedRows.forEach(function (selectedRow: any, index: any) {           
            timeSheet.push(selectedRow);
        });
        
        this.props.setSelectedTimeSheet(timeSheet);        
    };

    CustomInput = ({ value, onClick }: { value: any, onClick: any}) => (
        <div>
            <div className="calendar-custom-input approval-dateTime" onClick={onClick}>
                <FaRegCalendar /> &nbsp; &nbsp;{value}
                &nbsp;&nbsp; &nbsp;<IoChevronDown />
            </div>
        </div>
    );

    downloadTimesheets = (files: any) => {
        if (files.length == 0) return;
        if (files.length == 1) {
            var file = files.pop();
            const element = document.createElement("a");
            element.href = file;
            element.name = "myFile.xlsx";
            element.download = "myFile.xlsx";
            document.body.appendChild(element);

            element.click();
            element.remove();
        }
        else {
            const zip = new JSZip();
            let count = 0;
            const zipFilename = "Timesheets.zip";
            files.forEach(async function (url: any) {
                const urlArr = url.split('/');
                var filename = urlArr[urlArr.length - 1];
                filename = filename.split('?')[0];
                try {
                    const file = await JSZipUtils.getBinaryContent(url)
                    zip.file(filename, file, { binary: true });
                    count++;
                    if (count === files.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
    }

    ExportSelectedTimesheets() {
        let isAllTimesheetApproved = this.props.selectedTimeSheetForApproval.every((x: any) => x.statusId === 3);
        this.props.ShowExportPopup(isAllTimesheetApproved);
    }

    exportTimesheets() {
        let timeSheet: any = [];
        this.props.selectedTimeSheetForApproval.forEach(function (selectedRow: any, index: any) {
            timeSheet.push(selectedRow.id);
        });
        if (timeSheet.length > 0) {
            this.props.DownloadTimesheet(timeSheet, this.downloadTimesheets, this.props.selectedCheckOutDate);
        }
        this.gridApi.deselectAll();
    }

    renderTitle() {
        return (
            <div className="row padding-bottom-36 d-flex" style={{ border: '0px solid #000' }}>
                <div className="col-8 d-flex" style={{ border: '0px solid #000' }}>
                    <div className="timeandlabor-label">Team Timesheet Approvals</div>
                </div>

                <div className="col-4" style={{ border: '0px solid #000' }}>                    
                    <div className="text-align-center round-empty-button-approval addnewtimesheet-style" onClick={() => { this.AddNewTimesheet(); }}>ADD NEW TIMESHEET</div>
                </div>

            </div>
        );
    }
    
    renderHeader() {
        return (
            <div className="row padding-bottom-36 d-flex">
                <div className="col-5 d-flex">

                    <div className={"approvals-approve-button approvals-button-width " + (this.props.selectedTimeSheetForApproval.length == 0 ? " approvals-approve-button-disable" : "")} onClick={() => { this.ApproveTimesheet(); }}>
                        <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                        <span className="add-user">APPROVE</span>
                    </div>

                    <div className={"approvals-export-button  approvals-export-button-width " + (this.props.selectedTimeSheetForApproval.length == 0 ? " approvals-export-button-disable" : "")} onClick={() => { this.ExportSelectedTimesheets(); }}>
                        <i className="vector-noborder"><IoLogOutOutline /></i>
                        <span className="approval-export" >EXPORT</span>
                        </div>
                </div>

                <div className="col-3 text-right margin-right-minus-30" style={{ border: '0px solid #000' }}>
                    <DatePicker
                        selected={this.props.selectedCheckOutDate}
                        onChange={(date: Date) => this.props.GetApprovalList(date, this.props.searchFilter)}
                        minDate={new Date(new Date().setDate(new Date().getDate() - 180))}
                        maxDate={new Date()}                                            
                        customInput={(<this.CustomInput value='' onClick='' />)}
                    />
                </div>

                <div className="col-4" style={{ border: '0px solid #000' }}>                                            
                    <span className="approval-input-group flex-grow-1 " style={{ border: '0px solid #000' }}>
                        <input type="text" id="quickFilter" className="search-container-approval input-group-text-search-container flex-grow-1"
                            onChange={(event) => { this.setSearchFilter(event) }}
                            onKeyDown={(event) => { this.HandleSearchFilter(event) }}
                                placeholder="Search" />
                            <div className="input-group-append">
                                <span className="input-group-text input-group-text-search-filter">
                                    <i><IoSearchOutline className="cursor-pointer" onClick={this.searchClickHandler} /></i>
                                </span>
                            </div>
                        </span>                                            
                </div>                 
            </div>
        );
    }

    renderHeaderInTablet() {
        return (
            <div className="padding-bottom-36">
                <div className="row pb-3">
                    <div className="col-5">
                    <div className={"approvals-approve-button approvals-button-width " + (this.props.selectedTimeSheetForApproval.length == 0 ? " approvals-approve-button-disable" : "")} onClick={() => { this.ApproveTimesheet(); }}>
                        <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                        <span className="add-user">APPROVE</span>
                    </div>
                    </div>
                    <div className="col-6">
                        <div className={"approvals-export-button  approvals-export-button-width ml-0 " + (this.props.selectedTimeSheetForApproval.length == 0 ? " approvals-export-button-disable" : "")} onClick={() => { this.ExportSelectedTimesheets(); }}>
                        <i className="vector-noborder"><IoLogOutOutline /></i>
                        <span className="approval-export" >EXPORT</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-5 margin-right-minus-30" style={{ border: '0px solid #000' }}>
                    <DatePicker
                        selected={this.props.selectedCheckOutDate}
                        onChange={(date: Date) => this.props.GetApprovalList(date, this.props.searchFilter)}
                        minDate={new Date(new Date().setDate(new Date().getDate() - 180))}
                        maxDate={new Date()}
                        customInput={(<this.CustomInput value='' onClick='' />)}
                    />
                </div>

                <div className="col-6" style={{ border: '0px solid #000' }}>
                    <span className="approval-input-group flex-grow-1 " style={{ border: '0px solid #000' }}>
                        <input type="text" id="quickFilter" className="search-container-approval input-group-text-search-container flex-grow-1"
                            onChange={(event) => { this.setSearchFilter(event) }}
                            onKeyDown={(event) => { this.HandleSearchFilter(event) }}
                            placeholder="Search" />
                        <div className="input-group-append">
                            <span className="input-group-text input-group-text-search-filter">
                                <i><IoSearchOutline className="cursor-pointer" onClick={this.searchClickHandler} /></i>
                            </span>
                        </div>
                    </span>
                </div>
                </div>
               
            </div>
        );
    }

    renderApprovalListBody() {
        return (
            <div className="row">
                <div className="col-12 ag-theme-balham">
                    <AgGridReact
                        suppressRowClickSelection={true}
                        rowSelection={'multiple'}
                        onGridSizeChanged={this.onGridSizeChanged}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        gridAutoHeight={true}
                        rowHeight={55}
                        detailRowHeight={55}
                        enableFilter={true}
                        pagination={false}
                        columnDefs={this.props.columnDefs}
                        rowData={this.props.timesheetApprovalList}
                        accentedSort={true}
                        onSelectionChanged={this.onSelectionChanged}
                        onCellClicked={(events) => {
                            if (events && events.column && events.column.getColDef().field === "TimeSheetID") {
                                //console.log("grid click--", events);
                                //console.log(events.data);
                                const id = parseInt(events.data.id);
                                //console.log(id);
                                const url = `/timeAndLabor`;
                                //console.log(url);
                                this.props.history.push({ pathname: url, state: { id: id, selectedDate: this.props.selectedCheckOutDate } });
                            }
                        }
                        }
                    >
                    </AgGridReact>
                </div>
            </div>


        );
    }

    renderApprovalListBodyInTablet() {
        return (
            <div className="row">
                <div className="col-12 ag-theme-balham">
                    <AgGridReact
                        suppressRowClickSelection={true}
                        rowSelection={'multiple'}
                        onGridSizeChanged={this.onGridSizeChanged}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        gridAutoHeight={true}
                        rowHeight={55}
                        detailRowHeight={55}
                        enableFilter={true}
                        pagination={false}
                        columnDefs={this.props.columnDefsTablet}
                        rowData={this.props.timesheetApprovalList}
                        accentedSort={true}
                        onSelectionChanged={this.onSelectionChanged}
                        onCellClicked={(events) => {
                            if (events && events.column && events.column.getColDef().field === "TimeSheetID") {
                                //console.log("grid click--", events);
                                //console.log(events.data);
                                const id = parseInt(events.data.id);
                                //console.log(id);
                                const url = `/timeAndLabor`;
                                //console.log(url);
                                this.props.history.push({ pathname: url, state: { id: id, selectedDate: this.props.selectedCheckOutDate } });
                            }
                        }
                        }
                    >
                    </AgGridReact>
                </div>
            </div>


        );
    }

    public render() {

        console.log("Approval component:", this.props);
        return (
            <React.Fragment>

                {(this.props.isLoading) &&
                   <Loader fullPage={true} loading={this.props.isLoading} />
                }

                <Modal centered={true} className="smallModal" isOpen={this.props.showExportPopup}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseExportPopup(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        <div className="flex flex-center w-100">
                            {this.props.isAllTimesheetApproved ? "Are you sure?" :"Unable to Export"}
                        </div>
                    </ModalHeader>
                    <ModalBody className="text-center">
                        {this.props.isAllTimesheetApproved ? "":"Only Approved timesheets can be exported."}
                    </ModalBody>
                    <ModalFooter>
                        {this.props.isAllTimesheetApproved &&
                            <div className="flex flex-center w-100">
                                <button className="round-empty-button" type="reset" onClick={() => { this.props.CloseExportPopup(); }}>
                                   NO
                                </button>
                            <span>&nbsp;</span>
                            <button style={{ padding: "12px 39px" }} className="round-fill-button-2 border-0 model-btn-margin"
                                    onClick={() => { { this.exportTimesheets(); } }}>
                                    YES
                                </button>
                            </div>
                        }
                        { !this.props.isAllTimesheetApproved &&
                            <div className="flex flex-center w-100">
                                <button className="round-fill-button border-0 w-100 text-center"
                                onClick={() => { this.props.CloseExportPopup(); }}>
                                    OK
                                </button>
                            </div>
                        }
                    </ModalFooter>
                </Modal>

                <div className="row">
                    <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                </div>
                <ErrorModal
                    title={'Invalid Selection'}
                    message={'Only Pending timesheets are eligible for approval.'}
                    headerStyleClass={'diff-hours-modal'}
                    isOpen={this.props.isShowApprovedTimesheetModel}
                    onClose={() => { this.props.UpdateApprovedTimesheetSeleted(false) }}
                />

                <ConfirmationModal
                    title={'Are you sure?'}
                    message={'Selected timesheet will be approved.'}
                    confirmButtonText={'YES, APPROVE'}
                    isOpen={this.props.isShowApproveConfirmationModel}
                    onClose={() => { this.props.UpdateApprovedConfirmation(false) }}
                    onConfirm={() => { this.ConfirmApproveTimesheet()}}
                />


                {this.renderTitle()}
                <div className="hide-from-tablet">
                    {this.renderHeader()}
                </div>
                <div className="hide-in-big-screen show-in-tablet">
                    {this.renderHeaderInTablet()}
                </div>
                <div className="hide-from-tablet">
                    {this.renderApprovalListBody()}
                </div>
                <div className="hide-in-big-screen show-in-tablet">
                    {this.renderApprovalListBodyInTablet()}
                </div>
                
                

            </React.Fragment>);
    }
}
export default connect(
    (state: ApplicationState) => state.approval,
    ApprovalStore.actionCreators
)(Approval as any);
