import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as TraxFileStore from '../store/TraxFile';
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uploadIcon from '../upload.png';
import { NavLink } from 'react-router-dom';
// @ts-ignore
import { Loader } from 'react-overlay-loader';

import 'react-overlay-loader/styles.css';

type TraxFileProps =
    TraxFileStore.TraxFileState &
    typeof TraxFileStore.actionCreators &
    RouteComponentProps<{}>;

class TraxFile extends React.PureComponent<TraxFileProps> {

    public intervalID: any = {};

    public componentDidMount() {
        this.ensureDataFetched();

        this.intervalID = setInterval(this.ensureDataFetched.bind(this), 30000);
        //setInterval(this.ensureDataFetched, 120000);
    }

    componentWillUnmount() {
        /*
          stop getData() from continuing to run even
          after unmounting this component
        */
        clearInterval(this.intervalID);
    }

    public render() {
        return (
            <React.Fragment>
                    <p></p>
                {this.props.loading && <b>loading...</b>}
                {(this.props.loading) &&
                    <Loader fullPage={true} loading={this.props.loading} />
                }
                <ToastContainer toastClassName="info" position="bottom-right" hideProgressBar={true} />
                <div className="buttonBar">
                    <nav className="nav nav-pills nav-fill">
                        <NavLink className="nav-link active" to="/traxfile"><span className="traxfile-step">Trax</span></NavLink>
                        <a className="nav-link disabled" href="#"><span className="traxfile-step">Global</span></a>
                        <a className="nav-link disabled" href="#"><span className="traxfile-step">Production</span></a>
                </nav>
                </div>
                <br /><br />
                <div className="container container-table">
                    <div className="row vertical-center-row uploadContainer">
                        <div className="text-center col-12 ">
                            <p className="uploadFileLabel">Upload your files</p>
                           <p className="lightText"> Files should be Excel documents </p>
                        </div>
                        <div className="text-center col-12 dropzone">

                            <Dropzone
                                multiple={false}
                                accept=".xlsx, .xls"
                                onDrop={event => { this.props.select(event); }}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div {...getRootProps({ className: 'traxfile-dropzoneInner' })}>
                                            <input {...getInputProps()} />
                                            <img src={uploadIcon} className="uploadFile-icon" />
                                            <p className="lightText" >Drag 'n' drop the trax file here</p>
                                            <p className="lightText">OR</p>
                                            <p className="lightText orang" >Browse files</p>

                                        </div>
                                        <aside>
                                            {this.props.selectedFile && <div>
                                                <p>{
                                                    this.props.selectedFile.name

                                                }</p>
                                            </div>}


                                        </aside>
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                </div>

                

                <p></p>
 

                {this.props.requests && this.props.requests.length>0 && this.renderRequestsTable()}

            </React.Fragment>
        );
    }

    private renderRequestsTable() {
        return (
            <React.Fragment>
            <p>Last Few Trax Uploads </p>
            <table className='table tbborder' aria-labelledby="tabelLabel">
                <thead className='thead-light'>
                    <tr>
                        <th scope='col'>Name</th>
                        <th scope='col'>Time (Zulu)</th>
                        <th scope='col'>Author</th>
                        <th scope='col'>Status</th>
                    </tr>
                </thead>
                <tbody>
                        {this.props.requests.map((request: TraxFileStore.TraxFileRequests) => {
                            let classStyle = request.statusString == 'Processed' ? 'trax-grid-status-process'
                                             : request.statusString == 'Failed' ? 'trax-grid-status-failed':'';
                            let classStyletext = classStyle!=''? classStyle+'-text':'';
                            return <tr key={request.id}>
                                <td>{request.uploadedFileName}</td>
                                <td>{request.dateString}</td>
                                <td>{request.uploadedBy}</td>
                                <td>
                                    <div className={classStyle}>
                                        <span className={classStyletext}>{request.statusString}</span>
                                        
                                    </div>
                                </td>
                            </tr>
                        }
                    )}
                </tbody>
                </table>
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.fetachRequest();
    }
};

export default connect(
    (state: ApplicationState) => state.traxFile,
    TraxFileStore.actionCreators
)(TraxFile as any);
