import * as React from 'react';
import winIcon from '../winLogo.png';
import logo from '../feam-logo.png';
import { withRouter, RouteComponentProps, Redirect, RouteProps } from 'react-router';
import { ApplicationState } from '../store';
import * as HomeStore from '../store/Home';
import { connect } from 'react-redux';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';

interface IProps extends RouteComponentProps<any> {
    auth: any,
    onSignIn: any,
}

type HomeProps =
    HomeStore.HomeState &
    typeof HomeStore.actionCreators &
    RouteComponentProps<{}> 

class Home extends React.Component<RouteProps & HomeProps & IProps> {


    componentDidMount() {
        if (this.props.auth && this.props.auth.isAuthenticated) {
            this.props.fetachUser();
        }
    }

    

    public render() {
     
       
        return (
            <>
              
                {this.props.isLoading &&
                    <Loader fullPage={true} className="overlay" loading={this.props.isLoading} />

                }

            {(this.props.auth && this.props.auth.isAuthenticated) && this.props.user !== null &&
                    <Redirect to="/station" />
                }

                {(this.props.auth && this.props.auth.isAuthenticated) && !this.props.isLoading && this.props.user === null &&
                    <div className="centerContent" >
                    <br/><br/>
                                    You are not allowed to access this resource. Kindly contact your IT team.
                                    <br/><br/>
                      
                    </div >
                }

                {!(this.props.auth && this.props.auth.isAuthenticated) &&
                    <div className="centerContent aircraftbg col-12">
                    <div className="child centerBox col-lg-6">
                            <div className="container">


                                <div className="row p-5">
                                    <div className="col">
                                        <img src={logo} width="224" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col h1">
                                        Welcome Back
                        </div>
                                </div>

                                <div className="row">
                                    <div className="col h4">
                                        Login to your account
                        </div>
                                </div>


                                <div className="row padding5">
                                    <div className="col">
                                        <div className="divBtn" onClick={this.props.onSignIn} >
                                            <img src={winIcon} width="40" />&nbsp;&nbsp;
                                    Sign in with Microsoft
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                }

              

            </>);
    }
}

// @ts-ignore
export default connect(
    (state: ApplicationState) => state.home,
    HomeStore.actionCreators
)(withRouter(Home as React.ComponentClass<any,any>));
