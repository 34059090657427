import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { FaPlus } from 'react-icons/fa';
import App from '../App';


type gridColumns = {
    headerName: string;
    field: string;
    minWidth: number;
    width: number;
    cellRenderer: any;
    cellClass: string;
    unSortIcon: boolean;
    suppressSorting: boolean;
    suppressFilter: boolean;
}
var gridColumnHeaders: gridColumns[] = [
    { headerName: "Id", field: "strUserId", minWidth: 60, width: 60, cellRenderer: "", cellClass: 'gridText', unSortIcon: true, suppressSorting: false, suppressFilter: false},
   
    {
        headerName: "Name", field: "userName", minWidth: 210, width: 210, cellClass: 'gridText', unSortIcon: true, suppressSorting: false, suppressFilter: false,
        cellRenderer: function (params: any) {

            return `<Img src=${params.data.imageDisplayURI} style="border-radius:50%; width:25px;" /> &nbsp; ${params.data.userName}`
        }
        },
    {
        headerName: "Email", field: "email", minWidth: 300, width: 400, cellRenderer: "", cellClass: 'gridText', unSortIcon: true, suppressSorting: false,
        suppressFilter: false
    },
    { headerName: "Access Level", field: "roleDescription", minWidth: 150, width: 150, cellRenderer: "", cellClass: 'gridText', unSortIcon: true, suppressSorting: false, suppressFilter: false},
    {
        headerName: "See more", field: "id", minWidth: 100, width: 115,
        cellClass: 'gridText',
        unSortIcon: false,
        suppressSorting: true,
        suppressFilter: true,
        cellRenderer: function (params: any) {
            return '<span class="cursor-pointer" style="margin-left: 30px;"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="cursor-pointer" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144"></path></svg></span>'
        },

    }
];

export interface UserAccountDetailsState {
    quickFilterText: string;
    userRecords: UserDetailRequests[];
    isLoading: boolean;
    selectedUser: any;
    isUserSelected: boolean;
    columnDefs: gridColumns[];
    rolesList: { label: string, value: string }[];
    selectedRole: string;
    searchFilter: string;
}

export interface fieldObject {
    groupBy: string;
    text: string;
    value: number;
}


export interface UserDetailRequests {
    userId: number
    email: string;
    userName: string;
    roleDescription: string;
    strUserId: string;
}
export interface Result {
    userRecords: UserDetailRequests[],
    rolesList: { label: string, value: string }[];
}




export interface RequestUserDetailsAction { type: 'REQUEST_USERS', selectedRole: string, searchFilter: string }
export interface ReceiveUserDetailsAction {
    type: 'RECEIVE_USERS'; userRecords: UserDetailRequests[], selectedUser: any, roles: { label: string, value: string }[],
    
}
export interface UpdateSearchText {type: 'UPDATE_SEARCH', searchTerm: string}
export interface EnableLoading {
    type: 'ENABLE_LOADING'
}


export type KnownAction = EnableLoading|RequestUserDetailsAction | ReceiveUserDetailsAction | UpdateSearchText;

export const actionCreators = {
    SetFilterValue: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('hitting');
        dispatch({type:'UPDATE_SEARCH', searchTerm:event})
    },
    getUsers: (filterText: string,role: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        fetch(`api/users?filter=${filterText}&role=${role}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response =>
                response.json() as Promise<Result>
            )
            .then(data => {
                console.log(data)
                dispatch({
                    type: 'RECEIVE_USERS',
                    userRecords: data.userRecords, selectedUser: null, roles: data.rolesList
                });
            });

        dispatch({ type: 'REQUEST_USERS', selectedRole: role, searchFilter: filterText});
    },
    setLoader: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ENABLE_LOADING' })
    },
}

export const reducer: Reducer<UserAccountDetailsState> = (state: UserAccountDetailsState | undefined, incomingAction: Action): UserAccountDetailsState => {
    if (state === undefined) {
        return {
            quickFilterText: "",
            userRecords: [], isLoading: false,
            selectedUser: null, isUserSelected: false,
            columnDefs: gridColumnHeaders,
            rolesList: [],
            selectedRole: "", searchFilter: ""
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_USERS':
            return {
                quickFilterText: action.searchFilter,
                userRecords: [], isLoading: true,
                selectedUser: state.selectedUser, isUserSelected: state.isUserSelected,
                columnDefs: gridColumnHeaders,
                rolesList: [],
                selectedRole: action.selectedRole, searchFilter: action.searchFilter
            };
        case 'RECEIVE_USERS':            
            return {
                quickFilterText: state.quickFilterText,
                userRecords: action.userRecords, isLoading: false,
                selectedUser: action.selectedUser, isUserSelected: state.isUserSelected,
                columnDefs: gridColumnHeaders,
                rolesList: action.roles,
                selectedRole: state.selectedRole, searchFilter: state.searchFilter
            };
        case 'UPDATE_SEARCH':
            return {
                quickFilterText: state.quickFilterText,
                userRecords: state.userRecords, isLoading: false,
                selectedUser: state.selectedUser, isUserSelected: state.isUserSelected,
                columnDefs: state.columnDefs,
                rolesList: state.rolesList,
                selectedRole: state.selectedRole, searchFilter: action.searchTerm
            };
        case 'ENABLE_LOADING':
            return {
                quickFilterText: state.quickFilterText,
                userRecords: state.userRecords, isLoading: true,
                selectedUser: state.selectedUser, isUserSelected: state.isUserSelected,
                columnDefs: state.columnDefs,
                rolesList: state.rolesList,
                selectedRole: state.selectedRole, searchFilter: state.searchFilter
            };
        default:
            return state;
    }
}
