import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as CrewDebrief from '../store/CrewDebrief';
import { IoTrashOutline, IoCloseOutline  } from 'react-icons/io5';
import Dropzone from 'react-dropzone';
import uploadIcon from '../upload.png';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, NavLink } from 'reactstrap';
import ImageCropper from './ImageCropper';


type CrewDebriefProps =
    CrewDebrief.CrewDebriefState &
    typeof CrewDebrief.actionCreators &
    RouteComponentProps<{
    }> &
    IProps;

interface IProps {
    tailRecordId: number;
    stepNumber: number;
    isEditable: boolean;
    callStep: any;
    // any other props that come into the component
}

class CrewDeBrief extends React.PureComponent<CrewDebriefProps> {

    //
    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate(prevProps: any) {
        //   this.ensureDataFetched();
        if ((prevProps.tailRecordId !== this.props.tailRecordId) ||
            (prevProps.stepNumber !== this.props.stepNumber)) {
            this.ensureDataFetched();
        }
    }

    private ensureDataFetched() {
        console.log(this.props.tailRecordId);
        console.log(this.props.stepNumber);
        this.props.fetchImages(this.props.tailRecordId, this.props.stepNumber);
    }

    public render() {
        return (
            <React.Fragment>


                {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }
                <div className="row">
                    <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                </div>
                {this.props.selectedImage &&
                    <Modal className="" centered={true} isOpen={this.props.imageOpen}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.selectImage(null); }}
                                    ><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="flex flex-center w-100">
                                {this.props.selectedImage.docName}
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <ImageCropper key="pop1"
                                imgId={this.props.selectedImage.id}
                                uploadImage={this.props.uploadImage}
                                closePopUp={this.props.selectImage} imgSrc={this.props.selectedImage.imageDisplayURI} />

                        </ModalBody>
                        <ModalFooter >
                            <div className="flex flex-button w-100">

                            </div>
                        </ModalFooter>
                    </Modal >
                }

                {this.props.images &&
                    <Modal className="smallModal" centered={true} isOpen={this.props.confirmDelete}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                    onClick={() => { this.props.toggleConfirmDelete(0); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                        <div className="flex centerContent w-100">
                                Are you sure?
                            </div>
                        </ModalHeader>
                        <ModalBody>
                        <div className="flex centerContent w-100">
                                Your photo will be deleted
                            </div>
                        </ModalBody>
                        <ModalFooter >
                            <div className="flex flex-button w-100">
                                <div className="round-empty-button-popup" onClick={() => { this.props.toggleConfirmDelete(0); }} >
                                    NO, CANCEL
                                </div>
                                &nbsp;
                            <div className="round-fill-button-popup model-btn-margin" onClick={() => { this.props.deleteImage(); }}>
                                    YES, DELETE
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal >
                }


                <div>
                    <br />
                    {this.props.images === null || this.props.images.length === 0 && this.props.isEditable
                        &&
                        <div className="text-center col-12 dropzone">

                            <div className="text-center col-md-4 col-xs-12 dropzone flCol">
                                <div className="uploadText">
                                    Upload logbook photos
                                <br />
                                </div>
                                <Dropzone
                                    multiple={true}
                                    accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
                                    onDrop={event => { this.props.select(event); }}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className="flex" >
                                            <div {...getRootProps({ className: 'dropzoneInner' })}>
                                            <input {...getInputProps()} />
                                            <img src={uploadIcon} className="worksheet-uploadFile-icon" />
                                                <p className="lightTextsm showInMax" >Drag 'n' drop your files here</p>
                                                <p className="lightTextsm showInMax">OR</p>
                                                <p className="lightTextsm orang" >Browse files</p>

                                            </div>

                                        </section>
                                    )}
                                </Dropzone>

                            </div>
                        </div>

                    }
                    {this.props.images && this.props.images.length > 0 && this.props.stepNumber === 1 &&
                        <div className="mxButton  padding-bottom-10">
                        <div className="round-fill-button-2" onClick={() => { this.props.callStep(2, true) }} >Send Report</div>
                        <br/>
                        </div>
                    }
                    {this.props.images && this.props.images.length > 0 &&
                        <div className="outerDivMxR smhidden">
                            <div>
                                <div className="flex flex-space-between headerMxR">
                                    <div className="planningSubTitle w-20">Photo</div>
                                    <div className="planningSubTitle w-30">Name</div>
                                    <div className="planningSubTitle w-20">Upload Time</div>
                                    <div className="planningSubTitle w-15">Author</div>
                                    {this.props.isEditable && <div className="planningSubTitle w-10">Delete</div>}
                                </div>
                            </div>
                            <div >
                                {this.props.images.map((file: any) =>
                                    <div key={file.id} className="flex flex-space-between rowMxR">
                                        <div className="w-20">
                                            <img className="cursor-pointer" onClick={() => { this.props.selectImage(file); }} src={file.imageDisplayURI + "&t=" + new Date().getTime()} width="42px" />
                                        </div>
                                        <div className="w-30">{file.docName}</div>
                                        <div className="w-20">
                                            {file.lastModifiedAtString} </div>
                                        <div className="w-15">{file.user.userName}</div>
                                        {this.props.isEditable && <div className="w-10">
                                            <IoTrashOutline className="cursor-pointer" onClick={() => { this.props.toggleConfirmDelete(file.id) }} />
                                        </div>}
                                    </div>
                                )}
                                <div >
                                    <Dropzone
                                        multiple={true}
                                        accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
                                        onDrop={event => { this.props.select(event); }}>

                                        {({ getRootProps, getInputProps }) => (
                                            <section className="w-100">
                                            {this.props.isEditable && <div {...getRootProps({ className: 'fullFooterButton flex flex-center' })}>
                                                <input {...getInputProps()} />
                                                + ADD ADDITIONAL FILES

                                            </div>}

                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    }
                    {this.props.images && this.props.images.length > 0 &&

                        <div className="outerDivMxR smdisplay">

                            {this.props.images.map((file: any) =>
                                <div className="" key={file.id}>
                                    <br />
                                    <div className="flex flex-space-between rowMxR">
                                        <div className="mobileLeftTitle">Photo</div>
                                        <div className="w-50"> <img className="cursor-pointer" onClick={() => { this.props.selectImage(file); }}
                                            src={file.imageDisplayURI + "&t=" + new Date().getTime()} width="42px" /></div>
                                    </div>
                                    <div className="flex flex-space-between rowMxR">
                                        <div className="mobileLeftTitle">Name     </div>
                                        <div className="w-50">{file.docName}</div>
                                    </div>
                                    <div className="flex flex-space-between rowMxR">
                                        <div className="mobileLeftTitle">Upload Time     </div>
                                        <div className="w-50">{file.lastModifiedAtString}</div>
                                    </div>
                                    <div className="flex flex-space-between rowMxR">
                                        <div className="mobileLeftTitle">Author     </div>
                                        <div className="w-50">{file.user.userName}</div>
                                    </div>
                                    {this.props.isEditable && <div className="flex flex-space-between rowMxR">
                                        <div className="mobileLeftTitle">Delete    </div>
                                        <div className="w-50"><IoTrashOutline className="cursor-pointer"
                                            onClick={() => { this.props.toggleConfirmDelete(file.id) }}
                                        /></div>
                                    </div>}

                                </div>
                            )}
                            <div >
                                <Dropzone
                                    multiple={true}
                                    accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
                                    onDrop={event => { this.props.select(event); }}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className="w-100">
                                        {this.props.isEditable && <div {...getRootProps({ className: 'fullFooterButton flex flex-center' })}>
                                            <input {...getInputProps()} />
                                                + ADD ADDITIONAL FILES

                                            </div>}

                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.crewdebrief,
    CrewDebrief.actionCreators
)(CrewDeBrief as any);
