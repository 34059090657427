import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

export interface ArrivalDetailsRequest {
    acOwner: string;
    logoURI: string;
    tailNumber: string;
    tailRecordId: number;
    arrivalTime: string;
    arrivalStation: string;
    arrivalStationDetails: string;
    arrivalStatus: string;
    stationArrived: string;
    arrivalDateTime: string;
    departureTime: string;
    departureDateTime: string;
    arrivalEstimatedDepartureTimeToolTip: string;
    arrivalEstimatedDepartureDateToolTip: string;
    flightStatusId: number;
    isBlockedOutStatus: boolean;
}

export interface DeparturesDetailsRequest {
    acOwner: string;
    logoURI: string;
    tailNumber: string;
    tailRecordId: number;
    departureTime: string;
    departingStationName: string;
    departureStationDetails: string;
    departureStatus: string;
    departureColorCode: string;
    departureNotes: string;
    departureDateTime: string;
    gate: string;
    groundTimeMinuteDisplayValue: string;
    flightStatusId: number;
    isBlockedOutStatus: boolean;
    blockedOutTime: string;
    blockedOutDateTime: string;
    manuallyBlockedOut: boolean;
}
export interface GroundDetailsRequest {
    tailNumber: string;
    tailRecordId: number;
    fromGroundTime: string;
    toGroundTime: string;
    arrivalDateTime: string;
    departureDateTime: string;
    departingStationName: string;
    flightStatusId: number;
    departureNotes: string;
}

export interface FlightDetailsState {
    arrivalDetails: ArrivalDetailsRequest[];
    departureDetails: DeparturesDetailsRequest[];
    groundTimeDetails: GroundDetailsRequest[];
    customerDetails: any[];
    isLoading: boolean;
    isColorsLegend: boolean;
    isInformation: boolean;
    isZulu: boolean;
    time: number;
    location: string;
    isPopoverOpen: boolean;
    currentTime: string;
    customerIds: number[];
    currentDate: string;
}

export interface UpdateTimeAction {
    type: 'UPDATE_TIME'
}
export interface UpdateTimeFilterAction {
    type: 'UPDATE_TIME_FILTER', time: number
}
export interface UpdateCustomerIdsFilterAction {
    type: 'UPDATE_CUSTOMER_IDS_FILTER', customerIds: number[]
}
export interface OpenPopOverAction {
    type: 'TOGGLE_POP_OVER'
}
export interface CloseFilterPopOverAction {
    type: 'CLOSE_FILTER_POP_OVER'
}
export interface CloseInformationDetailsAction {
    type: 'CLOSE_INFORMATION'
}
export interface CloseLegendDetailsAction {
    type: 'CLOSE_COLORS_LEGEND'
}
export interface CloseFilterAction {
    type: 'CLOSE_FILTER'
}
export interface ShowInformationDetailsAction {
    type: 'SHOW_INFORMATION'
}
export interface ShowLegendDetailsAction {
    type: 'SHOW_COLORS_LEGEND'
}

export interface UpdateZuluDetailsAction {
    type: 'UPDATE_ZULU_INFO'; isZulu: boolean
}

export interface ReceiveFlightDetailsAction {
    type: 'RECEIVE_FLIGHT_DETAILS'; arrivalDetails: ArrivalDetailsRequest[], departureDetails: DeparturesDetailsRequest[]
    groundTimeDetails: GroundDetailsRequest[], customerDetails: any[], customerIds: number[];
}
export interface RequestFlightDetailsAction {
    type: 'REQUEST_FLIGHT_DETAILS'; isZulu: boolean, time: number,
    location: string, customerIds: number[]
}

export interface UpdateLocation {
    type: 'UPDATE_LOCATION', location:string
}
export type KnownAction = CloseFilterAction | UpdateCustomerIdsFilterAction | UpdateTimeFilterAction | UpdateTimeAction | CloseFilterPopOverAction | OpenPopOverAction | RequestFlightDetailsAction | ReceiveFlightDetailsAction | CloseInformationDetailsAction | CloseLegendDetailsAction
    | ShowInformationDetailsAction | ShowLegendDetailsAction | UpdateZuluDetailsAction | UpdateLocation;

export const actionCreators = {
    getFlightDetails: (time: number, location: string, isZulu: boolean, customerIds: number[], customers: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        fetch(`api/flights?isZulu=${isZulu}&time=${time}&location=${location}
                           &customers=${customers}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response =>
                response.json() as Promise<FlightDetailsState>
            )
            .then(data => {
                console.log(data.departureDetails)
                dispatch({
                    type: 'RECEIVE_FLIGHT_DETAILS',
                    arrivalDetails: data.arrivalDetails,
                    departureDetails: data.departureDetails,
                    groundTimeDetails: data.groundTimeDetails,
                    customerDetails: data.customerDetails,
                    customerIds: customerIds
                });
            });

        dispatch({
            type: 'REQUEST_FLIGHT_DETAILS',
            time: time, location: location, isZulu: isZulu, customerIds: customerIds
        });
    },

    CloseInformation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_INFORMATION' })
    },
    CloseFilter: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_FILTER' })
    },
    CloseColorLegend: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_COLORS_LEGEND' })
    },
    ShowColorLegend: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_COLORS_LEGEND' })
    },
    ShowInformation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_INFORMATION' })
    },

    ChangeZulu: (isZulu: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_ZULU_INFO', isZulu: isZulu })
    },
    TogglePopOver: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_POP_OVER' })
    },
    ClosePopOver: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_FILTER_POP_OVER' })
    },
    UpdateTime: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_TIME' })
    },
    UpdateTimeFilter: (time: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_TIME_FILTER', time: time })
    },
    UpdateLocation: (location:string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_LOCATION', location })
    },
    UpdateCustomerIdsFilter: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (appState && appState.flightBoards) {
            if (appState.flightBoards.customerIds.some(n => n === customerId)) {
                appState.flightBoards.customerIds.splice(appState.flightBoards.customerIds.indexOf(customerId), 1);
                console.log(appState.flightBoards.customerIds);
            }
            else {
                appState.flightBoards.customerIds.push(customerId);
            }
            dispatch({ type: 'UPDATE_CUSTOMER_IDS_FILTER', customerIds: appState.flightBoards.customerIds })

        }
    },

}


export const reducer: Reducer<FlightDetailsState> = (state: FlightDetailsState | undefined, incomingAction: Action): FlightDetailsState => {
    if (state === undefined) {
        //console.log(new Date().toString())
        return {
            arrivalDetails: [],
            departureDetails: [],
            groundTimeDetails: [],
            isLoading: true,
            isColorsLegend: false,
            isInformation: false,
            isZulu: false,
            location: "",
            time: 12,
            isPopoverOpen: false,
            currentTime: new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) + ' ' + new Date().toLocaleDateString('en-GB', {
                day: 'numeric', month: 'short'
            }).replace(/ /g, '-'),
            customerDetails: [],
            customerIds: [],
            currentDate: new Date().toString()
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_FLIGHT_DETAILS':
            return {
                arrivalDetails: [],
                departureDetails: [],
                groundTimeDetails: [],
                isLoading: true,
                isColorsLegend: false,
                isInformation: false,
                isZulu: action.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: false,
                currentTime: state.currentTime,
                customerDetails: [],
                customerIds: action.customerIds,
                currentDate: state.currentDate
            };
        case 'RECEIVE_FLIGHT_DETAILS':
            return {
                arrivalDetails: action.arrivalDetails,
                departureDetails: action.departureDetails,
                groundTimeDetails: action.groundTimeDetails,
                isLoading: false,
                isColorsLegend: false,
                isInformation: false,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: false,
                currentTime: state.currentTime,
                customerDetails: action.customerDetails,
                customerIds: action.customerIds,
                currentDate: state.currentDate
            };
        case 'CLOSE_COLORS_LEGEND':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: false,
                isColorsLegend: false,
                isInformation: state.isInformation,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: false,
                currentTime: state.currentTime,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'CLOSE_INFORMATION':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: false,
                isColorsLegend: state.isColorsLegend,
                isInformation: false,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: false,
                currentTime: state.currentTime,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'CLOSE_FILTER':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: state.isLoading,
                isColorsLegend: state.isColorsLegend,
                isInformation: state.isInformation,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: false,
                currentTime: state.currentTime,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'SHOW_COLORS_LEGEND':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: false,
                isColorsLegend: true,
                isInformation: state.isInformation,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: false,
                currentTime: state.currentTime,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'SHOW_INFORMATION':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: false,
                isColorsLegend: state.isColorsLegend,
                isInformation: true,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: false,
                currentTime: state.currentTime,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };

        case 'UPDATE_ZULU_INFO':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: state.isLoading,
                isColorsLegend: state.isColorsLegend,
                isInformation: state.isInformation,
                isZulu: action.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: true,
                currentTime: state.currentTime,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'TOGGLE_POP_OVER':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: state.isLoading,
                isColorsLegend: state.isColorsLegend,
                isInformation: state.isInformation,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: !state.isPopoverOpen,
                currentTime: state.currentTime,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'CLOSE_FILTER_POP_OVER':
            return {
                ...state,
                isPopoverOpen: false,
            };
        case 'UPDATE_TIME':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: state.isLoading,
                isColorsLegend: state.isColorsLegend,
                isInformation: state.isInformation,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: state.isPopoverOpen,
                currentTime: new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) + ' ' + new Date().toLocaleDateString('en-GB', {
                    day: 'numeric', month: 'short'
                }).replace(/ /g, '-'),
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'UPDATE_TIME_FILTER':
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: state.isLoading,
                isColorsLegend: state.isColorsLegend,
                isInformation: state.isInformation,

                isZulu: state.isZulu,
                location: state.location,
                time: action.time,
                isPopoverOpen: state.isPopoverOpen,
                currentTime: new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) + ' ' + new Date().toLocaleDateString('en-GB', {
                    day: 'numeric', month: 'short'
                }).replace(/ /g, '-'),
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                currentDate: state.currentDate
            };
        case 'UPDATE_CUSTOMER_IDS_FILTER':
            console.log(action.customerIds)
            return {
                arrivalDetails: state.arrivalDetails,
                departureDetails: state.departureDetails,
                groundTimeDetails: state.groundTimeDetails,
                isLoading: state.isLoading,
                isColorsLegend: state.isColorsLegend,
                isInformation: state.isInformation,
                isZulu: state.isZulu,
                location: state.location,
                time: state.time,
                isPopoverOpen: state.isPopoverOpen,
                currentTime: new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) + ' ' + new Date().toLocaleDateString('en-GB', {
                    day: 'numeric', month: 'short'
                }).replace(/ /g, '-'),
                customerDetails: state.customerDetails,
                customerIds: action.customerIds,
                currentDate: state.currentDate
            };
        case 'UPDATE_LOCATION':
            return {
                ...state,
                location:action.location
            };
        default:
            return state;
    }
}