import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { toast } from 'react-toastify';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CrewDebriefState {
    isLoading: boolean;
    images: any[];
    tailId: number;
    confirmDelete: boolean;
    selectedId: number;
    selectedImage: any;
    imageOpen: boolean;
    step: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface RequestImageAction { type: 'REQUEST_IMAGES', tailRecordId: number, step: number  }
export interface RecieveImageAction { type: 'RECIEVE_IMAGES', images: any [] }
export interface UploadImageAction { type: 'UPLOAD_IMAGE', isLoading: boolean }
export interface ToggleConfirmStartAction { type: 'TOGGLE_CONFIRM_DEL', toggle: boolean, selectedId: number }
export interface ToggleImageAction { type: 'TOGGLE_IMAGE', file: any, toggleImg: boolean }
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestImageAction | RecieveImageAction | UploadImageAction | ToggleConfirmStartAction | ToggleImageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    uploadImage: (blob: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('from action');
        console.log(blob);

        const appState = getState();
        if (appState && appState.crewdebrief) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('imageId', appState.crewdebrief.selectedImage.id.toString());
            data.append('tailId', appState.crewdebrief.tailId.toString());

            data.append('file', blob, appState.crewdebrief.selectedImage.docName);

            data.append('step', appState.crewdebrief.step.toString());


            axios.post(`api/WorkSheet/SaveWorkSheetImageML`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    dispatch({ type: 'RECIEVE_IMAGES', images: res });
                    toast.dismiss();
                    toast("image updated.");
                })
                .catch(error => dispatch({ type: 'UPLOAD_IMAGE', isLoading: false }))

            dispatch({ type: 'UPLOAD_IMAGE', isLoading: true });
        }
    },
    selectImage: (file: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('fileaction');
        console.log(file)
        const appState = getState();
        if (appState && appState.crewdebrief) {
            dispatch({ type: "TOGGLE_IMAGE", file: file, toggleImg: !appState.crewdebrief.imageOpen });
        }
    },
    deleteImage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crewdebrief && appState.crewdebrief.images) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('Id', appState.crewdebrief.selectedId.toString());


            axios.post(`api/WorkSheet/DeleteImage`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    console.log(res);

                    if (appState && appState.crewdebrief && appState.crewdebrief.images) {
                        const imgId = appState.crewdebrief.selectedId;
                        
                        const removeIndex = appState.crewdebrief.images.indexOf(appState.crewdebrief.images.find((n: any) => n.id === imgId));
                        console.log('index')
                        console.log(removeIndex);
                        ~removeIndex && appState.crewdebrief.images.splice(removeIndex, 1);
                        
                        dispatch({
                            type: 'RECIEVE_IMAGES',
                            images: appState.crewdebrief.images
                        });
                        toast.dismiss();
                        toast("image removed.");
                    }
                })
                .catch(error => dispatch({ type: 'UPLOAD_IMAGE', isLoading: false }))

            dispatch({ type: 'UPLOAD_IMAGE', isLoading: true });

        }

    },
    toggleConfirmDelete: (selectedId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crewdebrief) {
            dispatch({ type: 'TOGGLE_CONFIRM_DEL', toggle: !appState.crewdebrief.confirmDelete, selectedId: selectedId });
        }
    },
    fetchImages: (tailRecourdId: number, stepNumber: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');

        fetch(`api/WorkSheet/GetImagesByTailId?tailId=${tailRecourdId}&&step=${stepNumber}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
            .then(data => {
                dispatch({
                    type: 'RECIEVE_IMAGES',
                    images: data
                    });
            });

        dispatch({ type: 'REQUEST_IMAGES', tailRecordId:tailRecourdId, step: stepNumber });
    },
    select: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(event)
        if (event && event.length > 0) {
            const appState = getState();
            if (appState && appState.crewdebrief) {
                const token = sessionStorage.getItem('access_token_augur');
                const data = new FormData()
                data.append('tailId', appState.crewdebrief.tailId.toString());
                data.append('step', appState.crewdebrief.step.toString());
                for (let i = 0; i < event.length; i++) {
                    data.append('file', event[i], event[i].name);
                }



                axios.post(`api/WorkSheet/SaveWorkSheetImages`, data, {
                    headers: !token ? {} : {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.data as Promise<any>)
                    .then(res => { // then print response status
                        console.log(res);
                       
                        dispatch({ type: 'RECIEVE_IMAGES', images: res });
                        toast.dismiss();
                        toast("image updated.");
                    })
                    .catch(error => dispatch({ type: 'UPLOAD_IMAGE', isLoading: false }))

                dispatch({ type: 'UPLOAD_IMAGE', isLoading: true });
            }
        }
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<CrewDebriefState> = (state: CrewDebriefState | undefined, incomingAction: Action): CrewDebriefState => {
    if (state === undefined) {
        return {
            images: [] as any,
            isLoading: false,
            tailId: 0, 
            confirmDelete: false,
            selectedId: 0,
            selectedImage: null as any,
            imageOpen: false,
            step:0
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_IMAGES':
            return {
                images: state.images, isLoading: true, tailId: action.tailRecordId, confirmDelete: state.confirmDelete,
                selectedId: state.selectedId, selectedImage: state.selectedImage, imageOpen: state.imageOpen,
                step: action.step
            };
        case 'RECIEVE_IMAGES':
            return {
                images: action.images, isLoading: false, tailId: state.tailId, confirmDelete: state.confirmDelete,
                selectedId: state.selectedId, selectedImage: state.selectedImage, imageOpen: false, step: state.step
            };
        case 'UPLOAD_IMAGE':
            return {
                images: state.images, isLoading: action.isLoading, tailId: state.tailId, confirmDelete: false,
                selectedId: state.selectedId, selectedImage: state.selectedImage, imageOpen: state.imageOpen, step: state.step
            };
        case 'TOGGLE_CONFIRM_DEL':
            return {
                images: state.images, isLoading: false, tailId: state.tailId, confirmDelete: action.toggle,
                selectedId: action.selectedId, selectedImage: state.selectedImage, imageOpen: state.imageOpen, step: state.step
            };
        case 'TOGGLE_IMAGE':
            return {
                images: state.images, isLoading: false, tailId: state.tailId, confirmDelete: state.confirmDelete,
                selectedId: state.selectedId, selectedImage: action.file, imageOpen: action.toggleImg, step: state.step
            }
        default:
            return state;
    }
};
