import * as React from 'react';
import { connect } from 'react-redux';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRangePicker as DateRangePicker1, DateRange } from 'react-date-range';
import { ApplicationState } from '../store';
import * as DeferralReportStore from '../store/DeferralReport';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
//@ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import Select from 'react-select';
//@ts-ignore
import JSZip from 'jszip';
//@ts-ignore
import JSZipUtils from 'jszip-utils';
//@ts-ignore
import saveAs from 'save-as';
import { FaChevronLeft, FaSadCry, FaEllipsisH, FaRegCalendar } from 'react-icons/fa';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { ErrorModal } from './Shared/Modal/ErrorModal';



const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        height: "48px",
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "#eff1f5",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "#f47d30"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47d30" : "",
        color: state.isSelected ? "#fff" : "",
        fontWeight: "bold",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#f47d30",
            color: "#fff"
        }
    }),
    menu: (base: any) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    })
};
type DeferralReportProps =
    DeferralReportStore.DeferralReportState &
    typeof DeferralReportStore.actionCreators &
    RouteComponentProps<{}>;

class DeferralReports extends React.PureComponent<DeferralReportProps> {
    public reportIds: number[] = [];
    public isAllChecked: boolean = false;
    public componentDidMount() {
        this.addAGGridHeaderStyle();
        this.ensureDataFetched();        
    }
    private ValidateDateInDOM() {

        let isInValidDate = false;
        const dates = document.querySelectorAll('[class="react-daterange-picker__inputGroup"]');
        let fromDateItems = dates[0].querySelectorAll('input')
        let toDateItems = dates[1].querySelectorAll('input')

        //date format: mm-dd-yyyy
        const fromDateObject = {
            value: fromDateItems[0].value,
            month: {
                value: fromDateItems[1].value,
                max: fromDateItems[1].max,
                min: fromDateItems[1].min,
            },
            day: {
                value: fromDateItems[2].value,
                max: fromDateItems[2].max,
                min: fromDateItems[2].min,
            },
            year: {
                value: fromDateItems[3].value,
                max: fromDateItems[3].max,
                min: fromDateItems[3].min,
            }
        };

        const toDateObject = {
            value: toDateItems[0].value,
            month: {
                value: toDateItems[1].value,
                max: toDateItems[1].max,
                min: toDateItems[1].min,
            },
            day: {
                value: toDateItems[2].value,
                max: toDateItems[2].max,
                min: toDateItems[2].min,
            },
            year: {
                value: toDateItems[3].value,
                max: toDateItems[3].max,
                min: toDateItems[3].min,
            }
        };

        if (fromDateObject.year.value == '' || fromDateObject.month.value == '' || fromDateObject.day.value == '')
            isInValidDate = true;

        if (toDateObject.year.value == '' || toDateObject.month.value == '' || toDateObject.day.value == '')
            isInValidDate = true;

        if (!isInValidDate) {
            /*check if from/to date having a invalid value*/
            if (parseInt(fromDateObject.year.value) < parseInt(fromDateObject.year.min) || parseInt(fromDateObject.year.value) > parseInt(fromDateObject.year.max))
                isInValidDate = true;
            if (parseInt(fromDateObject.month.value) < parseInt(fromDateObject.month.min) || parseInt(fromDateObject.month.value) > parseInt(fromDateObject.month.max))
                isInValidDate = true;
            if (parseInt(fromDateObject.day.value) < parseInt(fromDateObject.day.min) || parseInt(fromDateObject.day.value) > parseInt(fromDateObject.day.max))
                isInValidDate = true;

            if (parseInt(toDateObject.year.value) < parseInt(toDateObject.year.min) || parseInt(toDateObject.year.value) > parseInt(toDateObject.year.max))
                isInValidDate = true;
            if (parseInt(toDateObject.month.value) < parseInt(toDateObject.month.min) || parseInt(toDateObject.month.value) > parseInt(toDateObject.month.max))
                isInValidDate = true;
            if (parseInt(toDateObject.day.value) < parseInt(toDateObject.day.min) || parseInt(toDateObject.day.value) > parseInt(toDateObject.day.max))
                isInValidDate = true;
        }

        if (isInValidDate) {
            this.props.UpdateInvalidDateSeleted(true);
        }
        else {
            const fromDate = moment(fromDateObject.value); 
            const toDate = moment(toDateObject.value); 
            let duration = moment.duration(toDate.diff(fromDate));
            if (fromDate > toDate) {
                isInValidDate = true;
                this.props.UpdateInvalidDateSeleted(true);
            }
            else if (duration.asDays() > 180) {                                
                isInValidDate = true;
                this.props.UpdateInvalidDateRangeSeleted(true);
            }
        }
        return !isInValidDate;
    }

    addAGGridHeaderStyle = () => {
        let item = document.querySelectorAll('[class="ag-header-cell-text"][role="columnheader"]');
        for (let i = 0; i < item.length; i++) {
            if (item[i].textContent == 'Report Name') {
                item[i].classList.add("approval-ag-grid-header-margin");
                break;
            }
        }
    }

    private ensureDataFetched() {
        this.props.SetFilter([], new Date().toUTCString(), new Date().toUTCString(), 0, "", this.props.selectedReportIds, this.props.selectedDocs);        
        this.props.fetchReports([], new Date().toUTCString(), new Date().toUTCString(), 0, "", this.props.selectedReportIds, this.props.selectedDocs);        
    }

    private handleFindReport() {
        this.ValidateDateInDOM() && this.props.fetchReports([], this.props.startDate, this.props.endDate, this.props.shiftId, this.props.fleetId, this.props.selectedReportIds, this.props.selectedDocs);
    }
    onGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    onGridSizeChanged = (params: any) => {
        params.api.sizeColumnsToFit();
    }

    state = {
        dates: [new Date(), new Date()]//[new Date().toUTCString(), new Date().toUTCString()]
    }
    addMonths = (date:any, months:any) => {
        date.setMonth(date.getMonth() + months);
        return date;
    }


    onSelect = (dates: any) => {
        if (dates.length > 1 && dates[0] != null && dates[1] != null) {
        this.setState({ dates })
        if (dates != null) {
            this.props.SetFilter(dates, dates[0].toDateString(), dates[1].toDateString(), this.props.shiftId, this.props.fleetId, this.props.selectedReportIds, this.props.selectedDocs)
        }
        else
            this.props.SetFilter(dates, null, null, this.props.shiftId, this.props.fleetId, this.props.selectedReportIds, this.props.selectedDocs)
        } else {
            this.props.UpdateInvalidDateSeleted(true);

        }
    }

    onSelectionChanged = (params: any) => {
        var selectedRows = params.api.getSelectedRows();
        let reportIds: any = [];
        let reportUrls: any = [];
        selectedRows.forEach(function (selectedRow: any, index: any) {
            reportIds.push(selectedRow.id);
            reportUrls.push(selectedRow.docURL)
        });

        this.props.SetSelectedReportIds(reportIds, reportUrls);
    };

    private handleReports(event: any, id: number, docURL: string) {
        this.props.UpdateReportIdsFilter(id, docURL, event.currentTarget.checked);
        this.reportIds = this.props.selectedReportIds;
        this.forceUpdate();
        if (event.currentTarget.checked == false) {
            this.isAllChecked = false;
        }
    }
    public onSelectShift(event: any) {
        this.props.SetFilter([], this.props.startDate, this.props.endDate, parseInt(event.id), this.props.fleetId, this.props.selectedReportIds, this.props.selectedDocs);
    }

    public onSelectFleet(event: any) {
        this.props.SetFilter([], this.props.startDate, this.props.endDate, this.props.shiftId, event.value, this.props.selectedReportIds, this.props.selectedDocs);

    }
    public ExportReport() {
        this.isAllChecked = false;
        this.downloadAll(this.props.selectedDocs);
    }

    public ExportIndReport(reportid: any, docUrl: string) {
        this.isAllChecked = false;
        var selectedDocs = []
        selectedDocs.push(docUrl);
        this.downloadAll(selectedDocs);

        //.push(docUrl)
    }
    public exportAllReports(event: any) {
        if (event.currentTarget.checked == true) {
            for (let i = 0; i < this.props.selectedDateRangeReports.length; i++) {
                this.props.UpdateReportIdsFilter(this.props.selectedDateRangeReports[i].id, this.props.selectedDateRangeReports[i].docURL, true
                );
            }
        }
        else {
            this.props.ClearReportIdsFilter();
        }
        this.reportIds = this.props.selectedReportIds;
        this.isAllChecked = event.currentTarget.checked;
        this.forceUpdate();
        //this.downloadAll(this.props.selectedDocs);
    }
    downloadFile = (data: any) => {
        if (data != "") {
            const element = document.createElement("a");
            element.href = data;
            element.name = "myFile.xlsx";
            element.download = "myFile.xlsx";
            document.body.appendChild(element);
            element.click();
            element.remove();
        }
    }
    downloadAll = (files: any) => {

        if (files.length == 0) return;
        if (files.length == 1) {
            //var file = files.pop();
            const element = document.createElement("a");
            element.href = files;
            element.name = "myFile.xlsx";
            element.download = "myFile.xlsx";
            document.body.appendChild(element);

            element.click();
            element.remove();
        }
        else {
            const zip = new JSZip();
            let count = 0;
            const zipFilename = "Reports.zip";
            files.forEach(async function (url: any) {
                const urlArr = url.split('/');
                var filename = urlArr[urlArr.length - 1];
                filename = filename.split('?')[0];
                try {
                    const file = await JSZipUtils.getBinaryContent(url)
                    zip.file(filename, file, { binary: true });
                    count++;
                    if (count === files.length) {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, zipFilename);
                        });
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        }
        toast.dismiss();
        toast("Your Daily Deferral Reports have been successfully exported.");
        this.props.SetFilter([], this.props.startDate, this.props.endDate, this.props.shiftId, this.props.fleetId, [], []);

    }
    public backToReportList() {
        this.props.history.push("/Reporting");
    }
    renderGrid() {
        return (
            <div>
            <div>
                <div className="flex flex-space-between headerMxR">
                    <div className="planningSubTitle subHeading-wip-list w-5">
                        <div className="containerCheckBox">
                            <div className="round deferralReport">
                                <input type="checkbox" id="rdAll" className="" onChange={event => { this.exportAllReports(event) }}
                                    checked={this.isAllChecked}
                                />
                                <label htmlFor="rdAll" className="filter-checkbox-label bold-text">&nbsp;</label>
                            </div>
                        </div>

                    </div>
                    <div className="planningSubTitle header-report w-50">Report Name</div>
                    <div className="planningSubTitle header-report w-30">Shift</div>
                    <div className="planningSubTitle header-report w-20"></div>

                </div>
            </div>
             {
            this.props.selectedDateRangeReports != null && this.props.selectedDateRangeReports.map((req: any) =>
                <div key={"inbound_" + req.id}>
                    <div className="flex flex-space-between rowMxR rowMxR-reports">
                        <div className="w-5 subheading-wip-data">
                            <div className="round deferralReport">
                                <input type="checkbox" id={"check_" + req.id} className=""
                                    onChange={event => { this.handleReports(event, req.id, req.docURL) }}
                                    //onChange={event => { this.exportAllReports(event) }}
                                    checked={this.props.selectedReportIds.some(n => n === req.id)}
                                    value={req.id}
                                />
                                <label htmlFor={"check_" + req.id} className="filter-checkbox-label bold-text">&nbsp;</label>
                            </div>
                        </div>


                        <div className="w-50 menuItemMx">{req.reportName}</div>
                        <div className="w-30 menuItemMx">{req.shiftName}</div>
                        <div className="w-20 flex flex-end report-padding-2"><div>
                            <UncontrolledDropdown className="contextMenuHeader">
                                <DropdownToggle nav>
                                    <FaEllipsisH />
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem className="" onClick={event => { this.ExportIndReport(req.id, req.docURL) }} >download</DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </div></div>
                    </div>
                </div>
            )
                }
                </div>
        )
    }
    renderAgGrid() {
        return (
            <div>
                <div id="manpowerGrid" className="row report-padding">
                    <div
                        className="col-12 ag-theme-balham manpowerGrid"
                    >
                        <AgGridReact

                            suppressRowClickSelection={true}
                            onGridSizeChanged={this.onGridSizeChanged}
                            onGridReady={this.onGridReady}
                            enableSorting={true}
                            gridAutoHeight={true}
                            rowSelection={'multiple'}
                            rowHeight={45}
                            detailRowHeight={45}
                            enableFilter={true}
                            pagination={true}
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.selectedDateRangeReports}
                            accentedSort={true}
                            paginationPageSize={10}
                            onSelectionChanged={this.onSelectionChanged}
                            onCellClicked={(events) => {

                            }
                            }

                        >
                        </AgGridReact>
                    </div>
                </div>
            </div>
            )
    }
    render() {
        return (
            <>
                {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }
                <div className="row">
                    <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                </div>

                <ErrorModal
                    title={'Invalid Date Selection'}
                    message={'Please Select a Valid Date Range.'}
                    headerStyleClass={'diff-hours-modal'}
                    isOpen={this.props.isShowInvalidDateModel}
                    onClose={() => { this.props.UpdateInvalidDateSeleted(false) }}
                />

                <ErrorModal
                    title={'Invalid Date Selection'}
                    message={'Maximum Date Range is 6 months. Please Select a Valid Date Range.'}
                    headerStyleClass={'diff-hours-modal'}
                    isOpen={this.props.isShowInvalidDateRangeModel}
                    onClose={() => { this.props.UpdateInvalidDateRangeSeleted(false) }}
                />
                
                <div className="flex flex-space-between report-padding w-100">
                    <div>
                        <FaChevronLeft className="width-Fa-Left" onClick={() => { this.backToReportList(); }} /> <label className="planTitle userTitle"> Daily Deferral Reports</label>
                    </div>
                    <div className={"text-align-center round-fill-button manpower-export-button " + (this.props.selectedReportIds && this.props.selectedReportIds.length == 0 ? "manpower-export-button-disable" : "")} onClick={() => { this.ExportReport(); }}>
                        Export
                    </div>
                </div>
                <br />
                <div className="flex flex-space-between report-padding">
                    <div className="col-4 p-0">
                        <p className="middleHeaderInstance">Date</p>
                        <DateRangePicker
                            className="w-40 text-align-center manpowerDate"
                            onChange={(range: any) => this.onSelect(range)}
                            value={this.state.dates}
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            maxDate={new Date()}
                            //minDate={this.addMonths(new Date(), -6)}
                            calendarIcon={<FaRegCalendar />}
                            clearIcon={null}
                            format="MM/dd/yyyy"
                            openCalendarOnFocus={true}
                            formatShortWeekday={(locale: any, value: any) => ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][value.getDay()]}
                           
                        />
                    </div>
                    <div className="col-2">
                        <p className="middleHeaderInstance">Station</p>
                        <Select
                            styles={customStyles}
                            className="zIndex3"
                            options={this.props.stations}
                            placeholder="Select your station"
                            //onChange={(event) => { this.onStationChange(event); }}
                            value={this.props.stations && this.props.stations.filter(stn => stn.id == this.props.selectedStationId)[0]}
                            isDisabled

                        />
                    </div>
                    
                    <div className="col-2">
                        <p className="middleHeaderInstance">Shift</p>
                        <Select
                            styles={customStyles}
                            className="zIndex3"
                            options={this.props.shiftList}
                            placeholder="Select your shift"
                            onChange={(event) => { this.onSelectShift(event); }}

                        />
                    </div>
                   
                    <div className="col-2">
                        <p className="middleHeaderInstance">Fleet</p>
                        <Select
                            styles={customStyles}
                            className="zIndex3"
                            options={this.props.fleetList}
                            placeholder="Select Fleet Type"
                            onChange={(event) => { this.onSelectFleet(event); }}

                        />
                    </div>
                    <div className="col-1.5 manpower-export">
                        <div className="mt-3 text-align-center round-empty-button print-schedule-style"
                            onClick={() => { this.handleFindReport() }}  >
                            FIND REPORT
                        </div>
                    </div>
                </div>
                <br />
                {/*this.renderGrid()*/}
                {this.renderAgGrid()}



            </>
        )
    }
}
//export default DeferralReports as any;
export default connect(
    (state: ApplicationState) => state.deferralReport,
    DeferralReportStore.actionCreators
)(DeferralReports as any);