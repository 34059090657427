import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify'; import axios from 'axios';
import moment from 'moment';
import d from 'final-form-arrays';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface DepartureEvent {
    id: number,
    tailRecordId: number,
    eventText: string,
}
export interface WorksheetState {
    isLoading: boolean;
    tailrecord: any;
    ata: Date | null;
    atd: Date | null;
    gate: string;
    status: number;
    confirmStart: boolean;
    statuses: { label: string, value: string }[];
    selectedStatus: { label: string, value: string }
    allStatus: [];
    currentStep: number;
    showDepartureEvent: boolean;
    selectedEvent: DepartureEvent;
    isEditable: boolean;
    showConfirmBox: boolean;
    stepToGo: number;
    isDisableSTA: boolean;
    isDisableSTD: boolean;
    isATAValidInput: boolean;
    isATDValidInput: boolean;
    showDeleteDepartureEvent: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface RequestTailRecordAction { type: 'REQUEST_TAILRECORD' }
export interface RecieveTailRecordAction {
    type: 'RECIEVE_TAILRECORD',
    tr: any, ata: any, atd: any, gate: string, status: number,
    statuses: { label: string, value: string }[],
    selectedStatus: { label: string, value: string },
    allStatus: [], isEditable: boolean, currentStep: number,
    isDisableSTA: boolean, isDisableSTD: boolean,
}
export interface RenderAgainAction { type: 'RENDER_AGAIN' }
export interface ToggleConfirmStartAction { type: 'TOGGLE_CONFIRM', toggle: boolean }
export interface ChangeStepAction { type: 'CHANGE_STEP', step: number }
export interface CloseDepartureEventAction { type: 'CLOSE_DEPARTURE_EVENTS', isLoading: boolean }
export interface ShowDepartureEventAction { type: 'SHOW_DEPARTURE_EVENTS', isLoading: boolean }

export interface UpdateDepartureEventAction { type: 'UPDATE_DEPARTURE_EVENT', updatedEvent: DepartureEvent }
export interface ToggleConfirmBox { type: 'TOGGLE_CONFIRM_BOX', step: number, toggle: boolean }
export interface UpdateATAInputValidationAction { type: 'UPDATE_ATA_INPUT_VALIDATION', isValid: boolean }
export interface UpdateATDInputValidationAction { type: 'UPDATE_ATD_INPUT_VALIDATION', isValid: boolean }
export interface ShowDeleteDepartureEvent { type: 'SHOW_DELETE_DEPARTURE_EVENT', departEvent: any }
export interface CloseDeleteDepartureEvent { type: 'CLOSE_DELETE_DEPARTURE_EVENT' }
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ToggleConfirmBox | UpdateDepartureEventAction | ShowDepartureEventAction | CloseDepartureEventAction | RequestTailRecordAction | RecieveTailRecordAction | RenderAgainAction | ToggleConfirmStartAction
    | ChangeStepAction | UpdateATAInputValidationAction | UpdateATDInputValidationAction | ShowDeleteDepartureEvent | CloseDeleteDepartureEvent;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    stepCalled: (step: number, showpop: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.worksheet && appState.worksheet.currentStep) {
            const currentStep = appState.worksheet.currentStep;

            if (showpop) {
                if (currentStep === 1 && step > 1) {
                    dispatch({ type: 'TOGGLE_CONFIRM_BOX', step: step, toggle: true })
                }
                else if (currentStep === 3 && step === 3) {
                    dispatch({ type: 'TOGGLE_CONFIRM_BOX', step: 3, toggle: true })
                }
                else {
                    dispatch({ type: 'CHANGE_STEP', step: step });
                }
            }
            else {
                dispatch({ type: 'CHANGE_STEP', step: step });
            }
        }
    },
    moveStep: (step: number, sendEmail: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var station="";
        if (appState && appState.station && appState.station.selectedStation) {
            station = appState.station.selectedStation.stationName;
        }
        if (appState && appState.worksheet && appState.worksheet.tailrecord) {
            if (step === 4) {
                //update status as well here
                appState.worksheet.status = 5; //MX-Ready
            }
            if (step !== 4) {
                dispatch({ type: 'CHANGE_STEP', step: step });
            }

            //call api if sendmail & dont wait cause sending email could take time
            if (sendEmail) {
                const token = sessionStorage.getItem('access_token_augur');

                const data = new FormData()
                data.append('step', step.toString())
                data.append('TailRecordId', appState.worksheet.tailrecord.id)
                data.append('station', station)

                axios.post(`api/Tails/SendEmailForStep`, data, {
                    headers: !token ? {} : {
                        'Authorization': `Bearer ${token}`
                    }
                })
            }
        }

    },
    getTailRecord: (tailRecordId: number, currentStep: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        fetch(`api/tails/GetByTailId?tailId=${tailRecordId}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response =>
                response.json() as Promise<any>
            )
            .then(data => {
                console.log(data);
                if (data.tail.flightStatusId === null)
                    data.tail.flightStatusId = 1;
                let options = data.flightStatuses.map((d: any) => ({
                    "value": d.id,
                    "label": d.statusDescription
                }))

                let allStatus = (<any>Object).assign([], data.flightStatuses);
                console.log(allStatus);
                if (data.tail.flightStatusId == null || (data.tail.flightStatusId == 1 || data.tail.flightStatusId == 2)) {
                    options = options.filter((obj: any) => obj.value < 3)
                } else {
                    options = options.filter((obj: any) => obj.value >= 3)
                }
                console.log(options)
                const selStaus = data.flightStatuses.find((i: any) => i.id == data.tail.flightStatusId);
                let aat = data.tail.aat;
                let adt = data.tail.adt;
                if (data.tail.aat !== null) {
                    aat = new Date(data.tail.aat);

                }

                if (data.tail.adt !== null) {
                    adt = new Date(data.tail.adt);

                }

                let disableSTA = true;
                let disableSTD = true;
                const currentZulu = moment(new Date()).utc().format('MM/DD/YYYY HH:mm');
                if (data.tail && data.tail.sta != null && data.tail.sta != '') {
                    const date = data.tail.sta.split("-")[0];
                    const time = data.tail.sta.split("-")[1];
                    const sta = moment(new Date(date).toDateString() + ' ' + time).format('MM/DD/YYYY HH:mm');                    
                    let minDiff = moment(sta).diff(currentZulu, 'minutes');
                    if (minDiff <= 60) {
                        disableSTA = false;
                    }
                }

                if (data.tail && data.tail.std != null && data.tail.std != '') {
                    const date = data.tail.std.split("-")[0];
                    const time = data.tail.std.split("-")[1];
                    const std = moment(new Date(date).toDateString() + ' ' + time).format('MM/DD/YYYY HH:mm');
                    let minDiff = moment(std).diff(currentZulu, 'minutes');
                    if (minDiff <= 60) {
                        disableSTD = false;
                    }
                }

                const user = sessionStorage.getItem('logged_user');
                var isEditable = true;
                if (user) {
                    const userLogged = JSON.parse(user);
                    let userRole = userLogged.role.roleDescription;
                    if (userRole == 'Customers') {
                        isEditable = false;
                    }
                }

                dispatch({
                    type: 'RECIEVE_TAILRECORD',
                    tr: data.tail,
                    ata: data.tail.aat != null ? new Date(Date.UTC(aat.getFullYear()
                        , aat.getMonth(), aat.getDate(), aat.getHours(), aat.getMinutes(), aat.getSeconds())) : null,
                    atd: data.tail.adt != null ? new Date(Date.UTC(adt.getFullYear()
                        , adt.getMonth(), adt.getDate(), adt.getHours(), adt.getMinutes(), adt.getSeconds())) : null,
                    gate: data.tail.gate,
                    status: data.tail.flightStatusId,
                    statuses: options,
                    selectedStatus: {
                        label: selStaus.statusDescription, value: selStaus.id
                    },
                    allStatus: allStatus,
                    isEditable: isEditable,
                    currentStep: currentStep,
                    isDisableSTA:disableSTA,
                    isDisableSTD:disableSTD,
                });
            });

        dispatch({
            type: 'REQUEST_TAILRECORD',
        });
    },
    updateATA: (ata: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheet && appState.worksheet.tailrecord) {            
            appState.worksheet.ata = ata == ""? null:new Date(ata);
            appState.worksheet.tailrecord.ata = ata == "" ? null : new Date(ata);
            dispatch({ type: 'RENDER_AGAIN' });
        }
    },
    updateADA: (ada: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheet && appState.worksheet.tailrecord) {
            appState.worksheet.atd = ada==""?null: new Date(ada);
            appState.worksheet.tailrecord.atd = ada==""?null: new Date(ada);
            dispatch({ type: 'RENDER_AGAIN' });
        }
    },
    updateStatus: (status: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheet && appState.worksheet.tailrecord) {
            console.log(status);
            appState.worksheet.status = status;
            dispatch({ type: 'RENDER_AGAIN' });
        }
    },
    updateGate: (gate: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheet && appState.worksheet.tailrecord) {
            appState.worksheet.gate = gate;
            dispatch({ type: 'RENDER_AGAIN' });
        }
    },
    updateState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheet && appState.worksheet.tailrecord) {
            console.log(appState.worksheet.gate);
            console.log(appState.worksheet.status);
            console.log(appState.worksheet.ata);
            console.log(appState.worksheet.atd);

            //call api to update here
            const token = sessionStorage.getItem('access_token_augur');

            const data = new FormData()
            data.append('gate', appState.worksheet.gate)
            data.append('status', appState.worksheet.status.toString())
            data.append('ata', appState.worksheet.ata !== null ? appState.worksheet.ata.toJSON() : '')
            data.append('atd', appState.worksheet.atd !== null ? appState.worksheet.atd.toJSON() : '')
            data.append('TailRecordId', appState.worksheet.tailrecord.id)
            axios.post(`api/Tails/SaveWorkSheet`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    if (appState && appState.worksheet) {
                        let tailrecord = appState.worksheet.tailrecord;
                        tailrecord.flightStatusId = appState.worksheet.status;
                        tailrecord.aat = appState.worksheet.ata;
                        tailrecord.adt = appState.worksheet.atd;
                        tailrecord.gate = appState.worksheet.gate;

                        let options = (<any>Object).assign([], appState.worksheet.allStatus);

                        options = options.map((d: any) => ({
                            "value": d.id,
                            "label": d.statusDescription
                        }))

                        let allStatus = (<any>Object).assign([], options);

                        if (tailrecord.flightStatusId == null || (tailrecord.flightStatusId == 1 || tailrecord.flightStatusId == 2)) {
                            options = options.filter((obj: any) => obj.value < 3)
                        } else {
                            options = options.filter((obj: any) => obj.value >= 3)
                        }
                        console.log(allStatus)
                        console.log(tailrecord.flightStatusId);
                        console.log('allStatus')
                        console.log(appState.worksheet.allStatus)
                        let selStaus = appState.worksheet.allStatus.find((i: any) => i.id == tailrecord.flightStatusId) as any;

                        console.log(appState.worksheet.allStatus)

                        console.log('status');
                        console.log(selStaus);
                        let disableSTA = true;
                        let disableSTD = true;
                        const currentZulu = moment(new Date()).utc().format('MM/DD/YYYY HH:mm');
                        if (tailrecord && tailrecord.sta != null && tailrecord.sta != '') {
                            const date = tailrecord.sta.split("-")[0];
                            const time = tailrecord.sta.split("-")[1];
                            const sta = moment(new Date(date).toDateString() + ' ' + time).format('MM/DD/YYYY HH:mm');
                            let minDiff = moment(sta).diff(currentZulu, 'minutes');
                            if (minDiff <= 60) {
                                disableSTA = false;
                            }
                        }

                        if (tailrecord && tailrecord.std != null && tailrecord.std != '') {
                            const date = tailrecord.std.split("-")[0];
                            const time = tailrecord.std.split("-")[1];
                            const std = moment(new Date(date).toDateString() + ' ' + time).format('MM/DD/YYYY HH:mm');
                            let minDiff = moment(std).diff(currentZulu, 'minutes');
                            if (minDiff <= 60) {
                                disableSTD = false;
                            }
                        }

                        const user = sessionStorage.getItem('logged_user');
                        var isEditable = true;
                        if (user) {
                            const userLogged = JSON.parse(user);
                            let userRole = userLogged.role.roleDescription;
                            if (userRole == 'Customers') {
                                isEditable = false;
                            }
                        }
                        if (selStaus) {
                            console.log(selStaus);
                            dispatch({
                                type: 'RECIEVE_TAILRECORD',
                                tr: tailrecord,
                                ata: tailrecord.aat != null ? new Date(tailrecord.aat) : null,
                                atd: tailrecord.adt != null ? new Date(tailrecord.adt) : null,
                                gate: tailrecord.gate,
                                status: tailrecord.flightStatusId,
                                statuses: options,
                                selectedStatus: {
                                    label: selStaus.statusDescription, value: selStaus.id
                                },
                                allStatus: appState.worksheet.allStatus,
                                isEditable: isEditable,
                                currentStep: appState.worksheet.currentStep,
                                isDisableSTA: disableSTA,
                                isDisableSTD: disableSTD
                            });
                        }
                    }
                    toast.dismiss();
                    toast("information updated.");
                })


            dispatch({ type: 'REQUEST_TAILRECORD' });

        }
    },
    toggleConfirmStart: (toggle: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheet && appState.worksheet.tailrecord) {
            if (toggle) {
                appState.worksheet.status = 3;
            }
            dispatch({ type: 'TOGGLE_CONFIRM', toggle: !appState.worksheet.confirmStart });
        }
    },
    CloseDepartureEvent: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DEPARTURE_EVENTS', isLoading: false })
    },
    UpdateATAInputValidation: (status: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_ATA_INPUT_VALIDATION', isValid: status })
    },
    UpdateATDInputValidation: (status: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_ATD_INPUT_VALIDATION', isValid: status })
    },
    ShowAddDepartureNotes: (tailRecordId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedNote = appState.worksheet && appState.worksheet.selectedEvent;
        if (selectedNote) {
            selectedNote.id = 0;
            selectedNote.tailRecordId = tailRecordId;
            selectedNote.eventText = '';
            dispatch({ type: 'SHOW_DEPARTURE_EVENTS', isLoading: false })
        }
    },
    UpdateNotes: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedEvent = appState.worksheet && appState.worksheet.selectedEvent;
        if (selectedEvent) {
            selectedEvent.eventText = event.target.value;
            dispatch({ type: 'UPDATE_DEPARTURE_EVENT', updatedEvent: selectedEvent })
        }
    },
    SaveNote: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedEvent = appState.worksheet && appState.worksheet.selectedEvent;
        if (selectedEvent && selectedEvent.eventText == '') {
            return;
        }

        if (selectedEvent) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('TailRecordId', selectedEvent.tailRecordId.toString())
            data.append('Id', selectedEvent.id.toString())
            data.append('EventText', selectedEvent.eventText)

            axios.post(`api/Tails/SaveDepartureEvent`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    if (appState.worksheet && appState.worksheet.selectedEvent) {
                        const tailrecordid = appState.worksheet && appState.worksheet.selectedEvent && appState.worksheet.selectedEvent.tailRecordId;
                        let selTail = appState.worksheet && appState.worksheet.tailrecord;
                        console.log(selTail);
                        if (selTail.departureEvents.find((n: any) => n.id === res.id)) {
                            selTail.departureEvents[selTail.departureEvents.indexOf(selTail.departureEvents.find((n: any) => n.id === res.id))]
                                = res;
                        }
                        else {
                            selTail.departureEvents.push(res);
                        }

                    }

                    dispatch({ type: 'CLOSE_DEPARTURE_EVENTS', isLoading: false });
                })
                .catch(error => dispatch({ type: 'CLOSE_DEPARTURE_EVENTS', isLoading: false }))
            dispatch({ type: 'CLOSE_DEPARTURE_EVENTS', isLoading: true });
        }
    },
    ShowDeleteDepartureEvent: (departureEvent: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_DEPARTURE_EVENT', departEvent: departureEvent });
    },
    CloseDeleteDepartureEvent: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_DEPARTURE_EVENT'});
    },
    DeleteDepartureEvent: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.worksheet && appState.worksheet.selectedEvent &&
            appState.worksheet.tailrecord.departureEvents) {
            var selRecord = appState.worksheet.tailrecord.departureEvents;
            let dEvent = appState.worksheet.selectedEvent;
            const removeIndex = selRecord.indexOf(selRecord.find((n: any) => n.id === dEvent.id));
            ~removeIndex && selRecord.splice(removeIndex, 1);

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('DepartureEventId', dEvent.id.toString())

            axios.post(`api/Tails/DeleteDepartureEvent`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    dispatch({ type: 'CLOSE_DELETE_DEPARTURE_EVENT'});
                })

        }
        dispatch({ type: 'CLOSE_DELETE_DEPARTURE_EVENT'});
    },
    EditDepartureEvent: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedEvent = appState.worksheet && appState.worksheet.selectedEvent;
        if (selectedEvent) {
            selectedEvent.id = event.id;
            selectedEvent.tailRecordId = event.tailRecordId;
            selectedEvent.eventText = event.eventText;
            dispatch({ type: 'SHOW_DEPARTURE_EVENTS', isLoading: false })
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<WorksheetState> = (state: WorksheetState | undefined, incomingAction: Action): WorksheetState => {
    if (state === undefined) {
        return {
            isLoading: false, tailrecord: null, ata: null as any,
            atd: null as any, gate: '', status: 0, confirmStart: false,
            selectedStatus: { label: '', value: '' },
            statuses: [], allStatus: [], currentStep: 1, showDepartureEvent: false
            , selectedEvent: { id: 0, tailRecordId: 0, eventText: '' } as DepartureEvent,
            isEditable: true,
            showConfirmBox: false,
            stepToGo: 0,
            isDisableSTA: true,
            isDisableSTD: true,
            isATAValidInput: true,
            isATDValidInput: true,
            showDeleteDepartureEvent: false,
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECIEVE_TAILRECORD':
            return {
                isLoading: false, tailrecord: action.tr, ata: action.ata, atd: action.atd, gate: action.gate,
                status: action.status, confirmStart: false,
                selectedStatus: action.selectedStatus, statuses: action.statuses, allStatus: action.allStatus,
                currentStep: action.currentStep, showDepartureEvent: state.showDepartureEvent
                , selectedEvent: state.selectedEvent, isEditable: action.isEditable, showConfirmBox: false, stepToGo: state.stepToGo
                , isDisableSTA: action.isDisableSTA, isDisableSTD: action.isDisableSTD, isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'REQUEST_TAILRECORD':
            return {
                isLoading: true, tailrecord: state.tailrecord, ata: state.ata, atd: state.atd, gate: state.gate,
                status: state.status, confirmStart: state.confirmStart,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: state.currentStep, showDepartureEvent: state.showDepartureEvent
                , selectedEvent: state.selectedEvent, showConfirmBox: state.showConfirmBox, stepToGo: state.stepToGo, isEditable: state.isEditable
                , isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD, isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'RENDER_AGAIN':
            return {
                isLoading: false, ata: state.ata, atd: state.atd, gate: state.gate, status: state.status,
                tailrecord: state.tailrecord, confirmStart: false,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: state.currentStep, showDepartureEvent: state.showDepartureEvent
                , selectedEvent: state.selectedEvent, showConfirmBox: state.showConfirmBox, stepToGo: state.stepToGo, isEditable: state.isEditable
                , isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD, isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'TOGGLE_CONFIRM':
            return {
                isLoading: state.isLoading, ata: state.ata, atd: state.atd, gate: state.gate, status: state.status,
                tailrecord: state.tailrecord, confirmStart: action.toggle,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: state.currentStep, showDepartureEvent: state.showDepartureEvent
                , selectedEvent: state.selectedEvent, showConfirmBox: state.showConfirmBox, stepToGo: state.stepToGo, isEditable: state.isEditable
                , isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD, isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'CHANGE_STEP':
            return {
                isLoading: false, ata: state.ata, atd: state.atd, gate: state.gate, status: state.status,
                tailrecord: state.tailrecord, confirmStart: false,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: action.step, showDepartureEvent: state.showDepartureEvent
                , selectedEvent: state.selectedEvent, showConfirmBox: false, stepToGo: action.step, isEditable: state.isEditable
                , isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD, isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'CLOSE_DEPARTURE_EVENTS':
            return {
                isLoading: action.isLoading, tailrecord: state.tailrecord, ata: state.ata, atd: state.atd, gate: state.gate,
                status: state.status, confirmStart: state.confirmStart,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: state.currentStep, showDepartureEvent: false
                , selectedEvent: state.selectedEvent, showConfirmBox: state.showConfirmBox, stepToGo: state.stepToGo, isEditable: state.isEditable
                , isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD, isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'SHOW_DEPARTURE_EVENTS':
            return {
                isLoading: action.isLoading, tailrecord: state.tailrecord, ata: state.ata, atd: state.atd, gate: state.gate,
                status: state.status, confirmStart: state.confirmStart,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: state.currentStep, showDepartureEvent: true
                , selectedEvent: state.selectedEvent, showConfirmBox: state.showConfirmBox, stepToGo: state.stepToGo, isEditable: state.isEditable
                , isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD, isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'UPDATE_DEPARTURE_EVENT':
            return {
                isLoading: state.isLoading, tailrecord: state.tailrecord, ata: state.ata, atd: state.atd, gate: state.gate,
                status: state.status, confirmStart: state.confirmStart,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: state.currentStep, showDepartureEvent: state.showDepartureEvent, selectedEvent: action.updatedEvent
                , showConfirmBox: state.showConfirmBox, stepToGo: state.stepToGo,
                isEditable: state.isEditable, isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD
                , isATAValidInput: state.isATAValidInput, isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'TOGGLE_CONFIRM_BOX':
            return {
                isLoading: state.isLoading, tailrecord: state.tailrecord, ata: state.ata, atd: state.atd, gate: state.gate,
                status: state.status, confirmStart: state.confirmStart,
                selectedStatus: state.selectedStatus, statuses: state.statuses, allStatus: state.allStatus,
                currentStep: state.currentStep, showDepartureEvent: state.showDepartureEvent, selectedEvent: state.selectedEvent
                , showConfirmBox: action.toggle, stepToGo: action.step, isEditable: state.isEditable
                , isDisableSTA: state.isDisableSTA, isDisableSTD: state.isDisableSTD, isATAValidInput: state.isATAValidInput,
                isATDValidInput: state.isATDValidInput,
                showDeleteDepartureEvent: state.showDeleteDepartureEvent
            };
        case 'UPDATE_ATA_INPUT_VALIDATION':
            return {
                ...state,
                isATAValidInput: action.isValid
            };
        case 'UPDATE_ATD_INPUT_VALIDATION':
            return {
                ...state,
                isATDValidInput: action.isValid
            };
        case 'SHOW_DELETE_DEPARTURE_EVENT':
            return {
                ...state,
                showDeleteDepartureEvent: true,
                selectedEvent: action.departEvent
            };
        case 'CLOSE_DELETE_DEPARTURE_EVENT':
            return {
                ...state,
                selectedEvent: { id: 0, tailRecordId: 0, eventText: '' } as DepartureEvent,
                showDeleteDepartureEvent: false
            };
        default:
            return state;
    }
};
