import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { toast } from 'react-toastify';


export interface MxRequestPlanningStatus {
    id: number,
    planningStatus: string
}

export interface MxRequestTypes {
    id: number,
    requestText: string
}
export interface DropDownType {
    id: number,
    value: string,
    label: string
}
export interface MxRequest {
    mxRequestTypesValues: { label: string; value: number; };
    mxRequestStatusValues: { label: string; id: number; };
    mxRequestDeferredReasonValues: { label: string; id: number; };
    mxRequestPlanningstatusvalues: { label: string; id: number; };
    id: number;
    mxRequestTypesId: number,
    mxRequestStatusId: number,
    deferredReasonId: number,
    refNumber: string,
    description: string,
    notes: string,
    tailRecordId: number,
    mxRequestPlanningStatusId: number,
    files: [],
    mxrequestFiles: [],
}

export interface InBoundRequest {
    id: number;
    typeValues: { label: string; id: number; };
    typeId: number;
    inBoundStatusValues: { label: string; id: number; };
    inBoundStatusId: number;
    description: string;
    files: [],
    inBoundFiles: [],
    notes: string,
    correctiveAction: string;
    ataCode: string;
    inBoundDeferredTypeValues: { label: string; id: number; };
    inBoundDeferredTypeId: number;
    deferralReason: string;
    mel: string;
    tailRecordId: number;
    fileName: string;
    isFileDeleted: boolean;
}

export interface PlannedItemRequest {
    id: number;
    defect_description: string;
    status: string;
    mddr: number;
    days: number;
    mddrstatusValues: { label: string; id: number; };
    mddrstatusId: number;
    correctiveAction: string;
    ataCode: string;
    plannedDeferredTypeValues: { label: string; id: number; };
    plannedDeferredTypeId: number;
    deferralReason: string;
    mel: string;
    tailRecordId: number;
}

export interface WorksheetWIPState {
    selectedTail: any;
    isLoading: boolean;
    showAddMx: boolean;
    showPlannedItem: boolean;
    showInBound: boolean;
    tailRecordId: number;
    selectedMxR: MxRequest;
    selectedPlannedItem: PlannedItemRequest;
    selectedInboundItem: InBoundRequest;
    mxTypes: MxRequestTypes[];
    mxStatus: DropDownType[];
    mxPlanningStatus: MxRequestPlanningStatus[];
    mxDeferredReason: DropDownType[];
    mddrStatus: DropDownType[];
    deferredType: DropDownType[];
    inBoundType: DropDownType[];
    mxRequestUploadedFiles: UploadableFile[];
    mxRequestDeleteFIles: string[];
    showFileValidation: boolean;
    errorMessage: string;
    selectedInboundItemToDelete: any;
    showDeleteInboundItemConfirmation: boolean;
    selectedmxrRequest: any;
    showDeleteMxrConfirmation: boolean;
    imageOpen: boolean;
    selectedImage: any;
}

export interface UploadableFile {
    id: number;
    uri: string;
    fileName: string;
    file: File;
    imageUri: string;
}

export interface ShowAddMxRAction { type: 'SHOW_ADDMxR'; selectedTail: any; files: UploadableFile[] }
export interface CloseMxRAction { type: 'CLOSE_ADDMxR', isLoading: boolean }
export interface ShowAddPlannedAction { type: 'SHOW_ADD_PLANNED'; selectedTail: any }
export interface ClosePlannedAction { type: 'CLOSE_ADD_PLANNED', isLoading: boolean }
export interface ShowAddInBoundAction { type: 'SHOW_ADD_INBOUND'; selectedTail: any }
export interface CloseInBoundAction { type: 'CLOSE_INBOUND', isLoading: boolean }
export interface RequestTailsAction { type: 'REQUEST_TAILS'; payload: number }
export interface UploadFilesAction { type: 'UPLOAD_FILES', files: UploadableFile[], deletedFiles: string[] }
export interface ShowImageValidAction { type: 'SHOW_IMAGE_VALID', showImageValid: boolean, message: string }
export interface CloseImageValidAction { type: 'CLOSE_IMAGE_VALID', showImageValid: boolean }
export interface ShowLoaderAction { type: 'SHOW_LOADER' }
export interface ShowDeleteIbItemConfirmationAction { type: 'SHOW_DELETE_IBITEM_CONF'; isloading: boolean, inboundItem: any }
export interface CloseDeleteIbItemConfirmationAction { type: 'CLOSE_DELETE_IBITEM_CONF'; isloading: boolean }
export interface ShowDeleteMxrConfirmationAction { type: 'SHOW_DELETE_Mxr_CONF'; isloading: boolean, mxr: any }
export interface CloseDeleteMxrConfirmationAction { type: 'CLOSE_DELETE_Mxr_CONF'; isloading: boolean }

export interface ReceiveTailsAction {
    type: 'RECEIVE_TAILS'; selectedTail: any, mxTypes: MxRequestTypes[], mxPlanningStatus: MxRequestPlanningStatus[], mxStatus: DropDownType[],
    mxDeferredReason: DropDownType[], mddrStatus: DropDownType[],
    deferredType: DropDownType[], inBoundType: DropDownType[]
}
export interface ToggleImageAction { type: 'TOGGLE_IMAGE', file: any, toggleImg: boolean }

export type KnownAction = ShowAddMxRAction | RequestTailsAction | ShowAddInBoundAction | CloseInBoundAction |
    ReceiveTailsAction | CloseMxRAction | ShowAddPlannedAction | ClosePlannedAction | UploadFilesAction
    | ShowImageValidAction | CloseImageValidAction | ShowLoaderAction | ShowDeleteIbItemConfirmationAction |
    CloseDeleteIbItemConfirmationAction | ShowDeleteMxrConfirmationAction | CloseDeleteMxrConfirmationAction | ToggleImageAction;

export const actionCreators = {
    ShowAddMxR: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxR = appState.worksheetWIP && appState.worksheetWIP.selectedMxR;
        if (selectedMxR) {
            selectedMxR.id = 0;
            selectedMxR.description = '';
            selectedMxR.mxRequestTypesId = 1;
            selectedMxR.notes = '';
            selectedMxR.refNumber = '';
            selectedMxR.mxRequestPlanningStatusId = 0;
            selectedMxR.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;

            selectedMxR.mxRequestTypesValues = { value: 0, label: "Select type" };
            selectedMxR.mxRequestDeferredReasonValues = { id: 0, label: "Select deferred reason" };
            selectedMxR.mxRequestStatusValues = { id: 0, label: "Select status" };
            selectedMxR.mxRequestPlanningstatusvalues = { id: 0, label: 'Select planning status' };
            dispatch({ type: 'SHOW_ADDMxR', selectedTail: selectedMxR.tailRecordId, files: [] })

        }
    },
    CloseMxR: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_ADDMxR', isLoading: false })
    },
    SaveMxR: (mxr: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxR = appState.worksheetWIP && appState.worksheetWIP.selectedMxR;
        if (selectedMxR) {
            selectedMxR.description = mxr.description;
            selectedMxR.mxRequestTypesId = mxr.mxRequestTypesValues.value;
            selectedMxR.notes = mxr.notes ? mxr.notes : "";
            selectedMxR.refNumber = mxr.refNumber ? mxr.refNumber : "";
            selectedMxR.mxRequestPlanningStatusId = mxr.mxRequestPlanningstatusvalues.value !== undefined ? mxr.mxRequestPlanningstatusvalues.value : mxr.mxRequestPlanningstatusvalues.id;
            selectedMxR.mxRequestStatusId = mxr.mxRequestStatusValues.value !== undefined ? mxr.mxRequestStatusValues.value : mxr.mxRequestStatusValues.id;
            selectedMxR.deferredReasonId = mxr.mxRequestDeferredReasonValues.value !== undefined ? mxr.mxRequestDeferredReasonValues.value : mxr.mxRequestDeferredReasonValues.id;
            selectedMxR.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;



            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            
            data.append('Id', selectedMxR.id.toString());
            data.append('Description', selectedMxR.description);
            data.append('MxRequestTypesId', selectedMxR.mxRequestTypesId.toString());
           // data.append('Notes', selectedMxR.notes);
            data.append('RefNumber', selectedMxR.refNumber);
            data.append('mxRequestPlanningStatusId', selectedMxR.mxRequestPlanningStatusId.toString());
            data.append('MxrequestStatusId', selectedMxR.mxRequestStatusId != undefined ? selectedMxR.mxRequestStatusId.toString() : "");
            data.append('DeferredReasonId', selectedMxR.deferredReasonId != undefined ? selectedMxR.deferredReasonId.toString() : "");
            data.append('TailRecordId', selectedMxR.tailRecordId.toString());
            data.append('IsFromWorksheet', "true");

            if (selectedMxR.notes) {
                data.append("MxNotes[" + 0 + "].Note", selectedMxR.notes);
                data.append("MxNotes[" + 0 + "].MXId", selectedMxR.id.toString());
                data.append("MxNotes[" + 0 + "].SourceScreen", "2");
            }

            if (appState && appState.planning && appState.planning.mxRequestUploadedFiles) {
                let files = appState.planning.mxRequestUploadedFiles.filter(a => a.file && a.file.name != 'filename.remove');
                if (files) {
                    for (let i = 0; i < files.length; i++) {
                        data.append('file', files[i].file, files[i].file.name);
                    }
                }
            }

            if (appState && appState.planning && appState.planning.mxRequestDeleteFIles) {
                for (let i = 0; i < appState.planning.mxRequestDeleteFIles.length; i++) {
                    data.append("FilesToDelete[" + i + "]", appState.planning.mxRequestDeleteFIles[i]);
                }
            }

            let files = appState && appState.planning && appState.planning.mxRequestUploadedFiles;
            if (files && files.length > 10) {
                dispatch({
                    type: 'SHOW_IMAGE_VALID',
                    showImageValid: true,
                    message: 'Maximum of 10 files can be uploaded.'
                });
            }
            else if (files && files.map(a => a.uri).some((val, i) => files && files.map(a => a.uri).indexOf(val) !== i)) {
                dispatch({
                    type: 'SHOW_IMAGE_VALID',
                    showImageValid: true,
                    message: 'Please remove the duplicate entries.'
                });
            }
            else if (files && files.filter(a => a.file && a.file.name != 'filename.remove').map(a => a.file.size).some((val, i) => val > 10485760)) {
                let fileNames = files && files.filter(a => a.file && a.file.name != 'filename.remove' && a.file.size > 10485760).map(a => a.file.name)
                if (fileNames) {
                    dispatch({
                        type: 'SHOW_IMAGE_VALID',
                        showImageValid: true,
                        message: 'Files upto 10MB are only allowed to upload.'
                    });
                }
            }
            else {
                axios.post(`api/Tails/SaveMxR`, data, {
                    headers: !token ? {} : {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.data as Promise<any>)
                    .then(res => { // then print response status
                        if (appState.worksheetWIP && appState.worksheetWIP.selectedMxR) {
                            var selMxr = appState.worksheetWIP.selectedTail.tail.mxrequests;

                            if (selMxr.find((n: any) => n.id === res.id)) {
                                selMxr[selMxr.indexOf(selMxr.find((n: any) => n.id === res.id))]
                                    = res;
                            }
                            else {
                                selMxr.push(res);
                            }
                            appState.worksheetWIP.selectedTail.tail.mxrequests = selMxr;

                        }

                        dispatch({ type: 'CLOSE_ADDMxR', isLoading: false });
                    })
                    .catch(error => dispatch({ type: 'CLOSE_ADDMxR', isLoading: false }))

                dispatch({ type: 'CLOSE_ADDMxR', isLoading: true });
            }
        }
    },


    EditMxR: (event: any, mxr: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxR = appState.worksheetWIP && appState.worksheetWIP.selectedMxR;
        if (selectedMxR) {
            selectedMxR.id = mxr.id;
            selectedMxR.description = mxr.description;
            selectedMxR.mxRequestTypesId = mxr.mxRequestTypesId;
            selectedMxR.notes = mxr.mxNotes.filter((mx: any) => mx.sourceScreen == 2).length > 0 ? mxr.mxNotes.filter((mx: any) => mx.sourceScreen == 2)[0].note: "";
            selectedMxR.refNumber = mxr.refNumber;
            selectedMxR.mxRequestPlanningStatusId = mxr.mxRequestPlanningStatusId;
            selectedMxR.mxRequestStatusId = mxr.mxRequestStatusId == null ? 1 : mxr.mxRequestStatusId;
            selectedMxR.deferredReasonId = mxr.deferredReasonId;
            let mxRequestStatusId = mxr.mxRequestStatusId == null ? 1 : mxr.mxRequestStatusId;
            selectedMxR.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;
            let selectedMXStatus = appState.worksheetWIP && appState.worksheetWIP.mxStatus.filter(option => option.id === mxRequestStatusId)[0]
            if (selectedMXStatus) {
                selectedMxR.mxRequestStatusValues = {
                    label: selectedMXStatus.value, id: selectedMXStatus.id
                };
            }
            let selectedMxPlanningStatus = appState.worksheetWIP && appState.worksheetWIP.mxPlanningStatus.filter(option => option.id === mxr.mxRequestPlanningStatusId)[0]
           if (selectedMxPlanningStatus) {
                console.log(selectedMxPlanningStatus);
                selectedMxR.mxRequestPlanningstatusvalues = {
                    label: selectedMxPlanningStatus.planningStatus, id: selectedMxPlanningStatus.id 
                }
            }

            let selectedDeferredStatus = appState.worksheetWIP && appState.worksheetWIP.mxDeferredReason.filter(option => option.id === mxr.deferredReasonId)[0]
            if (selectedDeferredStatus) {
                selectedMxR.mxRequestDeferredReasonValues = {
                    label: selectedDeferredStatus.value, id: selectedDeferredStatus.id
                };
            }

            let uploadedFiles: Array<UploadableFile> = [];
            if (mxr.mxrequestFiles) {
                for (let i = 0; i < mxr.mxrequestFiles.length; i++) {
                    var file = { id: mxr.mxrequestFiles[i].id, file: new File([""], "filename.remove"), uri: mxr.mxrequestFiles[i].uri, fileName: mxr.mxrequestFiles[i].fileName, imageUri: mxr.mxrequestFiles[i].imageUri };
                    uploadedFiles.push(file);
                }
            }
            let selectedMxType = appState.worksheetWIP && appState.worksheetWIP.mxTypes.filter(option => option.id === mxr.mxRequestTypesId)[0]
            if (selectedMxType) {
                selectedMxR.mxRequestTypesValues = {
                    label: selectedMxType.requestText, value: selectedMxType.id
                };
                dispatch({ type: 'SHOW_ADDMxR', selectedTail: mxr.tailRecordId, files: uploadedFiles})
            }
        }
    },
    ShowDeleteMxrConfirmation: (mxr: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_Mxr_CONF', isloading: true, mxr: mxr })
    },
    CloseDeleteMxrConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_Mxr_CONF', isloading: false })
    },
    DeleteMxR: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.worksheetWIP && appState.worksheetWIP.selectedMxR &&
            appState.worksheetWIP.selectedTail.tail.mxrequests) {
            var selMxr = appState.worksheetWIP.selectedTail.tail.mxrequests;
            let mxr = appState.worksheetWIP.selectedmxrRequest;
            const removeIndex = selMxr.indexOf(selMxr.find((n: any) => n.id === mxr.id));
            ~removeIndex && selMxr.splice(removeIndex, 1);

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('MxRequestId', mxr.id.toString())

            axios.post(`api/Tails/DeleteMxR`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    dispatch({ type: 'CLOSE_ADDMxR', isLoading: false });
                })

        }
        dispatch({ type: 'CLOSE_ADDMxR', isLoading: true });
    },
    FetchTailDetails: (tailRecordId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        fetch(`api/tails/GetTailDetailsforWip?tailRecordId=${tailRecordId}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
            .then(data => {
                //if (data.tailRecordsWithMddrs.length > 0) {
                //    data.tailRecordsWithMddrs[0].defects.sort((n1: any, n2: any) => {
                //        if (n1.plannedStatusSubCategory.category.id == n2.plannedStatusSubCategory.category.id) {
                //            return n1.remainDays - n2.remainDays;
                //        }
                //        return n1.plannedStatusSubCategory.category.id - n2.plannedStatusSubCategory.category.id;
                //    });                    
                //}                   
                dispatch({
                    type: 'RECEIVE_TAILS',
                    selectedTail: data.tailRecordsWithMddrs.length > 0 ? data.tailRecordsWithMddrs[0] : null,
                    mxTypes: data.mxTypes && data.mxTypes.length > 0 ? data.mxTypes :
                        appState.worksheetWIP ? appState.worksheetWIP.mxTypes : null,
                    mxStatus: data.mxStatus && data.mxStatus.length > 0 ? data.mxStatus :
                        appState.worksheetWIP ? appState.worksheetWIP.mxStatus : null,
                    mxPlanningStatus: data.mxPlanningStatus && data.mxPlanningStatus.length > 0 ? data.mxPlanningStatus :
                        appState.worksheetWIP ? appState.worksheetWIP.mxPlanningStatus : null,
                    mxDeferredReason: data.mxDeferredReason && data.mxDeferredReason.length > 0 ? data.mxDeferredReason :
                        appState.worksheetWIP ? appState.worksheetWIP.mxDeferredReason : null,
                    deferredType: data.deferredType && data.deferredType.length > 0 ? data.deferredType :
                        appState.worksheetWIP ? appState.worksheetWIP.deferredType : null,
                    mddrStatus: data.mddrStatus && data.mddrStatus.length > 0 ? data.mddrStatus :
                        appState.worksheetWIP ? appState.worksheetWIP.mddrStatus : null,
                    inBoundType: data.inBoundType && data.inBoundType.length > 0 ? data.inBoundType :
                        appState.worksheetWIP ? appState.worksheetWIP.inBoundType : null

                });
            });

        dispatch({ type: 'REQUEST_TAILS', payload: tailRecordId });
    },

    EditPlannedItem: (event: any, plannedItem: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedPlannedItem = appState.worksheetWIP && appState.worksheetWIP.selectedPlannedItem;
        if (selectedPlannedItem) {
            selectedPlannedItem.id = plannedItem.id;
            selectedPlannedItem.ataCode = plannedItem.atacode;
            selectedPlannedItem.correctiveAction = plannedItem.correctiveAction;
            selectedPlannedItem.days = plannedItem.remainDays;
            selectedPlannedItem.defect_description = plannedItem.defectDescription;
            selectedPlannedItem.deferralReason = plannedItem.deferralReason;
            selectedPlannedItem.mddr = plannedItem.mddr;
            selectedPlannedItem.mel = plannedItem.melNumber;
            selectedPlannedItem.plannedDeferredTypeId = plannedItem.deferralType;
            selectedPlannedItem.status = plannedItem.plannedStatusSubCategory.category.categoryName === "Not Planned to work - reason" ?
                "NOT PLANNED TO WORK" : plannedItem.plannedStatusSubCategory.category.categoryName === "Plan to work" ? "PLANNED TO WORK" : "NOT SCHEDULED";
            selectedPlannedItem.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;
            let selectedMDDRStatus = appState.worksheetWIP && appState.worksheetWIP.mddrStatus.filter(option => option.id === plannedItem.mddrstatusId)[0]
            if (selectedMDDRStatus) {
                selectedPlannedItem.mddrstatusValues = {
                    label: selectedMDDRStatus.value, id: selectedMDDRStatus.id
                };
            }
            let deferralTypeId = parseInt(plannedItem.deferralType);
            let selectedDeferredType = appState.worksheetWIP && appState.worksheetWIP.deferredType.filter(option => option.id === deferralTypeId)[0]
            if (selectedDeferredType) {
                selectedPlannedItem.plannedDeferredTypeValues = {
                    label: selectedDeferredType.value, id: selectedDeferredType.id
                };
            }
            dispatch({ type: 'SHOW_ADD_PLANNED', selectedTail: plannedItem.tailRecordId })

        }
    },
    ClosePlannedItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_ADD_PLANNED', isLoading: false })
    },

    SavePlannedItem: (plannedItem: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedPlannedItem = appState.worksheetWIP && appState.worksheetWIP.selectedPlannedItem;
        if (selectedPlannedItem) {
            selectedPlannedItem.ataCode = plannedItem.ataCode;
            selectedPlannedItem.correctiveAction = plannedItem.correctiveAction;
            selectedPlannedItem.defect_description = plannedItem.defect_description;
            selectedPlannedItem.mddrstatusId = (plannedItem.mddrstatusValues != null && plannedItem.mddrstatusValues.value !== undefined) ? plannedItem.mddrstatusValues.value : plannedItem.mddrstatusValues.id;
            selectedPlannedItem.deferralReason = plannedItem.deferralReason;
            selectedPlannedItem.plannedDeferredTypeId = (plannedItem.plannedDeferredTypeValues != null && plannedItem.plannedDeferredTypeValues.value !== undefined) ? plannedItem.plannedDeferredTypeValues.value : plannedItem.plannedDeferredTypeValues.id;
            selectedPlannedItem.mel = plannedItem.mel;
            selectedPlannedItem.id = plannedItem.id;
            selectedPlannedItem.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;



            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('Id', selectedPlannedItem.id.toString());
            data.append('DefectDescription', selectedPlannedItem.defect_description);
            data.append('Atacode', selectedPlannedItem.ataCode);
            data.append('CorrectiveAction', selectedPlannedItem.correctiveAction);
            data.append('DeferralReason', selectedPlannedItem.deferralReason);
            data.append('DeferralType', selectedPlannedItem.plannedDeferredTypeId.toString());
            data.append('MelNumber', selectedPlannedItem.mel);
            data.append('MddrstatusId', selectedPlannedItem.mddrstatusId != undefined ? selectedPlannedItem.mddrstatusId.toString() : "");
            data.append('TailRecordId', selectedPlannedItem.tailRecordId.toString());

            axios.post(`api/Tails/SavePlannedItem`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    if (appState.worksheetWIP && appState.worksheetWIP.selectedPlannedItem) {
                        var selMxr = appState.worksheetWIP.selectedTail.defects;

                        if (selMxr.find((n: any) => n.id === res.id)) {
                            selMxr[selMxr.indexOf(selMxr.find((n: any) => n.id === res.id))]
                                = res;
                        }
                        else {
                            selMxr.push(res);
                        }


                    }

                    dispatch({ type: 'CLOSE_ADD_PLANNED', isLoading: false });
                })
                .catch(error => dispatch({ type: 'CLOSE_ADD_PLANNED', isLoading: false }))

            dispatch({ type: 'CLOSE_ADD_PLANNED', isLoading: true });
        }

    },

    ShowInBound: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedInboundItem = appState.worksheetWIP && appState.worksheetWIP.selectedInboundItem;
        if (selectedInboundItem) {
            selectedInboundItem.id = 0;
            selectedInboundItem.description = '';
            selectedInboundItem.inBoundDeferredTypeId = 1;
            selectedInboundItem.notes = '';
            selectedInboundItem.typeId = 1;
            selectedInboundItem.inBoundStatusId = 1;
            selectedInboundItem.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;

            selectedInboundItem.inBoundDeferredTypeValues = { id: 0, label: "Select deferred type" };
            selectedInboundItem.typeValues = { id: 0, label: "Select type" };
            selectedInboundItem.inBoundStatusValues = { id: 1, label: "Open" };

            selectedInboundItem.mel = '';
            selectedInboundItem.correctiveAction = '';
            selectedInboundItem.ataCode = '';
            selectedInboundItem.deferralReason = '';
            selectedInboundItem.fileName = '';

            dispatch({ type: 'SHOW_ADD_INBOUND', selectedTail: selectedInboundItem.tailRecordId })

        }
    },
    CloseInBound: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_INBOUND', isLoading: false })
    },
    EditInBound: (event: any, inboundItem: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedInboundItem = appState.worksheetWIP && appState.worksheetWIP.selectedInboundItem;
        if (selectedInboundItem) {
            selectedInboundItem.id = inboundItem.id;
            selectedInboundItem.ataCode = inboundItem.atacode;
            selectedInboundItem.correctiveAction = inboundItem.correctiveAction;
            selectedInboundItem.description = inboundItem.description;
            selectedInboundItem.deferralReason = inboundItem.deferralReason;
            selectedInboundItem.mel = inboundItem.melNumber;
            selectedInboundItem.notes = inboundItem.notes != undefined ? inboundItem.notes : '';
            selectedInboundItem.inBoundDeferredTypeId = inboundItem.deferralTypeId;
            selectedInboundItem.typeId = inboundItem.typeId;
            selectedInboundItem.inBoundStatusId = inboundItem.inBoundStatusId;
            selectedInboundItem.fileName = inboundItem.fileName;
            selectedInboundItem.isFileDeleted = inboundItem.isFileDeleted;
            selectedInboundItem.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;
            let selectedInBoundStatus = appState.worksheetWIP && appState.worksheetWIP.mddrStatus.filter(option => option.id === inboundItem.inBoundStatusId)[0]
            if (selectedInBoundStatus) {
                selectedInboundItem.inBoundStatusValues = {
                    label: selectedInBoundStatus.value, id: selectedInBoundStatus.id
                };
            }
            let selectedDeferredType = appState.worksheetWIP && appState.worksheetWIP.deferredType.filter(option => option.id === inboundItem.deferralTypeId)[0]
            if (selectedDeferredType) {
                selectedInboundItem.inBoundDeferredTypeValues = {
                    label: selectedDeferredType.value, id: selectedDeferredType.id
                };
            }
            let selectedType = appState.worksheetWIP && appState.worksheetWIP.inBoundType.filter(option => option.id === inboundItem.typeId)[0]
            if (selectedType) {
                selectedInboundItem.typeValues = {
                    label: selectedType.value, id: selectedType.id
                };
            }
            dispatch({ type: 'SHOW_ADD_INBOUND', selectedTail: inboundItem.tailRecordId })

        }
    },
    SaveInBoundItem: (inboundItem: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedInboundItem = appState.worksheetWIP && appState.worksheetWIP.selectedInboundItem;
        if (selectedInboundItem) {
            selectedInboundItem.id = inboundItem.id;
            selectedInboundItem.ataCode = inboundItem.ataCode;
            selectedInboundItem.correctiveAction = inboundItem.correctiveAction;
            selectedInboundItem.description = inboundItem.description;
            selectedInboundItem.deferralReason = inboundItem.deferralReason;
            selectedInboundItem.mel = inboundItem.mel;
            selectedInboundItem.notes = inboundItem.notes != undefined ? inboundItem.notes : '';
            selectedInboundItem.fileName = inboundItem.fileName;

            selectedInboundItem.inBoundDeferredTypeId = (inboundItem.inBoundDeferredTypeValues != null && inboundItem.inBoundDeferredTypeValues.value !== undefined) ? inboundItem.inBoundDeferredTypeValues.value : inboundItem.inBoundDeferredTypeValues.id;

            selectedInboundItem.typeId = (inboundItem.typeValues != null && inboundItem.typeValues.value !== undefined) ? inboundItem.typeValues.value : inboundItem.typeValues.id;
            selectedInboundItem.inBoundStatusId = (inboundItem.inBoundStatusValues != null && inboundItem.inBoundStatusValues.value !== undefined) ? inboundItem.inBoundStatusValues.value : inboundItem.inBoundStatusValues.id;
            selectedInboundItem.tailRecordId = appState.worksheetWIP && appState.worksheetWIP.selectedTail &&
                appState.worksheetWIP.selectedTail.tail && appState.worksheetWIP.selectedTail.tail.id;

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('Id', selectedInboundItem.id.toString());
            data.append('Description', selectedInboundItem.description);
            data.append('Atacode', selectedInboundItem.ataCode);
            data.append('CorrectiveAction', selectedInboundItem.correctiveAction);
            data.append('DeferralReason', selectedInboundItem.deferralReason);
            data.append('TypeId', selectedInboundItem.typeId.toString());
            data.append('InBoundStatusId', selectedInboundItem.inBoundStatusId.toString());
            data.append('DeferralTypeId', selectedInboundItem.inBoundDeferredTypeId.toString());
            data.append('MelNumber', selectedInboundItem.mel);
            data.append('Notes', selectedInboundItem.notes);
            data.append('TailRecordId', selectedInboundItem.tailRecordId.toString());
            data.append('IsFileDeleted', new Boolean(selectedInboundItem.isFileDeleted).toString());
            if (inboundItem.files != undefined && inboundItem.files.length>0) {
                for (let i = 0; i < inboundItem.files.length; i++) {
                    data.append('file', inboundItem.files[i], inboundItem.files[i].name);
                }
                data.append('PostFileName', inboundItem.files[0].name);
            }

            axios.post(`api/Tails/SaveInboundItem`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status                    
                    if (appState.worksheetWIP && appState.worksheetWIP.selectedInboundItem) {
                        var selMxr = appState.worksheetWIP.selectedTail.tail.inboundItems;

                        if (selMxr.find((n: any) => n.id === res.id)) {
                            var ref = selMxr[selMxr.indexOf(selMxr.find((n: any) => n.id === res.id))].ref;
                            selMxr[selMxr.indexOf(selMxr.find((n: any) => n.id === res.id))]
                                = res;
                          }
                        else {
                            selMxr.push(res);
                        }
                    }

                    dispatch({ type: 'CLOSE_INBOUND', isLoading: false });
                })
                .catch(error => dispatch({ type: 'CLOSE_INBOUND', isLoading: false }))

            dispatch({ type: 'CLOSE_INBOUND', isLoading: true });
        }

    },

    DeleteInBoundImage: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.worksheetWIP && appState.worksheetWIP.selectedInboundItem &&
            appState.worksheetWIP.selectedTail.tail.inboundItems) {
            var selInbound = appState.worksheetWIP.selectedInboundItem;
            selInbound.fileName = '';
        }
    },

    ShowDeleteIbItemConfirmation: (ibItem: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_IBITEM_CONF', isloading: true, inboundItem: ibItem })
    },
    CloseDeleteIbItemConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_IBITEM_CONF', isloading: false })
    },
    DeleteInboundItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.worksheetWIP && appState.worksheetWIP.selectedInboundItem &&
            appState.worksheetWIP.selectedTail.tail.inboundItems) {
            var selInbound = appState.worksheetWIP.selectedTail.tail.inboundItems;
            let inboundItem = appState.worksheetWIP.selectedInboundItemToDelete;
            const removeIndex = selInbound.indexOf(selInbound.find((n: any) => n.id === inboundItem.id));
            ~removeIndex && selInbound.splice(removeIndex, 1);

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('Id', inboundItem.id.toString())

            axios.post(`api/Tails/DeleteInboundItem`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    dispatch({ type: 'CLOSE_INBOUND', isLoading: false });
                })

        }
        dispatch({ type: 'CLOSE_INBOUND', isLoading: true });
    },
    UpdateDeleteStatus: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.worksheetWIP && appState.worksheetWIP.selectedInboundItem &&
            appState.worksheetWIP.selectedTail.tail.inboundItems) {
            var selInbound = appState.worksheetWIP.selectedInboundItem;
            selInbound.isFileDeleted = true;
        }
    },
    select: (files: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (files && files.length > 0) {
            const appState = getState();
            if (appState && appState.planning) {
                let uploadedFiles: Array<UploadableFile> = [];
                uploadedFiles = appState.planning.mxRequestUploadedFiles;
                for (let i = 0; i < files.length; i++) {
                    let maxTaskId = 0;
                    if (uploadedFiles) {
                        maxTaskId = uploadedFiles && Math.max.apply(null, uploadedFiles.map(function (o) { return o.id; }))
                    }
                    var file = { id: maxTaskId + 1, file: files[i], uri: files[i].name, fileName: '', imageUri:'' };
                    uploadedFiles.push(file);
                }

                dispatch({ type: 'UPLOAD_FILES', files: uploadedFiles, deletedFiles: appState.planning.mxRequestDeleteFIles })
            }
            setTimeout(() => {
                dispatch({ type: 'SHOW_LOADER' })
            }, 2000)
        }
    },
    deleteFile: (fileToDelete: File, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (fileToDelete) {
            const appState = getState();
            if (appState && appState.planning) {
                let uploadedFiles: Array<UploadableFile> = [];
                uploadedFiles = appState.planning.mxRequestUploadedFiles;
                uploadedFiles = uploadedFiles.filter((fw) => fw.file !== fileToDelete);
                let deletedFiles: Array<string> = [];
                if (fileName && fileName != '') {

                    deletedFiles = appState.planning.mxRequestDeleteFIles;
                    deletedFiles.push(fileName);
                }
                dispatch({ type: 'UPLOAD_FILES', files: uploadedFiles, deletedFiles: deletedFiles })
            }
        }
    },
    CloseImageValidError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_IMAGE_VALID', showImageValid: false })
    },
    selectImage: (file: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheetWIP) {
            dispatch({ type: "TOGGLE_IMAGE", file: file, toggleImg: !appState.worksheetWIP.imageOpen });
        }
    },
}

export const reducer: Reducer<WorksheetWIPState> = (state: WorksheetWIPState | undefined, incomingAction: Action): WorksheetWIPState => {
    if (state === undefined) {
        return {
            isLoading: false,
            selectedTail: null,
            showAddMx: false,
            tailRecordId: 0,
            selectedMxR: {
                mxrequestFiles: [],
                description: '', id: 0, mxRequestTypesId: 1, deferredReasonId: 1, mxRequestStatusId: 1, notes: '', refNumber: '', mxRequestPlanningStatusId: 1, tailRecordId: 0, mxRequestTypesValues: { label: 'Select type', value: 0 },
                mxRequestStatusValues: { label: 'Select Status', id: 0 },
                mxRequestDeferredReasonValues: { label: 'Select Reason', id: 0 },
                mxRequestPlanningstatusvalues: { label:'Select Planning Status', id:0 },
                files: [],
            }, mxTypes: [{ id: 1, requestText: 'Workload planning WO' }, { id: 2, requestText: 'Action Plan' }]
            , mxPlanningStatus: []
            , mxStatus: []
            , mxDeferredReason: []
            , selectedPlannedItem: {
                ataCode: '', correctiveAction: '', days: 0, defect_description: '', deferralReason: '',
                id: 0, mddr: 0, mel: '', plannedDeferredTypeId: 1, status: '', mddrstatusId: 0,
                mddrstatusValues: { label: 'Select Status', id: 0 },
                plannedDeferredTypeValues: { label: 'Select deferred type', id: 0 },
                tailRecordId: 0
            }, deferredType: []
            , mddrStatus: []
            , showPlannedItem: false,
            showInBound: false,
            selectedInboundItem: {
                ataCode: '', correctiveAction: '', description: '', deferralReason: '',
                id: 0, mel: '', inBoundDeferredTypeId: 1, inBoundStatusId: 0,
                inBoundStatusValues: { label: 'Select status', id: 0 },
                inBoundDeferredTypeValues: { label: 'Select deferred type', id: 0 },
                files: [], notes: '', typeId: 0, typeValues: { label: 'Select type', id: 0 },
                inBoundFiles: [], tailRecordId: 0, fileName: "", isFileDeleted: false
            }, inBoundType: [],
            mxRequestUploadedFiles: [],
            mxRequestDeleteFIles: [],
            showFileValidation: false,
            errorMessage: '',
            selectedInboundItemToDelete: null,
            showDeleteInboundItemConfirmation: false,
            selectedmxrRequest: null,
            showDeleteMxrConfirmation: false,
            imageOpen: false,
            selectedImage: null as any,
        }
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_TAILS':
            return {
                isLoading: true,
                selectedTail: state.selectedTail, showAddMx: state.showAddMx,
                showPlannedItem: state.showPlannedItem,
                tailRecordId: action.payload,
                selectedMxR: state.selectedMxR
                , mxTypes: state.mxTypes
                , mxPlanningStatus: state.mxPlanningStatus
                , mxStatus: state.mxStatus
                , mxDeferredReason: state.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: state.deferredType
                , mddrStatus: state.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: state.showInBound
                , inBoundType: state.inBoundType,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                showDeleteInboundItemConfirmation: false,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };
        case 'RECEIVE_TAILS':
            return {
                isLoading: false,
                selectedTail: action.selectedTail, showAddMx: state.showAddMx,
                showPlannedItem: state.showPlannedItem,
                tailRecordId: state.tailRecordId,
                selectedMxR: state.selectedMxR
                , mxTypes: action.mxTypes
                , mxPlanningStatus: action.mxPlanningStatus
                , mxStatus: action.mxStatus
                , mxDeferredReason: action.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: action.deferredType
                , mddrStatus: action.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: state.showInBound
                , inBoundType: action.inBoundType,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                showDeleteInboundItemConfirmation: false,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };
        case 'SHOW_ADDMxR':
            return {
                isLoading: state.isLoading,
                selectedTail: state.selectedTail,
                showAddMx: true,
                showPlannedItem: state.showPlannedItem,
                tailRecordId: state.tailRecordId,
                selectedMxR: state.selectedMxR
                , mxPlanningStatus: state.mxPlanningStatus
                , mxTypes: state.mxTypes
                , mxStatus: state.mxStatus
                , mxDeferredReason: state.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: state.deferredType
                , mddrStatus: state.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: state.showInBound
                , inBoundType: state.inBoundType,
                mxRequestUploadedFiles: action.files,
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                showDeleteInboundItemConfirmation: false,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };
        case 'CLOSE_ADDMxR':
            return {
                isLoading: action.isLoading,
                selectedTail: state.selectedTail,
                showAddMx: false,
                showPlannedItem: state.showPlannedItem,
                tailRecordId: state.tailRecordId,
                selectedMxR: state.selectedMxR
                , mxPlanningStatus: state.mxPlanningStatus
                , mxTypes: state.mxTypes
                , mxStatus: state.mxStatus
                , mxDeferredReason: state.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: state.deferredType
                , mddrStatus: state.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: state.showInBound
                , inBoundType: state.inBoundType,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                showDeleteInboundItemConfirmation: false,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };

        case 'SHOW_ADD_PLANNED':
            return {
                isLoading: state.isLoading,
                selectedTail: state.selectedTail,
                showAddMx: state.showAddMx,
                showPlannedItem: true,
                tailRecordId: state.tailRecordId,
                selectedMxR: state.selectedMxR
                , mxTypes: state.mxTypes
                , mxPlanningStatus: state.mxPlanningStatus
                , mxStatus: state.mxStatus
                , mxDeferredReason: state.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: state.deferredType
                , mddrStatus: state.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: state.showInBound
                , inBoundType: state.inBoundType,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                showDeleteInboundItemConfirmation: false,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };
        case 'CLOSE_ADD_PLANNED':
            return {
                isLoading: action.isLoading,
                selectedTail: state.selectedTail,
                showAddMx: state.showAddMx,
                showPlannedItem: false,
                tailRecordId: state.tailRecordId,
                selectedMxR: state.selectedMxR
                , mxTypes: state.mxTypes
                , mxPlanningStatus: state.mxPlanningStatus
                , mxStatus: state.mxStatus
                , mxDeferredReason: state.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: state.deferredType
                , mddrStatus: state.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: state.showInBound
                , inBoundType: state.inBoundType,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                showDeleteInboundItemConfirmation: false,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };

        case 'SHOW_ADD_INBOUND':
            return {
                isLoading: state.isLoading,
                selectedTail: state.selectedTail,
                showAddMx: state.showAddMx,
                showPlannedItem: state.showPlannedItem,
                tailRecordId: state.tailRecordId,
                selectedMxR: state.selectedMxR
                , mxTypes: state.mxTypes
                , mxPlanningStatus: state.mxPlanningStatus
                , mxStatus: state.mxStatus
                , mxDeferredReason: state.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: state.deferredType
                , mddrStatus: state.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: true
                , inBoundType: state.inBoundType,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                selectedmxrRequest: null,
                showDeleteInboundItemConfirmation: false,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };
        case 'CLOSE_INBOUND':
            return {
                isLoading: action.isLoading,
                selectedTail: state.selectedTail,
                showAddMx: state.showAddMx,
                showPlannedItem: state.showPlannedItem,
                tailRecordId: state.tailRecordId,
                selectedMxR: state.selectedMxR
                , mxTypes: state.mxTypes
                , mxPlanningStatus: state.mxPlanningStatus
                , mxStatus: state.mxStatus
                , mxDeferredReason: state.mxDeferredReason
                , selectedPlannedItem: state.selectedPlannedItem
                , deferredType: state.deferredType
                , mddrStatus: state.mddrStatus
                , selectedInboundItem: state.selectedInboundItem
                , showInBound: false
                , inBoundType: state.inBoundType,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                selectedInboundItemToDelete: null,
                showDeleteInboundItemConfirmation: false,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                imageOpen: false
                , selectedImage: state.selectedImage,
            };
        case 'UPLOAD_FILES':
            return {
                ...state,
                mxRequestUploadedFiles: action.files,
                mxRequestDeleteFIles: action.deletedFiles,
                isLoading: false,
            };
        case 'CLOSE_IMAGE_VALID':
            return {
                ...state,
                errorMessage: '',
                showFileValidation: action.showImageValid
            };
        case 'SHOW_IMAGE_VALID':
            return {
                ...state,
                errorMessage: action.message,
                showFileValidation: action.showImageValid
            };
        case 'SHOW_LOADER':
            return {
                ...state,
                isLoading: true
            }
        case 'SHOW_DELETE_IBITEM_CONF':
            return {
                ...state,
                showDeleteInboundItemConfirmation: true,
                selectedInboundItemToDelete: action.inboundItem
            };
        case 'CLOSE_DELETE_IBITEM_CONF':
            return {
                ...state,
                showDeleteInboundItemConfirmation: false,
            };
        case 'SHOW_DELETE_Mxr_CONF':
            return {
                ...state,
                showDeleteMxrConfirmation: true,
                selectedmxrRequest: action.mxr
            };
        case 'CLOSE_DELETE_Mxr_CONF':
            return {
                ...state,
                showDeleteMxrConfirmation: false,
            };
        case 'TOGGLE_IMAGE':
            return {
                ...state,
                imageOpen: action.toggleImg,
                selectedImage: action.file,
                isLoading: false,                 
            }
        default:
            return state;
    }
}