import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { toast } from 'react-toastify';

export interface TraxFileState {
    loading: boolean;
    selectedFile: any;
    statusMessage: string;
    requests: TraxFileRequests[];
    loadingRequests: boolean;
}



export interface TraxFileRequests {
    id: number
    dateString: string;
    uploadedFileName: string;
    uploadedBy: string;
    statusString: string;
}

export interface RequestRequestsAction {type: 'REQUEST_REQUESTS';}
export interface ReceiveRequestsAction { type: 'RECEIVE_REQUESTS'; requests: TraxFileRequests[]; }
export interface UploadingFileAction { type: 'UPLOAD_FILE', event: any; }
export interface SelectFileAction { type: 'SELECT_FILE', event: any; }
export interface FileUploadedSuccessAction { type: 'FILE_UPLOAD_SUCCESS'; requests: TraxFileRequests[];}
export interface FileUploadFailureAction { type: 'FILE_UPLOAD_FAILURE' }
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = UploadingFileAction | SelectFileAction |
    FileUploadedSuccessAction | FileUploadFailureAction |
    ReceiveRequestsAction | RequestRequestsAction;

export const actionCreators = {
    upload: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (appState && appState.traxFile) {
            console.log(appState.traxFile)
            if (appState.traxFile.selectedFile == null) {
                toast('Please select a file to upload');
                return;
            }

            dispatch({ type: 'UPLOAD_FILE', event: appState.traxFile.selectedFile });

            const token = sessionStorage.getItem('access_token_augur');
            //console.log(token);

            const data = new FormData()
            data.append('file', appState.traxFile.selectedFile)
            data.append('SID', "1")

                axios.post(`api/TraxRequest`, data, {
                    headers: !token ? {} : {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.data as Promise<TraxFileRequests>)
                    .then(res => { // then print response status
                        var reqs = [res];
                        if (appState.traxFile && appState.traxFile.requests) {
                            reqs = reqs.concat(appState.traxFile.requests);
                        }
                    toast('File queued for processing.');
                    dispatch({ type: 'FILE_UPLOAD_SUCCESS', requests: reqs });
                })

        }

    },
    select: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(event)
        //dispatch({ type: 'SELECT_FILE', event: event[0] });

        const appState = getState();
        if (appState && appState.traxFile) {
            console.log(appState.traxFile)
            if (event ===null || event[0] === null) {
                toast('Please select a file to upload');
                return;
            }

            dispatch({ type: 'UPLOAD_FILE', event: event[0] });

            const token = sessionStorage.getItem('access_token_augur');
            //console.log(token);

            const data = new FormData()
            data.append('file', event[0])
            data.append('SID', "1")

            axios.post(`api/TraxRequest`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<TraxFileRequests>)
                .then(res => { // then print response status
                    var reqs = [res];
                    if (appState.traxFile && appState.traxFile.requests) {
                        reqs = reqs.concat(appState.traxFile.requests);
                    }
                    toast('File queued for processing.');
                    dispatch({ type: 'FILE_UPLOAD_SUCCESS', requests: reqs });
                })
        }
    },
    fetachRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        //const appState = getState();

        //fetch station
        //would be used to fetch all at a later stage
        const appState = getState();
        //if (appState && appState.station) {
        //    alert(appState.station.selectedStation.stationName);
        //}

        const token = sessionStorage.getItem('access_token_augur');
            console.log(token);
            fetch(`api/TraxRequest?StationID=${"1"}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<TraxFileRequests[]>)
                .then(data => {
                    console.log(data);
                    dispatch({ type: 'RECEIVE_REQUESTS', requests: data });
                });

        dispatch({ type: 'REQUEST_REQUESTS' });
    }

};

export const reducer: Reducer<TraxFileState> = (state: TraxFileState | undefined, incomingAction: Action): TraxFileState => {
    if (state === undefined) {
        return { loading: false, selectedFile: null, statusMessage: "", requests: [], loadingRequests: false };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'UPLOAD_FILE':
            return { loading: true, selectedFile: action.event, statusMessage: "", requests: state.requests, loadingRequests: true };
        case 'SELECT_FILE':
            {
                console.log(action.event);
                return { loading: false, selectedFile: action.event, statusMessage: "", requests: state.requests, loadingRequests: false };
            }
        case 'FILE_UPLOAD_SUCCESS':
            {
                return { loading: false, selectedFile: null, statusMessage: "", requests: action.requests, loadingRequests: false }
            }
        case 'REQUEST_REQUESTS':
            return {
                requests: state.requests, loadingRequests: true, loading: false, selectedFile: null, statusMessage: "",
            }
        case 'RECEIVE_REQUESTS':
                return {
                    requests: action.requests,
                    loadingRequests: false,
                    loading: false, selectedFile: null, statusMessage: "",
                };
        default:
            return state;
    }
};