import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import moment from 'moment';

type gridColumns = {
    headerName: string;
    field: string;
    minWidth: number;
    width: number;
    cellRenderer: any;
    cellClass: string;
    unSortIcon: boolean;
    suppressSorting: boolean;
    suppressFilter: boolean;
    checkboxSelection: boolean;
    headerCheckboxSelection: boolean;
}
var gridColumnHeaders: gridColumns[] = [
    {
        headerName: "Name", field: "reportName", minWidth: 170, width: 270, cellClass: 'gridText', unSortIcon: true, suppressSorting: true, suppressFilter: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: function (params: any) {
            return `<span style="margin-left: 15px;">${params.data.reportName}</span>`
        },

    },
    {
        headerName: "Date Range", field: "reportDateRange", minWidth: 80, width: 140, cellClass: 'gridText', unSortIcon: true, suppressSorting: true,
        suppressFilter: false,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        cellRenderer: ""
    },
    {
        headerName: "Station", field: "stationName", minWidth: 80, width: 140, cellClass: 'gridText', unSortIcon: true, suppressSorting: true,
        suppressFilter: false,
        checkboxSelection: false, headerCheckboxSelection: false,
        cellRenderer: ""
    }
];


export interface ManpowerReports {
    id: number;
    ReportName: string;
    ReportDate: Date;
    StationId: number;
    docURL: string;
    ReportDateRange: string;
}

export interface ManpowerReportState {
    isLoading: boolean;
    startDate: any;
    endDate: any;
    manpowerReports: ManpowerReports[];
    selectedReportIds: number[];
    selectedReportUrls: any[];
    stations: any[];
    selectedStationId: number;
    columnDefs: gridColumns[];
    showDateRangeValidation: boolean;
    isShowInvalidDateModel: boolean;
    isShowInvalidDateRangeModel: boolean;
}
export interface DropDownTypes {
    id: number,
    requestText: string
}
export interface ReceiveReportsAction {
    type: 'RECEIVE_REPORTS', reports:any ,stations: any, stationId: number;
}
export interface SetSelectedReportIds {
    type: 'UPDATE_REPORT_IDS', reportIds: number[],reportUrls:any[]
}
export interface UpdateLoader {
    type: 'UPDATE_LOADER', isLoading: boolean
}
export interface SetFilters {
    type:'SET_FILTERS',filters:any
}
export interface ToggleDateRangeValidation {
    type: 'TOGGLE_DATE_RANGE_VALIDATION', showDateRangeValidation: boolean
}
export interface UpdateInvalidDateSeletedAction {
    type: 'UPDATE_INVALID_DATE_SELECTED',
    isShow: boolean;
}
export interface UpdateInvalidDateRangeSeletedAction {
    type: 'UPDATE_INVALID_DATE_RANGE_SELECTED',
    isShow: boolean;
}

export type KnownAction = ReceiveReportsAction | SetSelectedReportIds | SetFilters | UpdateLoader | ToggleDateRangeValidation | UpdateInvalidDateSeletedAction | UpdateInvalidDateRangeSeletedAction;

export const actionCreators = {

    fetchReports: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const token = sessionStorage.getItem('access_token_augur');
        let stationId = 0;
        if (sessionStorage.getItem('selectedStation')) {
            stationId = JSON.parse(String(sessionStorage.getItem('selectedStation'))).value;
        }
        fetch(`api/reportheads/FetchManpowerReports?&stationId=${stationId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(response => response.json() as Promise<any>)
            .then(data => {
                dispatch({
                    type: 'RECEIVE_REPORTS',
                    reports: data.manpowerReports,
                    stations: data.stations,
                    stationId
                });
            });
        dispatch({ type: 'UPDATE_LOADER', isLoading:true });
    },

    FetchFilteredReports: (stationId: number, startDate: any, endDate: any): AppThunkAction<KnownAction> => (dispatch, getState) => {        
        if (startDate!==null && endDate!==null) {
            const appState = getState();
            let stations: any = [];
            if (appState && appState.manpowerReport) {
                stations = appState.manpowerReport.stations;
            }
            const token = sessionStorage.getItem('access_token_augur');
            if (!stationId || stationId== 0 &&  sessionStorage.getItem('selectedStation')) {
                stationId = JSON.parse(String(sessionStorage.getItem('selectedStation'))).value;
            }
            fetch(`api/reportheads/FetchFilteredManpowerReports?&stationId=${stationId}&startDate=${startDate}&endDate=${endDate}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
                .then(data => {
                    dispatch({
                        type: 'RECEIVE_REPORTS',
                        reports: data.manpowerReports,
                        stations: stations && stations.length > 0 ? stations  : data.stations,
                        stationId
                    });
                });
            dispatch({ type: 'TOGGLE_DATE_RANGE_VALIDATION', showDateRangeValidation: false });
            dispatch({ type: 'UPDATE_LOADER', isLoading: true });
        }
        else {            
            dispatch({ type: 'TOGGLE_DATE_RANGE_VALIDATION', showDateRangeValidation:true });
        }
    },
    ExportReports: (reportIds: number[], selectedDocs: any[], downloadFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        for (let i = 0; i < selectedDocs.length; i++) {
            downloadFile(selectedDocs[i].toString())
        }
    },
    SetSelectedReportIds: (reportIds: number[], reportUrls: any[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_REPORT_IDS', reportIds, reportUrls });
    },
    SetFilters: (startDate: any, endDate: any, stationId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_FILTERS', filters: { startDate, endDate, stationId } });
    },
    CloseDateRangeValidation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_DATE_RANGE_VALIDATION', showDateRangeValidation:false });
    },
    UpdateInvalidDateSeleted: (isShowflag: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_INVALID_DATE_SELECTED', isShow: isShowflag })
    },
    UpdateInvalidDateRangeSeleted: (isShowflag: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_INVALID_DATE_RANGE_SELECTED', isShow: isShowflag })
    },
}

export const reducer: Reducer<ManpowerReportState> = (state: ManpowerReportState | undefined, incomingAction: Action): ManpowerReportState => {
    if (state === undefined) {
        return {
            endDate: null,
            isLoading: false,
            startDate: null,
            manpowerReports: [],
            selectedReportIds: [],
            selectedReportUrls: [],
            columnDefs: gridColumnHeaders,
            stations: [],
            selectedStationId: 0,
            showDateRangeValidation: false,
            isShowInvalidDateModel: false,
            isShowInvalidDateRangeModel: false
        };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_REPORTS':
            return {
                ...state,
                manpowerReports: action.reports,
                stations: action.stations,
                selectedStationId: action.stationId,
                isLoading: false,
                selectedReportIds: [],
                selectedReportUrls: []
            };
        case 'UPDATE_REPORT_IDS':
            return {
                ...state,
                selectedReportIds: action.reportIds,
                selectedReportUrls: action.reportUrls
            };
        case 'SET_FILTERS':
            return {
                ...state,
                startDate: action.filters.startDate,
                endDate: action.filters.endDate,
                selectedStationId: action.filters.stationId
            };
        case 'UPDATE_LOADER':
            return {
                ...state,
                isLoading: action.isLoading
            };
        case 'TOGGLE_DATE_RANGE_VALIDATION':
            return {
                ...state,
                showDateRangeValidation: action.showDateRangeValidation
            };
        case 'UPDATE_INVALID_DATE_SELECTED':
            return {
                ...state,
                isShowInvalidDateModel: action.isShow
            };
        case 'UPDATE_INVALID_DATE_RANGE_SELECTED':
            return {
                ...state,
                isShowInvalidDateRangeModel: action.isShow
            };
        default:
            return state;
    }
}