import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
 

export interface DropDownTypes {
    value: number,
    label: string
}

export interface UserDetail {
    userId: number
    id: number
    email: string;
    userName: string;
    roleDescription: string;
    strUserId: string;
    roleId: number;
}
export interface UserResult {
    userRecords: UserDetail[],
    rolesList: { label: string, value: string }[];
}

export interface Tasks {
    id: number;
    taskId: number;
    taskCategoryId: number;
    aircraftId: number;
    taskDescription: string;
    time: string;
    comment: string;
    isAircraftRequired: boolean;
}

export interface Timesheet {
    id: number;
    clockInDate: Date;
    clockOutDate: Date;
    clockInTime: string;
    clockOutTime: string;
    statusId: number;
    updatedAt: Date;
    userDisplayId: string;
    userName: string;
    position: string;
    totalShiftHours: number;
    totalTaskHous: number;
    totalOTHours: string;
}

export interface TimeAndLaborState {
    id: number;
    selectedCheckInDate: Date;
    selectedCheckOutDate: Date;
    tasks: Tasks[];
    userId: string;
    userName: string;
    position: string;
    clickInTime: string;
    clickOutTime: string;
    totalShiftHours: number;
    statusId: number;
    totalTaskHous: number;
    totalOTHours: string;
    taskCategoriesList: any[]
    aircraftList: any[];
    showAddComment: boolean;
    showClickInTime: boolean;
    showClickOutTime: boolean;
    showHoursDifference: boolean;
    showDeleteConfirmation: boolean;
    selectedComment: TaskComment;
    isLoading: boolean;
    updatedAt: Date;
    employeeId: string;
    selIdToDelete: number;
    selTaskIdToDelete: number;
    isDisable: boolean;
    showTaskValidation: boolean;
    closeTaskValidation: boolean;
    errorMessage: string;
    taskCategories: TaskCategories[];
    userList: UserDetail[];
    userListOption: DropDownTypes[];
    isShowApprovalPage: boolean;
    isTimesheetApprovalSuccess: number,
    isTablet: boolean
}

export interface TaskComment {
    id: number,
    taskId: number,
    commentText: string,
}

export interface TaskCategories {
    id: number,
    stationId: number,
    name: string,
    isAircraftRequired: boolean
}

export interface AddTaskAction { type: 'ADD_TASK'; task: Tasks}
export interface ShowTimeAction { type: 'SHOW_TIME', tasks: any[], userId: string, userName: string, position: string }
export interface UpdateSearchText { type: 'UPDATE_SEARCH', searchTerm: string }
export interface EnableLoading { type: 'ENABLE_LOADING' }
export interface ShowAddCommentAction { type: 'SHOW_ADDCOMMENT'; selectedTask: any }
export interface CloseAddCommentAction { type: 'CLOSE_ADDCOMMENT'; isloading: boolean }
export interface CloseDeleteConfirmationAction { type: 'CLOSE_DELETE_CONF'; isloading: boolean }
export interface ShowDeleteConfirmationAction { type: 'SHOW_DELETE_CONF'; isloading: boolean, id: number, selectedTaskId: number}
export interface CloseHoursDifferenceAction { type: 'CLOSE_HOURS_DIFF'; isloading: boolean }
export interface ShowHoursDifferenceAction { type: 'SHOW_HOURS_DIFF'; isloading: boolean }
export interface DeleteTaskAction { type: 'DELETE_TASK'; id: number }
export interface InitialRequestAction { type: 'INITIAL_REQUEST', taskCategories: TaskCategories[], updatedAt: Date, empId: string, tasks: any[], userId: string, userName: string, position: string, taskCategoriesList: any[], aircraftList: any[]}
export interface RecieveTime { type: 'RECIEVE_TIME', totalShift: number, taskCategories: TaskCategories[], updatedAt: Date, empId: string, tasks: any[], userId: string, userName: string, position: string, taskCategoriesList: any[], aircraftList: any[], timesheet: Timesheet }
export interface RecieveUserTimeSheetAction { type: 'RECIEVE_USER_TIMESHEET', totalShift: number, taskCategories: TaskCategories[], updatedAt: Date, empId: string, tasks: any[], userId: string, userName: string, position: string, taskCategoriesList: any[], aircraftList: any[], timesheet: Timesheet }
export interface ShowClickInTimeAction { type: 'SHOW_CLICK_IN', time: string, shiftHours: number }
export interface ShowClickOutTimeAction { type: 'SHOW_CLICK_OUT', time: string, shiftHours: number }
export interface ShowClickInTimeKeeperAction { type: 'SHOW_CLICK_IN_TIME' }
export interface HideClickInTimeKeeperAction { type: 'HIDE_CLICK_IN_TIME' }
export interface ShowClickOutTimeKeeperAction { type: 'SHOW_CLICK_OUT_TIME' }
export interface HideClickOutTimeKeeperAction { type: 'HIDE_CLICK_OUT_TIME' }
export interface SelectCheckInDateAction { type: 'SELECT_CHECKIN_DATE'; date: Date, shiftHours: number }
export interface SelectCheckOutDateAction { type: 'SELECT_CHECKOUT_DATE'; date: Date }
export interface UpdateTaskAction { type: 'UPDATE_TASK'; tasks: Tasks[], totalTaskHours: number }
export interface UpdateTimeIdAction { type: 'UPDATE_TIMEID'; id: number, statusId: number, updatedAt: Date, tasks: Tasks[], isDisable: boolean, isLandTopprovalPage: boolean }
export interface UpdateCommentAction { type: 'UPDATE_COMMENT', selectedComment: TaskComment }
export interface UpdateOTHoursAction { type: 'UPDATE_OT_HOURS', totalHours: string }
export interface ShowTaskValidAction { type: 'SHOW_TASK_VALID', showTaskValid: boolean, message: string }
export interface CloseTaskValidAction { type: 'CLOSE_TASK_VALID', showTaskValid: boolean }
export interface UpdateLoaderAction { type: 'UPDATE_LOADER', isLoading: boolean }
export interface EditAction { type: 'EDIT_TIME', statusId: number, updatedAt: Date, isDisable: boolean }
export interface ReceiveUserAction { type: 'RECEIVE_USERS'; userRecords: UserDetail[], userListOption: DropDownTypes[], roles: { label: string, value: string }[] }
export interface ApproveTimesheetAction { type: 'APPROVE_TIMESHEET', timesheetApprovalStatus: number }
export interface UpdateShowApprovalPageAction { type: 'UPDATE_SHOWAPPROVAL_PAGE', isLanding: boolean }
export interface UpdateScreenType { type: 'UPDATE_SCREEN_TYPE', IsTablet:boolean }

export type KnownAction = InitialRequestAction | ShowDeleteConfirmationAction | CloseDeleteConfirmationAction
    | ShowHoursDifferenceAction | CloseHoursDifferenceAction | UpdateTimeIdAction | UpdateTaskAction
    | ShowClickOutTimeAction | SelectCheckInDateAction | SelectCheckOutDateAction | ShowClickOutTimeKeeperAction
    | HideClickOutTimeKeeperAction | ShowClickInTimeAction | ShowClickInTimeKeeperAction | HideClickInTimeKeeperAction
    | RecieveTime | AddTaskAction | ShowTimeAction | ShowAddCommentAction | CloseAddCommentAction | DeleteTaskAction
    | UpdateCommentAction | UpdateOTHoursAction | ShowTaskValidAction | CloseTaskValidAction | UpdateLoaderAction
    | EditAction | ReceiveUserAction | RecieveUserTimeSheetAction | ApproveTimesheetAction | UpdateShowApprovalPageAction
    | UpdateScreenType;

export const actionCreators = {

    SelectUser: (id: string,): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.timeAndLabor) {
            const token = sessionStorage.getItem('access_token_augur');
            let selectedUser = appState.timeAndLabor.userList.filter(a => a.id.toString() == id)
            //console.log('--Add_USER_Filtered--', selectedUser);
            let userEmail = selectedUser[0].email;
            let station = 'CVG';
            let stationId=0;
            if (appState.station && appState.station.selectedStation) {
                station = appState.station.selectedStation.stationName;
                stationId = parseInt(appState.station.selectedStation.value);
            }

            let url = `api/TimeAndLabor/GetUserTimeSheet?date=${new Date().toDateString()}&stationName=${station}&emailId=${userEmail}&stationId=${stationId}`;
            fetch(url,
                {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                }).then(response => response.json() as Promise<any>)
                .then(data => {
                    //console.log("selected user timeSheet:---", data);
                    if (data.timesheet.id === 0) {
                        dispatch({
                            type: 'INITIAL_REQUEST',
                            tasks: [],
                            taskCategories: data.taskCategories && data.taskCategories.length > 0 ? data.taskCategories : [],
                            userId: data.userId,
                            userName: data.userName,
                            position: data.position,
                            empId: data.employeeId,
                            taskCategoriesList: data.taskCategoriesList && data.taskCategoriesList.length > 0 ? data.taskCategoriesList : [],
                            aircraftList: data.aircraftList && data.aircraftList.length > 0 ? data.aircraftList : [],
                            updatedAt: new Date(),

                        });
                    }
                    else {
                        var checkInDate = moment(new Date(data.timesheet.clockInDate).toDateString() + ' ' + data.timesheet.clockInTime);
                        var checkOutDate = moment(new Date(data.timesheet.clockOutDate).toDateString() + ' ' + data.timesheet.clockOutTime);
                        //console.log(checkInDate);
                        //console.log(checkOutDate);
                        var duration = moment.duration(checkOutDate.diff(checkInDate));
                        var hours = duration.asHours();
                        let updated = new Date(data.timesheet.updatedAt)
                        updated.setMinutes(updated.getMinutes() - updated.getTimezoneOffset())
                        if (data.timesheet.statusId === 2) {
                            let param = new FormData();
                            param.append('timesheetId', data.timesheet.id);
                            param.append('lockTimesheet', "true");
                            axios.post(`api/TimeAndLabor/LockUnlockTimesheetStatus`, param, {
                                headers: !token ? {} : {
                                    'Authorization': `Bearer ${token}`
                                }
                            })
                                .then(response =>
                                    response.data as Promise<any>
                                )
                            data.timesheet.statusId = 4;
                        }
                        dispatch({
                            type: 'RECIEVE_USER_TIMESHEET',
                            userId: data.userId,
                            userName: data.userName,
                            position: data.position,
                            empId: data.employeeId,
                            tasks: data.timesheet && data.timesheet.tasks as Tasks[],
                            taskCategories: data.taskCategories && data.taskCategories.length > 0 ? data.taskCategories as TaskCategories[] : [],
                            taskCategoriesList: data.taskCategoriesList && data.taskCategoriesList.length > 0 ? data.taskCategoriesList : [],
                            aircraftList: data.aircraftList && data.aircraftList.length > 0 ? data.aircraftList : [],
                            timesheet: data.timesheet,
                            //updatedAt: new Date(data.timesheet.updatedAt).setMinutes(date.getMinutes() - date.getTimezoneOffset()),
                            totalShift: hours,
                            updatedAt: updated,
                        });
                    }
                }).catch(error =>
                    dispatch({
                        type: 'INITIAL_REQUEST',
                        empId: '',
                        tasks: [],
                        taskCategories: [],
                        userId: '',
                        userName: '',
                        position: '',
                        taskCategoriesList: [],
                        aircraftList: [],
                        updatedAt: new Date(),
                    }))
            dispatch({ type: 'UPDATE_LOADER', isLoading: true });

        }
    },
    FetchHeads: (id: string, selectedDate: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        let station = 'CVG';
        let stationId = 0;
        if (appState && appState.station && appState.station.selectedStation) {
            station = appState.station.selectedStation.stationName;
            stationId = parseInt(appState.station.selectedStation.value);
        }
        let url = '';
        let isSupervisor = false;
        if (id == null) {
            url = `api/TimeAndLabor?date=${new Date().toDateString()}&stationName=${station}&stationId=${stationId}`;
        }
        else {
            if (parseInt(id) == 0) {
                selectedDate = new Date();
            }
            url = `api/TimeAndLabor/GetTimeSheet?id=${id}&selectedDate=${selectedDate.toDateString()}&station=${station}`;
            isSupervisor = true;
        }
        //fetch(`api/TimeAndLabor?date=${new Date().toDateString()}&stationName=${station}`      
        fetch(url,
            {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
            .then(data => {
                //console.log(data);
                if (data.timesheet.id === 0) {
                    dispatch({
                        type: 'INITIAL_REQUEST',
                        tasks: [],
                        taskCategories: data.taskCategories && data.taskCategories.length > 0 ? data.taskCategories : [],
                        userId: data.userId,
                        userName: data.userName,
                        position: data.position,
                        empId: data.employeeId,
                        taskCategoriesList: data.taskCategoriesList && data.taskCategoriesList.length > 0 ? data.taskCategoriesList : [],
                        aircraftList: data.aircraftList && data.aircraftList.length > 0 ? data.aircraftList : [],
                        updatedAt: new Date(),

                    });
                    if (data.userId == null) {
                        //console.log('INITIAL_REQUEST-- adding user details', data.userId);
                        fetch(`api/users`
                            , {
                                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                            }).then(response =>
                                response.json() as Promise<UserResult>
                            )
                            .then(data => {
                                let userListOptions= data.userRecords.filter(a => a.roleId != 5).map(t => {
                                    return { value: t.id, label: t.userName };
                                })                                
                                dispatch({
                                    type: 'RECEIVE_USERS',
                                    userRecords: data.userRecords,
                                    userListOption: userListOptions,
                                    roles: data.rolesList
                                });
                            });
                    }
                }
                else {
                    var checkInDate = moment(new Date(data.timesheet.clockInDate).toDateString() + ' ' + data.timesheet.clockInTime);
                    var checkOutDate = moment(new Date(data.timesheet.clockOutDate).toDateString() + ' ' + data.timesheet.clockOutTime);
                    //console.log(checkInDate);
                    //console.log(checkOutDate);
                    var duration = moment.duration(checkOutDate.diff(checkInDate));
                    var hours = duration.asHours();
                    let updated = new Date(data.timesheet.updatedAt)
                    updated.setMinutes(updated.getMinutes() - updated.getTimezoneOffset());
                    if (data.timesheet.statusId === 2 && isSupervisor) {
                        let param = new FormData();
                        param.append('timesheetId', data.timesheet.id);
                        param.append('lockTimesheet', "true");
                        axios.post(`api/TimeAndLabor/LockUnlockTimesheetStatus`, param, {
                            headers: !token ? {} : {
                                'Authorization': `Bearer ${token}`
                            }
                        })
                            .then(response =>
                                response.data as Promise<any>
                        )
                        data.timesheet.statusId = 4;
                    }
                        dispatch({
                            type: 'RECIEVE_TIME',
                            userId: data.userId,
                            userName: data.userName,
                            position: data.position,
                            empId: data.employeeId,
                            tasks: data.timesheet && data.timesheet.tasks as Tasks[],
                            taskCategories: data.taskCategories && data.taskCategories.length > 0 ? data.taskCategories as TaskCategories[] : [],
                            taskCategoriesList: data.taskCategoriesList && data.taskCategoriesList.length > 0 ? data.taskCategoriesList : [],
                            aircraftList: data.aircraftList && data.aircraftList.length > 0 ? data.aircraftList : [],
                            timesheet: data.timesheet,
                            //updatedAt: new Date(data.timesheet.updatedAt).setMinutes(date.getMinutes() - date.getTimezoneOffset()),
                            totalShift: hours,
                            updatedAt: updated
                        });
                }
            })
            .catch(error =>
                dispatch({
                    type: 'INITIAL_REQUEST',
                    empId: '',
                    tasks: [],
                    taskCategories: [],
                    userId: '',
                    userName: '',
                    position: '',
                    taskCategoriesList: [],
                    aircraftList: [],
                    updatedAt: new Date(),

                }))
    },
    ShowHoursDifference: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_HOURS_DIFF', isloading: true })
    },
    ShowDeleteConfirmation: (id: number, taskId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_CONF', isloading: true, id: id, selectedTaskId: taskId })
    },
    ShowAddComment: (task: Tasks): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let commentRestricted = task.aircraftId == 0
            || task.taskCategoryId == 0
            || task.taskDescription == null
            || task.taskDescription == ''
            || task.time == ''

        if (commentRestricted) {
            dispatch({
                type: 'SHOW_TASK_VALID',
                showTaskValid: true,
                message: 'The Category, Aircraft, Task and Time must be filled out in order to add a Comment.'
            });
        }
        else {
            let selectedComment = appState.timeAndLabor && appState.timeAndLabor.selectedComment;
            if (selectedComment) {
                selectedComment.id = 0;
                selectedComment.taskId = task.taskId;
                selectedComment.commentText = task.comment;
                dispatch({ type: 'SHOW_ADDCOMMENT', selectedTask: task.taskId })
            }
        }
    },
    CloseAddComment: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_ADDCOMMENT', isloading: false })
    },
    CloseHoursDifference: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_HOURS_DIFF', isloading: false })
    },
    CloseTaskValidError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_TASK_VALID', showTaskValid: false })
    },
    CloseDeleteConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_CONF', isloading: false })
    },
    DeleteTask: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const data = new FormData()
        const appState = getState();

        if (appState && appState.timeAndLabor && appState.timeAndLabor.selIdToDelete) {
            data.append('taskId', appState.timeAndLabor.selIdToDelete.toString())

            axios.post(`api/TimeAndLabor/DeleteTask`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    toast.dismiss();
                    toast("Task deleted Successfully!");
                })
        }
        dispatch({ type: 'UPDATE_LOADER', isLoading: true });
        if (appState && appState.timeAndLabor) {
            dispatch({ type: 'DELETE_TASK', id: appState.timeAndLabor.selTaskIdToDelete });
        }
    },
    AddTask: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.timeAndLabor) {
            const maxTaskId = appState.timeAndLabor.tasks && Math.max.apply(null, appState.timeAndLabor.tasks.map(function (o) { return o.taskId; }))
            var task = { id: 0, aircraftId: 0, taskCategoryId: 0, taskDescription: '', taskId: maxTaskId >= 0 ? maxTaskId + 1 : 1, time: '', comment: '', isAircraftRequired: true }
            dispatch({ type: 'ADD_TASK', task: task })
        }
    },
    SetClickInTime: (time: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (time) {
            const appState = getState();

            if (appState && appState.timeAndLabor) {
                var checkInDate = moment(appState.timeAndLabor.selectedCheckInDate.toDateString() + ' ' + time);
                var checkOutDate = moment(appState.timeAndLabor.selectedCheckOutDate.toDateString() + ' ' + appState.timeAndLabor.clickOutTime);

                var duration = moment.duration(checkOutDate.diff(checkInDate));
                var hours = duration.asHours();
                if (hours > 0) {
                    dispatch({ type: 'SHOW_CLICK_IN', time: time, shiftHours: hours });
                }
            }
        }
    },
    SetClickOutTime: (time: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (time) {
            const appState = getState();

            if (appState && appState.timeAndLabor) {
                var checkInDate = moment(appState.timeAndLabor.selectedCheckInDate.toDateString() + ' ' + appState.timeAndLabor.clickInTime);
                var checkOutDate = moment(appState.timeAndLabor.selectedCheckOutDate.toDateString() + ' ' + time);

                var duration = moment.duration(checkOutDate.diff(checkInDate));
                var hours = duration.asHours();
                if (hours > 0) {
                    dispatch({ type: 'SHOW_CLICK_OUT', time: time, shiftHours: hours });
                }
            }
        }
    },
    ShowClickInTimekeeeper: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_CLICK_IN_TIME' });
    },
    HideClickInTimekeeeper: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HIDE_CLICK_IN_TIME' });
    },
    ShowClickOutTimekeeeper: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_CLICK_OUT_TIME' });
    },
    HideClickOutTimekeeeper: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HIDE_CLICK_OUT_TIME' });
    },
    SelectCheckInDat: (selDate: Date): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.timeAndLabor) {
            var checkInDate = moment(selDate.toDateString() + ' ' + appState.timeAndLabor.clickInTime);
            var checkOutDate = moment(appState.timeAndLabor.selectedCheckOutDate.toDateString() + ' ' + appState.timeAndLabor.clickOutTime);

            var duration = moment.duration(checkOutDate.diff(checkInDate));
            var hours = duration.asHours();
            if (hours > 0) {
                dispatch({ type: 'SELECT_CHECKIN_DATE', date: selDate, shiftHours: hours });
            }
        }
    },
    SelectCheckOutDat: (selDate: Date): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_CHECKOUT_DATE', date: selDate });
    },
    SaveTimesheet: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.timeAndLabor) {
            //console.log('Auto save called')
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            let stationId= 0;
            if (appState && appState.station && appState.station.selectedStation) {
                stationId = parseInt(appState.station.selectedStation.value);
            }
            data.append('Id', appState.timeAndLabor.id ? appState.timeAndLabor.id.toString() : '0');
            data.append('ClockInDate', appState.timeAndLabor.selectedCheckInDate.toDateString());
            data.append('ClockInTime', appState.timeAndLabor.clickInTime.toString());
            data.append('ClockOutDate', appState.timeAndLabor.selectedCheckOutDate.toDateString());
            data.append('ClockOutTime', appState.timeAndLabor.clickOutTime.toString());
            data.append('UserId', appState.timeAndLabor.employeeId);
            data.append('TotalOTHours', appState.timeAndLabor.totalOTHours && appState.timeAndLabor.totalOTHours != '' ? Number(appState.timeAndLabor.totalOTHours).toFixed(2).toString() : '0.0');
            data.append('StationId', stationId.toString());
            let filledTasks = appState.timeAndLabor.tasks.filter(a => (a.aircraftId > 0 || !a.isAircraftRequired) && a.taskCategoryId > 0 && a.taskDescription && a.taskDescription != '' && a.time != '')
            if (filledTasks) {
                for (let i = 0; i < filledTasks.length; i++) {
                    data.append("Tasks[" + i + "].Id", filledTasks[i].id.toString());
                    data.append("Tasks[" + i + "].AircraftId", filledTasks[i].aircraftId.toString());
                    data.append("Tasks[" + i + "].TaskId", filledTasks[i].taskId.toString());
                    data.append("Tasks[" + i + "].TaskCategoryId", filledTasks[i].taskCategoryId.toString());
                    data.append("Tasks[" + i + "].TaskDescription", filledTasks[i].taskDescription);
                    data.append("Tasks[" + i + "].Time", Number(filledTasks[i].time).toFixed(2).toString());
                    data.append("Tasks[" + i + "].Comment", filledTasks[i].comment != null ? filledTasks[i].comment : '');
                }
            }

            axios.post(`api/TimeAndLabor/SaveTimesheet`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(data => {
                    //console.log(data)
                    dispatch({
                        type: 'UPDATE_TIMEID',
                        id: data.id,
                        statusId: data.statusId,
                        updatedAt: new Date(data.updatedAt),
                        tasks: data.tasks,
                        isDisable: data.statusId == 1 ? false : true,
                        isLandTopprovalPage: false,
                    });
                })
        }
    },
    SubmitTimesheet: (isLandToApprovalPage: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.timeAndLabor) {

            let emptyTasks = appState.timeAndLabor.tasks
                .filter(a => a.aircraftId == 0
                    || a.taskCategoryId == 0
                    || a.taskDescription == null
                    || a.taskDescription == ''
                    || a.time == '')

            if (emptyTasks && emptyTasks.length > 0) {
                dispatch({
                    type: 'SHOW_TASK_VALID',
                    showTaskValid: true,
                    message: 'Please complete all the entries in the task section.'
                });
            }
            else {
                const token = sessionStorage.getItem('access_token_augur');
                const data = new FormData()
                let stationId = 0;
                if (appState && appState.station && appState.station.selectedStation) {
                    stationId = parseInt(appState.station.selectedStation.value);
                }
                data.append('Id', appState.timeAndLabor.id ? appState.timeAndLabor.id.toString() : '0');
                data.append('ClockInDate', appState.timeAndLabor.selectedCheckInDate.toDateString());
                data.append('ClockInTime', appState.timeAndLabor.clickInTime.toString());
                data.append('ClockOutDate', appState.timeAndLabor.selectedCheckOutDate.toDateString());
                data.append('ClockOutTime', appState.timeAndLabor.clickOutTime.toString());
                data.append('UserId', appState.timeAndLabor.employeeId);
                data.append('TotalOTHours', appState.timeAndLabor.totalOTHours && appState.timeAndLabor.totalOTHours != '' ? Number(appState.timeAndLabor.totalOTHours).toFixed(2).toString() : '0.0');
                data.append('StationId', stationId.toString());
                let filledTasks = appState.timeAndLabor.tasks
                if (filledTasks) {
                    for (let i = 0; i < filledTasks.length; i++) {
                        data.append("Tasks[" + i + "].Id", filledTasks[i].id.toString());
                        data.append("Tasks[" + i + "].AircraftId", filledTasks[i].aircraftId.toString());
                        data.append("Tasks[" + i + "].TaskId", filledTasks[i].taskId.toString());
                        data.append("Tasks[" + i + "].TaskCategoryId", filledTasks[i].taskCategoryId.toString());
                        data.append("Tasks[" + i + "].TaskDescription", filledTasks[i].taskDescription);
                        data.append("Tasks[" + i + "].Time", Number(filledTasks[i].time).toFixed(2).toString());
                        data.append("Tasks[" + i + "].Comment", filledTasks[i].comment != null ? filledTasks[i].comment : '');
                    }
                }

                axios.post(`api/TimeAndLabor/SubmitTimesheet`, data, {
                    headers: !token ? {} : {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.data as Promise<any>)
                    .then(data => {
                        dispatch({
                            type: 'UPDATE_TIMEID',
                            id: data.id,
                            statusId: data.statusId,
                            updatedAt: data.updatedAt,
                            tasks: data.tasks,
                            isDisable: true,
                            isLandTopprovalPage: isLandToApprovalPage
                        });
                    })
                dispatch({
                    type: 'UPDATE_LOADER',
                    isLoading: true
                });
            }
        }
    },
    UpdateTask: (name: string, value: string, taskId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.timeAndLabor) {
            let newArr = [...appState.timeAndLabor.tasks];
            let task = newArr.find(a => a.taskId === taskId) as Tasks;
            if (task) {
                switch (name) {
                    case 'taskCategoryId':
                        task.taskCategoryId = Number(value)
                        let taskCategory = appState && appState.timeAndLabor && appState.timeAndLabor.taskCategories
                            && appState.timeAndLabor.taskCategories.find(a => a.id === task.taskCategoryId)
                        task.isAircraftRequired = taskCategory != null ? taskCategory.isAircraftRequired : true;
                        if (!task.isAircraftRequired) {
                            task.aircraftId = -1;                            
                        }
                        else {
                            if (task.aircraftId == -1) {
                                task.aircraftId = 0;
                            }
                        }
                        break;
                    case 'taskDescription':
                        task.taskDescription = value
                        break;
                    case 'aircraftId':
                        task.aircraftId = Number(value)
                        break;
                    case 'time':
                        //console.log(value)
                        const regex = /^\d{1,2}(\.\d{0,2})?$/;
                        if (value === '' || regex.test(value)) {
                            task.time = value
                        }
                        break;
                    default:
                        break;
                }
            }
            let totalHours = newArr.reduce(function (prev, cur) {
                return Number(prev) + Number(cur.time);
            }, 0);
            dispatch({ type: 'UPDATE_TASK', tasks: newArr, totalTaskHours: totalHours})
        }
    },
    UpdateOTHours: (value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const regex = /^\d{1,2}(\.\d{0,2})?$/;
        const appState = getState();
        //console.log(value);
        if (appState && appState.timeAndLabor) {
            //console.log('inside if')
            let totalOTHours = appState && appState.timeAndLabor && appState.timeAndLabor.totalOTHours
            if (value === '' || regex.test(value)) {
                //console.log(value)
                //console.log('passed')
                totalOTHours = value
            }

            dispatch({ type: 'UPDATE_OT_HOURS', totalHours: totalOTHours })
        }
    },
    SaveComment: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedComment = appState.timeAndLabor && appState.timeAndLabor.selectedComment;
        let taskId = selectedComment && selectedComment.taskId;
        if (selectedComment && appState.timeAndLabor && taskId != 0) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('taskId', selectedComment.taskId.toString())
            data.append('timesheetId', appState.timeAndLabor.id.toString())
            data.append('commentText', selectedComment.commentText)

            axios.post(`api/TimeAndLabor/SaveComment`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    if (appState.timeAndLabor && appState.timeAndLabor.selectedComment) {
                        let selTask = appState.timeAndLabor.tasks.find((e: any) => e && e.taskId
                            == taskId);
                        if (selTask && selectedComment) {
                            selTask.comment = selectedComment.commentText;
                        }
                    }

                    dispatch({ type: 'UPDATE_LOADER', isLoading: false });
                })
                .catch(error => dispatch({ type: 'CLOSE_ADDCOMMENT', isloading: true }))
            dispatch({ type: 'CLOSE_ADDCOMMENT', isloading: true });
        }
    },
    UpdateComment: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedComment = appState.timeAndLabor && appState.timeAndLabor.selectedComment;
        if (selectedComment) {
            selectedComment.commentText = event.target.value;
            dispatch({ type: 'UPDATE_COMMENT', selectedComment: selectedComment })
        }
    },
    TestRefresh: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        //console.log(appState.timeAndLabor);
    },
    EditTimesheet: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        let timeId = appState && appState.timeAndLabor && appState.timeAndLabor.id;
        if (timeId) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('timesheetId', timeId.toString())

            axios.post(`api/TimeAndLabor/EditTimesheet`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    //console.log(res)
                    dispatch({ type: 'EDIT_TIME', statusId: res.statusId, updatedAt: new Date(res.lastUpdatedAt), isDisable: false,});
                })
                .catch(error => dispatch({ type: 'UPDATE_LOADER', isLoading: false }))
            dispatch({ type: 'UPDATE_LOADER', isLoading: true });
        }
    },
    ApproveTimesheet: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let timeId = appState && appState.timeAndLabor && appState.timeAndLabor.id;
        if (timeId) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('timesheetId', timeId.toString());
            axios.post(`api/TimeAndLabor/ApproveTimesheet`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => response.data as Promise<any>).then(res => { // then print response status               
                let isTimesheetApprovalSuccess = 1;
                if (res.statusId == -1)
                    isTimesheetApprovalSuccess = 0;                
                dispatch({ type: 'APPROVE_TIMESHEET', timesheetApprovalStatus: isTimesheetApprovalSuccess});

            }).catch(error => {
               // console.log("error:", error);
                dispatch({ type: 'UPDATE_LOADER', isLoading: false })
            });
            dispatch({ type: 'UPDATE_LOADER', isLoading: true });
        }
    },
    ResetShowApprovalPage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_SHOWAPPROVAL_PAGE', isLanding:false  });
    },
    UnlockTimesheet: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let timesheetId = appState.timeAndLabor && appState.timeAndLabor.id
        if (timesheetId) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('timesheetId', timesheetId.toString());
            data.append('lockTimesheet', "false");
            axios.post(`api/TimeAndLabor/LockUnlockTimesheetStatus`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
        }
    },
    updateScreenType: (IsTablet: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_SCREEN_TYPE', IsTablet })
    },
};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<TimeAndLaborState> = (state: TimeAndLaborState | undefined, incomingAction: Action): TimeAndLaborState => {
    var today = new Date()
    var currentTime = today.getHours() + ':' + (today.getMinutes() < 10 ? '0' : '') + today.getMinutes()
    if (state === undefined) {
        return {
            id: 0,
            selectedCheckInDate: today,
            selectedCheckOutDate: today,
            clickInTime: currentTime,
            clickOutTime: currentTime,
            totalShiftHours: 0.0,
            totalTaskHous: 0.0,
            totalOTHours: '',
            statusId: 0,
            userId: '',
            userName: '',
            position: '',
            updatedAt: today,
            tasks: [],
            taskCategoriesList: [],
            aircraftList: [], showAddComment: false,
            showClickInTime: false,
            showClickOutTime: false,
            showHoursDifference: false,
            showDeleteConfirmation: false,
            selectedComment: { id: 0, taskId: 0, commentText: '' } as TaskComment,
            isLoading: true,
            employeeId: '',
            selIdToDelete: 0,
            selTaskIdToDelete: 0,
            isDisable: false,
            showTaskValidation: false,
            closeTaskValidation: false,
            errorMessage: '',
            taskCategories: [],
            userList: [],
            userListOption: [],
            isShowApprovalPage: false,
            isTimesheetApprovalSuccess: -1,
            isTablet:false
        };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_TASK':
            return {
                ...state,
                tasks: [...state.tasks, action.task]
            };
        case 'RECIEVE_TIME':
            return {
                ...state,
                userId: action.userId,
                userName: action.userName,
                position: action.position,
                employeeId: action.empId,
                taskCategoriesList: action.taskCategoriesList,
                aircraftList: action.aircraftList,
                tasks: action.tasks != null ? action.tasks : [{ id: 0, aircraftId: 0, taskCategoryId: 0, taskDescription: '', taskId: 1, time: '', comment: '' }],
                selectedCheckInDate: new Date(action.timesheet.clockInDate),
                selectedCheckOutDate: new Date(action.timesheet.clockOutDate),
                clickInTime: action.timesheet && action.timesheet.clockInTime,
                clickOutTime: action.timesheet&& action.timesheet.clockOutTime,
                id: action.timesheet && action.timesheet.id,
                totalTaskHous: action.tasks != null ? action.tasks.reduce(function (prev, cur) {
                    return Number(prev) + Number(cur.time);
                }, 0) : 0,
                totalOTHours: action.timesheet && action.timesheet.totalOTHours,
                statusId: action.timesheet && action.timesheet.statusId,
                updatedAt: action.updatedAt,
                totalShiftHours: action.totalShift,
                isDisable: action.timesheet && action.timesheet.statusId > 1,
                isLoading: false,
                taskCategories: action.taskCategories,
                isShowApprovalPage: false
            };
        case 'RECIEVE_USER_TIMESHEET':
            return {
                ...state,
                userId: action.userId,
                userName: action.userName,
                position: action.position,
                employeeId: action.empId,
                taskCategoriesList: action.taskCategoriesList,
                aircraftList: action.aircraftList,
                tasks: action.tasks != null ? action.tasks : [{ id: 0, aircraftId: 0, taskCategoryId: 0, taskDescription: '', taskId: 1, time: '', comment: '' }],
                selectedCheckInDate: new Date(action.timesheet.clockInDate),
                selectedCheckOutDate: new Date(action.timesheet.clockOutDate),
                clickInTime: action.timesheet && action.timesheet.clockInTime,
                clickOutTime: action.timesheet&& action.timesheet.clockOutTime,
                id: action.timesheet && action.timesheet.id,
                totalTaskHous: action.tasks != null ? action.tasks.reduce(function (prev, cur) {
                    return Number(prev) + Number(cur.time);
                }, 0) : 0,
                totalOTHours: action.timesheet && action.timesheet.totalOTHours,
                statusId: action.timesheet && action.timesheet.statusId,
                updatedAt: action.updatedAt,
                totalShiftHours: action.totalShift,
                isDisable: action.timesheet.statusId==3?true: false,
                isLoading: false,
                taskCategories: action.taskCategories
            };
        case 'INITIAL_REQUEST':
            return {
                ...state,
                id: 0,
                userId: action.userId,
                userName: action.userName,
                position: action.position,
                employeeId: action.empId,
                taskCategoriesList: action.taskCategoriesList,
                aircraftList: action.aircraftList,
                tasks: [{ id: 0, aircraftId: 0, taskCategoryId: 0, taskDescription: '', taskId: 1, time: '', comment: '', isAircraftRequired: true }],
                totalShiftHours: 0.0,
                totalTaskHous: 0.0,
                totalOTHours: '',
                isLoading: false,
                updatedAt: action.updatedAt,
                taskCategories: action.taskCategories,
                selectedCheckInDate: today,
                selectedCheckOutDate: today,
                clickInTime: currentTime,
                clickOutTime: currentTime,
                isShowApprovalPage:false,
                statusId: 0,
                isTimesheetApprovalSuccess: -1,
                isDisable: false,
            };
        case 'SHOW_ADDCOMMENT':
            return {
                ...state,
                showAddComment: true,
                isLoading: false
            };
        case 'CLOSE_ADDCOMMENT':
            return {
                ...state,
                showAddComment: false,
                isLoading: action.isloading
            };
        case 'CLOSE_HOURS_DIFF':
            return {
                ...state,
                showHoursDifference: false
            };
        case 'SHOW_HOURS_DIFF':
            return {
                ...state,
                showHoursDifference: true
            };
        case 'CLOSE_TASK_VALID':
            return {
                ...state,
                errorMessage: '',
                showTaskValidation: action.showTaskValid
            };
        case 'SHOW_TASK_VALID':
            return {
                ...state,
                errorMessage: action.message,
                showTaskValidation: action.showTaskValid
            };
        case 'CLOSE_DELETE_CONF':
            return {
                ...state,
                showDeleteConfirmation: false,
                selIdToDelete: 0,
                selTaskIdToDelete: 0
            };
        case 'SHOW_DELETE_CONF':
            return {
                ...state,
                showDeleteConfirmation: true,
                selIdToDelete: action.id,
                selTaskIdToDelete: action.selectedTaskId
            };
        case 'DELETE_TASK':
            let filteredTasks = state.tasks.filter(task => task.taskId !== action.id)
            return {
                ...state,
                showDeleteConfirmation: false,
                tasks: filteredTasks,
                totalTaskHous: filteredTasks.reduce(function (prev, cur) {
                    return Number(prev) + Number(cur.time);
                }, 0),
                isLoading: false
            };
        case 'SHOW_CLICK_IN':
            return {
                ...state,
                clickInTime: action.time,
                totalShiftHours: action.shiftHours
            };
        case 'SHOW_CLICK_OUT':
            return {
                ...state,
                clickOutTime: action.time,
                totalShiftHours: action.shiftHours
            };
        case 'SHOW_CLICK_IN_TIME':
            return {
                ...state,
                showClickInTime: true
            };
        case 'HIDE_CLICK_IN_TIME':
            return {
                ...state,
                showClickInTime: false
            };
        case 'SHOW_CLICK_OUT_TIME':
            return {
                ...state,
                showClickOutTime: true
            };
        case 'HIDE_CLICK_OUT_TIME':
            return {
                ...state,
                showClickOutTime: false
            };
        case 'SELECT_CHECKIN_DATE':
            return {
                ...state,
                selectedCheckInDate: action.date,
                totalShiftHours: action.shiftHours
            };
        case 'SELECT_CHECKOUT_DATE':
            return {
                ...state,
                selectedCheckOutDate: action.date
            };
        case 'UPDATE_TASK':
            return {
                ...state,
                tasks: action.tasks,
                totalTaskHous: action.totalTaskHours
            };
        case 'UPDATE_TIMEID':
            let allTasks = state.tasks.map((currentTask) => {
                let curTask = action.tasks && action.tasks.find(a => a.taskId == currentTask.taskId);
                if (curTask) {
                    return { ...currentTask, id: curTask.id }
                }
                else {
                    return { ...currentTask}
                }
            })
            let totalOTHours = state.totalOTHours;
            if (action.statusId == 2 && totalOTHours == '') {
                totalOTHours = '0.0'
            }
            //console.log(totalOTHours)                
            return {
                ...state,
                id: action.id,
                statusId: action.statusId,
                tasks: allTasks,
                isDisable: action.isDisable,
                updatedAt: action.updatedAt,
                totalOTHours: totalOTHours,
                isLoading: false,
                isShowApprovalPage: action.isLandTopprovalPage
            };
        case 'UPDATE_COMMENT':
            return {
                ...state,
                selectedComment: action.selectedComment
            };
        case 'UPDATE_OT_HOURS':
            return {
                ...state,
                totalOTHours: action.totalHours
            };
        case 'UPDATE_LOADER':
            return {
                ...state,
                isLoading: action.isLoading
            };
        case 'EDIT_TIME':
            //console.log(action)
            return {
                ...state,
                statusId: action.statusId,
                isDisable: action.isDisable,
                updatedAt: action.updatedAt,
                isLoading: false
            };
        case 'RECEIVE_USERS':
            return {
                ...state,
                userList: action.userRecords,
                userListOption: action.userListOption,
                isDisable:false,
                isLoading: false,
                isTimesheetApprovalSuccess:-1,
            };
        case 'APPROVE_TIMESHEET':            
            return {
                ...state,
                isTimesheetApprovalSuccess: action.timesheetApprovalStatus,
                isShowApprovalPage: action.timesheetApprovalStatus==1?true:false,
            };
        case 'UPDATE_SHOWAPPROVAL_PAGE':
            return {
                ...state,
                isShowApprovalPage: action.isLanding
            };
        case 'UPDATE_SCREEN_TYPE':
            return {
                ...state,
                isTablet: action.IsTablet
            }
        default:
            return state;
    }
};
