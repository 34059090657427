import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FormEvent } from 'react';
import { Dropdown } from 'reactstrap';

export interface DropdownValues {
    label: string,
    value:number
}
export interface UserState {
    rolesList: any[];
    userDetails: User;
    accessLevelList: any[];
    shiftList: any[];
    shiftHoursList: any[];
    defaultStationList: any[];
    shiftDaysList: any[];
    isLoading: boolean;
    email: string;
    roleId: number;
    accessLevelId: number;
    isEditMode: boolean;
    confirmDelete: boolean;
    selectFile: boolean;

}
export interface User {
    imageDisplayURI: string;
    isActive: boolean;
    userName: string;
    email: string;
    userId: number;
    roleId: number;
    roleDescription: string;
    accessLevelId: number;
    id: number;
    uploadedImageURL: string;
    imageSelected: boolean;
    file: any;
    firstName: string;
    lastName: string;
    shiftId: number;
    shiftDayId: number;
    shiftHourId: number;
    weekHours: number;
    defaultStationId: number;
    accessLevel:any
    roleValues: DropdownValues;
    accessLevelValues: DropdownValues;
    shiftValues: DropdownValues;
    shiftDaysValues: DropdownValues;
    shiftHoursValues: DropdownValues;
    defaultStationValues: DropdownValues;
}

export interface EditInformationAction { type: 'EDIT_USER'}
export interface RequestUserDetailsAction { type: 'REQUEST_USER_DETAILS'; payload: string, isEditMode: boolean }
export interface CloseAddUserAction { type: 'CLOSE_ADDUSER'; isloading: boolean, isEditMode: boolean }
export interface ReceiveUserDetailsAction {
    type: 'RECEIVE_USER_DETAILS'; userDetails: User, roles: any[], accessLevels: any[], shifts: any[], shiftDays: any[],
    shiftHours: any[], defaultStation: any[]
}
export interface ToggleConfirmDelete { type: 'TOGGLE_CONFIRM'; confirmDelete: boolean }
export interface ToggleSelectFile { type: 'TOGGLE_SELECTFILE'; selectFile: boolean}
export type KnownAction = EditInformationAction | RequestUserDetailsAction | ReceiveUserDetailsAction | CloseAddUserAction
    | ToggleConfirmDelete | ToggleSelectFile;

export const actionCreators = {
    OnFileSeleect: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //console.log(event.target.files);
        const objectURL = URL.createObjectURL(event.target.files[0]);

        const appState = getState();
        const users = appState && appState.users;
        if (users) {
            URL.revokeObjectURL(users.userDetails.uploadedImageURL);
            users.userDetails.uploadedImageURL = objectURL;
            users.userDetails.imageSelected = true;
            users.userDetails.file = event.target.files[0];
            dispatch({ type: 'TOGGLE_SELECTFILE', selectFile: true });
        }
        
    },
    ToggleSelectFile: (persist: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const users = appState && appState.users;
        if (users && !users.isEditMode) {
            if (!persist) {
                //we are closing the modal
                users.userDetails.uploadedImageURL = users.userDetails.imageDisplayURI;
                users.userDetails.imageSelected = false;
                users.userDetails.file = null;
            } else {
                users.userDetails.imageDisplayURI = users.userDetails.uploadedImageURL;

            }

            dispatch({ type: 'TOGGLE_SELECTFILE', selectFile: !users.selectFile });
        }
    },
    ToggleConfirmDelete: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        const users = appState && appState.users;
        if(users)
        dispatch({ type: 'TOGGLE_CONFIRM', confirmDelete: !users.confirmDelete });
    },
    EditUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'EDIT_USER'});
    },
    GetUserDetails: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        fetch(`api/Account?id=${id}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<UserState>)
            .then(data => {
                //console.log("user",data);
                data.userDetails.uploadedImageURL = data.userDetails.imageDisplayURI;
                data.userDetails.imageSelected = false;
                data.userDetails.file = null;
                if (id) {
                    data.userDetails.roleValues = { label: data.userDetails.roleDescription, value: data.userDetails.roleId };
                    data.userDetails.accessLevelValues = { label: data.userDetails.accessLevel.accessLevelDescription, value: data.userDetails.accessLevel.accessLevelId };
                    let shift = data.shiftList.filter(sft => sft.id == data.userDetails.shiftId)[0];
                    if (shift) {
                        data.userDetails.shiftValues = { label: shift.value, value: shift.id };
                    }
                    else {
                        data.userDetails.shiftValues = { label: "Select Shift", value: 0 };
                    }
                    let shiftDay = data.shiftDaysList.filter(sftd => sftd.id == data.userDetails.shiftDayId)[0];
                    if (shiftDay) {
                        data.userDetails.shiftDaysValues = { label: shiftDay.value, value: shiftDay.id };
                    }
                    else {
                        data.userDetails.shiftDaysValues = { label: "Select shift days", value: 0 };
                    }
                    let shiftHour = data.shiftHoursList.filter(sftd => sftd.id == data.userDetails.shiftHourId)[0];
                    if (shiftHour) {
                        data.userDetails.shiftHoursValues = { label: shiftHour.value, value: shiftHour.id };
                    }
                    else {
                        data.userDetails.shiftHoursValues = { label: "Select shift hours", value: 0 };
                    }
                    let defaultStation = data.defaultStationList.filter(std => std.id == data.userDetails.defaultStationId)[0];
                    if (defaultStation) {
                        data.userDetails.defaultStationValues = { label: defaultStation.value, value: defaultStation.id };
                    }
                    else {
                        data.userDetails.defaultStationValues = { label: "Select default station", value: 0 };
                    }
                   
                }
                else {
                    data.userDetails.roleValues = { label: "Select Role", value: 0 };
                    data.userDetails.accessLevelValues = { label: "Select Position", value: 0 };
                    data.userDetails.shiftValues = { label: "Select Shift", value: 0 };
                    data.userDetails.shiftDaysValues = { label: "Select shift days", value: 0 };
                    data.userDetails.shiftHoursValues = { label: "Select shift hours", value: 0 };
                    data.userDetails.defaultStationValues = { label: "Select default station", value: 0 };
                }
                dispatch({                    
                    type: 'RECEIVE_USER_DETAILS',
                    userDetails: data.userDetails,
                    roles: data.rolesList && data.rolesList.length > 0 ? data.rolesList : [],
                    accessLevels: data.accessLevelList && data.accessLevelList.length > 0 ? data.accessLevelList : [],
                    shifts: data.shiftList && data.shiftList.length > 0 ? data.shiftList : [],
                    shiftDays: data.shiftDaysList && data.shiftDaysList.length > 0 ? data.shiftDaysList : [],
                    shiftHours: data.shiftHoursList && data.shiftHoursList.length > 0 ? data.shiftHoursList : [],
                    defaultStation: data.defaultStationList && data.defaultStationList.length > 0 ? data.defaultStationList:[]
                });
            });

        dispatch({ type: 'REQUEST_USER_DETAILS', payload: id, isEditMode: (id == "0" || id == null) ? false : true });
    },
    CloseAddUsers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        //console.log("Close Add User")
        //console.log(appState.users)
        dispatch({ type: 'CLOSE_ADDUSER', isloading: false, isEditMode: true })
        //console.log(appState.users)
    },
    SaveUser: (userDetails: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let user = appState.users && appState.users.userDetails;

        

        if (user) {
            
            user.id = userDetails.id;
            user.email = userDetails.email;
            user.userId = userDetails.userId ? userDetails.userId : 0;
            user.userName = userDetails.userName;
            user.roleId = userDetails.roleValues.value;
            user.accessLevelId = userDetails.accessLevelValues.value;
            user.firstName = userDetails.firstName;
            user.lastName = userDetails.lastName;
            user.shiftId = userDetails.shiftValues.value;
            user.shiftDayId = userDetails.shiftDaysValues.value;
            user.shiftHourId = userDetails.shiftHoursValues.value;
            user.weekHours = userDetails.weekHours;
            user.defaultStationId = userDetails.defaultStationValues.value;

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('UserId', user.userId ? user.userId.toString():"");
            data.append('Id', user.id.toString());
            data.append('UserName', user.userName);
            data.append('Email', user.email);
            data.append('RoleId', user.roleId.toString());
            data.append('AccessLevelId', user.accessLevelId.toString());
            data.append('FirstName', user.firstName);
            data.append('LastName', user.lastName);
            data.append('ShiftId', user.shiftId ? user.shiftId.toString(): "");
            data.append('ShiftDayId', user.shiftDayId ? user.shiftDayId.toString() : "");
            data.append('ShiftHourId', user.shiftHourId ? user.shiftHourId.toString() : "");
            data.append('WeekHours', user.weekHours ? user.weekHours.toString() : "");
            data.append('defaultStationId', user.defaultStationId ? user.defaultStationId.toString() : "");

            if (user.imageSelected) {
                data.append('file', user.file);
            }

            axios.post(`api/Users/SaveUser`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status                   
                    //console.log(res);                   
                        if (user) {
                            user.id = res.id;
                            user.isActive = true;
                            user.uploadedImageURL = user.imageDisplayURI;
                            user.imageSelected = false;
                            user.file = null;
                        }
                        dispatch({ type: 'CLOSE_ADDUSER', isloading: false, isEditMode: true });
                        toast.dismiss();
                        toast('Account information has been successfully saved.');
                    }
                )
                .catch(error => {
                    if (error.response.data) {
                        dispatch({ type: 'CLOSE_ADDUSER', isloading: false, isEditMode: false });
                        toast.dismiss();
                        toast(error.response.data.message);
                    }
                    else {
                        dispatch({ type: 'CLOSE_ADDUSER', isloading: false, isEditMode: appState && appState.users && appState.users.isEditMode !== null ? appState.users.isEditMode : false })
                        toast.dismiss();
                        toast('Error processing your request, please try again.');
                    }
                })
            dispatch({ type: 'CLOSE_ADDUSER', isloading: true, isEditMode: true });
        }

    },
    DeleteUser: (id: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const data = new FormData()
        data.append('UserId',id.toString());


        axios.post(`api/Users/DeleteUser`, data, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.data as Promise<any>)
            .then(res => { // then print response status                   
                const appState = getState();

                let user = appState.users && appState.users.userDetails;



                if (user) {
                    user.isActive = false;
                }
                dispatch({ type: 'CLOSE_ADDUSER', isloading: false, isEditMode: true });
                toast.dismiss();
                toast('Account information has been deleted.');
            })
            .catch(error => {
                dispatch({ type: 'CLOSE_ADDUSER', isloading: false, isEditMode: true })
                toast.dismiss();
                toast('Error processing your request, please try again.');
            })
        dispatch({ type: 'CLOSE_ADDUSER', isloading: true, isEditMode: true });

    }
};

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    let userDetails: User = {
        email: "",
        userName: "",
        accessLevelId: 0,
        roleId: 0,
        roleDescription: "Select Role",
        userId: 0,
        id: 0,
        isActive: false,
        imageDisplayURI: '',
        uploadedImageURL: '',
        imageSelected: false,
        file: null,
        firstName: "",
        lastName: "",
        shiftId: 0,
        shiftDayId: 0,
        shiftHourId: 0,
        defaultStationId:0,
        weekHours: 0.0,
        accessLevel: {},
        roleValues: { label: "Select Role", value: 0 },
        accessLevelValues: { label: "Select Position", value: 0 },
        shiftValues: { label: "Select Shift", value: 0 },
        shiftDaysValues: { label: "Select shift days", value: 0 },
        shiftHoursValues: { label: "Select shift hours", value: 0 },
        defaultStationValues: { label: "Select default station", value:0 }
    };
    if (state === undefined) {
        return {
            email: "",
            userDetails: userDetails,
            isLoading: false,
            rolesList: [],
            accessLevelList: [],
            shiftList: [],
            shiftDaysList: [],
            shiftHoursList: [],
            defaultStationList: [],
            accessLevelId: 0,
            roleId: 0,
            isEditMode: false,
            confirmDelete: false,
            selectFile: false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TOGGLE_SELECTFILE':
            return {
                userDetails: state.userDetails,
                accessLevelList: state.accessLevelList,
                rolesList: state.rolesList, email: state.email,
                accessLevelId: state.accessLevelId,
                roleId: state.roleId, isEditMode: state.isEditMode,
                confirmDelete: state.confirmDelete,
                isLoading: state.isLoading,
                selectFile: action.selectFile,
                shiftList: state.shiftList,
                shiftDaysList: state.shiftDaysList,
                shiftHoursList: state.shiftHoursList,
                defaultStationList: state.defaultStationList
            };
        case 'TOGGLE_CONFIRM':
            return {
                userDetails: state.userDetails, 
                accessLevelList: state.accessLevelList,
                rolesList: state.rolesList, email: state.email,
                accessLevelId: state.accessLevelId,
                roleId: state.roleId, isEditMode: state.isEditMode,
                confirmDelete: action.confirmDelete,
                isLoading: state.isLoading,
                selectFile: state.selectFile,
                shiftList: state.shiftList,
                shiftDaysList: state.shiftDaysList,
                shiftHoursList: state.shiftHoursList,
                defaultStationList: state.defaultStationList
            };

        case 'EDIT_USER':
            return {
                userDetails: state.userDetails, isLoading: false,
                accessLevelList: state.accessLevelList,
                rolesList: state.rolesList, email: state.email,
                accessLevelId: state.accessLevelId,
                roleId: state.roleId, isEditMode: false,
                confirmDelete: state.confirmDelete,
                shiftList: state.shiftList,
                shiftDaysList: state.shiftDaysList,
                shiftHoursList: state.shiftHoursList,
                selectFile: state.selectFile,
                defaultStationList: state.defaultStationList
            };
        case 'REQUEST_USER_DETAILS':
            return {
                userDetails: state.userDetails, isLoading: true,
                accessLevelList: state.accessLevelList,
                rolesList: state.rolesList, email: action.payload,
                accessLevelId: state.accessLevelId,
                roleId: state.roleId, isEditMode: action.isEditMode,
                confirmDelete: state.confirmDelete,
                selectFile: state.selectFile,
                shiftList: state.shiftList,
                shiftDaysList: state.shiftDaysList,
                shiftHoursList: state.shiftHoursList,
                defaultStationList: state.defaultStationList
            };
        case 'RECEIVE_USER_DETAILS':
            return {
                userDetails: action.userDetails, isLoading: false,
                accessLevelList: action.accessLevels,
                rolesList: action.roles, email: state.email,
                accessLevelId: state.accessLevelId,
                roleId: state.roleId, isEditMode: state.isEditMode,
                confirmDelete: state.confirmDelete,
                selectFile: state.selectFile,
                shiftList: action.shifts,
                shiftDaysList: action.shiftDays,
                shiftHoursList: action.shiftHours,
                defaultStationList: action.defaultStation
            };
        case 'CLOSE_ADDUSER':
            return {
                isLoading: action.isloading,
                userDetails: state.userDetails,
                accessLevelList: state.accessLevelList,
                rolesList: state.rolesList, email: state.email,
                accessLevelId: state.accessLevelId,
                roleId: state.roleId, isEditMode: action.isEditMode,
                confirmDelete: false,
                selectFile: state.selectFile,
                shiftList: state.shiftList,
                shiftDaysList: state.shiftDaysList,
                shiftHoursList: state.shiftHoursList,
                defaultStationList: state.defaultStationList
            };
        default:
            return state;
    }
}