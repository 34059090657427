import * as React from 'react';
import { Route, Redirect } from "react-router-dom";

export interface RoleScreen {
    role: string;
    screensBlocked: string[];
}

const RoleScreenMapping: RoleScreen[] = [
    { role: "Planner", screensBlocked: ["approval"] },
    { role: "Super Admin", screensBlocked: [] },
    { role: "Manager", screensBlocked: [] },
    { role: "Supervisor", screensBlocked: [] },
    { role: "Technicians", screensBlocked: ["planning", "traxfile", "users","reports","approval"] },
    { role: "Customers", screensBlocked: ["planning", "traxfile", "users", "timeAndLabor", "approval"] }


];

export const ScreenValid = function(name: string):boolean {    
    const user = sessionStorage.getItem('logged_user');
    if (user) {
        const userLogged = JSON.parse(user);
        let res = !RoleScreenMapping.filter(n => n.role === userLogged.role.roleDescription)[0].screensBlocked.some(n => n === name);
        return res;
    }
    return false;
}

export const IsMenuAllowed = function (name: string): boolean {
    const user = sessionStorage.getItem('logged_user');
    if (user) {
        const userLogged = JSON.parse(user);
        let menu = ["Super Admin", "Supervisor", "Manager","Planner"].filter(n => n === userLogged.role.roleDescription );
        if (menu.length>0)
            return true;
        else {           
            if (process.env.REACT_APP_ALLOWED_MENU) {
                if (userLogged.role.roleDescription === "Customers")
                    return false;
                if (process.env.REACT_APP_ALLOWED_MENU.includes(name)) {                    
                    return true;
                }
                else {                    
                    return false
                }
            }
            return true;
        }
        
    }
    return false;
    
}

const GuardedRoute = ({ component: Component, auth, name, ...rest }: any) => {
    return (<Route {...rest} render={(props) => (

        sessionStorage.getItem('access_token_augur')
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />);

    }

         



export default GuardedRoute;