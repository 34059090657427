import * as React from 'react';
import { Container, ContainerProps } from 'reactstrap';
import NavMenu from './NavMenu';
import 'react-pro-sidebar/dist/css/styles.css';
import { RouteComponentProps, withRouter } from 'react-router';


interface IProps extends RouteComponentProps<any> {
    children?: React.ReactNode, auth: any;
}

class Layout extends React.PureComponent<IProps, {}> {
    unlisten: any = {};
    activePathName: string = '';

    componentDidMount() {
        this.unlisten = this.props.history.listen((location: any, action: any) => {
            console.log("on route change from layout internal");
            console.log(location.pathname);
            this.activePathName = location.pathname;
            this.forceUpdate();
        });
        console.log(this.props.history.location.pathname);
        this.activePathName = this.props.history.location.pathname;
        console.log(this.activePathName);
        this.forceUpdate();
    }
    componentWillUnmount() {
        this.unlisten();
    }


    render(){

    return (


        <React.Fragment>


            <main className={this.props.auth && this.props.auth.isAuthenticated && ((this.activePathName !== '/station') && (this.activePathName !== "/"))  ?
                ((this.activePathName !== '/FlightBoards') ? (this.activePathName !== '/planning' ? "mainWAuth" :"mainWAuthPln") : "mainDarkModeTheme") : "mainWOAuth"}>
                <NavMenu {...this.props} />


                {(this.props.auth && this.props.auth.isAuthenticated && (this.activePathName !== '/station')) &&
                    <Container fluid={true} 
                    >
                    {this.props.children}
                    </Container>}


                {(!(this.props.auth && this.props.auth.isAuthenticated) || (this.activePathName === '/station')) &&
                    <Container fluid={true} className="fullHeight"
                    >
                    {this.props.children}
                    </Container>}


                {(this.props.auth && this.props.auth.isAuthenticated && ((this.activePathName !== '/station') && (this.activePathName !== "/"))) && <footer>
                    FEAM AERO SOFTWARE SUITE
                </footer>}
            </main>

        </React.Fragment>
    );
    }

}

export default withRouter(Layout);