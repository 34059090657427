import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IoCloseOutline } from 'react-icons/io5';

// @ts-ignore
export const DeleteModal = ({
    message,
    isOpen,
    onConfirm,
    onClose
}: {
    message: string;
    isOpen: boolean;
    onConfirm: () => void;
    onClose: () => void;
}) => {
    return (
        <Modal centered={true} className="smallModal" isOpen={isOpen}>
            <ModalHeader className="modalHeader w-100">
                <div className="flex flex-end w-100">
                    <div className="">
                        <div className="crossButton"
                            onClick={() => { onClose() }}><IoCloseOutline /></div>

                    </div>
                </div>

                <div className="text-align-center">
                    Are you sure?
                            </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-center w-100 text-center">
                    {message}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-button w-100">
                <div className="round-empty-button-popup workload-round-empty-button-popup" onClick={() => { onClose() }}>
                    NO, CANCEL
                        </div>
                <div className="round-fill-button-popup model-btn-margin" onClick={() => { onConfirm() }}>
                        YES, DELETE
                        </div>
                </div>

            </ModalFooter>
        </Modal >
    );
};

export default DeleteModal;