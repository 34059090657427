import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';
import { toast } from 'react-toastify';
import App from '../App';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface MxRequestPlanningStatus {
    id: number,
    planningStatus: string
}

export interface MxRequestTypes {
    id: number,
    requestText: string
}
export interface MddrNote {
    id: number,
    mddrid: number,
    noteText: string,
}

export interface MxNote {
    id: number,
    mxId: number,
    note: string,
}

export interface MddrPart {
    id: number,
    mddrid: number,
    partName: string,
    partNumber: string,
    serialNumber: string,
    qtyRequested: number,
    qtyAvailable: number,
    noteDesc: string
}

export interface MxRequest {
    mxRequestTypesValues: { label: string; value: number; };
    id: number;
    mxRequestTypesId: number,
    mxRequestStatusId:number,
    refNumber: string,
    description: string,
    notes: string,
    tailRecordId: number,
    mxRequestPlanningStatusId: number,
    mxRequestStatusValues: { label: string; value: number; },
    files: [],
    mxrequestFiles: []
}

export interface FilterValues {
    sortValue: number,
    sortType: string,
    showArchivedFlights:boolean
}

export interface PlanningState {
    selectedDate: Date;
    tailRecords: any[];
    isLoading: boolean;
    selectedTail: any;
    isUserSelected: boolean;
    ptwStatus: any[];
    isUpdatingMddr: boolean;
    showAddNotes: boolean;
    selectedMddr: any;
    selectedNote: MddrNote;
    showAddParts: boolean;
    selectedPart: MddrPart;
    showArchivePop: boolean;
    showCorrection: boolean;
    showReplace: boolean;
    isShowReplaceWarnning: boolean;
    showArch: boolean;
    isShowArchiveWarnning: boolean;
    showAddMx: boolean;
    selectedMxR: MxRequest;
    mxTypes: MxRequestTypes[];
    mxStatus: MxRequestPlanningStatus[];
    showPrintSchedule: boolean;
    printList: MxRequestTypes[];
    selectedPrintId: number;
    selectedFlightId: number;
    selectedFlightDetails: string;
    selectedFleetId: number;
    selectedFleetDetails: string;
    allFleets: any [];
    fileUrl: string;
    isTop: boolean;
    correctionTails: any[];
    seletedCorrectionTail: any;
    mxRequestUploadedFiles: UploadableFile[];
    mxRequestDeleteFIles: string[];
    showFileValidation: boolean;
    errorMessage: string;
    isFilterSortPopupOpen: boolean;
    filterValues: FilterValues,
    showUnarchivePopup: boolean,
    allTailRecords:any[]
    selectedmxrRequest: any;
    showDeleteMxrConfirmation: boolean;
    showDeletePartConfirmation: boolean;
    showDeleteNoteConfirmation: boolean;
    selectedMxNote: MxNote;
    showMxAddNote: boolean;
    SelectedMxRequestRef: string;
    showDeleteMxNoteConfirmation: boolean;
    imageOpen: boolean;
    selectedImage: any;
}

export interface UploadableFile {
    id: number;
    uri: string;
    fileName: string;
    file: File;
    imageUri: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface DeSelectTailAction { type: 'DESELECT_TAIL' }
export interface SelectDateAction { type: 'SELECT_DATE', payload: Date }
export interface SelectTailAction { type: 'SELECT_TAIL', payload: any }
export interface SelectCorrectionTailAction { type: 'SELECT_CORRECT_TAIL', payload: any}
export interface RequestTailsAction { type: 'REQUEST_TAILS'; payload: Date }
export interface ReceiveTailsAction { type: 'RECEIVE_TAILS'; tailRecords: any[], selectedTail: any, ptwStatus: any[], mxPlanningStatus: MxRequestPlanningStatus[], mxTypes: MxRequestTypes[],allFleets:[],allTailRecords:any[] }
export interface SelectStatusAction { type: 'SELECT_STATUS'; selectedTail: any }
export interface UpdatedStationAction { type: 'UPDATED_STATUS' }
export interface ShowAddNoteAction { type: 'SHOW_ADDNOTE'; selectedMddr: any }
export interface CloseAddNoteAction { type: 'CLOSE_ADDNOTE'; isloading: boolean }
export interface NoteAddedAction { type: 'NOTE_ADDED'; isloading: boolean }
export interface UpdateNoteAction { type: 'UPDATE_NOTE', updatedNote: MddrNote }

export interface ShowAddPartAction { type: 'SHOW_ADDPART'; selectedMddr: any }
export interface CloseAddPartAction { type: 'CLOSE_ADDPART'; isloading: boolean }
export interface UpdatePartAction { type: 'UPDATE_PART', updatedPart: MddrPart }

export interface ShowAddMxRAction { type: 'SHOW_ADDMxR'; selectedTail: any; files: UploadableFile[] }
export interface CloseAddMxRAction { type: 'CLOSE_ADDMxR'; isloading: boolean }
export interface SortScreenActions { type: 'SORT_SCREEN'; tailRecords: any[], selectedTail: any }

export interface ShowPrintScheduleAction { type: 'SHOW_PRINT_SCHEDULE'; }
export interface ClosePrintScheduleAction { type: 'CLOSE_PRINT_SCHEDULE'; isloading: boolean, fileUrl: any }

export interface SelectPrintMethodAction { type: 'SELECT_PRINT_SCHEDULE'; selectedPrintMethodId: number }
export interface SelectFlightDetailsAction { type: 'SELECT_FLIGHT_DETAILS_FOR_PRINT'; selectedFlightId: number, selectedFlightDetails: string }
export interface SelectFleetDetailsAction { type: 'SELECT_FLEET_DETAILS_FOR_PRINT'; selectedFleetId: number, selectedFleetDetails: string }
export interface ToggleTopAction { type: 'TOGGLE_TOP'; isTop: boolean }
export interface ShowArchiveConfirmAction { type: 'SHOW_ARCHIVECONFIRM' }
export interface ShowCorectAction { type: 'SHOW_CORECT', correctionFlight: any[] }
export interface ShowPopUpArchiveAction { type: 'SHOW_ARCHIV' }
export interface ShowPopUpArchiveWarning { type: 'SHOW_ARCHIV_WARNING' }
export interface ShowPopUpReplaceAction { type: 'SHOW_REPLACE' }
export interface ShowPopUpReplaceWarning { type: 'SHOW_REPLACE_WARNING' }

export interface CloseArchiveAction { type: 'CLOSE_ARCHIV'; isloading: boolean }
export interface CloseCorrectionAction { type: 'CLOSE_CORECT'; isloading: boolean }
export interface FetchCorrectionTailsAction {type: 'FETCH_CORRECT'}
export interface UploadFilesAction { type: 'UPLOAD_FILES', files: UploadableFile[], deletedFiles: string[] }
export interface ShowImageValidAction { type: 'SHOW_IMAGE_VALID', showImageValid: boolean, message: string }
export interface CloseImageValidAction { type: 'CLOSE_IMAGE_VALID', showImageValid: boolean }
export interface ShowLoaderAction { type: 'SHOW_LOADER' }
export interface ToggleFilterSortPopup { type: 'TOGGLE_FILTER_SORT_POPUP' }
export interface UpdateFilterValues { type: 'UPDATE_FILTER_VALUES', filterValues: any }
export interface CloseFilterPopup { type: 'CLOSE_FILTER_POPUP' }
export interface ShowUnarchiveConfirmation { type: 'SHOW_UNARCHIVE_POPUP' }
export interface CloseUnarchivePopup { type: 'CLOSE_UNARCHIVE_POPUP', isLoading:boolean}
export interface ShowDeleteMxrConfirmationAction { type: 'SHOW_DELETE_Mxr_CONF'; isloading: boolean, mxr: any }
export interface CloseDeleteMxrConfirmationAction { type: 'CLOSE_DELETE_Mxr_CONF'; isloading: boolean }
export interface ShowDeletePartConfirmationAction { type: 'SHOW_DELETE_PART_CONF'; isloading: boolean, part: any }
export interface CloseDeletePartConfirmationAction { type: 'CLOSE_DELETE_PART_CONF'; isloading: boolean }
export interface ShowDeleteNoteConfirmationAction { type: 'SHOW_DELETE_NOTE_CONF'; isloading: boolean, note: any }
export interface CloseDeleteNoteConfirmationAction { type: 'CLOSE_DELETE_NOTE_CONF'; isloading: boolean }
export interface ShowMxAddNotes { type: 'SHOW_MX_ADD_NOTE', SelectedMxRequestRef: any }
export interface CloseMxAddNotes { type: 'CLOSE_MX_ADD_NOTE', isLoading: boolean }
export interface UpdateMxNote { type: 'UPDATE_MX_NOTE', updatedMxNote: MxNote }
export interface ShowDeleteMxNoteConfirmation { type: 'SHOW_DELETE_MX_NOTE_CONF'; isloading: boolean, note: any }
export interface CloseDeleteMxNoteConfirmation { type: 'CLOSE_DELETE_MX_NOTE_CONF'; isloading: boolean }
export interface ToggleImageAction { type: 'TOGGLE_IMAGE', file: any, toggleImg: boolean }
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SelectStatusAction | SelectDateAction | RequestTailsAction |
    ReceiveTailsAction | SelectTailAction | DeSelectTailAction | UpdatedStationAction |
    ShowAddNoteAction | CloseAddNoteAction | UpdateNoteAction | NoteAddedAction |
    ShowAddPartAction | CloseAddPartAction | UpdatePartAction | ShowAddMxRAction |
    CloseAddMxRAction | SortScreenActions | ShowPrintScheduleAction |
    ClosePrintScheduleAction | SelectPrintMethodAction | SelectFlightDetailsAction
    | SelectFleetDetailsAction | ToggleTopAction | ShowArchiveConfirmAction | ShowCorectAction | ShowPopUpArchiveAction | ShowPopUpArchiveWarning
    | FetchCorrectionTailsAction | SelectCorrectionTailAction | CloseArchiveAction | CloseCorrectionAction | UploadFilesAction
    | ShowImageValidAction | CloseImageValidAction | ShowLoaderAction | ToggleFilterSortPopup | UpdateFilterValues | CloseFilterPopup
    | ShowUnarchiveConfirmation | CloseUnarchivePopup | ShowDeleteMxrConfirmationAction | CloseDeleteMxrConfirmationAction
    | ShowDeletePartConfirmationAction | CloseDeletePartConfirmationAction | ShowDeleteNoteConfirmationAction | CloseDeleteNoteConfirmationAction
    | ShowMxAddNotes | CloseMxAddNotes | UpdateMxNote | ShowDeleteMxNoteConfirmation | CloseDeleteMxNoteConfirmation | ToggleImageAction
    | ShowPopUpReplaceAction | ShowPopUpReplaceWarning;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const handleFilterSort = (getState:any,tails:any) => {
    const appState = getState;
    if (appState && appState.planning) {
        let id = appState.planning.filterValues.sortValue;
        let showArchivedFlights = appState.planning.filterValues.showArchivedFlights;
        let tailRecords;
        if (showArchivedFlights) {
            tailRecords = tails.filter((record: any) => record.tail.isArchived == true);
        }
        else {
            tailRecords = tails.filter((record: any) => record.tail.isArchived === false || record.tail.isArchived == null);
        }
        let sortType = appState.planning.filterValues.sortType;
        tailRecords = tailRecords.sort((n1:any, n2:any) => {
            if (id === 1) {
                if (n1.tail.arrivalTimeRank > n2.tail.arrivalTimeRank) {
                    return sortType === "asc" ? 1 : -1;
                }

                if (n1.tail.arrivalTimeRank < n2.tail.arrivalTimeRank) {
                    return sortType === "asc" ? -1 : 1;
                }
                return 0;
            }

            if (id === 3) {
                let tail1Items = 0;
                let tail2Items = 0;
                if (n1.defects) {
                    tail1Items = n1.defects
                        .filter((def: any) => {
                            return def.plannedStatusSubCategory.category.categoryName === "Not Scheduled"
                        })
                        .length;
                }

                if (n2.defects) {
                    tail2Items = n2.defects
                        .filter((def: any) => def.plannedStatusSubCategory.category.categoryName === "Not Scheduled")
                        .length;
                }


                if (tail1Items < tail2Items) {
                    return sortType === "asc" ? -1 : 1;;
                }

                if (tail1Items > tail2Items) {
                    return sortType === "asc" ? 1 : -1;;
                }
                return 0;
            }

            if (id === 2) {
                if (n1.tail.groundTimeRank > n2.tail.groundTimeRank) {
                    return sortType === "asc" ? 1 : -1;;
                }

                if (n1.tail.groundTimeRank < n2.tail.groundTimeRank) {
                    return sortType === "asc" ? -1 : 1;;
                }
                return 0;
            }

            return 0;
        });
        return tailRecords;
    }
};

export const actionCreators = {
    FetchCorrections: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        let station = 'CVG';
        if (appState && appState.station && appState.station.selectedStation) {
            station = appState.station.selectedStation.stationName;
        }

        if (appState.planning && appState.planning.selectedDate) {
            fetch(`api/tails/GetCorrectionFlights?date=${appState.planning.selectedDate.toDateString()}&stationName=${station}&tailNumber=${appState.planning.selectedTail.tail.airCrafts.tail}`
                , {
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                }).then(response => response.json() as Promise<any>)
                .then(data => {
                    dispatch({
                        type: 'SHOW_CORECT',
                        correctionFlight: data.tailRecordsWithMddrs
                    });
                });
        }
        dispatch({ type: 'FETCH_CORRECT' });
    },
    HandleFilterSort: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.planning && appState.planning.tailRecords) {
            let id = appState.planning.filterValues.sortValue;
            let showArchivedFlights = appState.planning.filterValues.showArchivedFlights;
            let tailRecords;
            if (showArchivedFlights) {
                tailRecords = appState.planning.allTailRecords.filter((record: any) => record.tail.isArchived == true);
            }
            else {
                tailRecords = appState.planning.allTailRecords.filter((record: any) => record.tail.isArchived === false || record.tail.isArchived == null);
            }
            let sortType = appState.planning.filterValues.sortType;
            tailRecords = tailRecords.sort((n1, n2) => {
                if (id === 1) {
                    if (n1.tail.arrivalTimeRank > n2.tail.arrivalTimeRank) {
                        return sortType === "asc" ? 1 : -1;
                    }

                    if (n1.tail.arrivalTimeRank < n2.tail.arrivalTimeRank) {
                        return sortType === "asc" ? -1 : 1;
                    }
                    return 0;
                }

                if (id === 3) {
                    let tail1Items = 0;
                    let tail2Items = 0;
                    if (n1.defects) {
                        tail1Items = n1.defects
                            .filter((def: any) => {
                                return def.plannedStatusSubCategory.category.categoryName === "Not Scheduled"
                            })
                            .length;
                    }

                    if (n2.defects) {
                        tail2Items = n2.defects
                            .filter((def: any) => def.plannedStatusSubCategory.category.categoryName === "Not Scheduled")
                            .length;
                    }


                    if (tail1Items < tail2Items) {
                        return sortType === "asc" ? -1 : 1;;
                    }

                    if (tail1Items > tail2Items) {
                        return sortType === "asc" ? 1 : -1;;
                    }
                    return 0;
                }

                if (id === 2) {
                    if (n1.tail.groundTimeRank > n2.tail.groundTimeRank) {
                        return sortType === "asc" ? 1 : -1;;
                    }

                    if (n1.tail.groundTimeRank < n2.tail.groundTimeRank) {
                        return sortType === "asc" ? -1 : 1;;
                    }
                    return 0;
                }

                return 0;
            });
            toast.dismiss();
            toast("Filter and Sort Completed!");
            dispatch({ type: "SORT_SCREEN", selectedTail: tailRecords[0], tailRecords: tailRecords });
            dispatch({ type: "CLOSE_FILTER_POPUP" });

        }
    },
    SelectDat: (selDate: Date,isFilterSort:Boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        let station = 'CVG';
        let showArchivedFlights = false;
        if (appState && appState.station && appState.station.selectedStation) {
            station = appState.station.selectedStation.stationName;
        }
        fetch(`api/tails?date=${selDate.toDateString()}&stationName=${station}&listStatus=${!(appState.planning &&
            appState.planning.ptwStatus && appState.planning.ptwStatus.length > 0)}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
            .then(data => {
                let tails = data.tailRecordsWithMddrs;
                let excludeArchivedRecords = data.tailRecordsWithMddrs.filter((record: any) => record.tail.isArchived === false || record.tail.isArchived == null);
                dispatch({
                    type: 'RECEIVE_TAILS',
                    tailRecords: excludeArchivedRecords,
                    selectedTail: excludeArchivedRecords.length > 0 ? excludeArchivedRecords[0] : null,
                    ptwStatus: data.plannedStatusSubs && data.plannedStatusSubs.length > 0 ? data.plannedStatusSubs :
                        appState.planning ? appState.planning.ptwStatus : null,
                    mxTypes: data.mxTypes && data.mxTypes.length > 0 ? data.mxTypes :
                        appState.planning ? appState.planning.mxTypes : null,
                    mxPlanningStatus: data.mxPlanningStatus && data.mxPlanningStatus.length > 0 ? data.mxPlanningStatus :
                        appState.planning ? appState.planning.mxStatus : null,
                    allFleets: data.allFleets && data.allFleets.length > 0 ? data.allFleets :
                        appState.planning ? appState.planning.allFleets : null,
                    allTailRecords: data.tailRecordsWithMddrs
                });
                if (isFilterSort) {
                    let tailRecords = handleFilterSort(appState, tails);
                    dispatch({ type: "SORT_SCREEN", selectedTail: tailRecords[0], tailRecords: tailRecords });
                }
            });

        dispatch({ type: 'REQUEST_TAILS', payload: selDate });
    },
    SelectTail: (selectedTail: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_TAIL', payload: selectedTail })
    },
    SelectCorrectionTail: (selectedTail: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_CORRECT_TAIL', payload: selectedTail })
    },
    DeSelectTail: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DESELECT_TAIL' })
    },
    SelectStatus: (event: any, mddrId: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        const filtered = (<any>Object).assign([], appState.planning ? appState.planning.ptwStatus : null)
            // filter nested lists first
            .map((obj: any) => {
                // here we can use Object.assign to make shallow copy of obj, to preserve previous instance unchanged
                return (<any>Object).assign({}, obj, {
                    list: obj.options.filter((el: any) => el.label === event.label)
                })
            })
            // then omit all items with empty list
            .filter((obj: any) => obj.list.length > 0)

        let selectedTail = (<any>Object).assign({}, appState.planning ? appState.planning.selectedTail : null);
        let defect = selectedTail.defects.filter((def: any) => def.id === mddrId)[0];
        defect.plannedStatusSubCategory.subCategoryName = event.label;
        defect.plannedStatusSubCategory.id = parseInt(event.value);
        defect.plannedStatusSubCategoryId = parseInt(event.value);        
        defect.plannedStatusSubCategory.category.categoryName = filtered[0].label;
        defect.plannedStatusSubCategory.category.id = filtered[0].label === 'Plan to work' ? 1
            : filtered[0].label === 'Not Planned to work - reason' ? 2 : 3;
         
        selectedTail.defects.sort((n1: any, n2: any) => {           
            return n2.plannedStatusSubCategory.category.id - n1.plannedStatusSubCategory.category.id;
        });

        const allTailRecords = (<any>Object).assign([], appState.planning ? appState.planning.tailRecords : null);
        allTailRecords.filter((t: any) => t.tail.airCrafts.tail === selectedTail.tail.airCrafts.tail).forEach((d: any) => {
            let allDefect = d.defects.filter((def: any) => def.id === mddrId)[0];
            allDefect.plannedStatusSubCategory.subCategoryName = event.label;
            allDefect.plannedStatusSubCategory.id = parseInt(event.value);
            allDefect.plannedStatusSubCategoryId = parseInt(event.value);
            allDefect.plannedStatusSubCategory.category.categoryName = filtered[0].label;
            allDefect.plannedStatusSubCategory.category.id = filtered[0].label === 'Plan to work' ? 1
                : filtered[0].label === 'Not Planned to work - reason' ? 2 : 3;            
            d.defects.sort((n1: any, n2: any) => {
                return n2.plannedStatusSubCategory.category.id - n1.plannedStatusSubCategory.category.id;
            });
        })

        //call api to update here
        const token = sessionStorage.getItem('access_token_augur');

        const data = new FormData()
        data.append('MddrId', mddrId)
        data.append('TailRecordId', defect.plannedStatusSubCategory.subCategoryName === 'Not Scheduled' ? 0 : selectedTail.tail.id)
        data.append('SubStatusId', defect.plannedStatusSubCategory.id)

        axios.post(`api/Tails/SaveStatus`, data, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.data as Promise<{}>)
            .then(res => { // then print response status
                dispatch({ type: 'UPDATED_STATUS' });
            })


        dispatch({ type: 'SELECT_STATUS', selectedTail: selectedTail })
    },
    ShowAddNotes: (mddrid: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedNote = appState.planning && appState.planning.selectedNote;
        if (selectedNote) {
            selectedNote.id = 0;
            selectedNote.mddrid = mddrid;
            selectedNote.noteText = '';
            dispatch({ type: 'SHOW_ADDNOTE', selectedMddr: mddrid })
        }
    },
    CloseAddNotes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_ADDNOTE', isloading: false })
    },
    UpdateNotes: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedNote = appState.planning && appState.planning.selectedNote;
        if (selectedNote) {
            selectedNote.noteText = event.target.value;
            dispatch({ type: 'UPDATE_NOTE', updatedNote: selectedNote })
        }
    },
    EditNotes: (event: any, note: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedNote = appState.planning && appState.planning.selectedNote;
        if (selectedNote) {
            selectedNote.id = note.id;
            selectedNote.mddrid = note.mddrId;
            selectedNote.noteText = note.noteText
            dispatch({ type: 'SHOW_ADDNOTE', selectedMddr: note.mddrId })
        }
    },
    SaveNote: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedNote = appState.planning && appState.planning.selectedNote;
        if (selectedNote && selectedNote.noteText == '') {
            return;
        }

        if (selectedNote) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('MddrId', selectedNote.mddrid.toString())
            data.append('NoteId', selectedNote.id.toString())
            data.append('NoteText', selectedNote.noteText)

            axios.post(`api/Tails/SaveNote`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    if (appState.planning && appState.planning.selectedNote) {
                        const mddrid = appState.planning && appState.planning.selectedNote && appState.planning.selectedNote.mddrid;
                        let selMddr = appState.planning && appState.planning.selectedTail.defects.find((e: any) => e && e.id
                            === mddrid);
                       // console.log(selMddr);
                        if (selMddr.mddrNotes.find((n: any) => n.id === res.id)) {
                            selMddr.mddrNotes[selMddr.mddrNotes.indexOf(selMddr.mddrNotes.find((n: any) => n.id === res.id))]
                                = res;
                        }
                        else {
                            selMddr.mddrNotes.push(res);
                        }

                    }

                    dispatch({ type: 'CLOSE_ADDNOTE', isloading: false });
                })
                .catch(error => dispatch({ type: 'CLOSE_ADDNOTE', isloading: false }))
            dispatch({ type: 'CLOSE_ADDNOTE', isloading: true });
        }
    },
    DeleteNotes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.planning && appState.planning.selectedNote) {
            let note = appState.planning && appState.planning.selectedNote as any;
            
            let selMddr = appState.planning && appState.planning.selectedTail &&
                appState.planning.selectedTail.defects &&
                appState.planning.selectedTail.defects.find((e: any) => e.id === note.mddrId);
            const removeIndex = selMddr.mddrNotes.indexOf(selMddr.mddrNotes.find((n: any) => n.id === note.id));
            ~removeIndex && selMddr.mddrNotes.splice(removeIndex, 1);

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('NoteId', note.id.toString())

            axios.post(`api/Tails/DeleteNote`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    dispatch({ type: 'CLOSE_ADDNOTE', isloading: false });
                })

        }
        dispatch({ type: 'CLOSE_ADDNOTE', isloading: true });

    }
    ,
    EditParts: (event: any, note: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedPart = appState.planning && appState.planning.selectedPart;
        if (selectedPart) {
            selectedPart.id = note.id;
            selectedPart.mddrid = note.mddrId;
            selectedPart.noteDesc = note.description;
            selectedPart.partName = note.partName;
            selectedPart.partNumber = note.partNumber;
            selectedPart.qtyAvailable = note.qtyAvailable;
            selectedPart.qtyRequested = note.qtyRequested;
            selectedPart.serialNumber = '';


            dispatch({ type: 'SHOW_ADDPART', selectedMddr: note.mddrId })
        }
    },
    DeleteParts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.planning && appState.planning.selectedPart) {
            let part = appState.planning && appState.planning.selectedPart as any;
            let selMddr = appState.planning && appState.planning.selectedNote &&
                appState.planning.selectedTail.defects &&
                appState.planning.selectedTail.defects.find((e: any) => e.id === part.mddrId);
            const removeIndex = selMddr.mddrParts.indexOf(selMddr.mddrParts.find((n: any) => n.id === part.id));
            ~removeIndex && selMddr.mddrParts.splice(removeIndex, 1);

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('PartId', part.id.toString())

            axios.post(`api/Tails/DeletePart`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    dispatch({ type: 'CLOSE_ADDNOTE', isloading: false });
                })

        }
        dispatch({ type: 'CLOSE_ADDNOTE', isloading: true });
    },
    ShowAddParts: (mddrid: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedPart = appState.planning && appState.planning.selectedPart;
        if (selectedPart) {
            selectedPart.id = 0;
            selectedPart.mddrid = mddrid;
            selectedPart.noteDesc = '';
            selectedPart.partName = '';
            selectedPart.partNumber = '';
            selectedPart.qtyAvailable = 0;
            selectedPart.qtyRequested = 0;
            selectedPart.serialNumber = '';
            dispatch({ type: 'SHOW_ADDPART', selectedMddr: mddrid })
        }
    },
    CloseAddParts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_ADDPART', isloading: false })
    },
    SavePart: (mddrPart: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        let selectedPart = appState.planning && appState.planning.selectedPart;



        if (selectedPart) {
            selectedPart.noteDesc = mddrPart.noteDesc;
            selectedPart.partName = mddrPart.partName;
            selectedPart.partNumber = mddrPart.partNumber;
            selectedPart.qtyAvailable = mddrPart.qtyAvailable;
            selectedPart.qtyRequested = mddrPart.qtyRequested;
            selectedPart.serialNumber = mddrPart.serialNumber;

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('MddrId', selectedPart.mddrid.toString());
            data.append('Id', selectedPart.id.toString());
            data.append('Description', selectedPart.noteDesc);
            data.append('PartName', selectedPart.partName);
            data.append('PartNumber', selectedPart.partNumber);
            data.append('QtyAvailable', selectedPart.qtyAvailable.toString());
            data.append('QtyRequested', selectedPart.qtyRequested.toString());
            data.append('SerialNumber', selectedPart.serialNumber);



            axios.post(`api/Tails/SavePart`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    if (appState.planning && appState.planning.selectedPart) {
                        const mddrid = appState.planning && appState.planning.selectedPart && appState.planning.selectedPart.mddrid;
                        let selMddr = appState.planning && appState.planning.selectedTail.defects.find((e: any) => e && e.id
                            === mddrid);
                        if (selMddr.mddrParts.find((n: any) => n.id === res.id)) {
                            selMddr.mddrParts[selMddr.mddrParts.indexOf(selMddr.mddrParts.find((n: any) => n.id === res.id))]
                                = res;
                        }
                        else {
                            selMddr.mddrParts.push(res);
                        }


                    }

                    dispatch({ type: 'CLOSE_ADDPART', isloading: false });
                })
                .catch(error => dispatch({ type: 'CLOSE_ADDPART', isloading: false }))
            dispatch({ type: 'CLOSE_ADDPART', isloading: true });
        }

    },
    ShowReplaceConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (appState.planning && appState.planning) {
            dispatch({ type: 'SHOW_REPLACE' })
        }
    },
    ShowReplaceWarning: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.planning && appState.planning) {
            dispatch({ type: 'SHOW_REPLACE_WARNING' })
        }
    },
    ShowArchiveConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      

        const appState = getState();
        if (appState.planning && appState.planning) {
                dispatch({ type: 'SHOW_ARCHIV'})
        }
    },
    ShowArchiveWarning: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.planning && appState.planning) {
            dispatch({ type: 'SHOW_ARCHIV_WARNING' })
        }
    },
    ArchiveFlight: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let id = appState && appState.planning && appState.planning.selectedTail
            && appState.planning.selectedTail.tail && appState.planning.selectedTail.tail.id.toString();

        if (id) {
            const data = new FormData();
            data.append('id', id);
            const token = sessionStorage.getItem('access_token_augur');
            axios.post(`api/Tails/ArchiveFlight`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => {
                    if (appState.planning && appState.planning.tailRecords) {
                        var tailRecords = appState.planning.tailRecords;
                        const removeIndex = tailRecords.indexOf(tailRecords.find(n => n.tail.id == id));
                        ~removeIndex && tailRecords.splice(removeIndex, 1);
                        if (appState.planning.allTailRecords) {
                            var allTailRecords = appState.planning.allTailRecords;
                            const updateIndex = allTailRecords.indexOf(allTailRecords.find(n => n.tail.id == id));
                            ~updateIndex && (allTailRecords[updateIndex].tail.isArchived = true);
                            allTailRecords = appState.planning.allTailRecords.filter((record: any) => record.tail.isArchived === false || record.tail.isArchived == null);
                            tailRecords = handleFilterSort(appState, allTailRecords);
                            dispatch({ type: "SORT_SCREEN", selectedTail: tailRecords[0], tailRecords: tailRecords });
                        }
                    }
                    dispatch({ type: 'CLOSE_ARCHIV', isloading: false });
                    toast.dismiss();
                    toast("Archived Successfully!");
                })
                .catch(error => dispatch({ type: 'CLOSE_ARCHIV', isloading: false }))
            dispatch({ type: 'CLOSE_ARCHIV', isloading: true });
        }
    },
    ReplaceFlight: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let sourceId = appState && appState.planning && appState.planning.selectedTail
            && appState.planning.selectedTail.tail && appState.planning.selectedTail.tail.id.toString();

        let targetId = appState && appState.planning && appState.planning.seletedCorrectionTail
            && appState.planning.seletedCorrectionTail.tail && appState.planning.seletedCorrectionTail.tail.id.toString();

        let station = 'CVG';
        if (appState && appState.station && appState.station.selectedStation) {
            station = appState.station.selectedStation.stationName;
        }

        let listStatus = !(appState.planning &&
            appState.planning.ptwStatus && appState.planning.ptwStatus.length > 0);
        if (sourceId && targetId) {
            const data = new FormData();
            data.append('sourceId', sourceId);
            data.append('targetId', targetId);
            data.append('date', new Date().toDateString());
            data.append('stationName', station);
            data.append('listStatus', listStatus.toString());
            const token = sessionStorage.getItem('access_token_augur');
            axios.post(`api/Tails/ReplaceFlight`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(data => {
                    let tails = data.tailRecordsWithMddrs;
                    let excludeArchivedRecords = data.tailRecordsWithMddrs.filter((record: any) => record.tail.isArchived === false || record.tail.isArchived == null);
                    dispatch({
                        type: 'RECEIVE_TAILS',
                        tailRecords: excludeArchivedRecords,
                        selectedTail: excludeArchivedRecords.length > 0 ? excludeArchivedRecords[0] : null,
                        ptwStatus: data.plannedStatusSubs && data.plannedStatusSubs.length > 0 ? data.plannedStatusSubs :
                            appState.planning ? appState.planning.ptwStatus : null,
                        mxTypes: data.mxTypes && data.mxTypes.length > 0 ? data.mxTypes :
                            appState.planning ? appState.planning.mxTypes : null,
                        mxPlanningStatus: data.mxPlanningStatus && data.mxPlanningStatus.length > 0 ? data.mxPlanningStatus :
                            appState.planning ? appState.planning.mxStatus : null,
                        allFleets: data.allFleets && data.allFleets.length > 0 ? data.allFleets :
                            appState.planning ? appState.planning.allFleets : null,
                        allTailRecords: data.tailRecordsWithMddrs
                    });
                    let tailRecords = handleFilterSort(appState, tails);
                    dispatch({ type: "SORT_SCREEN", selectedTail: tailRecords[0], tailRecords: tailRecords });
                    dispatch({ type: 'CLOSE_CORECT', isloading: false });
                    toast.dismiss();
                    toast("Replaced Successfully!");
                })
                .catch(error => dispatch({ type: 'CLOSE_CORECT', isloading: false }))
            
            dispatch({ type: 'CLOSE_CORECT', isloading: true });
        }
    },
    ShowAddMxR: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxR = appState.planning && appState.planning.selectedMxR;
        if (selectedMxR) {
            selectedMxR.id = 0;
            selectedMxR.description = '';
            selectedMxR.mxRequestTypesId = 1;
            selectedMxR.notes = '';
            selectedMxR.refNumber = '';
            selectedMxR.mxRequestPlanningStatusId = 0;
            selectedMxR.tailRecordId = appState.planning && appState.planning.selectedTail &&
                appState.planning.selectedTail.tail && appState.planning.selectedTail.tail.id;

            selectedMxR.mxRequestTypesValues = { value: 0, label: "Select type" };
            selectedMxR.mxRequestStatusValues = { value: 0, label: "Select status" };
            dispatch({ type: 'SHOW_ADDMxR', selectedTail: selectedMxR.tailRecordId, files: [] })

        }
    },
    ShowCorrectTool: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        dispatch({ type: "SHOW_CORECT", correctionFlight: [] });
    },
    CloseAddMxR: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_ADDMxR', isloading: false })
    },
    EditMxR: (event: any, mxr: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxR = appState.planning && appState.planning.selectedMxR;
        if (selectedMxR) {
            selectedMxR.id = mxr.id;
            selectedMxR.description = mxr.description;
            selectedMxR.mxRequestStatusId = mxr.mxRequestStatusId == null ? 1 : mxr.mxRequestStatusId;
            selectedMxR.mxRequestTypesId = mxr.mxRequestTypesId;
            selectedMxR.notes = mxr.notes;
            selectedMxR.refNumber = mxr.refNumber;
            selectedMxR.mxRequestPlanningStatusId = mxr.mxRequestPlanningStatusId;
            selectedMxR.tailRecordId = appState.planning && appState.planning.selectedTail &&
                appState.planning.selectedTail.tail && appState.planning.selectedTail.tail.id;

            let uploadedFiles: Array<UploadableFile> = [];

            if (mxr.mxrequestFiles) {
                for (let i = 0; i < mxr.mxrequestFiles.length; i++) {
                    var file = { id: mxr.mxrequestFiles[i].id, file: new File([""], "filename.remove"), uri: mxr.mxrequestFiles[i].uri, fileName: mxr.mxrequestFiles[i].fileName, imageUri: mxr.mxrequestFiles[i].imageUri };
                    uploadedFiles.push(file);
                }
            }
            let selectedMxType = appState.planning && appState.planning.mxTypes.filter(option => option.id === mxr.mxRequestTypesId)[0]
            if (selectedMxType) {
                selectedMxR.mxRequestTypesValues = {
                    label: selectedMxType.requestText, value: selectedMxType.id
                };
                let selectedMxStatus = appState.planning && appState.planning.mxStatus.filter(option => option.id === mxr.mxRequestPlanningStatusId)[0]
                if (selectedMxStatus) {
                    selectedMxR.mxRequestStatusValues = {
                        label: selectedMxStatus.planningStatus, value: selectedMxStatus.id
                    }
                }

                dispatch({ type: 'SHOW_ADDMxR', selectedTail: mxr.tailRecordId, files: uploadedFiles })
            }
        }
    },
    ShowDeleteMxrConfirmation: (mxr: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_Mxr_CONF', isloading: true, mxr: mxr })
    },
    CloseDeleteMxrConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_Mxr_CONF', isloading: false })
    },
    ShowDeletePartConfirmation: (part: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_PART_CONF', isloading: true, part: part })
    },
    CloseDeletePartConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_PART_CONF', isloading: false })
    },
    ShowDeleteNoteConfirmation: (note: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_NOTE_CONF', isloading: true, note: note })
    },
    CloseDeleteNoteConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_NOTE_CONF', isloading: false })
    },
    DeleteMxR: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState.planning && appState.planning.selectedMxR &&
            appState.planning.selectedTail.tail.mxrequests) {
            var selMxr = appState.planning.selectedTail.tail.mxrequests;
            let mxr = appState.planning.selectedmxrRequest;
            const removeIndex = selMxr.indexOf(selMxr.find((n: any) => n.id === mxr.id));
            ~removeIndex && selMxr.splice(removeIndex, 1);

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('MxRequestId', mxr.id.toString())

            axios.post(`api/Tails/DeleteMxR`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    dispatch({ type: 'CLOSE_ADDMxR', isloading: false });
                })

        }
        dispatch({ type: 'CLOSE_ADDMxR', isloading: true });
    },
    SaveMxR: (mxr: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        let selectedMxR = appState.planning && appState.planning.selectedMxR;
        if (selectedMxR) {
            selectedMxR.description = mxr.description;
            selectedMxR.mxRequestTypesId = mxr.mxRequestTypesValues.value;
            selectedMxR.mxRequestStatusId = mxr.mxRequestStatusId == null ? 1 : mxr.mxRequestStatusId;
            selectedMxR.mxRequestPlanningStatusId = mxr.mxRequestStatusValues.value;
            selectedMxR.refNumber = mxr.refNumber ? mxr.refNumber : "";            
            selectedMxR.tailRecordId = appState.planning && appState.planning.selectedTail &&
                appState.planning.selectedTail.tail && appState.planning.selectedTail.tail.id;



            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('Id', selectedMxR.id.toString());
            data.append('Description', selectedMxR.description);
            data.append('MxRequestTypesId', selectedMxR.mxRequestTypesId.toString());
            data.append('RefNumber', selectedMxR.refNumber);
            data.append('mxRequestPlanningStatusId', selectedMxR.mxRequestPlanningStatusId.toString());
            data.append('TailRecordId', selectedMxR.tailRecordId.toString());
            data.append('MxRequestStatusId', selectedMxR.mxRequestStatusId.toString());

            if (appState && appState.planning && appState.planning.mxRequestUploadedFiles) {
                let files = appState.planning.mxRequestUploadedFiles.filter(a => a.file && a.file.name != 'filename.remove');
                if (files) {
                    for (let i = 0; i < files.length; i++) {
                        data.append('file', files[i].file, files[i].file.name);
                    }
                }
            }

            if (appState && appState.planning && appState.planning.mxRequestDeleteFIles) {
                for (let i = 0; i < appState.planning.mxRequestDeleteFIles.length; i++) {
                    data.append("FilesToDelete[" + i + "]", appState.planning.mxRequestDeleteFIles[i]);
                }
            }

            let files = appState && appState.planning && appState.planning.mxRequestUploadedFiles;
            if (files && files.length > 10) {
                dispatch({
                    type: 'SHOW_IMAGE_VALID',
                    showImageValid: true,
                    message: 'Maximum of 10 files can be uploaded.'
                });
            }
            else if (files && files.map(a => a.uri).some((val, i) => files && files.map(a => a.uri).indexOf(val) !== i)) {
                dispatch({
                    type: 'SHOW_IMAGE_VALID',
                    showImageValid: true,
                    message: 'Please remove the duplicate entries.'
                });
            }
            else if (files && files.filter(a => a.file && a.file.name != 'filename.remove').map(a => a.file.size).some((val, i) => val > 10485760)) {
                let fileNames = files && files.filter(a => a.file && a.file.name != 'filename.remove' && a.file.size > 10485760).map(a => a.file.name)
                if (fileNames) {
                    dispatch({
                        type: 'SHOW_IMAGE_VALID',
                        showImageValid: true,
                        message: 'Files upto 10MB are only allowed to upload.'
                    });
                }
            }
            else {
                axios.post(`api/Tails/SaveMxR`, data, {
                    headers: !token ? {} : {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then(response => response.data as Promise<any>)
                    .then(res => { // then print response status
                        if (appState.planning && appState.planning.selectedMxR) {
                            var selMxr = appState.planning.selectedTail.tail.mxrequests;

                            if (selMxr.find((n: any) => n.id === res.id)) {
                                selMxr[selMxr.indexOf(selMxr.find((n: any) => n.id === res.id))]
                                    = res;
                            }
                            else {
                                selMxr.push(res);
                            }


                        }

                        dispatch({ type: 'CLOSE_ADDMxR', isloading: false });
                    })
                    .catch(error => dispatch({ type: 'CLOSE_ADDMxR', isloading: false }))

                dispatch({ type: 'CLOSE_ADDMxR', isloading: true });
            }
        }
    },

    ClosePrintSchedule: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_PRINT_SCHEDULE', isloading: false, fileUrl: "" })
    },

    ShowPrintSchedule: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_PRINT_SCHEDULE' })
    },
    SelectPrintSchedule: (selectedPrintMethodId: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_PRINT_SCHEDULE', selectedPrintMethodId: selectedPrintMethodId })
    },
    SavePrintSchedule: (selectedPrintMethodId: number, selectedPrintTypeId: number, selectedPrintTypeDetails: string, downloadFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const token = sessionStorage.getItem('access_token_augur');
        const data = new FormData()
        const appState = getState();
        let station = '';
        let selectedDate="";
        if (selectedPrintMethodId == 2 && selectedPrintTypeId == 0) {
            selectedPrintTypeId = appState.planning ? appState.planning.selectedTail.tail.id:0;
        }
        if (appState && appState.station && appState.station.selectedStation) {
            station = appState.station.selectedStation.stationName;
        }
        if (appState.planning && appState.planning.selectedDate) {
            selectedDate = appState.planning.selectedDate.toDateString();
        }
        data.append('MethodId', selectedPrintMethodId.toString())
        data.append('TypeId', selectedPrintTypeId.toString())
        data.append('TypeDetails', selectedPrintTypeDetails.toString())
        data.append('StationName', station)
        data.append('SelectedDate', selectedDate)

        axios.post(`api/Tails/PrintReport`, data, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.data as Promise<any>)
            .then(res => { // then print response status
                downloadFile(res);
                dispatch({ type: 'CLOSE_PRINT_SCHEDULE', isloading: false, fileUrl: res });
               
            })


        dispatch({ type: 'CLOSE_PRINT_SCHEDULE', isloading: true, fileUrl:"" });
    },
    UpdateSelectedFlightDetailsSchedule: (selectedFlightId: number, selectedFlightDetails: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_FLIGHT_DETAILS_FOR_PRINT', selectedFlightId: selectedFlightId, selectedFlightDetails: selectedFlightDetails })
    },
    UpdateSelectedFleetDetailsSchedule: (selectedFleetId: number, selectedFleetDetails: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SELECT_FLEET_DETAILS_FOR_PRINT', selectedFleetId: selectedFleetId, selectedFleetDetails: selectedFleetDetails })
    },
    ToggleTop: (isTop: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_TOP', isTop: isTop })
    },
    select: (files: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (files && files.length > 0) {
            const appState = getState();
            if (appState && appState.planning) {
                let uploadedFiles: Array<UploadableFile> = [];
                uploadedFiles = appState.planning.mxRequestUploadedFiles;
                for (let i = 0; i < files.length; i++) {
                    let maxTaskId = 0;
                    if (uploadedFiles) {
                        maxTaskId = uploadedFiles && Math.max.apply(null, uploadedFiles.map(function (o) { return o.id; }))
                    }
                    var file = { id: maxTaskId + 1, file: files[i], uri: files[i].name, fileName: '',imageUri:'' };
                    uploadedFiles.push(file);
                }

                dispatch({ type: 'UPLOAD_FILES', files: uploadedFiles, deletedFiles: appState.planning.mxRequestDeleteFIles })
            }
            setTimeout(() => {
                dispatch({ type: 'SHOW_LOADER' })
            }, 2000)
        }
    },
    deleteFile: (fileToDelete: File, fileName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (fileToDelete) {
            const appState = getState();
            if (appState && appState.planning) {
                let uploadedFiles: Array<UploadableFile> = [];
                uploadedFiles = appState.planning.mxRequestUploadedFiles;
                uploadedFiles = uploadedFiles.filter((fw) => fw.file !== fileToDelete);
                let deletedFiles: Array<string> = [];
                if (fileName && fileName != '') {
                    
                    deletedFiles = appState.planning.mxRequestDeleteFIles;
                    deletedFiles.push(fileName);
                }
                dispatch({ type: 'UPLOAD_FILES', files: uploadedFiles, deletedFiles: deletedFiles })
            }
        }
    },
    CloseImageValidError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_IMAGE_VALID', showImageValid: false })
    },
    ToggleFilterSortPopup: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_FILTER_SORT_POPUP'})
    },
    UpdateFilterValues: (filterValues: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_FILTER_VALUES', filterValues })
    },
    CloseFilterPopup: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_FILTER_POPUP' })
    },
    ShowUnarchiveConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_UNARCHIVE_POPUP' })
    },
    UnArchiveFlightStatus: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let tailRecordId = appState && appState.planning && appState.planning.selectedTail
            && appState.planning.selectedTail.tail && appState.planning.selectedTail.tail.id.toString();

        if (tailRecordId) {
            const data = new FormData();
            const token = sessionStorage.getItem('access_token_augur');
            data.append('TailRecordId', tailRecordId.toString());
            axios.post(`api/tails/UnArchivedFlight`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                
                .then(response => response.data as Promise<any>)
                .then(res => {
                    if (appState.planning && appState.planning.tailRecords) {
                        var tailRecords = appState.planning.tailRecords;
                        const removeIndex = tailRecords.indexOf(tailRecords.find(n => n.tail.id == tailRecordId));
                        ~removeIndex && tailRecords.splice(removeIndex, 1);
                        if (appState.planning.allTailRecords) {
                            var allTailRecords = appState.planning.allTailRecords;
                            const updateIndex = allTailRecords.indexOf(allTailRecords.find(n => n.tail.id == tailRecordId));
                            ~updateIndex && (allTailRecords[updateIndex].tail.isArchived = false);
                            tailRecords = appState.planning.allTailRecords.filter((record: any) => record.tail.isArchived == true);
                            tailRecords = handleFilterSort(appState, tailRecords);
                            dispatch({ type: "SORT_SCREEN", selectedTail: tailRecords[0], tailRecords: tailRecords });
                        }
                    }

                    dispatch({ type: 'CLOSE_UNARCHIVE_POPUP', isLoading: false });
                    toast.dismiss();
                    toast("Unarchived Successfully!");
                })
                .catch(error => dispatch({ type: 'CLOSE_UNARCHIVE_POPUP', isLoading:false }))
            dispatch({ type: 'CLOSE_UNARCHIVE_POPUP', isLoading: true });
        }
    },
    ShowMxAddNotes: (mxId:number, ref: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxNote = appState.planning && appState.planning.selectedMxNote;
        if (selectedMxNote) {
            selectedMxNote.id = 0;
            selectedMxNote.mxId = mxId;
            selectedMxNote.note = '';
            dispatch({ type: 'SHOW_MX_ADD_NOTE', SelectedMxRequestRef: ref });
        }
    },
    CloseMxAddNotes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_MX_ADD_NOTE', isLoading: false });
    },
    UpdateMxNotes: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxNote = appState.planning && appState.planning.selectedMxNote;
        if (selectedMxNote) {
            selectedMxNote.note = event.target.value;
            dispatch({ type: 'UPDATE_MX_NOTE', updatedMxNote: selectedMxNote })
        }
    },
    SaveMxNote: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxNote = appState.planning && appState.planning.selectedMxNote;
        if (selectedMxNote && selectedMxNote.note == '') {
            return;
        }

        if (selectedMxNote) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData();
            data.append('MXId', selectedMxNote.mxId.toString());
            data.append('Id', selectedMxNote.id.toString());
            data.append('Note', selectedMxNote.note);
            data.append('SourceScreen', "1");

            axios.post(`api/Tails/SaveMxNote`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<any>)
                .then(res => { // then print response status
                    if (appState.planning && appState.planning.selectedMxNote) {
                        const mxid = appState.planning && appState.planning.selectedMxNote && appState.planning.selectedMxNote.mxId;
                        let selMxReq = appState.planning && appState.planning.selectedTail.tail.mxrequests.find((e: any) => e && e.id
                            === mxid);
                        if (selMxReq.mxNotes.find((n: any) => n.id === res.id)) {
                            selMxReq.mxNotes[selMxReq.mxNotes.indexOf(selMxReq.mxNotes.find((n: any) => n.id === res.id))]
                                = res;
                        }
                        else {
                            selMxReq.mxNotes.push(res);
                        }

                    }

                    dispatch({ type: 'CLOSE_MX_ADD_NOTE', isLoading: false });
                })
                .catch(error => dispatch({ type: 'CLOSE_MX_ADD_NOTE', isLoading: false }))
            dispatch({ type: 'CLOSE_MX_ADD_NOTE', isLoading: true });
        }
    },
    EditMxNotes: (ref: any, note: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let selectedMxNote = appState.planning && appState.planning.selectedMxNote;
        if (selectedMxNote) {
            selectedMxNote.id = note.id;
            selectedMxNote.mxId = note.mxId;
            selectedMxNote.note = note.note
            dispatch({ type: 'SHOW_MX_ADD_NOTE', SelectedMxRequestRef: ref })
        }
    },
    ShowDeleteMxNoteConfirmation: (note: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_DELETE_MX_NOTE_CONF', isloading: true, note: note })
    },
    CloseDeleteMxNoteConfirmation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_DELETE_MX_NOTE_CONF', isloading: false })
    },
    DeleteMxNotes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState.planning && appState.planning.selectedMxNote) {
            let note = appState.planning && appState.planning.selectedMxNote as any;

            let selMxr = appState.planning && appState.planning.selectedTail &&
                appState.planning.selectedTail.tail.mxrequests &&
                appState.planning.selectedTail.tail.mxrequests.find((e: any) => e.id === note.mxId);
            const removeIndex = selMxr.mxNotes.indexOf(selMxr.mxNotes.find((n: any) => n.id === note.id));
            ~removeIndex && selMxr.mxNotes.splice(removeIndex, 1);

            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            data.append('Id', note.id.toString())
            data.append('MXId', note.mxId.toString())

            axios.post(`api/Tails/DeleteMxNote`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.data as Promise<{}>)
                .then(res => { // then print response status
                    dispatch({ type: 'CLOSE_DELETE_MX_NOTE_CONF', isloading: false });
                })

        }
        dispatch({ type: 'CLOSE_DELETE_MX_NOTE_CONF', isloading: true });

    },
    selectImage: (file: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.worksheetWIP) {
            dispatch({ type: "TOGGLE_IMAGE", file: file, toggleImg: !appState.worksheetWIP.imageOpen });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<PlanningState> = (state: PlanningState | undefined, incomingAction: Action): PlanningState => {
    if (state === undefined) {
        return {
            selectedDate: new Date(), tailRecords: [], allTailRecords:[], isLoading: false,
            selectedTail: null, isUserSelected: false, ptwStatus: [],
            isUpdatingMddr: false, showAddNotes: false, selectedMddr: null,
            selectedNote: { id: 0, mddrid: 0, noteText: '' } as MddrNote,
            selectedPart: {
                id: 0, mddrid: 0, noteDesc: '', partName: '', partNumber: '',
                qtyAvailable: 1, qtyRequested: 1, serialNumber: ''
            } as MddrPart,
            showAddParts: false,
            selectedMxR: {
                mxrequestFiles: [],
                description: '', id: 0, mxRequestTypesId: 1, notes: '', refNumber: '', mxRequestPlanningStatusId: 1, tailRecordId: 0, mxRequestTypesValues: { label: '', value: 0 },
                mxRequestStatusValues: { label: '', value: 0 },
                files: [], mxRequestStatusId: 1
            }
            , mxStatus: []
            , showAddMx: false
            , mxTypes: [{ id: 1, requestText: 'Workload planning WO' }, { id: 2, requestText: 'Action Plan' }]
            , showPrintSchedule: false
            , printList: [{ id: 1, requestText: 'For All Flights' }, { id: 2, requestText: 'For Single Flight' }
                , { id: 3, requestText: 'Per Fleet' }
            ],
            selectedPrintId: 1,
            selectedFlightId: 0,
            selectedFlightDetails: "",
            selectedFleetId: 0,
            selectedFleetDetails: "",
            allFleets: [],
            fileUrl: "",
            isTop: false,
            showArchivePop: false,
            showArch: false,
            showCorrection: false,
            correctionTails: [],
            seletedCorrectionTail: null,
            mxRequestUploadedFiles: [],
            mxRequestDeleteFIles: [],
            showFileValidation: false,
            errorMessage: '',
            isFilterSortPopupOpen: false,
            filterValues: {
                sortValue: 0,
                sortType: 'asc',
                showArchivedFlights:false
            },
            showUnarchivePopup:false,
            selectedmxrRequest: null,
            showDeleteMxrConfirmation: false,
            showDeletePartConfirmation: false,
            showDeleteNoteConfirmation: false,
            selectedMxNote: { id: 0, mxId: 0, note: '' } as MxNote,
            SelectedMxRequestRef: '',
            showMxAddNote:false,
            showDeleteMxNoteConfirmation: false,
            imageOpen: false,
            selectedImage: null as any,
            isShowArchiveWarnning: false,
            showReplace: false,
            isShowReplaceWarnning: false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'SHOW_REPLACE_WARNING':
            return {
                ...state,
                isShowReplaceWarnning: !state.isShowReplaceWarnning,
            }
        case 'SHOW_ARCHIV_WARNING':
            return {
                ...state,                 
                isShowArchiveWarnning: !state.isShowArchiveWarnning,
            }
        case 'SHOW_REPLACE':
            return {
                ...state,
                showReplace: !state.showReplace,
            }

        case 'SHOW_ARCHIV':
            return {
                ...state,
                showCorrection: state.showCorrection,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: !state.showArch,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'CLOSE_ARCHIV':
            return {
                ...state,
                showCorrection: state.showCorrection,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: action.isloading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: false,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SELECT_CORRECT_TAIL':
            return {
                ...state,
                seletedCorrectionTail: action.payload,
                filterValues: state.filterValues
            }
        case 'FETCH_CORRECT':
            return {
                ...state,
                isLoading: true,
                seletedCorrectionTail: null,
                correctionTails: [],
                filterValues: state.filterValues
            };
        case 'SHOW_CORECT':
            return {
                ...state,
                showReplace: false,
                showCorrection: !state.showCorrection,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: false,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch,
                correctionTails: action.correctionFlight,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'REQUEST_TAILS':
            return {
                ...state,
                selectedDate: action.payload, tailRecords: [], isLoading: true,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords:[],
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'RECEIVE_TAILS':
            return {
                ...state,
                selectedDate: state.selectedDate, tailRecords: action.tailRecords, isLoading: false,
                selectedTail: action.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: action.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: action.mxTypes
                , mxStatus: action.mxPlanningStatus
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: action.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: action.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SELECT_TAIL':
            return {
                ...state,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: action.payload, isUserSelected: true,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'DESELECT_TAIL':
            return {
                ...state,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: false,
                selectedTail: null, isUserSelected: false,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SELECT_STATUS':
            return {
                ...state,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: action.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: true,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'UPDATED_STATUS':
            return {
                ...state,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: false,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SHOW_ADDNOTE':
            return {
                ...state,
                showAddNotes: true, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: action.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'UPDATE_NOTE':
            return {
                ...state,
                showAddNotes: true, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: action.updatedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'CLOSE_ADDNOTE':
            return {
                ...state,
                showAddNotes: false, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: action.isloading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'CLOSE_ADDPART':
            return {
                ...state,
                showAddNotes: false, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: action.isloading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: false
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SHOW_ADDPART':
            return {
                ...state,
                showAddNotes: false, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: action.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: true
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'CLOSE_ADDMxR':
            return {
                ...state,
                showAddNotes: false, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: action.isloading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: false
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SHOW_ADDMxR':
            return {
                ...state,
                showAddNotes: false, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: true
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: action.files,
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SORT_SCREEN':
            return {
                ...state,
                showAddNotes: false, selectedDate: state.selectedDate, tailRecords: action.tailRecords, isLoading: state.isLoading,
                selectedTail: action.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: false
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'CLOSE_PRINT_SCHEDULE':
            return {
                ...state,
                showAddNotes: state.showAddNotes, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: action.isloading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: false
                , printList: state.printList
                , selectedPrintId: 1
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: action.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SHOW_PRINT_SCHEDULE':
            return {
                ...state,
                showAddNotes: state.showAddNotes, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: true
                , printList: state.printList
                , selectedPrintId: 1
                , selectedFlightId: 0
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.allFleets.length > 0 ? state.allFleets[0] : state.selectedFleetId
                , selectedFleetDetails: state.allFleets.length > 0 ? state.allFleets[0] : state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SELECT_PRINT_SCHEDULE':
            return {
                ...state,
                showAddNotes: state.showAddNotes, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: action.selectedPrintMethodId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SELECT_FLIGHT_DETAILS_FOR_PRINT':
            return {
                ...state,
                showAddNotes: state.showAddNotes, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: action.selectedFlightId
                , selectedFlightDetails: action.selectedFlightDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'SELECT_FLEET_DETAILS_FOR_PRINT':
            return {
                ...state,
                showAddNotes: state.showAddNotes, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: 0
                , selectedFlightDetails: ""
                , selectedFleetId: action.selectedFleetId
                , selectedFleetDetails: action.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'TOGGLE_TOP':
            return {
                ...state,
                showAddNotes: state.showAddNotes, selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: state.isLoading,
                selectedTail: state.selectedTail, isUserSelected: state.isUserSelected,
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: 0
                , selectedFlightDetails: ""
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: action.isTop
                , showArchivePop: state.showArchivePop
                , showArch: state.showArch
                , showCorrection: state.showCorrection,
                correctionTails: state.correctionTails,
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: false,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'CLOSE_CORECT':
            return {
                ...state,
                showCorrection: false,
                selectedDate: state.selectedDate, tailRecords: state.tailRecords, isLoading: action.isloading,
                isUserSelected: state.isUserSelected, 
                ptwStatus: state.ptwStatus, isUpdatingMddr: state.isUpdatingMddr,
                showAddNotes: state.showAddNotes, selectedMddr: state.selectedMddr,
                selectedNote: state.selectedNote, selectedPart: state.selectedPart, showAddParts: state.showAddParts
                , selectedMxR: state.selectedMxR, showAddMx: state.showAddMx
                , mxTypes: state.mxTypes
                , showPrintSchedule: state.showPrintSchedule
                , printList: state.printList
                , selectedPrintId: state.selectedPrintId
                , selectedFlightId: state.selectedFlightId
                , selectedFlightDetails: state.selectedFlightDetails
                , selectedFleetId: state.selectedFleetId
                , selectedFleetDetails: state.selectedFleetDetails
                , allFleets: state.allFleets
                , fileUrl: state.fileUrl
                , isTop: state.isTop
                , showArchivePop: state.showArchivePop
                , showArch: false,
                correctionTails: [],
                seletedCorrectionTail: state.seletedCorrectionTail,
                mxRequestUploadedFiles: [],
                mxRequestDeleteFIles: [],
                showFileValidation: false,
                errorMessage: '',
                isFilterSortPopupOpen: state.isFilterSortPopupOpen,
                filterValues: state.filterValues,
                showUnarchivePopup: state.showUnarchivePopup,
                allTailRecords: state.allTailRecords,
                selectedmxrRequest: null,
                showDeleteMxrConfirmation: false,
                showDeletePartConfirmation: false,
                showDeleteNoteConfirmation: false
            };
        case 'UPLOAD_FILES':
            return {
                ...state,
                mxRequestUploadedFiles: action.files,
                mxRequestDeleteFIles: action.deletedFiles,
                isLoading: false,
                filterValues: state.filterValues
            };
        case 'CLOSE_IMAGE_VALID':
            return {
                ...state,
                errorMessage: '',
                showFileValidation: action.showImageValid,
                filterValues: state.filterValues
            };
        case 'SHOW_IMAGE_VALID':
            return {
                ...state,
                errorMessage: action.message,
                showFileValidation: action.showImageValid,
                filterValues: state.filterValues
            };
        case 'SHOW_LOADER':
            return {
                ...state,
                isLoading: true,
                filterValues: state.filterValues
            };
        case 'TOGGLE_FILTER_SORT_POPUP':
            return {
                ...state,
                isFilterSortPopupOpen: !state.isFilterSortPopupOpen,
                filterValues: state.filterValues
            };
        case 'UPDATE_FILTER_VALUES':
            return {
                ...state,
                filterValues: {
                    sortType: action.filterValues.sortType,
                    sortValue: action.filterValues.sortValue,
                    showArchivedFlights: action.filterValues.showArchivedFlights
                } 
            };
        case 'CLOSE_FILTER_POPUP':
            return {
                ...state,
                isFilterSortPopupOpen:false
            }
        case 'SHOW_UNARCHIVE_POPUP':
            return {
                ...state,
                showUnarchivePopup: !state.showUnarchivePopup
            }
        case 'CLOSE_UNARCHIVE_POPUP':
            return {
                ...state,
                showUnarchivePopup: false,
                isLoading: action.isLoading
            }
        case 'SHOW_DELETE_Mxr_CONF':
            return {
                ...state,
                showDeleteMxrConfirmation: true,
                selectedmxrRequest: action.mxr
            };
        case 'CLOSE_DELETE_Mxr_CONF':
            return {
                ...state,
                showDeleteMxrConfirmation: false,
            };
        case 'SHOW_DELETE_PART_CONF':
            return {
                ...state,
                showDeletePartConfirmation: true,
                selectedPart: action.part
            };
        case 'CLOSE_DELETE_PART_CONF':
            return {
                ...state,
                selectedPart: {
                    id: 0, mddrid: 0, noteDesc: '', partName: '', partNumber: '',
                    qtyAvailable: 1, qtyRequested: 1, serialNumber: ''
                } as MddrPart,
                showDeletePartConfirmation: false,
            };
        case 'SHOW_DELETE_NOTE_CONF':
            return {
                ...state,
                showDeleteNoteConfirmation: true,
                selectedNote: action.note
            };
        case 'CLOSE_DELETE_NOTE_CONF':
            return {
                ...state,
                selectedNote: { id: 0, mddrid: 0, noteText: '' } as MddrNote,
                showDeleteNoteConfirmation: false,
            };
        case 'SHOW_MX_ADD_NOTE':
            return {
                ...state,
                showMxAddNote: true,
                SelectedMxRequestRef: action.SelectedMxRequestRef
            };
        case 'CLOSE_MX_ADD_NOTE':
            return {
                ...state,
                showMxAddNote: false,
                isLoading: action.isLoading
            };
        case 'UPDATE_MX_NOTE':
            return {
                ...state,
                selectedMxNote: action.updatedMxNote
            };
        case 'SHOW_DELETE_MX_NOTE_CONF':
            return {
                ...state,
                showDeleteMxNoteConfirmation: true,
                selectedMxNote: action.note
            };
        case 'CLOSE_DELETE_MX_NOTE_CONF':
            return {
                ...state,
                selectedMxNote: { id: 0, mxId: 0, note: '' } as MxNote,
                showDeleteMxNoteConfirmation: false,
            };
        case 'TOGGLE_IMAGE':
            return {
                ...state,
                imageOpen: action.toggleImg,
                selectedImage: action.file,
                isLoading: false,
            }
        default:
            return state;
    }
};
