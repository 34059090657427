import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as UserStore from '../store/Users';
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar, FaCaretDown, FaCalendarAlt, FaRegCalendar, FaChevronLeft } from 'react-icons/fa';
import Select from 'react-select';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { render } from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoCloseOutline } from 'react-icons/io5';


type UserProps =
    UserStore.UserState &
    typeof UserStore.actionCreators &
    RouteComponentProps<{}>;

const ReactSelectAdapter = ({ input, ...rest }: any) => (
    <Select className=""
        defaultValue={{ value: "0", label: "Select Role" }}
        styles={customStyles} {...input} {...rest} />
)

const customStyles = {
    control: (base: any, state: { isFocused: any; isDisabled: any; }) => ({
        ...base,
        background: state.isDisabled ? "#e9ecef":"#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "#d6d8e7",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "#d6d8e7"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#ffd0bc" : "#fff",
        color: state.isSelected ? "#f47d30" : "#636569",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#ffd0bc",
            color: "#f47d30"
        }
    }),
    menu: (base: any) => ({
        ...base,
        //width: "400px",
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
        paddingLeft: 10,
    })
}


class Users extends React.PureComponent<UserProps> {
    public componentDidMount() {
        var id = new URLSearchParams(this.props.location.search).get("id");
        this.ensureDataFetched(id);
    }
    private ensureDataFetched(id: any) {
        this.props.GetUserDetails(id);
    }

    public selectFile(event: any) {
        this.props.OnFileSeleect(event);
        this.forceUpdate();
    }
    public closeUser() {
        if (this.props.email == null || this.props.email == "") {
            this.props.history.push("/Users");
        }
        else {
            this.props.CloseAddUsers();
            this.componentDidMount();
        }
    };
    public backToUserList() {
        this.props.history.push("/Users");
    }
    
    public render() {
        return (
            <React.Fragment>




                <Modal className="partsModal" centered={true} isOpen={this.props.selectFile}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.ToggleSelectFile(false); }}><IoCloseOutline /></div>

                            </div>
                        </div>

                        <div className="flex flex-center w-100">
                            Choose your Profile Picture
                            </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex flex-center w-100">
                            <div>
                                <img src={this.props.userDetails.uploadedImageURL}
                                    className="profilePictureSelector" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter >
                        {!this.props.userDetails.imageSelected &&
                            <div className="flex flex-center w-100 margin-bottom-10">
                                <input type="file" id="img" className="profle-file-input" name="img" accept="image/*" onChange={(event) => { this.selectFile(event); }} />
                            </div>
                        }

                        {this.props.userDetails.imageSelected &&
                            <div className="flex flex-center w-100 margin-bottom-10">
                            <div className="round-empty-button-auto" onClick={() => { this.props.ToggleSelectFile(false); }} >
                                    DISCARD CHANGES
                        </div>
                            &nbsp;
                            <div className="round-fill-button margin-left-24" onClick={() => { this.props.ToggleSelectFile(true); }}>
                                    USE PHOTO
                        </div>
                            </div>
                        }


                    </ModalFooter>
                </Modal >


                <Modal centered={true} className="smallModal" isOpen={this.props.confirmDelete}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.ToggleConfirmDelete(); }}><IoCloseOutline /></div>

                            </div>
                        </div>

                        <div className="text-center">
                            Are you sure?
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center">
                            The user account will be deleted
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="round-empty-button" onClick={() => { this.props.ToggleConfirmDelete(); }}>
                            NO, CANCEL
                        </div>
                        <div className="round-fill-button margin-left-24" onClick={() => { this.props.DeleteUser(this.props.userDetails.id); }}>
                            YES, DELETE
                        </div>

                    </ModalFooter>
                </Modal >


                {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }
                <div className="row">
                    <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                </div>

                <div >
                    {(this.props.userDetails.email) &&
                        <div>
                            <FaChevronLeft className="width-Fa-Left" onClick={() => { this.backToUserList(); }} /> <label className="planTitle userTitle"> User Details</label>
                        </div>
                    }
                    {(!this.props.userDetails.email) &&
                        <div>
                            <FaChevronLeft className="width-Fa-Left" onClick={() => { this.backToUserList(); }} /> <label className="planTitle userTitle"> Add a new user</label>
                        </div>
                    }
                </div>
                <div className="spacer">
                    &nbsp;
                </div>
                {this.props.userDetails &&
                    <div className={this.props.isEditMode ? "" : "profileBox"} onClick={() => { this.props.ToggleSelectFile(false); }}>
                        <img className="profilePicture" src={this.props.userDetails.imageDisplayURI}  ></img>
                    </div>
                }


                <Form
                    onSubmit={this.props.SaveUser}
                    initialValues={this.props.userDetails}
                    validate={values => {
                        let errors = {} as any;
                        if (!values.firstName) {
                            errors.firstName = 'Please enter first name'
                        }
                        if (!values.lastName) {
                            errors.lastName = 'Please enter last name'
                        }
                        if (values.userName && values.userName.match(/\d/)) {
                            errors.userName = 'Please avoid numbers'
                        }
                        if (!values.userId) {
                            errors.userId = 'Please enter employee Id'
                        }
                        if (values.userId && !String(values.userId).match(/^[0-9]*$/)) {
                            errors.userId = 'Please enter numbers only'
                        }
                        if (!values.email || (values.email && !String(values.email).match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/))) {
                            errors.email = 'Please enter email'
                        }
                        if (values.roleValues && values.roleValues.value== 0) {
                            errors.roleValues = 'Please select access level'
                        }                       
                        if (values.accessLevelValues && values.accessLevelValues.value == 0) {
                            errors.accessLevelValues = 'Please select position'
                        }
                        if (values.weekHours && !String(values.weekHours).match(/^\d+(\.\d{1})?$/)) {
                            errors.weekHours = 'Please enter hours in format 0.0'
                        }
                        if (values.defaultStationValues && values.defaultStationValues.value == 0) {
                            errors.defaultStationValues = 'Please select default station'
                        }
                        return errors
                    }}

                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>

                            <div className="partsForm">
                                <fieldset disabled={this.props.isEditMode}>
                                    <div className="row col-12 flex flex-space-between paddingTopUserDetails">
                                        <div className="col-6 noPadding colUser" >
                                            <Field
                                                name="firstName"
                                            >
                                                {({ input, meta }) => (
                                                    <div className="col-12 noLeftPadding">
                                                        <label className="asterisk">First Name</label>
                                                         <input className="form-control" maxLength={64} {...input} type="text" placeholder="" />
                                                         {meta.error && meta.touched && <span>{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="col-6 flex flex-space-between noPadding colUser emailPadding">
                                            <div className="col-12 noPadding">
                                                <Field
                                                    name="lastName"
                                                >
                                                    {({ input, meta }) => (
                                                        <div className="col-12  noRightPadding">
                                                            <label className="asterisk">Last Name</label>
                                                            <input className="form-control" maxLength={64} {...input} type="text" placeholder="" />
                                                            {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-12 flex flex-space-between paddingTopUserDetails">
                                        <div className="col-6 noPadding colUser" >
                                            <Field
                                                name="userId"
                                            >
                                                {({ input, meta }) => (
                                                    <div className="col-12 noLeftPadding">
                                                        <label className="asterisk">Employee Id</label>
                                                        <input className="form-control" maxLength={5} {...input} type="text" placeholder=""
                                                            title="Please enter numbers only"
                                                        />
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>

                                        <div className="col-6 flex flex-space-between noPadding colUser emailPadding">
                                            <div className="col-12 noPadding">
                                                <Field
                                                    name="email"
                                                >
                                                    {({ input, meta }) => (
                                                        <div className="col-12  noRightPadding">
                                                            <label className="asterisk">Email</label>
                                                            <input className="form-control" maxLength={100} {...input} type="email" placeholder="" />
                                                            {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-12 flex flex-space-between paddingTopUserDetails">
                                        <div className="col-6 noLeftPadding colUser" >
                                               
                                                    <div className="col-12  noPadding">
                                                        <label className="asterisk">Access Level</label>
                                                <Field name="roleValues"
                                                    component={ReactSelectAdapter} >
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <ReactSelectAdapter isDisabled={this.props.isEditMode} options={this.props.rolesList.map((team) => {
                                                                    return { value: team.id, label: team.value };
                                                                }
                                                                )}  {...input}
                                                            />
                                                            {meta.error && meta.touched && <span>{meta.error}</span>}
                                                            </div>

                                                    )}
                                                        </Field>
                                                        
                                                    </div>
                                               
                                        </div>
                                        <div className="col-6 flex flex-space-between noPadding colUser emailPadding">
                                            <div className="col-12 noPadding">
                                                        <div className="col-12  noRightPadding">
                                                            <label className="asterisk">Job Title</label>
                                                    <Field name="accessLevelValues"
                                                                component={ReactSelectAdapter} >
                                                                {({ input, meta }) => (
                                                            <div>
                                                                <ReactSelectAdapter isDisabled={this.props.isEditMode} options={this.props.accessLevelList.map((team) => {
                                                                            return { value: team.id, label: team.value };
                                                                        }
                                                                        )}  {...input}
                                                                        />
                                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                    </div>

                                                                )}
                                                    </Field>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-12 flex flex-space-between paddingTopUserDetails">
                                        <div className="col-6 noLeftPadding colUser" >
                                                    <div className="col-12  noPadding">
                                                        <label>Shift</label>
                                                <Field name="shiftValues"
                                                    component={ReactSelectAdapter} >
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <ReactSelectAdapter isDisabled={this.props.isEditMode} options={this.props.shiftList.map((team) => {
                                                                return { value: team.id, label: team.value };
                                                            }
                                                            )}  {...input}
                                                            />
                                                        </div>

                                                    )}
                                                </Field>
                                                    </div>
                                        </div>
                                        <div className="col-6 flex flex-space-between noPadding colUser emailPadding">
                                            <div className="col-12 noPadding">
                                                <Field
                                                    name="weekHours"
                                                >
                                                    {({ input, meta }) => (
                                                        <div className="col-12  noRightPadding">
                                                            <label>Week Hours</label>
                                                            <input className="form-control" maxLength={5} {...input} type="text" placeholder=""
                                                                title="Please enter numbers only"
                                                            />
                                                            {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row col-12 flex flex-space-between paddingTopUserDetails">
                                        <div className="col-6 noLeftPadding colUser" >
                                                    <div className="col-12  noPadding">
                                                        <label>Shift Hours</label>
                                                <Field name="shiftHoursValues"
                                                    component={ReactSelectAdapter} >
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <ReactSelectAdapter isDisabled={this.props.isEditMode} options={this.props.shiftHoursList.map((team) => {
                                                                return { value: team.id, label: team.value };
                                                            }
                                                            )}  {...input}
                                                            />
                                                        </div>

                                                    )}
                                                </Field>
                                                    </div>
                                        </div>
                                        <div className="col-6 flex flex-space-between noPadding colUser emailPadding">
                                            <div className="col-12 noPadding">
                                                        <div className="col-12  noRightPadding">
                                                            <label>Shift Days</label>
                                                    <Field name="shiftDaysValues"
                                                        component={ReactSelectAdapter} >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <ReactSelectAdapter isDisabled={this.props.isEditMode} options={this.props.shiftDaysList.map((team) => {
                                                                    return { value: team.id, label: team.value };
                                                                }
                                                                )}  {...input}
                                                                />
                                                            </div>

                                                        )}
                                                    </Field>
                                                        </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="row col-12 flex flex-space-between paddingTopUserDetails">
                                        <div className="col-12 noLeftPadding noRightPadding" >
                                            <div className="col-12  noPadding">
                                                <label className="asterisk">Default Station</label>
                                                <Field name="defaultStationValues"
                                                    component={ReactSelectAdapter} >
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <ReactSelectAdapter isDisabled={this.props.isEditMode} options={this.props.defaultStationList.map((team) => {
                                                                return { value: team.id, label: team.value };
                                                            }
                                                            )}  {...input}                                                                
                                                            />
                                                            {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </div>

                                                    )}
                                                </Field>
                                            </div>
                                        </div>                                        
                                    </div>
                                </fieldset>
                                <br />
                                {(this.props.isEditMode) &&

                                    <div className="col-12 floatRight paddingTopUserDetails paddingRightDoubleUserDetails">
                                        {this.props.userDetails.isActive &&
                                            <button className="round-empty-button-auto" type="button" onClick={() => { this.props.ToggleConfirmDelete(); }}>
                                                DELETE ACCOUNT
                                    </button>
                                        }
                                    &nbsp;
                                    {this.props.userDetails.isActive &&
                                            <button className="round-fill-button border-0 margin-left-24" type="button" onClick={this.props.EditUser}>
                                                EDIT ACCOUNT
                                    </button>
                                        }
                                    </div>
                                }
                                {(!this.props.isEditMode) &&
                                    <div className="col-12 floatRight paddingTopUserDetails paddingRightDoubleUserDetails">

                                        <button className="round-empty-button" type="reset" onClick={() => { this.closeUser(); }}>CANCEL</button>
                                        <span>&nbsp;</span>
                                    <button className="round-fill-button border-0 margin-left-24" type="submit" disabled={submitting}>
                                            {!this.props.userDetails.email ? "SAVE" : "SAVE CHANGES"}
                                        </button>

                                    </div>
                                }
                            </div>
                        </form>
                    )}
                />

            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.users,
    UserStore.actionCreators
)(Users as any);

