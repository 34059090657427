import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import Select from 'react-select';
import { ApplicationState } from '../store';
import * as StationStore from '../store/Station';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';


const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "gray",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "gray"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47d30" : "#ffd0bc" ,
        color: state.isSelected ? "#fff" : "#f47d30",
        fontWeight: "bold",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#000",
            color:"#fff"
        }
    }),
    menu: (base: any) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    })
};

type StationProps =
    StationStore.StationState &
    typeof StationStore.actionCreators &
    RouteComponentProps<{}>;

class Station extends React.Component<StationProps> {

    selectedStations = [{ label: "Select ", value: "0" }];

    
    public componentDidMount() {
        this.ensureDataFetched();
    }

    public render() {
        return (
            <>
                {this.props.isLoading &&
                    <Loader fullPage={true} loading={this.props.isLoading} />

                }
                {!this.props.isLoading &&
                    <div className="centerContent aircraftbg  col-12">
                        <div className="child centerBoxST col-lg-6">
                            <div className="row paddingto5">
                                <div className="col h1">
                                    Select your Station                        </div>
                            </div>

                            <div className="row">
                                <div className="col h4">
                                    Please pick a FEAM service station                        </div>
                            </div>


                            <div className="row paddingt5">
                                <div className="col">
                                    <div className="horStack" >
                                        <div className="lighterText">
                                            Station / Airport
                            </div>
                                        <div>
                                        <Select
                                            styles={customStyles}
                                            className="dropdownSt" onChange={event => { this.props.SelectStation(event) }}
                                                options={this.props.mappedStations}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row paddingt5 paddingb5">
                                <div className="col">
                                <div className={(this.props.selectedStation.stationName && this.props.selectedStation.value) ? "stationBtn" : "stationBtn  stationBtn-disable"} onClick={(event) => { this.redirectToDashBoard() }}>SUBMIT</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </>
        );
    };

    public redirectToDashBoard() {
        //const { history: { push } } = this.props;
       // console.log(this.props);
        //if (this.props.selectedStation.stationName && this.props.selectedStation.value) {
            this.props.history.push("/dashboard");
        //}
        //this.props.history.push("/dashboard");
    };


    private ensureDataFetched() {
        this.props.fetachRequest();
             

    }
}
export default connect(
    (state: ApplicationState) => state.station,
    StationStore.actionCreators
)(withRouter(Station as any));
