import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Station } from './Station';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DashBoardState {
    selectedStation: string
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface FetchUserAction { type: 'FETCH_USER', station: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = FetchUserAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    fetchUser:() : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.station && appState.station.selectedStation) {
            dispatch({ type: 'FETCH_USER', station: appState.station.selectedStation.stationName });
        }
        else {
            dispatch({ type: 'FETCH_USER', station: "" });
        }
    
        
    }
}
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<DashBoardState> = (state: DashBoardState | undefined, incomingAction: Action): DashBoardState => {
    if (state === undefined) {
        let station: Station = {
            stationName: "",
            value: ""
        };
        const value = sessionStorage.getItem("selectedStation");
        if (value) {
            station = JSON.parse(String(value));
        } 
        return { selectedStation: station.stationName };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FETCH_USER':
            return { selectedStation: action.station };
        default:
            return state;
    }
};
