import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as DashBoardStore from '../store/DashBoard';

type DashBoardProps =
    DashBoardStore.DashBoardState &
    typeof DashBoardStore.actionCreators &
    RouteComponentProps<{}>;



class DashBoard extends React.PureComponent<DashBoardProps> {
    public componentDidMount() {
        this.ensureDataFetched();

        
    }

    public render() {
        return (
            <>
                <p></p>
                <div className="dashboard-Container">
                    <h1>Welcome to {this.props.selectedStation}!</h1>
                    <p className="dashboard-lighterText">
                        We’re excited to introduce you to FEAM’s most recent digital project,
                        AUGUR.
                    </p>
                    <p className="dashboard-lighterText">
                        Augur was developed with the goal of creating a more simplified and streamline operation.
                        We’ve collected the vast amount of information and communication flowing back and forth between an MRO and the aircraft
                        operator and centralized into one system. Through digitalization, we hope to eliminate the excess paperwork and increase
                        the frequency of real-time updates and collaboration.
                    </p>
                    <p className="dashboard-lighterText">
                        We are in the beginning stages of development and are exclusive to CVG but we are looking forward to working closely with
                        the FEAM TEAM to expand to other stations, integrate even more features and build a system that will allow for a more
                        efficient operation.

                    </p>
                    <p className="dashboard-lighterText">
                        Here are the most popular resources to help you get started:
                    </p>
                    <ul>
                        <li className="dashboard-listText"><a href="https://feam.sharepoint.com/:w:/s/AugurInternal/ERQxj2ATa0RNrcwKA9pXMzYBgkEOP3pYLktJnL05gfJ18Q?e=suWS0F" target="_blank">Guide to Getting Started with AUGUR </a></li>
                        <li className="dashboard-listText"><a href="https://feam.sharepoint.com/:w:/s/AugurInternal/EbXIP3iWptZMpAFjU3D552IBfmW5wKciBnxNWt6gN8KZqA?e=mHIYMy"
                            target="_blank">Augur User Manual </a></li>
                    </ul>

                    <p className="dashboard-lighterText">
                        Going forward, we will keep you connected with tons of resources and updates via our Teams Channel.
                    </p>

                    <p className="dashboard-lighterText">
                        If you’re feeling stuck or have any questions, You can always report an issue by sending an email to
                        <a href="mailto:Augurhelp@feam.aero"> Augurhelp@feam.aero</a>.
                        <br /><br />
                        We’re excited to have you on board!
                        <br /><br />
                        <b>The FEAM TEAM</b>
                    </p>



    </div>
                </>
        );


    }

    private ensureDataFetched() {
        this.props.fetchUser();
    }
};
export default connect(
    (state: ApplicationState) => state.dashBoard,
    DashBoardStore.actionCreators
)(DashBoard as any);
