import * as React from 'react';
import {
    Collapse, Container, Navbar, NavbarToggler, NavLink, NavItem
} from 'reactstrap';
import './NavMenu.css';
import  UserLogin  from './UserLogin';

import 'react-pro-sidebar/dist/css/styles.css';

import logo from '../feam-logo.png';
import { Link, withRouter } from 'react-router-dom';
import { FaHome, FaUpload } from 'react-icons/fa';
import { ScreenValid } from '../guards/GuardedRoute';
import {
    IoHomeOutline, IoCalendarClearOutline, IoCloudUploadOutline
    , IoPeopleOutline, IoGlobeOutline, IoSettingsOutline
} from 'react-icons/io5'


class NavMenu extends React.PureComponent<{
    auth: any;
    history: any}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    unlisten: any = {};
    activePathName: string = '';

    componentDidMount() {
        this.unlisten = this.props.history.listen((location: any, action: any) => {
            console.log("on route change from navmenu");
            this.activePathName = location.pathname;
            this.setState({
                isOpen: false
            });
        });
        console.log(this.props.history.location.pathname);
        this.activePathName = this.props.history.location.pathname;
        console.log(this.activePathName);
        this.forceUpdate();
    }
    componentWillUnmount() {
        this.unlisten();
    }

    public render() {
        console.log('1');
        return (
            <React.Fragment>
           

                {(this.props.auth && this.props.auth.isAuthenticated) &&
                    <header className="navbarBottom">
                   
                            <Navbar className="navbar-expand-sm navbar-toggleable-sm mb-3" light>
                        <Container fluid={true} >
                            <img src={logo} className={this.activePathName != '/station' ? 'navbarLogo' : 'navbarLogoPM'} />
                           <div className="smdisplay">
                            {this.activePathName == '/station' &&
                                <UserLogin  {...this.props} />
                                }
                                </div>
                            {this.activePathName != '/station' && <NavbarToggler onClick={this.toggle} className="mr-2" />}
                                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                                <ul className="navbar-nav flex-grow nav-list">

                                    <div className="smhidden">
                                        {this.activePathName == '/station' &&
                                            <UserLogin  {...this.props} />
                                        }
                                    </div>
                                    {this.activePathName != '/station' &&
                                        <div>
                                        &nbsp;
                                            </div>}

                                    {this.activePathName != '/station' &&
                                     
                                        <UserLogin  {...this.props} />
                                    }
                                    {this.activePathName != '/station' &&
                                        <NavItem className="smdisplay nav-list-item">
                                        <NavLink tag={Link} className="text-dark-white" to="/dashboard">
                                           
                                            <FaHome />&nbsp;&nbsp;
                                            Dashboard</NavLink>
                                        </NavItem>}

                                    {ScreenValid('traxfile') && this.activePathName != '/station' &&
                                        <NavItem className="smdisplay nav-list-item">
                                        <NavLink tag={Link} className="text-dark-white" to="/traxfile">

                                            <IoCloudUploadOutline />&nbsp;&nbsp;
                                            Upload</NavLink>
                                        </NavItem>}
                                    {ScreenValid('workload') && this.activePathName != '/station' &&
                                        <NavItem className="smdisplay nav-list-item">
                                            <NavLink tag={Link} className="text-dark-white" to="/workload">

                                                <IoSettingsOutline />&nbsp;&nbsp;
                                           Workload</NavLink>
                                        </NavItem>}

                                    {ScreenValid('flightboards') && this.activePathName != '/station' &&
                                        <NavItem className="smdisplay nav-list-item">
                                        <NavLink tag={Link} className="text-dark-white" to="/FlightBoards">

                                            <IoGlobeOutline />&nbsp;&nbsp;
                                            Maintenance Control</NavLink>
                                        </NavItem>}                                   

                                    {this.activePathName != '/station' &&
                                        <NavItem className="smdisplay nav-list-item">
                                        </NavItem>}
                                        </ul>
                                    </Collapse>
                                </Container>
                            </Navbar>
                      
                   
                    </header>
                }

             

            </React.Fragment>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}


export default withRouter(NavMenu as any)
