import * as React from 'react';

class ArrivingLogo extends React.Component {
	render() {
		return (
			<svg version="1.1" fill="currentColor" stroke="currentColor" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 490 490"  xmlSpace="preserve">
				<path d="M463.207,352.947c8.038-27.165-4.274-54.034-36.048-68.719l-116.596-52.059l5.105-138.784
	c0.548-17.523-10.199-36.529-25.277-42.773c-34.364-14.232-63.207,5.331-66.955,38.087l-7.372,105.098l-82.044-31.549l-52.83-93.924
	c-2.167-3.866-6.616-5.954-11.001-5.14c-4.392,0.82-7.769,4.34-8.401,8.761L37.487,240.664c-1.032,7.166,4.552,10.47,6.595,11.231
	l346.949,132.333C410.961,394.122,453.073,387.196,463.207,352.947z M399.015,364.971L59.309,235.387l18.741-130.116l39.64,70.468
	c1.174,2.095,3.075,3.737,5.342,4.622l98.698,37.963c4.891,2.055,13.263-0.171,14.147-9.007l8.359-119.158
	c0.612-8.648,11.769-30.953,36.796-20.8c8.936,3.625,14.09,13.773,13.795,23.312l-5.356,145.76
	c-0.166,4.255,2.289,8.179,6.169,9.899l123.759,55.264c24.814,12.099,28.264,30.742,24.097,42.556
	C434.562,371.472,407.971,368.959,399.015,364.971z"/>
				<rect y="423.245" width="490" height="20.851" />
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
				<g>
				</g>
			</svg>


		)
	}

}

export default ArrivingLogo;