import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';


export interface Station {
    stationName: string;
    value: string;
}



export interface StationMD {
    id: string;
    name: string;
}


export interface StationState {
    selectedStation: Station;
    stations: StationMD[];
    mappedStations: { label: string, value: string }[];
    isLoading: boolean
}


export interface SelectStationAction { type: 'SELECT_STATION', payload:Station }
export interface RequestRequestsAction { type: 'REQUEST_REQUESTS'; }
export interface ReceiveRequestsAction { type: 'RECEIVE_REQUESTS'; requests: StationMD[], mapped: { label: string, value: string}[]}

export type KnownAction = SelectStationAction | ReceiveRequestsAction | RequestRequestsAction;

export const actionCreators = {
    SelectStation: (selectedStat: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(selectedStat);
        const station: Station = {
            stationName: selectedStat.label,
            value: selectedStat.value
        }; 

        sessionStorage.setItem('selectedStation', JSON.stringify(station));

        dispatch({ type: 'SELECT_STATION', payload:station });
    },
    fetachRequest: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        sessionStorage.removeItem('selectedStation');
        const token = sessionStorage.getItem('access_token_augur');
        console.log(token);
        fetch(`api/station`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(response => response.json() as Promise<StationMD[]>)
            .then(data => {
                console.log(data);
                const options = data.map(d => ({
                    "value": d.id,
                    "label": d.name
                }))
                console.log(options);
                dispatch({ type: 'RECEIVE_REQUESTS', requests: data, mapped: options });
            });

        dispatch({ type: 'REQUEST_REQUESTS' });
    }
};


export const reducer: Reducer<StationState> = (state: StationState | undefined, incomingAction: Action): StationState => {
    if (state === undefined) {
        let station: Station = {
            stationName: "",
            value: ""
        };
        const value = sessionStorage.getItem("selectedStation");
        if (value) {
            station = JSON.parse(String(value));
        }
       
        return { selectedStation: station, stations: [], mappedStations: [{ label: "Select ", value: "0" }], isLoading:false };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SELECT_STATION':
            return { selectedStation: action.payload, stations: state.stations, mappedStations: state.mappedStations, isLoading: false}
        case 'REQUEST_REQUESTS':
            return {
                selectedStation: state.selectedStation, stations: state.stations, mappedStations: state.mappedStations, isLoading:true
            }
        case 'RECEIVE_REQUESTS':
            {
                //console.log('this is me');
                //console.log(action.mapped);

                return {

                    selectedStation: state.selectedStation, stations: action.requests, mappedStations: action.mapped, isLoading:false
                };
            };
        default:
            return state;
    }
};


