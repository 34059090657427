import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { Station } from './Station';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserLoginState {
    showAboutModal: boolean,
    softwareVersionData: any,
    bugFixes: [],
    addedFeatures:[]
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface HideShowAboutModal { type: 'HIDE_SHOW_ABOUT_MODAL' }
export interface ReceiveSoftwareVersion { type: 'RECEIVE_SOFTWARE_VERSION', softwareVer: any,bugFixes:any,addedFeatures:any }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = HideShowAboutModal | ReceiveSoftwareVersion;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    HideShowAboutModal: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        fetch(`api/Login/GetSoftwareVersion`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(response => response.json() as Promise<any>)
            .then(data => {
                if (data.id > 0) {
                    let bugFixes = data.releaseNotes && data.releaseNotes.filter((x: any) => x.releaseNoteTypeId == 1);
                    let addedFeatures = data.releaseNotes && data.releaseNotes.filter((x: any) => x.releaseNoteTypeId == 2);
                    dispatch({
                        type: 'RECEIVE_SOFTWARE_VERSION', softwareVer: data, bugFixes, addedFeatures
                    });
                }
                dispatch({ type: 'HIDE_SHOW_ABOUT_MODAL' });
            })
    }

}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<UserLoginState> = (state: UserLoginState | undefined, incomingAction: Action): UserLoginState => {
    if (state === undefined) {
        return {
            showAboutModal: false,
            softwareVersionData: {},
            bugFixes: [],
            addedFeatures:[]
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'HIDE_SHOW_ABOUT_MODAL':
            return {
                ...state,
                showAboutModal: !state.showAboutModal
            };
        case 'RECEIVE_SOFTWARE_VERSION':
            return {
                ...state,
                softwareVersionData: action.softwareVer,
                bugFixes: action.bugFixes,
                addedFeatures: action.addedFeatures
            };
        default:
            return state;
    }
};
