import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import axios from 'axios';

type gridColumns = {
    headerName: string;
    field: string;
    minWidth: number;
    width: number;
    cellRenderer: any;
    cellClass: string;
    unSortIcon: boolean;
    suppressSorting: boolean;
    suppressFilter: boolean;
    checkboxSelection: boolean;
    headerCheckboxSelection: boolean;
}
var gridColumnHeaders: gridColumns[] = [
    {
        headerName: "Report Name", field: "reportName", minWidth: 170, width: 270, cellClass: 'gridText', unSortIcon: true, suppressSorting: true, suppressFilter: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: function (params: any) {
            return `<span style="margin-left: 15px;">${params.data.reportName}</span>`
        },

    },
    {
        headerName: "Shift", field: "shiftName", minWidth: 80, width: 140, cellClass: 'gridText', unSortIcon: true, suppressSorting: true,
        suppressFilter: false,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        cellRenderer: ""
    }
];

export interface SelectedReportsDateRange {
    id: number;
    shiftId: number;
    ShiftName: string;
    ReportName: string;
    ReportDate: Date;
    StationId: number;
    docURL: string;
}

export interface DeferralReportState {
    fleetList: DropDownTypes[];
    shiftList: DropDownTypes[];
    isLoading: boolean;
    startDate: any;
    endDate: any;
    fullDate: [];
    selectedDateRangeReports: SelectedReportsDateRange[];
    fleetId: any;
    shiftId: any;
    selectedReportIds: number[];
    selectedDocs: any[];
    stations: any[];
    selectedStationId: number;
    columnDefs: gridColumns[];
    isShowInvalidDateModel: boolean;
    isShowInvalidDateRangeModel: boolean;
}
export interface DropDownTypes {
    id: number,
    requestText: string
}
export interface ReceiveReportsAction {
    type: 'RECEIVE_REPORTS';
    stations: any,
    selectedStationId: number,
    selectedDateRangeReports: any,
    fleetList: DropDownTypes[],
    shiftList: DropDownTypes[],
    selectedDoc: any[]
}
export interface SetFilterAction { type: 'SET_FILTER'; fullDate: [], startDate: Date, endDate: Date, shiftId: any, fleetId: any, selectedReportIds: number[], selectedDoc: any[] }
export interface UpdateReportIdsFilterAction {
    type: 'UPDATE_REPORT_IDS_FILTER', reportIds: number[], docURLs: any[]
}
export interface ClearReportIdsFilterAction {
    type: 'CLEAR_REPORT_IDS_FILTER', reportIds: number[], docURLs: any[]
}
export interface UpdateLoader {
    type: 'UPDATE_LOADER', isLoading: boolean
}
export interface SetSelectedReportIds {
    type: 'UPDATE_REPORT_IDS', reportIds: number[], reportUrls: any[]
}
export interface UpdateInvalidDateSeletedAction {
    type: 'UPDATE_INVALID_DATE_SELECTED',
    isShow: boolean;
}
export interface UpdateInvalidDateRangeSeletedAction {
    type: 'UPDATE_INVALID_DATE_RANGE_SELECTED',
    isShow: boolean;
}


export type KnownAction = SetFilterAction | ReceiveReportsAction | UpdateReportIdsFilterAction | ClearReportIdsFilterAction | UpdateLoader | SetSelectedReportIds | UpdateInvalidDateSeletedAction | UpdateInvalidDateRangeSeletedAction ;
export const actionCreators = {
    fetchReports: (fullDate: [], startDate: any, endDate: any, shiftId: any, fleet: any, selectedReportIds: number[]
        , selectedDocs: any[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        let stations: any = [];
        if (appState && appState.deferralReport) {
            stations = appState.deferralReport.stations;
        }
        let stationId = 0;
        if (sessionStorage.getItem('selectedStation')) {
            stationId = JSON.parse(String(sessionStorage.getItem('selectedStation'))).value;
            }           
            fetch(`api/reportheads/FetchReports?startDate=${startDate}&endDate=${endDate}&stationId=${stationId}&shiftId=${shiftId}&fleetId=${fleet}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response => response.json() as Promise<any>)
            .then(data => {
                dispatch({
                    type: 'RECEIVE_REPORTS',
                    fleetList: data.fleetList,
                    selectedDateRangeReports: data.selectedDateRangeReports,
                    shiftList: data.shiftList,
                    selectedDoc: selectedDocs == undefined ? [] : selectedDocs,
                    stations: stations && stations.length > 0 ? stations : data.stations,
                    selectedStationId: stationId
                });
            });
        dispatch({ type: 'UPDATE_LOADER', isLoading: true });
    },

    SetFilter: (fullDate: [], startDate: any, endDate: any, shiftId: any, fleet: any, selectedReportIds: number[]
        , selectedDocs: any[]
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        //const token = sessionStorage.getItem('access_token_augur');
        //const appState = getState();
        //let stations: any = [];
        //if (appState && appState.deferralReport) {
        //    stations = appState.deferralReport.stations;
        //}
        //let stationId = 0;
        //if (sessionStorage.getItem('selectedStation')) {
        //    stationId = JSON.parse(String(sessionStorage.getItem('selectedStation'))).value;
        //}

        //fetch(`api/reportheads/FetchReports?startDate=${startDate}&endDate=${endDate}
        //                        &stationId=${stationId}&shiftId=${shiftId}&fleetId=${fleet}`
        //    , {
        //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        //    }).then(response => response.json() as Promise<any>)
        //    .then(data => {
        //        dispatch({
        //            type: 'RECEIVE_REPORTS',
        //            fleetList: data.fleetList,
        //            selectedDateRangeReports: data.selectedDateRangeReports,
        //            shiftList: data.shiftList,
        //            selectedReportIds: selectedReportIds == undefined ? [] : selectedReportIds,
        //            selectedDoc: selectedDocs == undefined ? [] : selectedDocs,
        //            stations: stations && stations.length > 0 ? stations : data.stations,
        //            selectedStationId: stationId
        //        });
        //    });
        dispatch({ type: 'SET_FILTER', fullDate: fullDate, startDate: startDate, endDate: endDate, fleetId: fleet, shiftId: shiftId, selectedReportIds: selectedReportIds, selectedDoc: selectedDocs });
        },

    SetSelectedReportIds: (reportIds: number[], reportUrls: any[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_REPORT_IDS', reportIds, reportUrls });
    },
    UpdateReportIdsFilter: (reportId: number, docURL: string, isSelected: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();        
        if (appState && appState.deferralReport) {
            if (isSelected == true) {
                appState.deferralReport.selectedReportIds.push(reportId);
                appState.deferralReport.selectedDocs.push(docURL);
            }
            else {
                var reportIdIndex = appState.deferralReport.selectedReportIds.indexOf(reportId);
                appState.deferralReport.selectedReportIds.splice(reportIdIndex,1);
                var reportDocIndex = appState.deferralReport.selectedDocs.indexOf(docURL);
                appState.deferralReport.selectedDocs.splice(reportDocIndex,1);
            }
            dispatch({ type: 'UPDATE_REPORT_IDS_FILTER', reportIds: appState.deferralReport.selectedReportIds, docURLs: appState.deferralReport.selectedDocs })

        }

    },
    ClearReportIdsFilter: (): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        dispatch({ type: 'CLEAR_REPORT_IDS_FILTER', reportIds: [], docURLs: [] })


    },
    ExportReports: (reportIds: number[], selectedDocs: any[], downloadFile: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        for (let i = 0; i < selectedDocs.length; i++) {
            downloadFile(selectedDocs[i].toString())
        }
    },

    UpdateInvalidDateSeleted: (isShowflag: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_INVALID_DATE_SELECTED', isShow: isShowflag })
    },
    UpdateInvalidDateRangeSeleted: (isShowflag: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_INVALID_DATE_RANGE_SELECTED', isShow: isShowflag })
    },

}

export const reducer: Reducer<DeferralReportState> = (state: DeferralReportState | undefined, incomingAction: Action): DeferralReportState => {
    if (state === undefined) {
        return {
            endDate: new Date(),
            fleetList: [],
            fullDate: [],
            isLoading: false,
            shiftList: [],
            startDate: new Date(),
            selectedDateRangeReports: [],
            fleetId: "",
            shiftId: 0,
            selectedReportIds: [],
            selectedDocs: [],
            stations: [],
            selectedStationId: 0,
            columnDefs: gridColumnHeaders,
            isShowInvalidDateModel: false,
            isShowInvalidDateRangeModel: false
        };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RECEIVE_REPORTS':
            return {
                ...state,
                startDate: state.startDate,
                endDate: state.endDate,
                fleetList: action.fleetList,
                fullDate: state.fullDate,
                isLoading: false,
                shiftList: action.shiftList,
                selectedDateRangeReports: action.selectedDateRangeReports,
                shiftId: state.shiftId,
                fleetId: state.fleetId,
                selectedReportIds: [],
                selectedDocs: action.selectedDoc,
                stations: action.stations,
                selectedStationId: action.selectedStationId
            };
        case 'SET_FILTER':
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate,
                //fleetList: [],
                fullDate: action.fullDate,
                //isLoading: true,
                //shiftList: [],
                //selectedDateRangeReports: [],
                shiftId: action.shiftId,
                fleetId: action.fleetId,
                //selectedReportIds: action.selectedReportIds,
                //selectedDocs: action.selectedDoc
            };
        case 'CLEAR_REPORT_IDS_FILTER':
            return {
                ...state,
                startDate: state.startDate,
                endDate: state.endDate,
                fleetList: state.fleetList,
                fullDate: state.fullDate,
                isLoading: false,
                shiftList: state.shiftList,
                selectedDateRangeReports: state.selectedDateRangeReports,
                shiftId: state.shiftId,
                fleetId: state.fleetId,
                selectedReportIds: action.reportIds,
                selectedDocs: action.docURLs
            };
        case 'UPDATE_REPORT_IDS_FILTER':            
            return {
                ...state,
                startDate: state.startDate,
                endDate: state.endDate,
                fleetList: state.fleetList,
                fullDate: state.fullDate,
                isLoading: false,
                shiftList: state.shiftList,
                selectedDateRangeReports: state.selectedDateRangeReports,
                shiftId: state.shiftId,
                fleetId: state.fleetId,
                selectedReportIds: action.reportIds,
                selectedDocs: action.docURLs
            };
        case 'UPDATE_LOADER':
            return {
                ...state,
                isLoading: action.isLoading
            };
        case 'UPDATE_REPORT_IDS':
            return {
                ...state,
                selectedReportIds: action.reportIds,
                selectedDocs: action.reportUrls
            };
        case 'UPDATE_INVALID_DATE_SELECTED':
            return {
                ...state,
                isShowInvalidDateModel: action.isShow
            };
        case 'UPDATE_INVALID_DATE_RANGE_SELECTED':
            return {
                ...state,
                isShowInvalidDateRangeModel: action.isShow
            };
        default:
            return state;
    }
}