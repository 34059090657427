import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as TraxFile from './TraxFile';
import * as Station from './Station';
import * as DashBoard from './DashBoard';
import * as Planning from './Planning';
import * as UserAccountDetails from './UserAccountDetails';
import * as Users from './Users';
import * as Home from './Home';
import * as FlightBoard from './FlightBoard';
import * as Workload from './Workload';
import * as WorkSheet from './WorkSheet';
import * as CrewDebrief from './CrewDebrief';
import * as WorksheetWIP from './WorksheetWIP';
import * as Reporting from './Reporting';
import * as DeferralReport from './DeferralReport';
import * as TimeAndLabor from './TimeAndLabor'
import * as Approval from './Approval';
import * as ManpowerReport from './ManpowerReport';
import * as UserLogin from './UserLogin';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    traxFile: TraxFile.TraxFileState | undefined;
    station: Station.StationState | undefined;
    dashBoard: DashBoard.DashBoardState | undefined;
    planning: Planning.PlanningState | undefined;
    userAccountDetails: UserAccountDetails.UserAccountDetailsState | undefined;
    users: Users.UserState | undefined;
    home: Home.HomeState | undefined;
    flightBoards: FlightBoard.FlightDetailsState | undefined;
    workload: Workload.WorkloadDetailsState | undefined;
    worksheet: WorkSheet.WorksheetState | undefined;
    crewdebrief: CrewDebrief.CrewDebriefState | undefined;
    worksheetWIP: WorksheetWIP.WorksheetWIPState | undefined;
    reporting: Reporting.ReportingState | undefined;
    deferralReport: DeferralReport.DeferralReportState | undefined;
    timeAndLabor: TimeAndLabor.TimeAndLaborState | undefined;
    approval: Approval.TimesheetApprovalState | undefined;
    manpowerReport: ManpowerReport.ManpowerReportState | undefined;
    userLogin: UserLogin.UserLoginState | undefined;

}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    traxFile: TraxFile.reducer,
    station: Station.reducer,
    dashBoard: DashBoard.reducer,
    planning: Planning.reducer,
    userAccountDetails: UserAccountDetails.reducer,
    users: Users.reducer,
    home: Home.reducer,
    flightBoards: FlightBoard.reducer,
    workload: Workload.reducer,
    worksheet: WorkSheet.reducer,
    crewdebrief: CrewDebrief.reducer,

    worksheetWIP: WorksheetWIP.reducer,
    reporting: Reporting.reducer,
    deferralReport: DeferralReport.reducer,
    timeAndLabor: TimeAndLabor.reducer,
    approval: Approval.reducer,
    manpowerReport: ManpowerReport.reducer,
    userLogin: UserLogin.reducer
};


// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
