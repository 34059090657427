import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as ReportingStore from '../store/Reporting';
import {IoChevronForwardOutline,IoTimeOutline, IoPersonOutline, IoSettingsOutline,
    IoAirplaneOutline, IoDocumentOutline,IoDocumentTextOutline } from 'react-icons/io5';

type ReportingProps =
    ReportingStore.ReportingState &
    typeof ReportingStore.actionCreators &
    RouteComponentProps<{}>;

class Reporting extends React.PureComponent<ReportingProps> {

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.FetchHeads();

    }
    public NavigateToReport(url: any) {
        if (url == "undefined" || url == null) {
            return;
        }
        else
           this.props.history.push("/" + url);
    }
    public render() {
        return (
            <React.Fragment>
                {this.props.reportHeads && this.props.reportHeads.length > 0 && this.renderReportHeadsTable()
                   }
              
            </React.Fragment>
        );
    }

    private renderReportHeadsTable() {
        return (
            <React.Fragment>
                    <div className="reportingFlex report-Container"

                >
                        {this.props.reportHeads.map((request) =>
                           
                            <div key={request.id} className="report-outer cursor-pointer" onClick={() => {
                                    this.NavigateToReport(request.uri
                                    );
                            }} >
                                {request.id == 1 && <IoSettingsOutline className="reportIconStyle" />}
                                {request.id == 2 && <IoAirplaneOutline className="reportIconStyle" />}
                                {request.id == 3 && <IoPersonOutline className="reportIconStyle"/>}
                                {request.id == 4 && <IoDocumentOutline className="reportIconStyle"/>}
                                {request.id == 5 && <IoDocumentTextOutline className="reportIconStyle"  />}
                                {request.id == 6 && <IoTimeOutline className="reportIconStyle" />}
                                    <div className="report-title">{request.title}</div>
                                    <div className="report-subtitle">{request.subTitle}</div>
                                </div>
                               
                    )}
                    </div>
            </React.Fragment>
        );
    }
};


export default connect(
    (state: ApplicationState) => state.reporting,
    ReportingStore.actionCreators
)(Reporting as any);
