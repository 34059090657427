import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IoCloseOutline } from 'react-icons/io5'

// @ts-ignore
export const ConfirmationModal = ({
    title,
    message,
    confirmButtonText,
    isOpen,
    onClose,
    onConfirm
}: {
    title: string,
    message: string;
    confirmButtonText: string;
    isOpen: boolean;
        onClose: () => void;
        onConfirm: () => void;
}) => {
    return (
        <Modal className="smallModal" centered={true} isOpen={isOpen}>
            <ModalHeader className="modalHeader w-100">
                <div className="flex flex-end w-100">
                    <div className="">
                        <div className="crossButton"
                            onClick={() => { onClose() }}><IoCloseOutline /></div>
                    </div>
                </div>
                <div className="flex w-100 centerContent">
                    {title}
                 </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex w-100 centerContent">
                    <div>
                        {message}
                     </div>
                </div>
            </ModalBody>
            <ModalFooter >                 
                <div className="round-empty-button-popup workload-round-empty-button-popup" onClick={() => { onClose()}} >
                    NO, CANCEL
                </div>
                <div className="round-fill-button-popup-large" onClick={() => { onConfirm() }}>
                    {confirmButtonText ? confirmButtonText : 'YES' }
                </div>               
            </ModalFooter>             
        </Modal >
    );
};

export default ConfirmationModal;