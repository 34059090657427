import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { FaSatellite } from 'react-icons/fa';
import axios from 'axios';

export interface WorkloadDetailsRequest {
    companyDetails: string;
    tailNumber: string;
    sta: string;
    staDetails: string;
    std: string;
    stdDetails: string;
    ataDetails: string;
    remainingGroundTime: string;
    flightStatusDescription: string;
    maintenanceStatus: string;
    status: string;
    tailRecordId: number;
    remainingGroundTimeForTimer: number;
    isArchived: boolean;
    arrivingActualTimeOut: string;
    departingStationName: string;
}

export interface WorkloadDetailsState {
    workloadDetails: WorkloadDetailsRequest[];
    staDate: Date;
    stdDate: Date;
    isLoading: boolean;
    isBlockedOutFlights: boolean;
    isFlightsWithoutSTD: boolean;
    customerDetails: any[];
    customerIds: number[];
    searchFilter: string;
    isPopoverOpen: boolean;
    pageSize: number;
    pageNumber: number;
    totalRecords: number;
    confirmBlockOutPopup: boolean;
    isArchivedFlights: boolean;
    confirmArchivedFlightPopup: boolean;
}

export interface UpdateSearchFilterAction {
    type: 'UPDATE_SEARCH_FILTER'; searchTerm: string;
}

export interface UpdateSTAFilterAction {
    type: 'UPDATE_STA_DATE_FILTER'; staDate: Date;
}

export interface UpdateSTDFilterAction {
    type: 'UPDATE_STD_DATE_FILTER'; stdDate: Date;
}

export interface UpdateBlockedOutFilterAction {
    type: 'UPDATE_BLOCKED_OUT_FILTER'; isBlockedOut: boolean;
}

export interface UpdateWithoutSTDFilterAction {
    type: 'UPDATE_WITHOUT_STD_FILTER'; isWithoutStd: boolean;
}
export interface UpdateCustomerIdsFilterAction {
    type: 'UPDATE_CUSTOMER_IDS_FILTER', customerIds: number[]
}

export interface CloseFilterAction {
    type: 'CLOSE_FILTER';
}
export interface ReceiveWorkloadDetailsAction {
    type: 'RECEIVE_WORKLOAD_DETAILS'; workloadDetails: WorkloadDetailsRequest[], totalRecords: number, customerDetails: any[], customerIds: number[];
}
export interface RequestWorkloadDetailsAction {
    type: 'REQUEST_WORKLOAD_DETAILS'; staDate: Date, stdDate: Date,
    isBlockedOutFlights: boolean, isFlightsWithoutSTD: boolean, searchFilter: string, isPopOverOpen: boolean, pageSize: number, pageNumber: number
    , customerIds: number[], isArchivedFlights: boolean
}
export interface ToggleFilterAction {
    type: 'TOGGLE_FILTER'
}
export interface CloseBlockOutPopup {
    type: 'CLOSE_BLOCKOUT_POPUP'; isLoading: boolean;
}
export interface ShowBlockOutPopup {
    type: 'SHOW_BLOCKOUT_POPUP'
}
export interface SaveBlockOutFlightStatus {
    type: 'SAVE_BLOCKOUT_FLIGHT'
}
export interface EnableLoading {
    type: 'ENABLE_LOADING'
}
export interface UpdateArchivedFilterAction {
    type: 'UPDATE_ARCHIVED_FILTER'; isArchived: boolean;
}
export interface OpenCloseArchivedFlightPopup {
    type: 'OPEN_CLOSE_ARCHIVED_FLIGHT_POPUP'; isLoading: boolean; confirmArchivedFlightPopup: boolean;
}

export interface UpdatePreviousFiltersValues {
    type: 'UPDATE_PREVIOUS_FILTERS_VALUES'; filterValues: any;
}

export type KnownAction = UpdateCustomerIdsFilterAction | UpdateSTAFilterAction | UpdateSTDFilterAction |
    UpdateBlockedOutFilterAction | UpdateWithoutSTDFilterAction |
    UpdateSearchFilterAction | CloseFilterAction | ReceiveWorkloadDetailsAction |
    RequestWorkloadDetailsAction | ToggleFilterAction | CloseBlockOutPopup
    | ShowBlockOutPopup | SaveBlockOutFlightStatus | EnableLoading | UpdateArchivedFilterAction | OpenCloseArchivedFlightPopup | UpdatePreviousFiltersValues;

export const actionCreators = {
    getWorkloadDetails: (isBlockedOutFlights: boolean, isFlightsWithoutSTD: boolean, searchFilter: string,
        staDate: Date, stdDate: Date, isPopOverOpen: boolean,
        pageSize: number, pageNumber: number, customerIds: number[], customers: string, isArchivedFlights: boolean
    ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        let station = "CVG";
        if (appState.station && appState.station.selectedStation.stationName) {
            station = appState.station.selectedStation.stationName;
        }
        fetch(`api/workload?searchFilter=${searchFilter}&staDate=${staDate != null ? staDate.toDateString() : ""}&stdDate=${stdDate != null ? stdDate.toDateString() : ""}
                            &isBlockedOutFlights=${isBlockedOutFlights}&isFlightsWithoutSTD=${isFlightsWithoutSTD}
                            &pageSize=${pageSize}&pageNumber=${pageNumber}&customers=${customers}&isArchivedFlights=${isArchivedFlights}&station=${station}`
            //
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response =>
                response.json() as Promise<WorkloadDetailsState>
            )
            .then(data => {
                console.log(data)
                dispatch({
                    type: 'RECEIVE_WORKLOAD_DETAILS',
                    workloadDetails: data.workloadDetails,
                    totalRecords: data.totalRecords,
                    customerDetails: data.customerDetails,
                    customerIds: customerIds
                });
            });

        dispatch({
            type: 'REQUEST_WORKLOAD_DETAILS',
            isBlockedOutFlights: isBlockedOutFlights, stdDate: stdDate, isFlightsWithoutSTD: isFlightsWithoutSTD, searchFilter: searchFilter, staDate: staDate, isPopOverOpen: isPopOverOpen,
            pageSize: pageSize, pageNumber: pageNumber, customerIds: customerIds, isArchivedFlights
        });
    },
    setFilterValue: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log('hitting');
        dispatch({ type: 'UPDATE_SEARCH_FILTER', searchTerm: event })
    },
    ToggleFilter: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_FILTER' })
    },
    CloseFilter: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_FILTER' })
    },
    setSTDFilterValue: (stdDate: Date): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_STD_DATE_FILTER', stdDate })
    },
    setSTAFilterValue: (staDate: Date): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_STA_DATE_FILTER', staDate })
    },
    setWithoutSTDFilterValue: (isWithoutStd: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_WITHOUT_STD_FILTER', isWithoutStd })
    },
    setBlockedOutFilterValue: (isBlockedOut: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_BLOCKED_OUT_FILTER', isBlockedOut })
    },
    setArchivedFilterValue: (isArchived: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_ARCHIVED_FILTER', isArchived })
    },
    UpdateCustomerIdsFilter: (customerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if (appState && appState.workload) {
            if (appState.workload.customerIds.some(n => n === customerId)) {
                appState.workload.customerIds.splice(appState.workload.customerIds.indexOf(customerId), 1);
                console.log(appState.workload.customerIds);
            }
            else {
                appState.workload.customerIds.push(customerId);
            }
            dispatch({ type: 'UPDATE_CUSTOMER_IDS_FILTER', customerIds: appState.workload.customerIds })

        }
    },

    ShowBlockOutPopup: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_BLOCKOUT_POPUP' })
    },
    CloseBlockOutPopup: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_BLOCKOUT_POPUP', isLoading: false })
    },
    SaveBlockOutStatus: (tailRecordId: number, blockOutStatus: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const data = new FormData()
        const appState = getState();
        data.append('TailRecordId', tailRecordId.toString());
        data.append('BlockOutStatus', blockOutStatus.toString());

        axios.post(`api/workload/SaveBlockOutStatus`, data, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.data as Promise<any>)
            .then(res => { // then print response status                   
                
                if (appState && appState.workload && appState.workload.workloadDetails) {
                    let workloadDetails = appState.workload && appState.workload.workloadDetails;
                    if (!blockOutStatus) {
                        const removeIndex = workloadDetails.indexOf(workloadDetails.find((n: any) => n.tailRecordId === tailRecordId)!);
                        console.log('index')
                        console.log(removeIndex);
                        ~removeIndex && workloadDetails.splice(removeIndex, 1);
                        appState.workload.totalRecords = appState.workload.totalRecords - 1;
                        dispatch({ type: 'CLOSE_BLOCKOUT_POPUP', isLoading: true });
                    }
                   
                }
            })

        dispatch({ type: 'CLOSE_BLOCKOUT_POPUP', isLoading: true });

    },

    setLoader: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ENABLE_LOADING' })
    },
    OpenCloseArchivedFlightPopup: (confirmArchivedFlightPopup: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'OPEN_CLOSE_ARCHIVED_FLIGHT_POPUP', isLoading: false, confirmArchivedFlightPopup })
    },
    SaveUnArchivedFlightStatus: (tailRecordId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const data = new FormData()
        const appState = getState();
        data.append('TailRecordId', tailRecordId.toString());
        axios.post(`api/tails/UnArchivedFlight`, data, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.data as Promise<any>)
        dispatch({ type: 'OPEN_CLOSE_ARCHIVED_FLIGHT_POPUP', isLoading: true, confirmArchivedFlightPopup:false });

    },
    UpdatePreviousFiltersValues: (filterValues: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_PREVIOUS_FILTERS_VALUES', filterValues })
    },
}

export const reducer: Reducer<WorkloadDetailsState> = (state: WorkloadDetailsState | undefined, incomingAction: Action): WorkloadDetailsState => {
    if (state === undefined) {
        return {
            workloadDetails: [],
            staDate: null as any,
            stdDate: null as any,
            isLoading: true,
            isBlockedOutFlights: false,
            isFlightsWithoutSTD: false,
            searchFilter: "",
            isPopoverOpen: false,
            pageSize: 12,
            pageNumber: 1,
            totalRecords: 0,
            customerDetails: [],
            customerIds: [],
            confirmBlockOutPopup: false,
            isArchivedFlights: false,
            confirmArchivedFlightPopup:false
        };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_WORKLOAD_DETAILS':
            return {
                workloadDetails: [],
                staDate: action.staDate,
                stdDate: action.stdDate,
                isLoading: true,
                isBlockedOutFlights: action.isBlockedOutFlights,
                isFlightsWithoutSTD: action.isFlightsWithoutSTD,
                searchFilter: action.searchFilter,
                isPopoverOpen: action.isPopOverOpen,
                pageNumber: action.pageNumber,
                pageSize: action.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: [],
                customerIds: action.customerIds,
                confirmBlockOutPopup: false,
                isArchivedFlights: action.isArchivedFlights,
                confirmArchivedFlightPopup:false
            }
        case 'RECEIVE_WORKLOAD_DETAILS':
            return {
                workloadDetails: action.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: false,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: false,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: action.totalRecords,
                customerDetails: action.customerDetails,
                customerIds: action.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'UPDATE_SEARCH_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: state.isLoading,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: action.searchTerm,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'TOGGLE_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: false,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: !state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'CLOSE_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: false,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: false,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'UPDATE_STD_DATE_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: action.stdDate,
                isLoading: state.isLoading,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'UPDATE_STA_DATE_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: action.staDate,
                stdDate: state.stdDate,
                isLoading: state.isLoading,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'UPDATE_WITHOUT_STD_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: state.isLoading,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: action.isWithoutStd,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'UPDATE_BLOCKED_OUT_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: state.isLoading,
                isBlockedOutFlights: action.isBlockedOut,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'UPDATE_CUSTOMER_IDS_FILTER':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: state.isLoading,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: action.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'CLOSE_BLOCKOUT_POPUP':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: action.isLoading,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: false,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'SHOW_BLOCKOUT_POPUP':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: state.isLoading,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: true,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'ENABLE_LOADING':
            return {
                workloadDetails: state.workloadDetails,
                staDate: state.staDate,
                stdDate: state.stdDate,
                isLoading: true,
                isBlockedOutFlights: state.isBlockedOutFlights,
                isFlightsWithoutSTD: state.isFlightsWithoutSTD,
                searchFilter: state.searchFilter,
                isPopoverOpen: state.isPopoverOpen,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize,
                totalRecords: state.totalRecords,
                customerDetails: state.customerDetails,
                customerIds: state.customerIds,
                confirmBlockOutPopup: state.confirmBlockOutPopup,
                isArchivedFlights: state.isArchivedFlights,
                confirmArchivedFlightPopup: state.confirmArchivedFlightPopup
            }
        case 'UPDATE_ARCHIVED_FILTER':
            return {
                ...state,
                isArchivedFlights: action.isArchived,
            }
        case 'OPEN_CLOSE_ARCHIVED_FLIGHT_POPUP':
            return {
                ...state,
                confirmArchivedFlightPopup: action.confirmArchivedFlightPopup
            };
        case 'UPDATE_PREVIOUS_FILTERS_VALUES':
            return {
                ...state,
                isArchivedFlights: action.filterValues.isArchivedFlights,
                isBlockedOutFlights: action.filterValues.isBlockedOutFlights,
                isFlightsWithoutSTD: action.filterValues.isFlightsWithoutSTD,
                staDate: action.filterValues.staDate ? action.filterValues.staDate:null,
                stdDate: action.filterValues.stdDate ? action.filterValues.staDate:null,
                customerIds: action.filterValues.customerIds ? action.filterValues.customerIds.split(',').map((i:string) => parseInt(i)):[]
            };
        default:
            return state
    }
}