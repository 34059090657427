import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import moment from 'moment';
import axios from 'axios';

type gridColumns = {
    headerName: string;
    field: string;
    minWidth: number;
    width: number;
    cellRenderer: any;
    cellClass: string;
    unSortIcon: boolean;
    suppressSorting: boolean;
    suppressFilter: boolean;
    checkboxSelection: boolean;
    headerCheckboxSelection: boolean;
}
 
var gridColumnHeaders: gridColumns[] = [

    {
        headerName: "Employee", field: "userDetail.userName", minWidth: 170, width: 270, cellClass: 'gridText', unSortIcon: true, suppressSorting: false, suppressFilter: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: function (params: any) {           
            return `<Img src=${params.data.userDetail.imageDisplayURI} style="border-radius:50%; width:25px;margin-left: 10px;" /> &nbsp; ${params.data.userDetail.userName}`
        },
        
    },
    {
        headerName: "Clock In", field: "clockInDate", minWidth: 80, width: 140, cellClass: 'gridText', unSortIcon: true, suppressSorting: true,
        suppressFilter: false,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        cellRenderer: function (params: any) {            
            let checkInDate = moment(new Date(params.data.clockInDate).toDateString());            
            let checkInDateTimeDisplay = checkInDate.month()+1 + "/" + checkInDate.date() + "/" + checkInDate.year() + ' ' + params.data.clockInTime
            return checkInDateTimeDisplay;
        },
      
        
    },
    {
        headerName: "Clock Out", field: "clockOutDate", minWidth: 80, width: 140, cellClass: 'gridText', unSortIcon: true, suppressSorting: true,
        suppressFilter: false,
        checkboxSelection: false, headerCheckboxSelection: false,
        cellRenderer: function (params: any) {
            let checkOutDate = moment(new Date(params.data.clockOutDate).toDateString());
            let checkOutDateTimeDisplay = checkOutDate.month()+1 + "/" + checkOutDate.date() + "/" + checkOutDate.year() + ' ' + params.data.clockOutTime
            return checkOutDateTimeDisplay;
        },
        
    },
    {
        headerName: "Total", field: "totalShiftHours", minWidth: 90, width: 90, cellClass: 'gridText', unSortIcon: true, suppressSorting: false,
        suppressFilter: false,
        checkboxSelection: false, headerCheckboxSelection: false,
        //cellRenderer: "",
        cellRenderer: function (params: any) {
            let totalShiftHours = "";          
            if (params.data.totalShiftHours.toFixed(2).toString().length==4)
                totalShiftHours= "0" + params.data.totalShiftHours.toFixed(2);
            else
                totalShiftHours = params.data.totalShiftHours.toFixed(2);
            return totalShiftHours;
        },
        
       
    },
    {
        headerName: "Status", field: "statusId", minWidth: 100, width: 120, cellClass: 'gridText', unSortIcon: true, suppressSorting: false,
        suppressFilter: false,
        checkboxSelection: false, headerCheckboxSelection: false,
        cellRenderer: function (params: any) {
            //console.log("rendering grid :", params);
            return params.data.statusId === 3 ?
                `<div class="approval-grid-status"><span class="approval-grid-status-text">Approved</span></div>`                
                :
                `<div class="approval-grid-status-pending"><span class="approval-grid-status-pending-text">Pending</span></div>`
        }
    },
    {
        headerName: "View Timesheet", field: "TimeSheetID", minWidth: 50, width: 140,
        cellClass: 'gridText',
        unSortIcon: false,
        suppressSorting: true,
        suppressFilter: true,
        checkboxSelection: false, headerCheckboxSelection: false,
        cellRenderer: function (params: any) {
            return '<span class="cursor-pointer" style="margin-left: 50px;"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg></span>'
        },

    }
];

var gridColumnHeadersTablet: gridColumns[] = [

    {
        headerName: "Employee", field: "userDetail.userName", minWidth: 170, width: 220, cellClass: 'gridText', unSortIcon: true, suppressSorting: false, suppressFilter: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellRenderer: function (params: any) {
            return `<Img src=${params.data.userDetail.imageDisplayURI} style="border-radius:50%; width:25px;margin-left: 10px;" /> &nbsp; ${params.data.userDetail.userName}`
        },

    },
    {
        headerName: "Total", field: "totalShiftHours", minWidth: 90, width: 90, cellClass: 'gridText', unSortIcon: true, suppressSorting: false,
        suppressFilter: false,
        checkboxSelection: false, headerCheckboxSelection: false,
        //cellRenderer: "",
        cellRenderer: function (params: any) {
            let totalShiftHours = "";
            if (params.data.totalShiftHours.toFixed(2).toString().length == 4)
                totalShiftHours = "0" + params.data.totalShiftHours.toFixed(2);
            else
                totalShiftHours = params.data.totalShiftHours.toFixed(2);
            return totalShiftHours;
        },


    },
    {
        headerName: "Status", field: "statusId", minWidth: 100, width: 120, cellClass: 'gridText', unSortIcon: true, suppressSorting: false,
        suppressFilter: false,
        checkboxSelection: false, headerCheckboxSelection: false,
        cellRenderer: function (params: any) {
            //console.log("rendering grid :", params);
            return params.data.statusId === 3 ?
                `<div class="approval-grid-status"><span class="approval-grid-status-text">Approved</span></div>`
                :
                `<div class="approval-grid-status-pending"><span class="approval-grid-status-pending-text">Pending</span></div>`
        }
    },
    {
        headerName: "View", field: "TimeSheetID", minWidth: 50, width: 80,
        cellClass: 'gridText',
        unSortIcon: false,
        suppressSorting: true,
        suppressFilter: true,
        checkboxSelection: false, headerCheckboxSelection: false,
        cellRenderer: function (params: any) {
            return '<span class="cursor-pointer" style="margin-left: 15px;"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg></span>'
        },

    }
];
 
export interface Result {
    timesheetsList: TimesheetApproval[];
}

export interface User{
                id:number,
                firstName: string,
                lastName: string,
                userId: number,
                email: string,
                createdOn: Date,
                modifiedOn: Date,
                roleId: number,                
                imageURI: string,
                imageDisplayURI:string
}

export interface TimesheetApproval {
    userName: string;   
    approvalID: number;
    clockInDate: Date;
    clockOutDate: Date;
    clockInTime: string;
    clockOutTime: string;
    displayClockIn: string;
    displayClockOut: string;
    totalShiftHours: number;
    statusId: number;
    statusText: string;
    UserDetail: User

}

export interface TimesheetApprovalState {
    isLoading: boolean;
    selectedCheckOutDate: Date;
    searchFilter: string;
    timesheetApprovalList: TimesheetApproval[];
    columnDefs: gridColumns[];
    columnDefsTablet: gridColumns[];
    selectedTimeSheetForApproval: [];
    isShowApprovedTimesheetModel: boolean;
    isReloadTimesheet: boolean;
    isShowApproveConfirmationModel: boolean;
    showExportPopup: boolean;
    isAllTimesheetApproved: boolean;
}

export interface SelectCheckOutDateAction {
    type: 'SELECT_CHECKOUT_DATE';
    date: Date
}
export interface ReceiveApprovalTimeSheetsAction {
    type: 'RECEIVE_APPROVAL_LIST';
    timeSheetRecords: TimesheetApproval[];
    
    
}
export interface UpdateLoaderAction {
    type: 'UPDATE_LOADER',
    isLoading: boolean;
}

export interface UpdateSearchFilterAction {
    type: 'UPDATE_SEARCH_FILTER'; searchTerm: string;
}
export interface UpdateSelectedTimeSheetAction {
    type: 'UPDATE_SELECTED_TIMESHEET',
    selectedTimeSheet: []
}
export interface IsApprovedTimesheetSelectedAction {
    type: 'APPROVED_TIMESHEET_MODEL',
    isShow: boolean;
}

export interface IsApproveConfirmationAction {
    type: 'APPROVED_CONFIRMATION_MODEL',
    isShow: boolean;
}

export interface ApproveTimesheetAction {
    type: 'APPROVE_TIMESHEET',
    timesheetApproved: boolean
}
       
export interface DownloadTimesheet {
    type: 'DOWNLOAD_TIMESHEET', isLoading: boolean;
}

export interface ShowExportPopup {
    type: 'SHOW_EXPORT_POPUP', isAllTimesheetApproved: boolean;
}

export interface CloseExportPopup {
    type: 'CLOSE_EXPORT_POPUP'
}


export type KnownAction = SelectCheckOutDateAction | ReceiveApprovalTimeSheetsAction | UpdateLoaderAction | UpdateSearchFilterAction
    | UpdateSelectedTimeSheetAction | IsApprovedTimesheetSelectedAction | ApproveTimesheetAction | IsApproveConfirmationAction | DownloadTimesheet
    | ShowExportPopup | CloseExportPopup;

export const actionCreators = {
    GetApprovalList: (filterDate: Date, searchFilter: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        let stationId = 0;
        if (sessionStorage.getItem('selectedStation')) {
            stationId = JSON.parse(String(sessionStorage.getItem('selectedStation'))).value;
        }
        fetch(`api/Approval?date=${filterDate.toDateString()}&searchFilter=${searchFilter}&stationId=${stationId}`
            , {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            }).then(response =>
                response.json() as Promise<Result>
        ).then(data => {           
            //console.log("response :", data.timesheetsList);   
            let data1 = data.timesheetsList.map(t => {                
                let checkInDate = moment(new Date(t.clockInDate).toDateString() + ' ' + t.clockInTime);
                var checkOutDate = moment(new Date(t.clockOutDate).toDateString() + ' ' + t.clockOutTime);
                var duration = moment.duration(checkOutDate.diff(checkInDate));
                t.totalShiftHours = duration.asHours();                
                return t;
            });
            dispatch({
                   type: 'RECEIVE_APPROVAL_LIST',
                timeSheetRecords: data1
                        });
                })
        dispatch({
            type: 'UPDATE_LOADER',
            isLoading: true
        });
        dispatch({ type: 'SELECT_CHECKOUT_DATE', date: filterDate });
    },
    setFilterValue: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_SEARCH_FILTER', searchTerm: event })
    },
    setSelectedTimeSheet: (event: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'UPDATE_SELECTED_TIMESHEET', selectedTimeSheet: event })
    },
    UpdateApprovedTimesheetSeleted: (isShowflag: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'APPROVED_TIMESHEET_MODEL', isShow: isShowflag })
    },
    UpdateApprovedConfirmation: (isShowflag: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'APPROVED_CONFIRMATION_MODEL', isShow: isShowflag })
    },
    ApproveTimesheet: (timeSheets: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (timeSheets) {
            const token = sessionStorage.getItem('access_token_augur');
            const data = new FormData()
            timeSheets.forEach(function (timeSheet: any, index: any) {
                data.append('timesheetId', timeSheet);
            });

            axios.post(`api/Approval/ApproveTimesheet`, data, {
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`
                }
            }).then(response => response.data as Promise<any>).then(res => { // then print response status  
                console.log("response:", res);
                dispatch({ type: 'APPROVE_TIMESHEET', timesheetApproved: true });

            }).catch(error => {
                console.log("error:", error);
                dispatch({ type: 'UPDATE_LOADER', isLoading: false })
            });
            dispatch({ type: 'UPDATE_LOADER', isLoading: true });
            dispatch({ type: 'APPROVED_CONFIRMATION_MODEL', isShow: false });
        }
    },
    DownloadTimesheet: (timesheetIds: any, downloadTimesheets:any,selectedDate:any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const token = sessionStorage.getItem('access_token_augur');
        const appState = getState();
        dispatch({ type: 'UPDATE_LOADER', isLoading: true });
        dispatch({ type: 'CLOSE_EXPORT_POPUP' });
        const data = new FormData();
        let station = "";
        if (appState && appState.station && appState.station.selectedStation) {
            station = appState.station.selectedStation.stationName;
        }
        data.append('timesheetIds', timesheetIds.toString());
        data.append('selectedDate', selectedDate.toDateString());
        data.append('stationId', station);
        axios.post(`api/TimeAndLabor/DownloadTimesheet`, data, {
            headers: !token ? {} : {
                'Authorization': `Bearer ${token}`
            }
        })
         .then(response => response.data as Promise<any>)
            .then(res => { // then print response status
                downloadTimesheets(res);
             dispatch({ type: 'DOWNLOAD_TIMESHEET', isLoading: false });
            }).catch(error => {
                dispatch({ type: 'UPDATE_LOADER', isLoading: false })
            });
        
    },
    ShowExportPopup: (isAllTimesheetApproved: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SHOW_EXPORT_POPUP', isAllTimesheetApproved });
    },
    CloseExportPopup: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CLOSE_EXPORT_POPUP'});
    },
}

export const reducer: Reducer<TimesheetApprovalState> = (state: TimesheetApprovalState | undefined, incomingAction: Action): TimesheetApprovalState => {
    if (state === undefined) {
        var today = new Date(new Date().setDate(new Date().getDate()));
        return {
            isLoading: true,
            selectedCheckOutDate: today,
            timesheetApprovalList: [],             
            columnDefs: gridColumnHeaders,
            columnDefsTablet: gridColumnHeadersTablet,
            searchFilter: "",
            selectedTimeSheetForApproval: [],
            isShowApprovedTimesheetModel: false,
            isReloadTimesheet: false,
            isShowApproveConfirmationModel: false,
            showExportPopup: false,
            isAllTimesheetApproved:false
        };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'SELECT_CHECKOUT_DATE':
            return {
                ...state,
                selectedCheckOutDate: action.date
            };
        case 'RECEIVE_APPROVAL_LIST':            
            return {
                ...state,                
                timesheetApprovalList: action.timeSheetRecords,
                isReloadTimesheet: false,
                isLoading: false,
                selectedTimeSheetForApproval: [],
            };
        case 'UPDATE_LOADER':
            return {
                ...state,
                isLoading: action.isLoading
            };
        case 'UPDATE_SEARCH_FILTER':
            return {
                ...state,
                searchFilter: action.searchTerm,
            };
        case 'UPDATE_SELECTED_TIMESHEET':
            return {
                ...state,
                selectedTimeSheetForApproval: action.selectedTimeSheet
            }
        case 'APPROVED_TIMESHEET_MODEL':
            return {
                ...state,
                isShowApprovedTimesheetModel: action.isShow
            };
        case 'APPROVED_CONFIRMATION_MODEL':
            return {
                ...state,
                isShowApproveConfirmationModel: action.isShow
            };
        case 'APPROVE_TIMESHEET':
            return {
                ...state,
                isLoading: false,
                isReloadTimesheet: true                
                /* isTimesheetApprovalSuccess: action.timesheetApprovalStatus,*/

            };
        case 'DOWNLOAD_TIMESHEET':
            return {
                ...state,
                isLoading: action.isLoading
            };
        case 'SHOW_EXPORT_POPUP':
            return {
                ...state,
                showExportPopup: true,
                isAllTimesheetApproved: action.isAllTimesheetApproved,
            };
        case 'CLOSE_EXPORT_POPUP':
            return {
                ...state,
                showExportPopup: false,
            }
        default:
            return state;
    }

}