import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { IoCloseOutline } from 'react-icons/io5';

// @ts-ignore
export const ErrorModal = ({
    title,
    message,
    headerStyleClass,
    isOpen,
    onClose
}: {
    title: string,
    message: string;
    headerStyleClass: string;
    isOpen: boolean;
    onClose: () => void;
}) => {
    return (

        <Modal centered={true} className={`smallModal  ${headerStyleClass}`}  isOpen={isOpen}>
            <ModalHeader className="modalHeader w-100">
                <div className="flex flex-end w-100">
                    <div className="">
                        <div className="crossButton"
                            onClick={() => { onClose() }}><IoCloseOutline /></div>

                    </div>
                </div>
                <div className="flex flex-center w-100">
                    <span>{title}</span>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="flex flex-center w-100">
                    <div className="flex flex-center w-100 text-center">  {message}</div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex flex-center w-100">
                    <div className="round-fill-button border-0 diff-hours-ok" onClick={() => { onClose() }}>OK</div>
                </div>

            </ModalFooter>
        </Modal>
    );
};

export default ErrorModal;