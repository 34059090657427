import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as WorkloadStore from '../store/Workload';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { Button, Popover, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { IoFunnelOutline, IoCloseOutline, IoSearchOutline, IoChevronDownSharp} from 'react-icons/io5'


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'rc-pagination';
import "rc-pagination/assets/index.css";
// @ts-ignore
import Timer from 'react-compound-timer'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useEffect } from 'react';
//@ts-ignore
import _, { debounce } from 'lodash';

type workloadProps =
    WorkloadStore.WorkloadDetailsState &
    typeof WorkloadStore.actionCreators &
    RouteComponentProps<{}>;

class Workload extends React.PureComponent<workloadProps>
{
    public customerIds: number[] = [];
    public customers: string = "";
    public tailNumber: string = "";
    public tailRecordId: number = 0;
    public blockoutStatus: boolean = false;
    public isArchivedFlights: boolean = false;
    public hideWorksheetBtn: boolean = false;
    public filterPreviousParams: any = {
        staDate:"",
        stdDate:"",
        blockoutStatus: false,
        isArchivedFlights: false,
        isFlightsWithoutSTD: false,
        customerIds: null as any
    };
    constructor(props: any) {
        super(props);
        this.handlePaging = this.handlePaging.bind(this);
        this.toggle = this.toggle.bind(this);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }
    public componentDidMount() {
        this.props.getWorkloadDetails(false, false, "", null as any,
            null as any, false, this.props.pageSize, 1, [], "",false);
    }
    public componentDidUnMount() {
        this.tailNumber = "";
        this.tailRecordId = 0;
        this.blockoutStatus = false;
    }

    private ensureDataFetched() {
        if ((this.props.searchFilter != undefined && this.props.searchFilter != null &&
            this.props.searchFilter != "" && this.props.searchFilter.toLowerCase() == "blockout") || this.props.isBlockedOutFlights) {
            this.blockoutStatus = true;
            this.isArchivedFlights = false;
            this.hideWorksheetBtn = true;
        }
        else if (this.props.isArchivedFlights) {
            this.isArchivedFlights = true;
            this.hideWorksheetBtn = true;
        }
        else {
            this.blockoutStatus = false;
            this.isArchivedFlights = false;
            this.hideWorksheetBtn = false;
        }
        this.props.getWorkloadDetails(this.props.isBlockedOutFlights, this.props.isFlightsWithoutSTD, this.props.searchFilter, this.props.staDate,
            this.props.stdDate, this.props.isPopoverOpen, this.props.pageSize, 1, this.customerIds, this.customers, this.props.isArchivedFlights);
    }

    public HandleSearchFilter(e: any) {
        if (this.props.searchFilter.length <= 2) {
            if (e.key === 'Enter') {
                this.ensureDataFetched();
            }
        }
    }
    searchClickHandler = () => {
        this.ensureDataFetched();
    }
    handleFlightsWithoutSTDChange(event: any) {
        this.props.setWithoutSTDFilterValue(event);
    }
    handleFlightsBlockedFlightsChange(event: any) {
        this.props.setBlockedOutFilterValue(event);
    }
    handleArchivedFlightsChange(event: any) {
        this.props.setArchivedFilterValue(event);
    }
    handleStaDate(staDate: Date) {
        this.props.setSTAFilterValue(staDate);
    }
    handleStdDate(stdDate: Date) {
        this.props.setSTDFilterValue(stdDate);
    }
    handlePaging(page: number, pageSize: number) {
        this.props.getWorkloadDetails(this.props.isBlockedOutFlights, this.props.isFlightsWithoutSTD, this.props.searchFilter, this.props.staDate,
            this.props.stdDate, false, pageSize, page, this.customerIds, this.customers, this.props.isArchivedFlights
        );
    }
    private handleCustomers(event: any, id: number) {
        this.props.UpdateCustomerIdsFilter(id);
        this.customerIds = this.props.customerIds;
        this.forceUpdate();
    }
    private filterData() {
        this.filterPreviousParams.isBlockedOutFlights = this.props.isBlockedOutFlights;
        this.filterPreviousParams.isFlightsWithoutSTD = this.props.isFlightsWithoutSTD;
        this.filterPreviousParams.isArchivedFlights = this.props.isArchivedFlights;
        this.filterPreviousParams.staDate = this.props.staDate;
        this.filterPreviousParams.stdDate = this.props.stdDate;
        this.filterPreviousParams.customerIds = this.props.customerIds.toString();
        if (this.props.customerIds.length && this.props.customerIds.length > 0) {
            this.customers = this.props.customerIds.toString();
            this.customerIds = this.props.customerIds;
        }
        else {
            this.customers = "";
        }
        this.props.ToggleFilter();
        this.ensureDataFetched();

    }
    private closeFilterData() {
        this.props.UpdatePreviousFiltersValues(this.filterPreviousParams);
        this.props.CloseFilter();
    }
    toggle() {
        this.props.ToggleFilter();
    }

    CustomInput = ({ value, onClick }: { value: any, onClick: any }) => (       
            <div className="calendar-custom-input calendar-custom-input-width" onClick={onClick}>
            &nbsp; &nbsp;{value == null || value === "" || value == undefined ? "Choose date" : value}
            &nbsp;&nbsp; &nbsp;<IoChevronDownSharp />
            </div>        
    );

    navigateToWorksheet(tailRecordId: number) {
        var path = "/worksheet/";
        this.props.history.push({
            pathname: path,
            state:{
                tailRecordId: tailRecordId,
                notFromWorksheet:true
        }
        });
    }

    onClickOutsideListener = () => {
        this.props.CloseFilter();
        document.removeEventListener("click", this.onClickOutsideListener)
    }

    blockOutFlight(tailRecordId: number, tailNumber: string) {
        this.tailNumber = tailNumber;
        this.tailRecordId = tailRecordId;
        this.props.ShowBlockOutPopup();
    }
    saveBlockOutFlightStatus() {
        if ((this.props.searchFilter != undefined && this.props.searchFilter != null &&
            this.props.searchFilter != "" && this.props.searchFilter.toLowerCase() == "blockout") || this.props.isBlockedOutFlights) {
            this.blockoutStatus = true;
        }
        else {
            this.blockoutStatus = false;
        }
        this.props.SaveBlockOutStatus(this.tailRecordId, this.blockoutStatus);        
        setTimeout(
            () => this.ensureDataFetched(),
            1000
        );
    }
    forceUpdateHandler() {
        this.forceUpdate();
    };
    public setSearchFilter(e: any) {
        this.props.setFilterValue(e.target.value);
        if (e.target.value.length == 0) {
            this.debouncedSearch(e.target.value);
        }
        else if (e.target.value.length > 2) {         
            this.debouncedSearch(e.target.value);
        }
        else {
            this.props.setFilterValue(e.target.value);
        }
    }
    debouncedSearch = debounce((query: any) => {
        this.ensureDataFetched();
    }, 2000);

    UnarchiveFlight(tailRecordId: number, tailNumber: string) {
        this.tailNumber = tailNumber;
        this.tailRecordId = tailRecordId;
        this.props.OpenCloseArchivedFlightPopup(!this.props.confirmArchivedFlightPopup);
    }

    SaveUnArchivedFlightStatus() {
        if (this.props.isArchivedFlights) {
            this.isArchivedFlights = true;
        }
        else {
            this.isArchivedFlights = false;
        }
        this.props.SaveUnArchivedFlightStatus(this.tailRecordId);
        setTimeout(
            () => this.ensureDataFetched(),
            1000
        );
    }
    public render() {
        return (
            <React.Fragment>
                <div className="workload-Container" id="workload_filter" onMouseLeave={() => {
                    document.addEventListener("click", this.onClickOutsideListener)
                }}>

                    <Modal centered={true} className="smallModal" isOpen={this.props.confirmBlockOutPopup}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseBlockOutPopup(); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="text-align-center">
                                Are you sure?
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                If confirmed, the flight {this.tailNumber} will be {this.blockoutStatus ? "unblocked and will appear in" : "blocked and will disappear from"}  the worksheet dashboard
                        </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="round-empty-button-popup workload-round-empty-button-popup" onClick={() => { this.props.CloseBlockOutPopup(); }}>
                                NO, CANCEL
                        </div>
                            <div className="round-fill-button-popup-large model-btn-margin" onClick={() => { this.saveBlockOutFlightStatus(); }}>
                                YES, {!this.blockoutStatus ? "BLOCK" : "UNBLOCK"}
                            </div>

                        </ModalFooter>
                    </Modal >

                    <Modal centered={true} className="smallModal" isOpen={this.props.confirmArchivedFlightPopup}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.OpenCloseArchivedFlightPopup(!this.props.confirmArchivedFlightPopup); }}><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="text-align-center">
                                Are you sure?
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                If confirmed, the flight {this.tailNumber} will be unarchived and will appear in the worksheet dashboard
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="round-empty-button-popup workload-round-empty-button-popup" onClick={() => { this.props.OpenCloseArchivedFlightPopup(!this.props.confirmArchivedFlightPopup); }}>
                                NO, CANCEL
                            </div>
                            <div className="round-fill-button-popup-large model-btn-margin" style={{ padding:"12px 43px" }} onClick={() => { this.SaveUnArchivedFlightStatus(); }}>
                                YES, UNARCHIVE
                            </div>

                        </ModalFooter>
                    </Modal >
                    {(this.props.isLoading) &&
                        <Loader fullPage={true} loading={this.props.isLoading} />
                    }

                    <div className="row margin-bottom-10 margin-5-child">
                        <div className="flex-grow-1 left-padding-10 width-workload-tab">
                            <label className="workload-label ">Workload</label>&nbsp;&nbsp;&nbsp; <label className="records-count-label">
                                {this.props.totalRecords > this.props.pageSize ?
                                    <div>{((this.props.pageNumber - 1) * (this.props.pageSize) + 1)} - {this.props.totalRecords > (this.props.pageNumber * this.props.pageSize) ? this.props.pageNumber * this.props.pageSize : this.props.totalRecords} of {this.props.totalRecords}</div> :
                                    <div>{this.props.totalRecords > 0 ? 1:0}-{this.props.totalRecords} of {this.props.totalRecords}</div>
                                }
                            </label>
                            <br /> <label className="records-count-label">Records are sorted by the est. departure time</label>
                        </div>
                        <div className="flex-grow-1 ">
                            <br />
                            <span className="input-group input-group-search-filter input-group-search-filter-margin flex-grow-1 width-100">
                                <input type="text" id="quickFilter" className="search-container input-group-text-search-container width-search-workload"
                                    onChange={(event) => { this.setSearchFilter(event) }}
                                    onKeyDown={(event) => { this.HandleSearchFilter(event) }}
                                    placeholder="Search" />
                                <div className="input-group-append">
                                    <span className="input-group-text input-group-text-search-filter"> <i><IoSearchOutline className="cursor-pointer" onClick={this.searchClickHandler} /></i></span>
                                </div>
                            </span>
                        </div>

                        <div className="margin-left-40 text-align-right ">
                            <br />
                            <Button id="mypopover" className="buttonFilter btnFilter leftMargin backGroundWhite filter-btn-height" onClick={() => { this.props.ToggleFilter() }} >
                                <IoFunnelOutline />

                                <span className="hideFilterBy">&nbsp;&nbsp;Filter By</span> </Button>
                            <UncontrolledPopover
                                placement="bottom"
                                target="mypopover"
                                className="popOver-filter"
                                isOpen={this.props.isPopoverOpen}

                                trigger="legacy"
                            >
                                <div className="flex flex-end w-100">
                                    <div className="filter-close-style">
                                        <div className="filter-crossButton"
                                            onClick={() => { this.closeFilterData(); }}> <IoCloseOutline /></div>

                                    </div>
                                </div>

                                <div className="workload-main-heading-filter ">
                                    Workload Filters
                                </div>
                                <br />
                                <PopoverHeader className="workload-filter-popover-header">
                                    STA DATE
                            </PopoverHeader>
                                <PopoverBody>                                   
                                        <DatePicker
                                            selected={this.props.staDate}
                                            onChange={(date: Date) => this.handleStaDate(date)}
                                            onCalendarClose={() => {
                                            }}
                                            minDate={new Date()}
                                            maxDate={new Date(new Date().setDate(new Date().getDate() + 6))}
                                            customInput={(<this.CustomInput value='' onClick='' />)}
                                            isClearable
                                        />                                    
                                </PopoverBody>
                                <br />
                                <PopoverHeader className="workload-filter-popover-header">
                                    STD DATE
                            </PopoverHeader>
                                <PopoverBody>
                                    <div>

                                        <DatePicker
                                            selected={this.props.stdDate}
                                            onChange={(date: Date) => this.handleStdDate(date)}
                                            minDate={new Date()}
                                            maxDate={new Date(new Date().setDate(new Date().getDate() + 6))}
                                            customInput={(<this.CustomInput value='' onClick='' />)}
                                            isClearable
                                            onCalendarClose={() => {
                                            }}
                                        />

                                    </div>
                                </PopoverBody>
                                <br />
                                <PopoverHeader className="workload-filter-popover-header">
                                    FLIGHT DETAILS
                            </PopoverHeader>
                                <PopoverBody>                                                                      
                                    <div className="checkbox-wrapper">
                                        <input
                                            name="isFlightsWithoutSTD"
                                            type="checkbox" className="filter-checkbox"
                                            checked={this.props.isFlightsWithoutSTD}
                                            onChange={event => { this.handleFlightsWithoutSTDChange(event.target.checked) }}
                                        />
                                        <span className="filter-checkbox-label">Flights without STD</span>
                                    </div> <br />
                                    <div className="checkbox-wrapper">
                                        <input
                                            name="isBlockedOutFlights"
                                            type="checkbox" className="filter-checkbox"
                                            checked={this.props.isBlockedOutFlights}
                                            onChange={event => { this.handleFlightsBlockedFlightsChange(event.target.checked) }}
                                        />
                                        <span className="filter-checkbox-label">Blocked out flights</span>
                                    </div>
                                    <br />
                                    <div className="checkbox-wrapper">
                                        <input
                                            name="isArchivedFlights"
                                            type="checkbox" className="filter-checkbox"
                                            checked={this.props.isArchivedFlights}
                                            onChange={event => { this.handleArchivedFlightsChange(event.target.checked) }}
                                        />
                                        <span className="filter-checkbox-label">Archived flights</span>
                                    </div>
                                    <br />
                                </PopoverBody>
                                <br />
                                <PopoverHeader className="workload-filter-popover-header">
                                    AIRLINE FILTERS
                            </PopoverHeader>
                                <PopoverBody>
                                    {this.props.customerDetails.map((customerDetail, index) => {
                                        return (
                                            <div key={index.toString()} className='custom-control custom-switch margin-bottom-10'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id={customerDetail.id}
                                                    onChange={event => { this.handleCustomers(event, customerDetail.id) }}
                                                    checked={this.props.customerIds.some(n => n === customerDetail.id)}
                                                />
                                                <label className='custom-control-label  data-customer-filter' htmlFor={customerDetail.id}>
                                                    {customerDetail.value}
                                                </label>
                                            </div>
                                        )
                                    }
                                    )}
                                </PopoverBody>

                                <PopoverBody className="">
                                    
                                    <button className="round-empty-button cancel-popover-button" type="reset" onClick={() => { this.closeFilterData() }}>CANCEL</button>
                                    <span>&nbsp;</span>
                                    <button className="round-fill-button border-0 save-popover-button" type="button" onClick={() => { this.filterData() }}>
                                        SAVE CHANGES
                                </button>
                                </PopoverBody>
                            </UncontrolledPopover>

                        </div>


                    </div>
                    <div className="row margin-5-child">
                        <div className="col-12 outerPlanning zero-left-right-padding margin-bottom-30">
                            {this.renderWorkloadTable()}
                            <br />
                            <div className="col-12 pagination-workload">
                                <Pagination pageSize={this.props.pageSize} total={this.props.totalRecords} current={this.props.pageNumber} onChange={this.handlePaging} showLessItems />
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
    private renderWorkloadTable() {

        if (!(this.props.workloadDetails != null && this.props.workloadDetails.length > 0) && !this.props.isLoading) {
            return (<div className="lighterText">
                <br />
                <br />

                No record found.
            </div>);
        }

        return (
            <>
                {this.props.workloadDetails.map((workloadRecord: WorkloadStore.WorkloadDetailsRequest, index) =>
                    <div key={workloadRecord.tailNumber + "_" + index} className="exWorkloadInstance workload-strech-margin">
                        <div className={(workloadRecord.isArchived || workloadRecord.arrivingActualTimeOut) ? "bg-card-color workLoadInstance otherContent content-margin-top content-margin-left worksheet-section-height worksheet-cancel-section-height" : "workLoadInstance otherContent content-margin-top content-margin-left worksheet-section-height"}>
                            <section>
                                <div className="row margin-bottom-6 margin-bottom-3">
                                    <div className="col tailNumberInstance workload-tail-title max-width-105 width-workload-header-100"><b>{workloadRecord.tailNumber}</b></div>

                                    <div className={workloadRecord.flightStatusDescription == "On Time" ? "col  flight-status zero-left-right-padding max-width-105 width-workload-header-100" 
                                            : workloadRecord.flightStatusDescription == "MX Ready" ? "col  flight-status zero-left-right-padding max-width-105 flight-status-mxready width-workload-header-100"
                                            : workloadRecord.flightStatusDescription == "Critical MX" ? "col  flight-status zero-left-right-padding max-width-105 flight-status-critical width-workload-header-100"
                                            : workloadRecord.flightStatusDescription == "Delayed" ? "col  flight-status zero-left-right-padding max-width-105 flight-status-delayed width-workload-header-100"
                                            : workloadRecord.flightStatusDescription == "AOG" ? "col  flight-status zero-left-right-padding max-width-105 flight-status-AOG width-workload-header-100"
                                            : workloadRecord.flightStatusDescription == "Routine MX" ? "col  flight-status zero-left-right-padding max-width-105 flight-status-routine width-workload-header-100"
                                            : workloadRecord.flightStatusDescription == "No Status" ? "col  flight-status zero-left-right-padding max-width-105 flight-status-nostatus width-workload-header-100"
                                                    : "col  flight-status zero-left-right-padding max-width-105 width-workload-header-100"
                                    }>
                                        {workloadRecord.flightStatusDescription}
                                    </div>
                                    {(workloadRecord.flightStatusDescription == "On Time" || workloadRecord.flightStatusDescription == "Delayed") ?
                                        <div className="col  status-inactive zero-left-right-padding max-width-105">{workloadRecord.maintenanceStatus}</div> :
                                       <div className="col-0"></div>
                                  }
                                </div>
                                <div className="row margin-bottom-6 margin-bottom-3">
                                    <div className="col tailNumberInstance workload-company-title"><b>{workloadRecord.companyDetails}</b></div>
                                </div>
                                <div className="row margin-bottom-6 margin-bottom-3">
                                    <div className="col tailNumberInstance workload-details-title ">Arrival</div>
                                    <div className="col middleHeaderInstance workload-details-title workload-details-font workload-section-text-align-right">{workloadRecord.staDetails}</div>
                                </div>
                                <div className="row margin-bottom-6 margin-bottom-3">
                                    {/*<div className="col tailNumberInstance workload-details-title ">ATA</div>*/}
                                    {/*<div className="col middleHeaderInstance workload-details-title workload-details-font workload-section-text-align-right">{workloadRecord.ataDetails}</div>*/}
                                </div>
                                <div className="row margin-bottom-6 margin-bottom-3">
                                    <div className="col tailNumberInstance workload-details-title ">Departure</div>
                                    <div className="col middleHeaderInstance workload-details-title workload-details-font workload-section-text-align-right">{workloadRecord.stdDetails}</div>
                                </div>
                                <div className="row margin-bottom-6 margin-bottom-3">
                                    <div className="col tailNumberInstance workload-details-title ">Remaining</div>
                                    {workloadRecord.remainingGroundTimeForTimer > 0 &&
                                        <div className="col middleHeaderInstance workload-details-title workload-remaing-color workload-details-font workload-section-text-align-right">
                                            {// @ts-ignore}
                                                <Timer key={index}
                                                    initialTime={workloadRecord.remainingGroundTimeForTimer}
                                                    direction="backward" lastUnit="h"
                                                >
                                                    <React.Fragment>
                                                        <div>
                                                            <Timer.Hours />:
                                                             <Timer.Minutes />:
                                                             <Timer.Seconds />
                                                        </div>
                                                    </React.Fragment>

                                                </Timer>
                                            }

                                        </div>
                                    }

                                </div>
                                <hr />
                                <div className="row margin-bottom-6 margin-bottom-3">
                                    <div className="col tailNumberInstance blockout-Status">
                                        <button className="round-empty-button-auto  worksheet-button-full-width text-align-center workload-btn-padding-tb" type="button" onClick={() => { workloadRecord.isArchived ? this.UnarchiveFlight(workloadRecord.tailRecordId, workloadRecord.tailNumber) : this.blockOutFlight(workloadRecord.tailRecordId, workloadRecord.tailNumber); }} >
                                            { workloadRecord.isArchived ? "UNARCHIVE" : (workloadRecord.arrivingActualTimeOut ? "CANCEL BLOCKOUT" : "BLOCKOUT")}
                                        </button>
                                    </div>
                                </div>
                                <div className="row padding-top-15">
                                    {((workloadRecord.isArchived == null || !workloadRecord.isArchived) && workloadRecord.arrivingActualTimeOut == null) &&
                                        <div className="col tailNumberInstance blockout-Status">
                                        <button className="round-fill-button border-0  worksheet-button-full-width text-align-center workload-btn-padding-tb" type="button" onClick={() => { this.navigateToWorksheet(workloadRecord.tailRecordId); }} >
                                                WORKSHEET
                                        </button></div>}
                                </div>

                            </section>
                        </div>
                    </div>
                )}
                <br />
            </>
        )
    }
}

export default connect((state: ApplicationState) => state.workload,
    WorkloadStore.actionCreators)(Workload as any);
