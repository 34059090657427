import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as TimeAndLaborStore from '../store/TimeAndLabor';
import lineLogo from '../Line.png';
import DatePicker from "react-datepicker";
import { FaCaretDown, FaRegCalendar, FaCheckCircle, FaSignOutAlt, FaChevronLeft} from 'react-icons/fa';
import { IoTrashOutline, IoChatbubbleOutline, IoChevronDown, IoCloseOutline , IoLogOutOutline, IoCheckmarkCircleSharp } from 'react-icons/io5';
import { Field, Form } from 'react-final-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import TimeKeeper from 'react-timekeeper';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { ScreenValid } from '../guards/GuardedRoute';
 
import moment from "moment";
import  TimePicker from './Shared/TimePicker';
const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "#ced4da",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "#ced4da"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47d30" : "",
        color: state.isSelected ? "#fff" : "",
        fontWeight: "normal",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#f47d30",
            color: "#fff"
        },


    }),
    menu: (base: any) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
        //width: "200px;",
        //overflow: "hidden;"
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
    }),
    placeholder: (base: any) => {
        return {
            ...base,
            color: '#000',
        }
    }
};

const taskCustomStyles = {
    control: (base: any, state: { isFocused: any; isDisabled:any }) => ({
        ...base,
        background: state.isDisabled ? "#e9ecef" : "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "#ced4da",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "#ced4da"
        },

    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47d30" : "",
        color: state.isSelected ? "#fff" : "",
        fontWeight: "normal",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#f47d30",
            color: "#fff"
        },
    }),
    menu: (base: any) => ({
        ...base,
        border: '1px solid #ced4da',
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
        width: "400px",
        /*        overflow: "hidden;"*/
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
    }),
    placeholder: (base: any, state: {isDisabled:any}) => {
        return {
            ...base,
            color: state.isDisabled ? "#62686f" : "#000",
            background: state.isDisabled ? "#e9ecef" : "#fff",
        }
    }
};

const aircraftCustomStyles = {
    control: (base: any, state: { isFocused: any; isDisabled: any }) => ({
        ...base,
        background: state.isDisabled ? "#e9ecef" : "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "#ced4da",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "#ced4da"
        },

    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#f47d30" : "",
        color: state.isSelected ? "#fff" : "",
        fontWeight: "normal",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#f47d30",
            color: "#fff"
        },
    }),
    menu: (base: any) => ({
        ...base,
        border: '1px solid #ced4da',
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
        /*width: "400px",*/
        /*        overflow: "hidden;"*/
    }),
    menuList: (base: any) => ({
        ...base,
        // kill the white space on first and last option
        padding: 0,
    }),
    placeholder: (base: any, state: { isDisabled: any }) => {
        return {
            ...base,
            color: state.isDisabled ? "#62686f" : "#000",
            background: state.isDisabled ? "#e9ecef" : "#fff",
        }
    }
};

type TimeAndLaborProps =
    TimeAndLaborStore.TimeAndLaborState &
    typeof TimeAndLaborStore.actionCreators &
    RouteComponentProps<{}>;

class TimeAndLabor extends React.PureComponent<TimeAndLaborProps> {

    selectAirCraftRef:any = React.createRef();

    //clickInDivRef: any = React.createRef();
    //clickOutDivRef: any = React.createRef();
    public tickId: any = {};
    public IsTablet: boolean = false;
    //handleOutsideClick = (e: { target: any; }) => {
    //    if ((!this.clickInDivRef || !this.clickInDivRef.current) &&
    //        (!this.clickOutDivRef || !this.clickOutDivRef.current)) {
    //        return;
    //    }

    //    if (this.clickInDivRef && this.clickInDivRef.current) {
    //        if (!this.clickInDivRef.current.contains(e.target)) {
    //            this.props.HideClickInTimekeeeper();
    //        }
    //    }

    //    if (this.clickOutDivRef && this.clickOutDivRef.current) {
    //        if (!this.clickOutDivRef.current.contains(e.target)) {
    //            this.props.HideClickOutTimekeeeper();
    //        }
    //    }
    //    this.forceUpdate();
    //};

    constructor(props: any) {
        super(props)
        //this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    private tick() {
        //console.log('Auto save is called--', "statusID--", this.props.statusId, ",userID---", this.props.userId);
        if ((this.props.userId != null) && (this.props.statusId == 0 || this.props.statusId == 1)) {
            //console.log('saving timesheet------>')
            this.props.SaveTimesheet();
        }
    }

    public componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        let id = null;
        let selectedDate = null;
        if (this.props.history.location.state) {
            id = this.props.history.location.state.id;
            selectedDate = this.props.history.location.state.selectedDate;
        }
        this.ensureDataFetched(id, selectedDate);
        //document.addEventListener("mousedown", this.handleOutsideClick);
        this.tickId = setInterval(
            () => this.tick(),
            30000
        );       
        this.styleTimePicker();
    }

    private styleTimePicker() {
        /* remove close button from rc time picker */ 
        let matches = document.querySelectorAll("a.rc-time-picker-clear");       
            matches.forEach(function (selecteItem: any, index: any) {                
                selecteItem.classList.remove("rc-time-picker-clear");
            });        
    }

    resize() {
        this.IsTablet = window.innerWidth <= 1025;
        this.props.updateScreenType(this.IsTablet);
    }

    public componentWillUnmount() {
        //document.removeEventListener("mousedown", this.handleOutsideClick);
        window.removeEventListener("resize", this.resize.bind(this));
        clearInterval(this.tickId);
    }

    private ensureDataFetched(id: any, selectedDate:any) {
        this.props.FetchHeads(id, selectedDate);
    }

    public HandleUserChange(event: any) {
        //console.log(event)
        //console.log(parseInt(event.value));
        this.props.SelectUser(event.value);
    }

    public HandleTaskCategoryChange(name:string,event: any, task: any) {
        //console.log(name, event.id, task.taskId);
        this.props.UpdateTask(name, event.id, task.taskId)
        if (!task.isAircraftRequired && this.selectAirCraftRef.select.state)
            this.selectAirCraftRef.select.state.selectValue.pop();
    }

    public HandleAircraftChange(name: string, event: any, taskId: number) {
        //console.log("handleAircraftChange----",event);
        //console.log("handleAircraftChange----",name, event.id, taskId);
        this.props.UpdateTask(name, event.id, taskId)
    }

    private HandleChange(evt: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>, taskId: number) {
        //console.log("handleChange----", evt.target.name, evt.target.value, taskId);
        this.props.UpdateTask(evt.target.name, evt.target.value, taskId)
    }

    private HandleOTHoursChange(evt: React.ChangeEvent<HTMLInputElement>) {
        this.props.UpdateOTHours(evt.target.value)
    }

    private FormatTimeHours(evt: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) {
        evt.target.value = Number(evt.target.value).toFixed(2).toString()
    }

    private HandleShiftTime(value:any, targetFunction:any) {
        if (value != null) {
            let hr = value.hour() < 10 ? '0' + value.hour() : value.hour();
            let min = value.minutes() < 10 ? '0' + value.minutes() : value.minutes();
            targetFunction(hr + ':' + min);
        }
        else {
            //console.log("null value", value);
        }
    }

    private backToApprovePage = (unlockTimesheet: any) => {
        if (unlockTimesheet) {
            this.props.UnlockTimesheet();
        }
        const url = `/approval`;
        this.props.history.push(url);
    }

    CustomInput = ({ value, onClick, css }: { value: any, onClick: any, css: any }) => (
        <div>

            <div className={css} onClick={onClick}>
                <FaRegCalendar /> &nbsp; &nbsp;{value}
                &nbsp;&nbsp; &nbsp;<IoChevronDown className="calendar-svg" />
            </div>
        </div>
    );

    public render() {

        if (this.props.isShowApprovalPage) {
            const url = `/Approval`;
            this.props.history.push(url);
            this.props.ResetShowApprovalPage();
        }        
        return (
            <React.Fragment>
                {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }

                <div className="row">
                    <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="bottom-center" />
                </div>
                    <div className="hide-from-tablet">
                        {this.renderTimsheet()}
                    </div>
                    <div className="hide-in-big-screen show-in-tablet">
                        {this.renderTimsheetinTablet()}
                    </div>

                <Modal centered={true} isOpen={this.props.showAddComment} >
                    <ModalHeader className="modalHeader">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseAddComment(); }}><IoCloseOutline /></div>

                            </div>
                        </div>

                    </ModalHeader>
                    <ModalBody>
                        <div className="modalLabel">Adding Comment</div>
                        <textarea rows={4}
                            defaultValue={this.props.selectedComment.commentText}
                            onChange={this.props.UpdateComment}
                            placeholder="Enter your comments here"
                            className="form-control w-100" />
                        <div>&nbsp;
                            </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="round-empty-button" onClick={() => { this.props.CloseAddComment(); }}>
                            CANCEL
                        </div>
                        <div className="round-fill-button margin-left-24" onClick={() => { this.props.SaveComment(); }}>
                            SAVE
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal centered={true} className="smallModal diff-hours-modal" isOpen={this.props.showHoursDifference}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseHoursDifference(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        <div className="flex flex-center w-100">
                            <span>{this.props.employeeId != "0"?"Different Hours":"Select User"}</span>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex flex-center w-100">
                            {this.props.employeeId != "0" ?
                                <div className="flex flex-center w-100 text-center"> Total Shift Hours are different<br />than Total Task Hours.<br />Please update your hours.</div>
                                : <div className="flex flex-center w-100 text-center">Please select user before submitting timesheet.</div>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex flex-center w-100">
                            <div className="round-fill-button border-0 diff-hours-ok" onClick={() => { this.props.CloseHoursDifference(); }}>OK</div>
                        </div>

                    </ModalFooter>
                </Modal>

                <Modal centered={true} className="smallModal diff-hours-modal" isOpen={this.props.showTaskValidation}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseTaskValidError(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        <div className="flex flex-center w-100">
                            <span>INCOMPLETE TASKS</span>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex flex-center w-100">
                            <div className="flex flex-center w-100 text-center">{this.props.errorMessage}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex flex-center w-100">
                            <div className="round-fill-button border-0 diff-hours-ok" onClick={() => { this.props.CloseTaskValidError(); }}>OK</div>
                        </div>

                    </ModalFooter>
                </Modal>

                <Modal centered={true} className="smallModal" isOpen={this.props.showDeleteConfirmation}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseDeleteConfirmation(); }}><IoCloseOutline /></div>

                            </div>
                        </div>

                        <div className="text-align-center">
                            Are you sure?
                            </div>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            This task will be permanently deleted from the timesheet.
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="round-empty-button-popup workload-round-empty-button-popup" onClick={() => { this.props.CloseDeleteConfirmation(); }}>
                            NO, CANCEL
                        </div>
                        <div className="round-fill-button-popup model-btn-margin" onClick={() => { this.props.DeleteTask(); }}>
                            YES, DELETE
                        </div>

                    </ModalFooter>
                </Modal >
            </React.Fragment>
        );
    }

    private renderTimsheetinTablet() {
        let timeSheetId = this.props.history.location.state ? this.props.history.location.state.id.toString() : null;//let timeSheetId = new URLSearchParams(this.props.location.search).get("id");
        return (
            <>
                <div className="row d-flex" style={{ border: '1px solid #000;' }}>
                    <div className="pb-3 d-flex" >
                        {ScreenValid('approval') && this.props.statusId === 3 &&
                            <FaChevronLeft className="width-Fa-Left" style={{ marginTop: "11px" }} onClick={() => { this.backToApprovePage(false); }} />
                        }
                        {!ScreenValid('approval') && <div className="pl-3 timeandlabor-label">My Timesheet</div>}
                        {ScreenValid('approval') && this.props.userName && <div className="timeandlabor-label">Timesheet of : {this.props.userName}</div>}
                        {ScreenValid('approval') && !this.props.userName && <div className="timeandlabor-label">Create a New Timesheet</div>}
                        {this.props.statusId === 1 &&
                            <div className="p-2"><img src={lineLogo} /></div>
                        }
                        {this.props.statusId === 1 &&
                            <div className="p-2 flex-grow-1 timeandlabor-sublabel">Last Saved: {this.props.updatedAt.toDateString()} - {this.props.updatedAt.getHours() + ':' + (this.props.updatedAt.getMinutes() < 10 ? '0' : '') + this.props.updatedAt.getMinutes()}</div>
                        }
                        {timeSheetId === null && this.props.statusId === 2 &&
                            <div className="p-2 status-submitted"><span className="submitted-status-text">SUBMITTED</span></div>
                        }
                        {this.props.statusId === 3 &&
                            <div className="p-2 status-approved"><span className="approved-status-text">APPROVED</span></div>
                        }
                        {timeSheetId === null && this.props.statusId === 4 &&
                            <div className="p-2 status-locked"><span className="locked-status-text">LOCKED- SUPERVISOR REVIEW</span></div>
                        }
                    </div>
                </div>
                <div className="row padding-bottom-36 justify-content-end">
                    {!ScreenValid('approval') && !this.props.isDisable &&
                        <div className="d-flex-reverse">
                            <div className="timeAndLabor-approvesheet-button submit-button-width" onClick={() => {
                                {
                                    this.props.totalShiftHours.toFixed(2) === this.props.totalTaskHous.toFixed(2) ?
                                        this.props.SubmitTimesheet(false) :
                                        this.props.ShowHoursDifference();
                                }
                            }}>
                                <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                                <span className="add-user">SUBMIT</span>
                            </div>
                        </div>
                    }

                    {ScreenValid('approval') && !this.props.isDisable &&
                        <div className="d-flex-reverse">
                            <div className="timeAndLabor-approvesheet-button submit-button-width" onClick={() => {
                                {
                                    (this.props.totalShiftHours.toFixed(2) === this.props.totalTaskHous.toFixed(2) && this.props.employeeId != "0") ?
                                        this.props.SubmitTimesheet(true) :
                                        this.props.ShowHoursDifference();
                                }
                            }}>
                                <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                                <span className="add-user">SUBMIT</span>
                            </div>  
                            <div className="float-right timeAndLabor-cancel-button timeAndLabor-button-cancel-width" onClick={() => { this.backToApprovePage(true); }}>
                                CANCEL
                                </div>
                        </div>
                    }
                    {this.props.isDisable && this.props.statusId == 2 && timeSheetId === null &&
                            <div className="round-empty-button timesheet-edit-button" onClick={() => { this.props.EditTimesheet(); }}>
                                EDIT
                            </div>
                    }
                    {
                        ScreenValid('approval') && this.props.isDisable && this.props.statusId !== 3 &&
                        <div className="d-flex-reverse">
                            <div className="timeAndLabor-approvesheet-button approve-button-width" onClick={() => { this.props.ApproveTimesheet(); }}>
                                <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                                <span className="add-user">APPROVE TIMESHEET</span>
                            </div>
                            <div className="float-right timeAndLabor-cancel-button timeAndLabor-button-cancel-width" onClick={() => { this.backToApprovePage(false); }}>
                                CANCEL
                            </div>
                        </div>
                    }
                </div>
                <form>
                    <fieldset disabled={this.props.isDisable}>
                        <div className="row padding-bottom-36">
                            <div className="col-sm-6">
                                <div className="my-shift-Box">
                                    {!ScreenValid('approval') && <div className="my-shift-title">My Shift:</div>}
                                    {ScreenValid('approval') && <div className="my-shift-title">Shift:</div>}
                                    <div className="my-shift-clock-label">Clock In:</div>
                                    <div className="flex">
                                        <div>
                                            <DatePicker
                                                disabled={this.props.isDisable}
                                                selected={this.props.selectedCheckInDate}
                                                onChange={(date: Date) => this.props.SelectCheckInDat(date)}
                                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                maxDate={new Date()}
                                                customInput={(<this.CustomInput value='' onClick='' css={this.props.isDisable ? "calendar-custom-input calender-disable padding-x-zero-tablet" : "calendar-custom-input padding-x-zero-tablet"} />)}
                                            />
                                        </div>
                                        <div className="w-30">
                                            {/*<input type="text" disabled={this.props.isDisable} id="clickInTime" className="form-control clock-in-time"*/}
                                            {/*    onClick={() => { this.props.ShowClickInTimekeeeper(); }}*/}
                                            {/*    value={this.props.clickInTime}*/}
                                            {/*/>*/}
                                            <TimePicker
                                                disabled={this.props.isDisable}
                                                value={moment(this.props.clickInTime, 'h:mm')}
                                                onChange={(value: any) => {
                                                    this.HandleShiftTime(value, this.props.SetClickInTime)
                                                }}
                                            />
                                             
                                        </div>
                                    </div>
                                    {/*{this.props.showClickInTime && (*/}
                                    {/*    <div ref={this.props.isTablet ? this.clickInDivRef:""}>*/}
                                            {/*<TimeKeeper*/}
                                            {/*    time={this.props.clickInTime}*/}
                                            {/*    onChange={(newTime) => this.props.SetClickInTime(newTime.formatted24)}*/}
                                            {/*    onDoneClick={() => { this.props.HideClickInTimekeeeper(); }}*/}
                                            {/*    coarseMinutes={15}*/}
                                            {/*    hour24Mode*/}
                                            {/*    switchToMinuteOnHourSelect*/}
                                            {/*/>*/}
                                           

                                    {/*    </div>*/}
                                    {/*)}*/}
                                    <br />
                                    <div className="my-shift-clock-label">Clock Out:</div>
                                    <div className="flex">
                                        <div>
                                            <DatePicker
                                                disabled={this.props.isDisable}
                                                selected={this.props.selectedCheckOutDate}
                                                onChange={(date: Date) => this.props.SelectCheckOutDat(date)}
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                customInput={(<this.CustomInput value='' onClick='' css={this.props.isDisable ? "calendar-custom-input calender-disable padding-x-zero-tablet" : "calendar-custom-input padding-x-zero-tablet"} />)}
                                            />
                                        </div>
                                        <div className="w-30">
                                            {/*<input type="text" disabled={this.props.isDisable} id="clickOutTime" className="form-control clock-in-time"*/}
                                            {/*    onClick={() => { this.props.ShowClickOutTimekeeeper(); }}*/}
                                            {/*    value={this.props.clickOutTime}*/}
                                            {/*/>*/}
                                            <TimePicker
                                                disabled={this.props.isDisable}
                                                value={moment(this.props.clickOutTime, 'h:mm')}
                                                onChange={(value: any) => {
                                                    this.HandleShiftTime(value, this.props.SetClickOutTime)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/*{this.props.showClickOutTime && (*/}
                                    {/*    <div ref={this.props.isTablet?this.clickOutDivRef:""}>*/}
                                            {/*<TimeKeeper*/}
                                            {/*    time={this.props.clickOutTime}*/}
                                            {/*    onChange={(newTime) => this.props.SetClickOutTime(newTime.formatted24)}*/}
                                            {/*    onDoneClick={() => { this.props.HideClickOutTimekeeeper(); }}*/}
                                            {/*    coarseMinutes={15}*/}
                                            {/*    hour24Mode*/}
                                            {/*    switchToMinuteOnHourSelect*/}
                                            {/*/>*/}
                                             
                                    {/*    </div>*/}
                                    {/*)}*/}

                                </div>
                            </div>

                            {timeSheetId === '0' && this.renderNewTimeSheetSummaryInTablet()}
                            {timeSheetId !== '0' && this.renderTimeSheetSummaryInTablet()}
                        </div>
                    </fieldset>
                </form>
                {this.renderTasksTableInTablet()}
            </>
        );
    }

    private renderTimsheet() {
        let timeSheetId = this.props.history.location.state ? this.props.history.location.state.id.toString() : null;//let timeSheetId = new URLSearchParams(this.props.location.search).get("id");
        return (
            <>
                <div className="row padding-bottom-36 d-flex">
                    <div className="col-6 d-flex" >
                        {ScreenValid('approval') && this.props.statusId === 3 &&
                            <FaChevronLeft className="width-Fa-Left" style={{ marginTop: "13px" }} onClick={() => { this.backToApprovePage(false); }} />
                        }
                        {!ScreenValid('approval') && <div className="timeandlabor-label">My Timesheet</div>}
                        {ScreenValid('approval') && this.props.userName && <div className="timeandlabor-label">Timesheet of : {this.props.userName}</div>}
                        {ScreenValid('approval') && !this.props.userName && <div className="timeandlabor-label">Create a New Timesheet</div>}
                        {this.props.statusId === 1 &&
                            <div className="p-2"><img src={lineLogo} /></div>
                        }
                        {this.props.statusId === 1 &&
                            <div className="p-2 flex-grow-1 timeandlabor-sublabel">Last Saved: {this.props.updatedAt.toDateString()} - {this.props.updatedAt.getHours() + ':' + (this.props.updatedAt.getMinutes() < 10 ? '0' : '') + this.props.updatedAt.getMinutes()}</div>
                        }
                        {timeSheetId === null && this.props.statusId === 2 &&
                            <div className="p-2 status-submitted"><span className="submitted-status-text">SUBMITTED</span></div>
                        }
                        {this.props.statusId === 3 &&
                            <div className="p-2 status-approved">
                            <span className="approved-status-text">APPROVED</span>
                        </div>
                        }
                        {timeSheetId === null && this.props.statusId === 4 &&
                            <div className="p-2 status-locked"><span className="locked-status-text">LOCKED- SUPERVISOR REVIEW</span></div>
                        }
                    </div>

                    {!ScreenValid('approval') && !this.props.isDisable &&
                        <div className="col-6 d-flex-reverse">
                            <div className="timeAndLabor-approvesheet-button submit-button-width" onClick={() => {
                                {
                                    this.props.totalShiftHours.toFixed(2) === this.props.totalTaskHous.toFixed(2) ?
                                        this.props.SubmitTimesheet(false) :
                                        this.props.ShowHoursDifference();
                                }
                            }}>
                                <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                                <span className="add-user">SUBMIT</span>
                            </div>
                        </div>
                    }

                    {ScreenValid('approval') && !this.props.isDisable &&
                        <div className="col-6 d-flex-reverse">
                            <div className="timeAndLabor-approvesheet-button submit-button-width" onClick={() => {
                                {
                                    (this.props.totalShiftHours.toFixed(2) === this.props.totalTaskHous.toFixed(2) && this.props.employeeId != "0") ?
                                        this.props.SubmitTimesheet(true) :
                                        this.props.ShowHoursDifference();
                                }
                            }}>
                                <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                                <span className="add-user">SUBMIT</span>
                            </div>
                            <div className="float-right timeAndLabor-cancel-button timeAndLabor-button-cancel-width" onClick={() => { this.backToApprovePage(true); }}>
                                CANCEL
                                </div>
                        </div>
                    }
                    {this.props.isDisable && this.props.statusId == 2 && timeSheetId === null &&
                        <div className="col-6">
                            <div className="col-2 round-empty-button timesheet-edit-button" onClick={() => { this.props.EditTimesheet(); }}>
                                EDIT
                            </div>
                        </div>
                    }
                    {
                        ScreenValid('approval') && this.props.isDisable && this.props.statusId !== 3 &&
                        <div className="col-6 d-flex-reverse">
                            <div className="timeAndLabor-approvesheet-button approve-button-width" onClick={() => { this.props.ApproveTimesheet(); }}>
                                <i className="vector-noborder"><IoCheckmarkCircleSharp /></i>
                                <span className="add-user">APPROVE TIMESHEET</span>
                            </div>
                            <div className="float-right timeAndLabor-cancel-button timeAndLabor-button-cancel-width" onClick={() => { this.backToApprovePage(false); }}>
                                CANCEL
                            </div>
                        </div>
                    }
                </div>
                <form>
                    <fieldset disabled={this.props.isDisable}>
                        <div className="row padding-bottom-36">
                            <div className="col-sm-4">
                                <div className="my-shift-Box">
                                    {!ScreenValid('approval') && <div className="my-shift-title">My Shift:</div>}
                                    {ScreenValid('approval') && <div className="my-shift-title">Shift:</div>}
                                    <div className="my-shift-clock-label">Clock In:</div>
                                    <div className="flex">
                                        <div>
                                            <DatePicker
                                                disabled={this.props.isDisable}
                                                selected={this.props.selectedCheckInDate}
                                                onChange={(date: Date) => this.props.SelectCheckInDat(date)}
                                                minDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                maxDate={new Date()}
                                                customInput={(<this.CustomInput value='' onClick='' css={this.props.isDisable ? "calendar-custom-input calender-disable" : "calendar-custom-input"} />)}
                                            />
                                        </div>
                                        <div className="w-30 margin-left-10">
                                            {/*<input type="text" disabled={this.props.isDisable} id="clickInTime" className="form-control clock-in-time"*/}
                                            {/*    onClick={() => { this.props.ShowClickInTimekeeeper(); }}*/}
                                            {/*    value={this.props.clickInTime}*/}
                                            {/*/>*/}
                                            <TimePicker
                                                disabled={this.props.isDisable}
                                                value={moment(this.props.clickInTime, 'h:mm')}
                                                onChange={(value: any) => {
                                                    this.HandleShiftTime(value, this.props.SetClickInTime)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/*{this.props.showClickInTime && (*/}
                                    {/*    <div ref={!this.props.isTablet?this.clickInDivRef:""}>*/}
                                            {/*<TimeKeeper*/}
                                            {/*    time={this.props.clickInTime}*/}
                                            {/*    onChange={(newTime) => this.props.SetClickInTime(newTime.formatted24)}*/}
                                            {/*    onDoneClick={() => { this.props.HideClickInTimekeeeper(); }}*/}
                                            {/*    coarseMinutes={15}*/}
                                            {/*    hour24Mode*/}
                                            {/*    switchToMinuteOnHourSelect*/}
                                            {/*/>*/}
                                            {/*<TimePicker value={moment(this.props.clickInTime, 'h:mm')} onChange={(value) => { console.log(value.hour() + ':' + value.minutes()); this.props.SetClickInTime(value.hour() + ':' + value.minutes())}} />*/}
                                    {/*    </div>*/}
                                    {/*)}*/}

                                    <div className="my-shift-clock-label">Clock Out:</div>
                                    <div className="flex">
                                        <div>
                                            <DatePicker
                                                disabled={this.props.isDisable}
                                                selected={this.props.selectedCheckOutDate}
                                                onChange={(date: Date) => this.props.SelectCheckOutDat(date)}
                                                minDate={new Date()}
                                                maxDate={new Date()}
                                                customInput={(<this.CustomInput value='' onClick='' css={this.props.isDisable ? "calendar-custom-input calender-disable" : "calendar-custom-input"} />)}
                                            />
                                        </div>
                                        <div className="w-30 margin-left-10">
                                            {/*<input type="text" disabled={this.props.isDisable} id="clickOutTime" className="form-control clock-in-time"*/}
                                            {/*    onClick={() => { this.props.ShowClickOutTimekeeeper(); }}*/}
                                            {/*    value={this.props.clickOutTime}*/}
                                            {/*/>*/}
                                            <TimePicker
                                                disabled={this.props.isDisable}
                                                value={moment(this.props.clickOutTime, 'h:mm')}
                                                onChange={(value: any) => {
                                                    this.HandleShiftTime(value, this.props.SetClickOutTime)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/*{this.props.showClickOutTime && (*/}
                                    {/*    <div ref={!this.props.isTablet?this.clickOutDivRef:""}>*/}
                                            {/*<TimeKeeper*/}
                                            {/*    time={this.props.clickOutTime}*/}
                                            {/*    onChange={(newTime) => this.props.SetClickOutTime(newTime.formatted24)}*/}
                                            {/*    onDoneClick={() => { this.props.HideClickOutTimekeeeper(); }}*/}
                                            {/*    coarseMinutes={15}*/}
                                            {/*    hour24Mode*/}
                                            {/*    switchToMinuteOnHourSelect*/}
                                            {/*/>*/}
                                            
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                            </div>

                            {timeSheetId === '0' && this.renderNewTimeSheetSummary()}
                            {timeSheetId !== '0' && this.renderTimeSheetSummary()}
                        </div>
                    </fieldset>
                </form>
                {this.renderTasksTable()}
            </>
        );
    }


    private renderNewTimeSheetSummaryInTablet() {
        return (
            <div className="col-sm-6">
                <div className="tablet-employee-profile" style={{gap:"6px"}}>
                    <div className="tablet-summary-title">Summary:</div>
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Name:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text" style={{width:"120px"}}>
                                <Select
                                    styles={customStyles}
                                    onChange={event => { this.HandleUserChange(event); }}
                                    options={this.props.userListOption}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Employee #:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text">{this.props.userId}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Position:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text">{this.props.position}</div>
                        </div>
                    </div>
                        <div className="row">
                        <div className="col-8">
                            <div className="name">Total Shift Hours:</div>
                        </div>
                        <div className="col-4">
                            <div className="text">{this.props.totalShiftHours.toFixed(2)}</div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Total Task Hours:</div>
                        </div>
                        <div className="col-4">
                            <div className="text">{this.props.totalTaskHous.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="row">
                       
                        <div className="col-8 hide">
                            <div className="name">Total OT Hours:</div>
                        </div>
                        <div className="col-4 hide">
                            <div className="text margin-left-minus">
                                {!this.props.isDisable &&
                                    <input type="text" id="otHours" value={this.props.totalOTHours}
                                        placeholder="0.0"
                                        onChange={(e) => this.HandleOTHoursChange(e)}
                                        onBlur={(e) => this.FormatTimeHours(e)}
                                        className="search-container input-group-text-search-container width-100 ot-time" />
                                }
                                {this.props.isDisable && this.props.totalOTHours}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderNewTimeSheetSummary() {
        return (
            <div className="col-sm-8">
                <div className="employee-profile">
                    <div className="summary-title">Summary:</div>
                    <div className="row">
                        <div className="col-3">
                            <div className="name">Name:</div>
                        </div>
                        <div className="col-3 noLeftPadding noRightPadding">
                            <div className="text">
                                <Select
                                    styles={customStyles}
                                    onChange={event => { this.HandleUserChange(event); }}
                                    options={this.props.userListOption}
                                />

                            </div>
                        </div>
                        <div className="col-4">
                            <div className="name">Total Shift Hours:</div>
                        </div>
                        <div className="col-2">
                            <div className="text">{this.props.totalShiftHours.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="name">Employee #:</div>
                        </div>
                        <div className="col-3 noLeftPadding noRightPadding">
                            <div className="text">{this.props.userId}</div>
                        </div>
                        <div className="col-4">
                            <div className="name">Total Task Hours:</div>
                        </div>
                        <div className="col-2">
                            <div className="text">{this.props.totalTaskHous.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="name">Position:</div>
                        </div>
                        <div className="col-3 noLeftPadding noRightPadding">
                            <div className="text">{this.props.position}</div>
                        </div>
                        <div className="col-4 hide">
                            <div className="name ">Total OT Hours:</div>
                        </div>
                        <div className="col-2 hide">
                            <div className="text margin-left-minus">
                                {!this.props.isDisable &&
                                    <input type="text" id="otHours" value={this.props.totalOTHours}
                                        placeholder="0.0"
                                        onChange={(e) => this.HandleOTHoursChange(e)}
                                        onBlur={(e) => this.FormatTimeHours(e)}
                                        className="search-container input-group-text-search-container width-100 ot-time" />
                                }
                                {this.props.isDisable && this.props.totalOTHours}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderTimeSheetSummaryInTablet() {
        return (
            <div className="col-sm-6" >
                <div className="tablet-employee-profile">
                    <div className="tablet-summary-title">Summary:</div>
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Name:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text employeeNameWidth">{this.props.userName}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Employee #:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text">{this.props.userId}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Position:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text">{this.props.position}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-8">
                            <div className="name">Total Shift Hours:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text">{this.props.totalShiftHours.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="name">Total Task Hours:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding">
                            <div className="text">{this.props.totalTaskHous.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 hide" >
                            <div className="name">Total OT Hours:</div>
                        </div>
                        <div className="col-4 noLeftPadding noRightPadding hide">
                            <div className="text">
                                {!this.props.isDisable &&
                                    <input type="text" id="otHours" value={this.props.totalOTHours}
                                        placeholder="0.0"
                                        onChange={(e) => this.HandleOTHoursChange(e)}
                                        onBlur={(e) => this.FormatTimeHours(e)}
                                        className="search-container input-group-text-search-container width-100 ot-time" />
                                }
                                {this.props.isDisable && this.props.totalOTHours}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    private renderTimeSheetSummary() {
        return (
            <div className="col-sm-8" >
                <div className="employee-profile">
                    <div className="summary-title">Summary:</div>
                    <div className="row">
                        <div className="col-3">
                            <div className="name">Name:</div>
                        </div>
                        <div className="col-3 noLeftPadding noRightPadding">
                            <div className="text">{this.props.userName}</div>
                        </div>
                        <div className="col-4">
                            <div className="name">Total Shift Hours:</div>
                        </div>
                        <div className="col-2">
                            <div className="text">{this.props.totalShiftHours.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="name">Employee #:</div>
                        </div>
                        <div className="col-3 noLeftPadding noRightPadding">
                            <div className="text">{this.props.userId}</div>
                        </div>
                        <div className="col-4">
                            <div className="name">Total Task Hours:</div>
                        </div>
                        <div className="col-2">
                            <div className="text">{this.props.totalTaskHous.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="name">Position:</div>
                        </div>
                        <div className="col-3 noLeftPadding noRightPadding">
                            <div className="text">{this.props.position}</div>
                        </div>
                        <div className="col-4 hide">
                            <div className="name">Total OT Hours:</div>
                        </div>
                        <div className="col-2 hide">
                            <div className="text">
                                {!this.props.isDisable &&
                                    <input type="text" id="otHours" value={this.props.totalOTHours}
                                        placeholder="0.0"
                                        onChange={(e) => this.HandleOTHoursChange(e)}
                                        onBlur={(e) => this.FormatTimeHours(e)}
                                        className="search-container input-group-text-search-container width-100 ot-time" />
                                }
                                {this.props.isDisable && this.props.totalOTHours}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    private renderTasksTableInTablet() {
        return (
            <div className="content">
                <form onSubmit={() => { this.props.SubmitTimesheet(false); }}>
                    <fieldset disabled={this.props.isDisable}>
                        <div className="my-tasks">
                            {!ScreenValid('approval') && <div className="summary-title">My Tasks:</div>}
                            {ScreenValid('approval') && <div className="summary-title">Tasks:</div>}

                            <div className="row">

                                <div className="col-4">
                                    <div className="name">Task Category:</div>
                                </div>
                                <div className="col-3">
                                    <div className="name">Aircraft</div>
                                </div>
                                <div className="col-3">
                                    <div className="name">WO/ DMI/ Task</div>
                                </div>
                                <div className="col-2 zeroLeftPadding">
                                    <div className="name">Time</div>
                                </div>
                            </div>
                            {this.props.tasks.map((task: any) =>
                                <div className="row" key={task.taskId}>
                                    <div className="col-4">
                                        <Select
                                            name="taskCategoryId"
                                            placeholder="Select"
                                            styles={taskCustomStyles}
                                            onChange={event => { this.HandleTaskCategoryChange("taskCategoryId", event, task) }}
                                            options={this.props.taskCategoriesList}
                                            value={task.taskCategoryId != null ? this.props.taskCategoriesList.filter(t => t.id == task.taskCategoryId) : null}
                                            maxMenuHeight={600}
                                            minMenuHeight={600}
                                            isSearchable={false}
                                            isDisabled={this.props.isDisable}
                                        />

                                    </div>
                                    <div className="col-3">
                                        <Select
                                            ref={ref => {
                                                this.selectAirCraftRef = ref;
                                            }}
                                            name="aircraftId"
                                            placeholder={task.isAircraftRequired ? "Select" : "N/A"}
                                            styles={aircraftCustomStyles}
                                            onChange={event => { this.HandleAircraftChange("aircraftId", event, task.taskId) }}
                                            options={this.props.aircraftList}
                                            value={task.aircraftId != null ? this.props.aircraftList.filter(t => t.id == task.aircraftId) : null}
                                            maxMenuHeight={600}
                                            minMenuHeight={600}
                                            isDisabled={!task.isAircraftRequired || this.props.isDisable}
                                            isSearchable={false}
                                        />

                                    </div>
                                    <div className="col-3">
                                        <input className="form-control width-100" name="taskDescription" type="text"
                                            placeholder="Enter" value={task.taskDescription}
                                            onChange={(e) => this.HandleChange(e, task.taskId)}
                                        />
                                    </div>
                                    <div className={`${this.props.isDisable ? 'col-2':'col-1' } zeroRightPadding zeroLeftPadding`}>
                                        <input className="form-control width-100" name="time" type="text"
                                            placeholder="0.0" value={task.time}
                                            onChange={(e) => this.HandleChange(e, task.taskId)}
                                            onBlur={(e) => this.FormatTimeHours(e)}
                                        />
                                    </div>
                                    {!this.props.isDisable &&
                                        <div className="col-1 pl-2  zeroRightPadding zeroLeftPadding">
                                            <IoChatbubbleOutline className="cursor-pointer comment-icon w-50" onClick={() => { this.props.ShowAddComment(task); }} />
                                            <IoTrashOutline className="cursor-pointer trash-icon w-50" onClick={() => { this.props.ShowDeleteConfirmation(task.id, task.taskId); }} />
                                        </div>
                                    }
                                    <div className="flex flex-space-between">

                                        {this.renderTaskComments(task.comment)
                                        }

                                    </div>
                                </div>
                            )}
                            {!this.props.isDisable &&
                                <div className="width-100 fullFooterButton flex flex-center" onClick={() => { this.props.AddTask() }}>
                                    + ADD ANOTHER TASK</div>
                            }
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }
    private renderTasksTable() {
        return (
            <div className="content">
                <form onSubmit={() => { this.props.SubmitTimesheet(false); }}>
                    <fieldset disabled={this.props.isDisable}>
                        <div className="my-tasks">
                            {!ScreenValid('approval') && <div className="summary-title">My Tasks:</div>}
                            {ScreenValid('approval') && <div className="summary-title">Tasks:</div>}

                            <div className="row">
                                <div className="col-3">
                                    <div className="name">Task Category:</div>
                                </div>
                                <div className="col-2">
                                    <div className="name">Aircraft</div>
                                </div>
                                <div className="col-4">
                                    <div className="name">WO/ DMI/ Task</div>
                                </div>
                                <div className="col-2">
                                    <div className="name">Time</div>
                                </div>
                            </div>
                            {this.props.tasks.map((task: any) =>
                                <div className="row" key={task.taskId}>
                                    <div className="col-3">
                                        {/*<select name="taskCategoryId" className="form-control customSelect selectUser" value={task.taskCategoryId}*/}
                                        {/*    onChange={(e) => this.HandleChange(e, task.taskId)}>*/}
                                        {/*    <option hidden>Select Task Category</option>*/}
                                        {/*    {this.props.taskCategoriesList.map(item => (*/}
                                        {/*        <option*/}
                                        {/*            key={item.id}*/}
                                        {/*            value={item.id}*/}
                                        {/*        >*/}
                                        {/*            {item.value}*/}
                                        {/*        </option>*/}
                                        {/*    ))}*/}
                                        {/*</select>*/}
                                        {/*{console.log("binding time-labor---task categoryid", task.taskCategoryId, task.taskCategoryId != null ? this.props.taskCategoriesList.filter(t => t.id == task.taskCategoryId) : null)}*/}
                                        {/*{console.log("binding time-labor---task aircraftId", task.aircraftId, task.aircraftId != null ? this.props.aircraftList.filter(t => t.id == task.aircraftId) : null)}*/}
                                        <Select
                                            name="taskCategoryId"
                                            placeholder="Select Task Category"
                                            styles={taskCustomStyles}
                                            onChange={event => { this.HandleTaskCategoryChange("taskCategoryId", event, task) }}
                                            options={this.props.taskCategoriesList}
                                            value={task.taskCategoryId != null ? this.props.taskCategoriesList.filter(t => t.id == task.taskCategoryId) : null}
                                            maxMenuHeight={600}
                                            minMenuHeight={600}
                                            isSearchable={false}
                                            isDisabled={this.props.isDisable}
                                        />

                                    </div>
                                    <div className="col-2">
                                        {/*<select disabled={!task.isAircraftRequired} name="aircraftId"*/}
                                        {/*    className="form-control customSelect selectUser"*/}
                                        {/*    value={task.aircraftId}*/}
                                        {/*    onChange={(e) => this.HandleChange(e, task.taskId)}>*/}
                                        {/*    <option hidden>Select Aircraft</option>*/}
                                        {/*    <option key="-1" value="-1" hidden>Not Required</option>*/}
                                        {/*    {this.props.aircraftList.map(item => (*/}
                                        {/*        <option*/}
                                        {/*            key={item.id}*/}
                                        {/*            value={item.id}*/}
                                        {/*        >*/}
                                        {/*            {item.value}*/}
                                        {/*        </option>*/}
                                        {/*    ))}*/}
                                        {/*</select>                                       */}
                                        {this.props.isDisable ?
                                            <input className="form-control width-100"
                                                name="aircraftName"
                                                type="text"
                                                placeholder={task.isAircraftRequired ? "Select Aircraft" : "Not Required"}
                                                value={task.aircraftName}
                                            //onChange={(e) => this.HandleChange(e, task.taskId)}
                                            />
                                            :
                                            <Select
                                                ref={ref => {
                                                    this.selectAirCraftRef = ref;
                                                }}
                                                name="aircraftId"
                                                placeholder={task.isAircraftRequired ? "Select Aircraft" : "Not Required"}
                                                styles={aircraftCustomStyles}
                                                onChange={event => { this.HandleAircraftChange("aircraftId", event, task.taskId) }}
                                                options={this.props.aircraftList}
                                                value={task.aircraftId != null ? this.props.aircraftList.filter(t => t.id == task.aircraftId) : null}
                                                maxMenuHeight={600}
                                                minMenuHeight={600}
                                                isDisabled={!task.isAircraftRequired || this.props.isDisable}
                                                isSearchable={false}
                                            />
                                        }
                                    </div>
                                    <div className="col-4">
                                        <input className="form-control width-100" name="taskDescription" type="text"
                                            placeholder="Enter" value={task.taskDescription}
                                            onChange={(e) => this.HandleChange(e, task.taskId)}
                                        />
                                    </div>
                                    <div className={this.props.isDisable?'col-3':'col-2'}>
                                        <input className="form-control width-100" name="time" type="text"
                                            placeholder="0.0" value={task.time}
                                            onChange={(e) => this.HandleChange(e, task.taskId)}
                                            onBlur={(e) => this.FormatTimeHours(e)}
                                        />
                                    </div>
                                    {!this.props.isDisable &&
                                        <div className="col-1">
                                            <IoChatbubbleOutline className="cursor-pointer comment-icon w-50" onClick={() => { this.props.ShowAddComment(task); }} />
                                            <IoTrashOutline className="cursor-pointer trash-icon w-50" onClick={() => { this.props.ShowDeleteConfirmation(task.id, task.taskId); }} />
                                        </div>
                                    }
                                    <div className="flex flex-space-between">

                                        {this.renderTaskComments(task.comment)
                                        }

                                    </div>
                                </div>
                            )}
                            {!this.props.isDisable &&
                                <div className="width-100 fullFooterButton flex flex-center" onClick={() => { this.props.AddTask() }}>
                                    + ADD ANOTHER TASK</div>
                            }
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }

    private renderTaskComments(comments: any) {

        if (comments != null && comments.length > 0) {
            return (
                <>
                    {
                        <div>
                            <div className="notesText notesText-tasks">
                                {comments}
                            </div>
                        </div>
                    }

                </>
            );
        }
        else {
            return "";
        }
    }
};

export default connect(
    (state: ApplicationState) => state.timeAndLabor,
    TimeAndLaborStore.actionCreators
)(TimeAndLabor as any);
