import * as React from 'react';
import { Route, withRouter } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import FetchData from './components/FetchData';
import TraxFile from './components/TraxFile';
import DashBoard from './components/DashBoard';
import { withAuth } from './msal/MsalAuthProvider';
import GuardedRoute from './guards/GuardedRoute';
import './custom.css'
import 'react-pro-sidebar/dist/css/styles.css';
import SideBar from './components/SideBar';
import Station from './components/Station';
import { browserName } from 'react-device-detect';
import Planning from './components/Planning';
//import AccountDetails from './components/AccountDetails';
import UserAccountDetails from './components/UserAccountDetails';
import Users from './components/Users';
import FlightBoards from './components/FlightBoard';
import Workload from './components/Workload';
import Reporting from './components/Reporting';
import WorkSheet from './components/WorkSheet';
import DeferralReport from './components/DeferralReport';
import TimeAndLabor from './components/TimeAndLabor';
import Approval from './components/Approval';
import WorksheetWIP from './components/WorksheetWIP';
import ManpowerReport from './components/ManpowerReport';
interface IProps {
    auth: any;
    history: any;
    onSignIn: any;
    // any other props that come into the component
}



class RootApp extends React.Component<IProps> {

    unlisten: any = {};
    activePathName: string = '';

    render() {
        if (browserName !== "Chrome") return (
            <div className="centerContent aircraftbg col-12">
                <div className="child centerBox col-lg-6">
                    <div className="container">
                        <br />
                        <br />
                        <br />
                        <br />
                        <b>
                            Only Chrome is supported<br/>
                            launch Chrome to use this app.
                        </b>
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>

           
        );


        return (
            <React.Fragment>

                <div className="app">
                    {this.props.auth && this.props.auth.isAuthenticated &&
                       
                        <SideBar {...this.props} >
                        </SideBar>
                       }
            <Layout {...this.props} >
                        <Route exact path='/' render={(props) => (
                            // @ts-ignore
                            <Home auth={this.props.auth} onSignIn={this.props.onSignIn}/>
                        )} />
                        <GuardedRoute path='/dashboard' name='dashboard' component={DashBoard} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/station' name='station' component={Station} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/traxfile' name='traxfile' component={TraxFile} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/planning' name='planning' component={Planning} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/timeandlabor' name='timeandlabor' component={TimeAndLabor} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/approval/:timesheetId?' name='approval' component={Approval} auth={this.props.auth && this.props.auth.isAuthenticated} />

                        <GuardedRoute path='/fetch-data/:startDateIndex?' name='fetchdata' component={FetchData} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/Users' name='users' component={UserAccountDetails} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/UserDetails' name='userdetails' component={Users} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/FlightBoards' name='flightboards' component={FlightBoards} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/workload' name='workload' component={Workload} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/Worksheet/:tailRecordId?' name='worksheet' component={WorkSheet} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/Reporting' name='reporting' component={Reporting} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/DeferralReport' name='deferralreport' component={DeferralReport} auth={this.props.auth && this.props.auth.isAuthenticated} />
                        <GuardedRoute path='/ManpowerReport' name='manpowerreport' component={ManpowerReport} auth={this.props.auth && this.props.auth.isAuthenticated} />

                    </Layout>
                    </div>
                </React.Fragment>
        );
    }
}


export default withAuth(withRouter(RootApp as any));
