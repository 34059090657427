import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { ApplicationState } from '../store';
import * as FlightBoardStore from '../store/FlightBoard';
import { IoCheckmarkCircle, IoAirplaneOutline, IoTimeOutline, IoCloseOutline, IoPlanetOutline, IoAirplane } from 'react-icons/io5'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Popover, PopoverHeader, PopoverBody, UncontrolledPopover, UncontrolledTooltip } from 'reactstrap';
import arrivalLogo from '../plane-arrival.png';
import departureLogo from '../plane-departure.png';
import departureLogoBlack from '../departure-plane-black.png';
import gtLogo from '../sand-clock.png';
import gtLogoBlack from '../sand-black-clock.png';
import planeLogo from '../plane.png';
import clockLogo from '../clock.png';
import arrivalLogoBlack from '../plane-arrival-black.png';
import filterLogo from '../filter.png';
import keyLogo from '../key.png';
import infoLogo from '../information.png';
import blackPlane from '../plane-black-fly.png';
import locationLogo from '../map-pin.png';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
// @ts-ignore
import moment from 'moment';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dlogo from './DepartingLogo';
import Alogo from './ArrivingLogo';

type flightBoardProps =
    FlightBoardStore.FlightDetailsState &
    typeof FlightBoardStore.actionCreators &
    RouteComponentProps<{}>;

class FlightBoard extends React.PureComponent<flightBoardProps>
{
    public customerIds: number[] = [];
    public customers: string = "";
    public isZuluFilter: boolean = false;
    public tickId: any = {};
    public intervalId: any = {};
    constructor(props: any) {
        super(props);
    }
    
    public componentDidMount() {
        this.closeFilterData();
        this.tickId = setInterval(
            () => this.tick(),
            1 * 60 * 1000
        );
        this.intervalId = setInterval(this.ensureDataFetched.bind(this), 15 * 60 * 1000);
    }

    tick() {
        this.props.UpdateTime();
    }

    componentWillUnmount() {
        clearInterval(this.tickId);
        clearInterval(this.intervalId);
    }
    private ensureDataFetched() {
        this.props.getFlightDetails(this.props.time, this.props.location, this.props.isZulu, this.customerIds, this.customers);

    }
    private navigateToWorksheet(tailRecordId: number) {
        var path = "/worksheet/";
        this.props.history.push({
            pathname: path,
            state: {
                tailRecordId: tailRecordId,
                notFromWorksheet: true
            }
        });
    }
    private handleTimeFilter(time: string) {
        if (parseInt(time) < 1 || parseInt(time) > 24) {
            toast.dismiss();
            toast('Please enter value between 1 to 24.');
            this.props.UpdateTimeFilter(12)
            return false;
        }
        this.props.UpdateTimeFilter(parseInt(time))
    }
    private filterData() {
        if (this.customerIds.length && this.customerIds.length > 0) {
            this.customers = this.customerIds.toString();
        }
        else {
            this.customers = "";
        }
        this.props.TogglePopOver();
        this.props.getFlightDetails(this.props.time,
            this.props.location, this.isZuluFilter, this.customerIds, this.customers);

    }
    private closeFilterData() {
        var location;
        if (sessionStorage.getItem('selectedStation')) {
            location = JSON.parse(String(sessionStorage.getItem('selectedStation'))).stationName;
        }
        this.props.UpdateLocation(location);
        this.props.UpdateTimeFilter(12)
        this.props.ClosePopOver();
        this.customerIds = [];
        this.customers = "";
        this.isZuluFilter = false;
        this.props.getFlightDetails(12,
            location, this.isZuluFilter, this.customerIds, this.customers);
    }
    private handleCustomers(event: any, id: number) {

        this.props.UpdateCustomerIdsFilter(id);
        this.customerIds = this.props.customerIds;
        this.forceUpdate();
    }

    private handleZulu(event: any) {
        this.isZuluFilter = event ? true : false;
        this.forceUpdate();
    }
    onClickOutsideListener = () => {
        this.props.ClosePopOver();
        document.removeEventListener("click",this.onClickOutsideListener)
    }


    public render() {
        return (
            <React.Fragment>
                <div className="flightBoard-Container" id="fltBoard_filter" onMouseLeave={() => {
                    document.addEventListener("click", this.onClickOutsideListener)
                }} >

                    {(this.props.isLoading) &&
                        <Loader fullPage={true} loading={this.props.isLoading} />
                    }

                    <div className="row">
                        <ToastContainer toastClassName="custom-info" hideProgressBar={true} position="top-center" />
                    </div>

                    <Modal className="colorLegendModal" contentClassName="colors-modal-content" isOpen={this.props.isColorsLegend}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseColorLegend(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            <label>
                                Colors Legend
                        </label>

                        </ModalHeader>
                        <ModalBody>
                            <span className="dot green-dot"></span>
                            <label className="colors-legend-labels">Maintenance Ready</label><br />
                            <span className="dot blue-dot"></span>
                            <label className="colors-legend-labels">Routine MX In-work</label><br />
                            <span className="dot yellow-dot"></span>
                            <label className="colors-legend-labels">Critical MX In-work</label><br />
                            <span className="dot red-dot"></span>
                            <label className="colors-legend-labels">AOG</label><br />
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>

                    <Modal className="informationModal information-top" contentClassName="information-modal-content" isOpen={this.props.isInformation}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.CloseInformation(); }}><IoCloseOutline /></div>

                                </div>
                            </div>
                            <div className="Flightboard-Boad-Update">
                                Flight Board Update
                        </div>
                            <div className="colors-legend-labels information-legend-sub-labels text-align-center text-align-left">
                                The flight board has been uploaded with live flight data and new features.
                        </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="info-section">
                                <div className="information-heading text-align-center">Automatic Flight Data Updates</div>
                                <div className="colors-legend-labels  information-legend-labels margin-left-minus">The flight data is on a 15 minute update cycle, this increases the accuracy of the flight board info. </div>
                                <br /><br />
                                <div className="colors-legend-labels  information-legend-labels  information-title margin-left-minus">A local/ Zulu switch is now a filter option, default is set to local. </div>
                            </div>
                            <div className="info-section info-section-large">
                                <div className="information-heading text-align-center bottom-margin">Arrivals Section</div>
                                <div className="colors-legend-labels  information-legend-labels   text-align-center margin-bottom-info-subheadings">Flight status icons show the status of the arriving flight.</div>
                                <img src={clockLogo} />&nbsp;
                            <label className="colors-legend-labels  information-legend-labels zero-left-margin">Aircraft has not blocked out yet.</label><br />
                                <img src={departureLogo} />&nbsp;
                            <label className="colors-legend-labels  information-legend-labels zero-left-margin">Blocked out but not in flight.</label><br />
                                <img src={planeLogo} />&nbsp;
                            <label className="colors-legend-labels  information-legend-labels zero-left-margin">Aircraft is in flight.</label><br />
                            </div>
                            <div className="info-section info-section-large">
                                <div className="information-heading text-align-center bottom-margin">Departures Section</div>
                                <div className="colors-legend-labels  information-legend-labels   text-align-center margin-bottom-info-subheadings">Flight status icons show the status of the arriving flight.</div>
                                <img src={clockLogo} />&nbsp;
                            <label className="colors-legend-labels  information-legend-labels ">Aircraft has not arrived yet.</label><br />
                                <img src={arrivalLogo} />&nbsp;
                            <label className="colors-legend-labels  information-legend-labels ">Aircraft has arrived.</label><br />
                                <div className="colors-legend-labels  information-legend-labels ">Blinking departure means it is past departure and not blocked out.</div><br />
                            </div>
                            <br />
                            <div className="text-align-center">
                                <button className="round-empty-button" type="reset" onClick={() => { this.props.CloseInformation(); }}>OK, GOT IT</button>
                            </div>
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>


                    <div className="row">
                        <div className="min-width-150 margin-bottom-10">
                            <label className="CVG-label">{this.props.location}</label>
                        </div>
                        <div className="min-width-150 margin-bottom-10">
                            <label className="CVG-label planTitle plan-title-mobile">
                                {this.props.isZulu &&
                                    <div>ZULU: {// @ts-ignore 
                                        moment(this.props.currentDate).utc().format("HH:mm DD-MMM")} </div>
                                }
                                {!this.props.isZulu &&
                                    <div>LOCAL: {this.props.currentTime}</div>
                                }
                            </label>
                        </div>
                        <div className="min-width-300  align-right flex-grow-1">
                            <img src={filterLogo} className="cursor-pointer" id="mypopover" onClick={() => { this.props.TogglePopOver() }} />
                            <span className="margin-IO-30 margin-IO-75"></span>

                            <UncontrolledPopover
                                placement="bottom" className="popOver-filter"
                                target="mypopover"
                                isOpen={this.props.isPopoverOpen}
                            >
                                <div className="flex flex-end w-100">
                                    <div className="filter-close-style">
                                        <div className="filter-crossButton"
                                            onClick={() => { this.props.CloseFilter(); }}><IoCloseOutline /></div>

                                    </div>
                                </div>

                                <div className="main-heading-filter">
                                    Flightboard Filters
                                </div>
                                <PopoverBody className="popover-body-padding-top">
                                    <br />
                                    {this.props.customerDetails.map((customerDetail, index) => {
                                        return (
                                            <div key={index.toString()} className='custom-control custom-switch margin-bottom-10'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id={customerDetail.id}
                                                    onChange={event => { this.handleCustomers(event, customerDetail.id) }}
                                                    checked={this.props.customerIds.some(n => n === customerDetail.id)}
                                                />
                                                <label className='custom-control-label  data-customer-filter' htmlFor={customerDetail.id}>
                                                    {customerDetail.value}
                                                </label>
                                            </div>
                                        )
                                    }
                                    )}

                                </PopoverBody>
                                <div className="sub-heading-filter">
                                    Hours
                                </div>
                                <PopoverBody>
                                    <div className="time-input  left-padding-20">
                                        <img src={gtLogo} />
                                        <input type="number" max={24} min={1} className="form-control left-padding-30"
                                            onChange={event => { this.handleTimeFilter(event.target.value) }}
                                            onBlur={event => { this.handleTimeFilter(event.target.value) }}
                                            value={this.props.time} />
                                    </div>
                                </PopoverBody>
                                <br />
                                <div className="sub-heading-filter">
                                    Location
                                </div>
                                <PopoverBody>
                                    <div className="time-input  left-padding-20">
                                        <img src={locationLogo} />
                                        <input type="text" className="form-control left-padding-30" value={this.props.location} disabled />
                                    </div>
                                </PopoverBody>
                                <br />
                                <div className="sub-heading-filter">
                                    Local Station Time

                                </div>
                                <PopoverBody>
                                    <div className='custom-control custom-switch'>
                                        <input
                                            type='checkbox'
                                            className='custom-control-input'
                                            id='customSwitchesZulu'
                                            onChange={event => { this.handleZulu(event.target.checked) }}
                                            checked={this.isZuluFilter}
                                        />
                                        <label className='custom-control-label  data-customer-filter' htmlFor='customSwitchesZulu'>
                                            Zulu
                                     </label>
                                    </div>
                                </PopoverBody>
                                <PopoverBody className="">                                  
                                    <button className="round-empty-button cancel-popover-button" type="reset" onClick={() => { this.closeFilterData() }}>CANCEL</button>
                                    <span>&nbsp;</span>
                                    <button className="round-fill-button border-0 save-popover-button" type="button" onClick={() => { this.filterData() }}>
                                        SAVE CHANGES
                                </button>
                                </PopoverBody>


                            </UncontrolledPopover>

                            <span className="greenIconsize"><IoCheckmarkCircle color="green" className="cursor-pointer" id="feedStatus" /><span className="margin-IO-30 margin-IO-75"></span></span>
                            <UncontrolledTooltip placement='bottom' target={"feedStatus"} >
                                <div className="tooltip-text ">
                                    <h6 className="text-decoration">DATA FEED STATUS</h6>
                                    <label className="colors-legend-labels"> Flight Feed: Current</label><br />
                                    <label className="colors-legend-labels"> Maint Feed: Current</label>
                                </div>
                            </UncontrolledTooltip>
                            <img src={infoLogo} className="cursor-pointer" onClick={() => { this.props.ShowInformation() }} /><span className="margin-IO-30 margin-IO-75"></span>
                            <img src={keyLogo} className="cursor-pointer" onClick={() => { this.props.ShowColorLegend() }} /><span className="margin-IO-30"></span>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-5 flightDivs  zero-left-right-padding">
                            <div className="col-12 flight-header">
                                <img src={arrivalLogoBlack} />&nbsp; &nbsp;&nbsp; ARRIVALS
                        </div>

                            {this.props.arrivalDetails !== null && this.props.arrivalDetails.length === 0 &&
                                <div className="flightDetails-data">
                                    No Records Found.
                            </div>
                            }
                            {this.props.arrivalDetails.map((arrivalDetail, index) => {
                                return (
                                    <div key={index.toString()} className={index % 2 == 0 ? "row col-12 row-bkg-Color div-data-row row-margin-left statusDP" + (arrivalDetail.isBlockedOutStatus == true ? "BlockOut" : arrivalDetail.flightStatusId !== null ? arrivalDetail.flightStatusId : 1)
                                        : "row col-12 alt-row-bkg-Color div-data-row row-margin-left statusDP" + (arrivalDetail.isBlockedOutStatus == true ? "BlockOut" : arrivalDetail.flightStatusId !== null ? arrivalDetail.flightStatusId : 1)}>
                                        <div className="customer-Logo-tail-div customer-logo-div-width  flex-grow-1">
                                            <img src={arrivalDetail.logoURI} alt={arrivalDetail.acOwner} className="img-customer-logo"></img>
                                        </div>
                                        <div className="customer-Logo-tail-div flightDetails-data left-padding flex-grow-1" onClick={() => { this.navigateToWorksheet(arrivalDetail.tailRecordId); }}>
                                            <Link to='#'>{arrivalDetail.tailNumber}</Link>
                                        </div>

                                        <div className="time-station-div flightDetails-data  flex-grow-1" id={"arrivingTimetip_" + index}>
                                            {!this.props.isZulu ? moment.utc(arrivalDetail.arrivalDateTime).local().format("HH:mm") : arrivalDetail.arrivalTime}
                                            <UncontrolledTooltip placement='top' target={"arrivingTimetip_" + index} >
                                                <div className="tooltip-text ">
                                                    {moment.utc(arrivalDetail.arrivalEstimatedDepartureDateToolTip).isValid() ? !this.props.isZulu ?
                                                        moment.utc(arrivalDetail.arrivalEstimatedDepartureDateToolTip).local().format("HH:mm") :
                                                        arrivalDetail.arrivalEstimatedDepartureTimeToolTip : "Information not available"
                                                    }
                                                    <img src={blackPlane} />
                                                    {!this.props.isZulu ? moment.utc(arrivalDetail.arrivalDateTime).local().format("HH:mm") : arrivalDetail.arrivalTime}

                                                </div>
                                            </UncontrolledTooltip>

                                        </div>


                                        <div className="time-station-div flightDetails-data  flex-grow-1" id={"arrivingStationTooltip_" + index}>{arrivalDetail.arrivalStation}
                                            <UncontrolledTooltip placement='top' target={"arrivingStationTooltip_" + index} >
                                                <div className="tooltip-text "> {arrivalDetail.arrivalStation} <img src={blackPlane} /> {arrivalDetail.stationArrived}</div>
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className="customer-Logo-tail-div flightDetails-data flex-grow-1">{arrivalDetail.arrivalStationDetails}</div>
                                        <div className="status-div">
                                            {arrivalDetail.arrivalStatus == "Awaiting Departure" || arrivalDetail.arrivalStatus == "Aircraft has not blocked out yet" ?
                                                <div>


                                                    <IoTimeOutline id={"AwaitingDepartureTooltip_" + index} />
                                                    <UncontrolledTooltip placement='top' target={"AwaitingDepartureTooltip_" + index} className="tooltip-top">
                                                        <div className="tooltip-text ">
                                                            {arrivalDetail.arrivalStatus}
                                                        </div>
                                                    </UncontrolledTooltip></div>
                                                :
                                                arrivalDetail.arrivalStatus == "In Flight" ?
                                                    <div>

                                                        <IoAirplane id={"InFlightTooltip_" + index} />
                                                        <UncontrolledTooltip placement='top' target={"InFlightTooltip_" + index} className="tooltip-top">
                                                            <div className="tooltip-text ">   {arrivalDetail.arrivalStatus}</div>
                                                        </UncontrolledTooltip></div>
                                                    :
                                                    arrivalDetail.arrivalStatus == "Blocked out but not in flight" ?
                                                        <div>

                                                            <div id={"InBlockedTooltip_" + index}>
                                                                <Dlogo />
                                                            </div>
                                                            <UncontrolledTooltip placement='top' target={"InBlockedTooltip_" + index} className="tooltip-top">
                                                                <div className="tooltip-text ">   {arrivalDetail.arrivalStatus}</div>
                                                            </UncontrolledTooltip></div>
                                                        :
                                                        <div>

                                                            <div id={"Landed_" + index}>
                                                                <Alogo />
                                                            </div>


                                                            <UncontrolledTooltip placement='top' target={"Landed_" + index} className="tooltip-top">
                                                                <div className="tooltip-text ">   {arrivalDetail.arrivalStatus}</div>
                                                            </UncontrolledTooltip></div>

                                            }
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <div className="col-7 flightDivs  zero-left-right-padding">
                            <div className="col-12 flight-header">
                                <img src={departureLogoBlack} />&nbsp; &nbsp;&nbsp;  DEPARTURES
                        </div>
                            {this.props.departureDetails !== null && this.props.departureDetails.length === 0 &&
                                <div className="flightDetails-data">
                                    No Records Found.
                            </div>
                            }
                            {this.props.departureDetails.map((departureDetail, index) => {
                                return (
                                    <div key={index.toString()} className={index % 2 == 0 ? "row col-12 row-bkg-Color div-data-row row-margin-left statusDP" + (departureDetail.isBlockedOutStatus == true ? "BlockOut": departureDetail.flightStatusId !== null ? departureDetail.flightStatusId : 1)
                                        : "row col-12 alt-row-bkg-Color div-data-row row-margin-left statusDP" + (departureDetail.isBlockedOutStatus == true ? "BlockOut" : departureDetail.flightStatusId !== null ? departureDetail.flightStatusId : 1)}>
                                        <div className="customer-Logo-tail-div customer-logo-div-width ">
                                            <img src={departureDetail.logoURI} alt={departureDetail.acOwner} className="img-customer-logo"></img>
                                        </div>
                                        <div className="customer-Logo-tail-div flightDetails-data left-padding" onClick={() => { this.navigateToWorksheet(departureDetail.tailRecordId); }}>
                                            <Link to='#'>{departureDetail.tailNumber}</Link>
                                        </div>
                                        <div className="time-station-div flightDetails-data" id={"departureDateTime_" + index}>
                                            {!this.props.isZulu ? moment.utc(departureDetail.departureDateTime).local().format("HH:mm") : departureDetail.departureTime}

                                            <UncontrolledTooltip placement='top' target={"departureDateTime_" + index} >
                                                <div className="tooltip-text "> {departureDetail.groundTimeMinuteDisplayValue} Minutes Ground Time Remaining</div>
                                            </UncontrolledTooltip>
                                        </div>
                                        <div className="time-station-div flightDetails-data ">{departureDetail.departingStationName}</div>
                                        <div className="customer-Logo-tail-div  flightDetails-data ">{departureDetail.departureStationDetails}</div>

                                        <div className="customer-Logo-tail-div flightDetails-data  hide-from-mobile">{departureDetail.gate}</div>

                                        <div className="status-div">
                                            {departureDetail.departureStatus == "Awaiting Arrival - Not Blocked Out"
                                                || departureDetail.departureStatus == "Past departure but not blocked out"
                                                ?
                                                <div>

                                                    <IoTimeOutline id={"AwaitingArrivalBlockedOut_" + index} />
                                                    <UncontrolledTooltip placement='top' target={"AwaitingArrivalBlockedOut_" + index}>
                                                        <div className="tooltip-text ">   {departureDetail.departureStatus}</div>
                                                    </UncontrolledTooltip></div>
                                                : departureDetail.departureStatus == "Awaiting Arrival - In Flight" ?
                                                    <div>
                                                        <IoAirplane id={"AwaitingArrivalNotBlockedOut_" + index} />
                                                        <UncontrolledTooltip placement='top' target={"AwaitingArrivalNotBlockedOut_" + index}>
                                                            <div className="tooltip-text ">   {departureDetail.departureStatus}</div>
                                                        </UncontrolledTooltip></div>
                                                    :
                                                    departureDetail.departureStatus == "Awaiting Arrival - Blocked Out" ?
                                                        <div>



                                                            <div id={"AwaitingArrivalBlockedOut_" + index}>
                                                                <Dlogo />
                                                            </div>


                                                            <UncontrolledTooltip placement='top' target={"AwaitingArrivalBlockedOut_" + index}>
                                                                <div className="tooltip-text ">   {departureDetail.departureStatus}</div>
                                                            </UncontrolledTooltip></div>
                                                        :
                                                        <div>

                                                            <div id={"BlockedIn_" + index}>

                                                                <Alogo />
                                                            </div>

                                                            <UncontrolledTooltip placement='top' target={"BlockedIn_" + index}>
                                                                <div className="tooltip-text ">  {departureDetail.departureStatus}</div>
                                                            </UncontrolledTooltip></div>

                                            }
                                        </div>
                                        <div className={"departure-notes-div flex-grow-1 " + (departureDetail.isBlockedOutStatus == true ? "bold-text" : "")}>
                                            {departureDetail.departureNotes}
                                            {departureDetail.isBlockedOutStatus == true && departureDetail.manuallyBlockedOut ==true &&(!this.props.isZulu ? " " + moment.utc(departureDetail.blockedOutDateTime).local().format("HH:mm") : departureDetail.blockedOutTime)}
                                                
                                        </div>

                                    </div>)
                            }
                            )}
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-12 outerPlanning zero-left-right-padding ">

                            <div className="col-12 flight-header">
                                <img src={gtLogoBlack} />&nbsp; &nbsp;&nbsp;EXTENDED GT
                        </div>

                            {this.props.groundTimeDetails !== null && this.props.groundTimeDetails.length === 0 &&
                                <div className="flightDetails-data">
                                    No Records Found.
                            </div>
                            }
                            {this.props.groundTimeDetails.map((groundDetails, index) => {
                                return (
                                    <>

                                        <div key={groundDetails.tailNumber}
                                            className={Math.floor(index / 2) % 2 == 0 ? "exGTInstance row-bkg-Color statusDP" + (groundDetails.flightStatusId !== null ? groundDetails.flightStatusId : 1)
                                                : "exGTInstance alt-row-bkg-Color statusDP" + (groundDetails.flightStatusId !== null ? groundDetails.flightStatusId : 1)}>
                                            <div>  <label className="customer-Logo-tail-label flightDetails-data left-padding left-padding-10 lbl-tailnumber" onClick={() => { this.navigateToWorksheet(groundDetails.tailRecordId); }}>
                                                <Link to='#'>{groundDetails.tailNumber}</Link>
                                            </label>
                                                <label className="status-label">

                                                    <Alogo />

                                                </label>
                                                <label className="customer-Logo-tail-label flightDetails-data left-padding ground-time-data lbl-grounddetails ">

                                                    {!this.props.isZulu ? "(" + moment.utc(groundDetails.arrivalDateTime).local().format("HH:mm DD-MMM") + ")" : groundDetails.fromGroundTime}
                                                </label>
                                                <label className="status-label">

                                                    <Alogo />


                                                </label>
                                                <label className="customer-Logo-tail-label flightDetails-data left-padding ground-time-data lbl-grounddetails  ">
                                                    {!this.props.isZulu ? "(" + moment.utc(groundDetails.departureDateTime).local().format("HH:mm DD-MMM") + ")-" + groundDetails.departingStationName : groundDetails.toGroundTime}

                                                </label>
                                                {groundDetails.departureNotes &&
                                                    <label className="customer-Logo-tail-label flightDetails-data left-padding">
                                                        {groundDetails.departureNotes}
                                                    </label>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                            )}

                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }

};
export default connect((state: ApplicationState) => state.flightBoards,
    FlightBoardStore.actionCreators)(FlightBoard as any);