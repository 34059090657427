import * as React from 'react';
import { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import { IoRefreshOutline, IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';

// @ts-ignore
export const ImageCropper = ({ imgId, imgSrc, closePopUp, uploadImage }: any) => {
    const [image, setImage] = useState(imgSrc);
    const [imageId, setImgId] = useState(imgId);
    const [loading, setLoading] = useState(true);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState<any>();

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setLoading(true)
           // console.log(cropper.getCroppedCanvas().toDataURL('image/jpeg'));

            cropper.getCroppedCanvas().toBlob(function (blob:any) {
                //console.log(blob);
                uploadImage(blob);
            }, 'image/jpeg');
        }
    };

    const rotateData = () => {
        if (typeof cropper !== "undefined") {
            cropper.rotate(30);
        }
    };

    const zoomUpData = () => {
        if (typeof cropper !== "undefined") {
            cropper.zoom(0.1);
        }
    };

    const zoomDownData = () => {
        if (typeof cropper !== "undefined") {
            cropper.zoom(-0.1);
        }
    };


    return (
        <>
            {
                <Loader fullPage={true} loading={loading} />
            }

            {loading &&
                <p>it can take a few minutes to set image up for manipulation</p>
            }
        <div>
                <div className="flex flex-center" style={{ width: "100%", height: "100%" }}>
                <Cropper
                    style={{ height: "100%", width: "400px" }}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    dragMode="move"
                    cropBoxMovable={true}
                    cropBoxResizable={true}
                    src={image}
                    viewMode={2}
                    guides={false}
                    background={false}
                    responsive={true}
                    autoCropArea={2}
                    autoCrop={true}
                    checkOrientation={false} 
                    ready={() => {
                        setLoading(false);
                    }}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                />
                </div>
                <br/>
            <div className="flex flex-center">
                
                    
                    <div onClick={zoomUpData} className="iconButton">
                        <IoAddCircleOutline />
                        Zoom In
                    </div>
                    &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                    <div onClick={zoomDownData} className="iconButton">
                        <IoRemoveCircleOutline />
                        Zoom Out
                    </div>
                    &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                    <div onClick={rotateData} className="iconButton">
                        <IoRefreshOutline />
                      
                        Rotate
                    </div>
                   
                </div>
                <br />
                <br />
                <div className="flex flex-center w-100">
                    <div className="round-empty-button-popup" onClick={() => { closePopUp(null) }}   >
                        CANCEL
                                </div>
                                &nbsp;
                                <div className="round-fill-button-popup-large" onClick={getCropData}>
                        SAVE CHANGES
                                </div>
                </div>

                
            </div>

            </>
    );
};

export default ImageCropper;