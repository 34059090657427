import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

export interface HomeState {
    user: any,
    isLoading: boolean
}

export interface UserRecievedAction { type: 'USER_RECIEVED', user:any }
export interface UserRequestedAction { type: 'USER_REQUESTED' }


export type KnownAction = UserRecievedAction | UserRequestedAction;

export const actionCreators = {
    fetachUser: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        localStorage.removeItem("logged_user");
        localStorage.removeItem("access_token_augur");

        const token = sessionStorage.getItem('msal.idtoken');
        fetch(`api/Login`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        }).then(response => response.json() as Promise<any>)
            .then(data => {
                console.log(data);
                //save to sessionstate
                if (data.id && data.token) {
                    sessionStorage.setItem('logged_user', JSON.stringify(data));
                    sessionStorage.setItem('access_token_augur', data.token);

                    dispatch({ type: 'USER_RECIEVED', user: data });
                }
                dispatch({ type: 'USER_RECIEVED', user: null });
            })
            .catch(error => {
                dispatch({ type: 'USER_RECIEVED', user: null })
            });

        dispatch({ type: 'USER_REQUESTED' });
    }

}

export const reducer: Reducer<HomeState> = (state: HomeState | undefined, incomingAction: Action): HomeState => {
    if (state === undefined) {
        return { user: null, isLoading: false };
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'USER_RECIEVED':
            return { user: action.user, isLoading: false };
        case 'USER_REQUESTED':
            return {user: state.user, isLoading: true}
        default:
            return state;
    }
};