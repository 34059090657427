import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as WorksheetWIPStore from '../store/WorksheetWIP';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar, FaCaretDown, FaCalendarAlt, FaRegCalendar, FaChevronLeft, FaChevronDown, FaAngleDown, FaEdit, FaTrashAlt, FaEllipsisH } from 'react-icons/fa';
// @ts-ignore
import { Loader } from 'react-overlay-loader';
import Select from 'react-select';
import 'react-overlay-loader/styles.css';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { render } from 'react-dom';

import { IoCreateOutline, IoTrashOutline, IoCloseOutline , IoFunnelOutline } from 'react-icons/io5'
import { FieldProps } from "react-final-form"
import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineFile, AiOutlineCloseCircle } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import DeleteModal from './DeleteModal';

type WorksheetWIPProps =
    WorksheetWIPStore.WorksheetWIPState &
    typeof WorksheetWIPStore.actionCreators &
    RouteComponentProps<{}> &
    IProps;

interface IProps {
    recordId: number;
    isEditable: boolean;
    isGetStarted: boolean;
    // any other props that come into the component
}

// @ts-ignore
const FileField: React.FC<FieldProps<FileList, HTMLInputElement>> = ({ name, ...props }: any) => {

    const [fileCount, setCount] = useState({ fileCount: 0 });

    return (<Field name={name}>
        {({ input: { value, onChange, ...input } }) => {


            const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
                onChange(target.files) // instead of the default target.value
            }
            return (
                <>

                    <input accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.JPG,.JPEG,.PNG,.PDF,.DOC,.DOCX" {...input} type="file" multiple
                        onChange={(event: any) => { setCount({ fileCount: event.target.files.length }); handleChange(event); }} className="custom-file-input" {...props} id="files" aria-describedby="fileHelp" />
                    <label className="custom-file-label" htmlFor="files">
                        Files (optional)&nbsp;&nbsp;
                   {fileCount.fileCount === 0 ? "" : fileCount.fileCount.toString() + " files selected"}

                    </label>



                </>
            )
        }}
    </Field>)
}

// @ts-ignore
const PhotoField: React.FC<FieldProps<FileList, HTMLInputElement>> = ({ name, ...props }: any) => {

    const [fileName, setName] = useState({ fileName: "" });
    useEffect(() => {
        console.log("in use effect");
        setName({ fileName: props.fileName != undefined ? props.fileName.length > 15 ? props.fileName.substring(0, 12) + '...' : props.fileName : "" });
    }, [props.fileName]);

    return (<Field name={name}>
        {({ input: { value, onChange, ...input } }) => {


            const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
                onChange(target.files) // instead of the default target.value
            }
            var handleToUpdate = props.handleToUpdate;

            return (
                <>

                    <input accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" {...input} type="file"
                        onChange={(event: any) => {
                            setName({ fileName: event.target.files.length > 0 ? event.target.files[0].name : "" });
                            handleChange(event);
                        }}
                        className="custom-file-input" {...props} id="cameraFiles" aria-describedby="fileHelp" />
                    <label className="custom-file-label custom-file-label-camera" htmlFor="cameraFiles">
                        Photo (optional)&nbsp;&nbsp;
                   {fileName.fileName === "" ? "" : <span> <span className="photo_inbound">
                            {fileName.fileName.length > 15 ? fileName.fileName.substring(0, 12) + '...' : fileName.fileName}
                        </span>
                        </span>
                        }
                    </label>
                    {fileName.fileName != "" &&
                        <span className="fa fa-trash-alt fa-lg" onClick=
                            {(event: any) => {
                                setName({ fileName: "" });
                                handleChange(event);
                                handleToUpdate(fileName);
                            }}

                        ></span>}

                </>
            )
        }}
    </Field >)
}


const ReactSelectAdapter = ({ input, ...rest }: any) => (
    <Select className=""
        defaultValue={{ value: "0", label: "Select" }}
        styles={customStyles} {...input} {...rest} />
)


const customStyles = {
    control: (base: any, state: { isFocused: any; }) => ({
        ...base,
        background: "#fff",
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#f47d30" : "#d6d8e7",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#f47d30" : "#d6d8e7"
        }
    }),
    option: (base: any, state: { isSelected: any; }) => ({
        ...base,
        backgroundColor: state.isSelected ? "#ffd0bc" : "#fff",
        color: state.isSelected ? "#f47d30" : "#636569",
        "&:hover": {
            // Overwrittes the different states of border
            backgroundColor: "#ffd0bc",
            color: "#f47d30"
        }
    }),
    menu: (base: any) => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
        // kill the gap
        marginTop: 0,
        paddingLeft: 10,
    })
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#121212',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Poppins',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
};

const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);


class WorksheetWIP extends React.PureComponent<WorksheetWIPProps> {
    constructor(props: any) {
        super(props);
        this.handleToUpdate = this.handleToUpdate.bind(this);

    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate(prevProps: any) {
        if (prevProps.recordId !== this.props.tailRecordId) {
            this.ensureDataFetched();
        }
    }

    private ensureDataFetched() {
        this.props.FetchTailDetails(this.props.recordId);
    }
    private AddMXREQUEST() {
        this.props.ShowAddMxR();
    }
    private AddInboundItem() {
        this.props.ShowInBound();
    }
    handleToUpdate(someArg: any) {
        this.props.UpdateDeleteStatus();
    }

    onFileChange = (event: any) => {
        this.props.select(event.target.files);
    };

    onDelete(file: File, filename: string) {
        this.props.deleteFile(file, filename)
    }

    onInputClick = (event: any) => {
        event.target.value = ''
    }

    openFile = (fileWrapper: any) => {
        if (fileWrapper.uri.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
            this.props.selectImage({ fileName: fileWrapper.uri, imageUri: fileWrapper.imageUri })
        }
        else if (fileWrapper.uri.match(/.(xls|XLS|xlsx|XLSX)$/i)) {
            this.downloadFile(fileWrapper.imageUri);
        }
    };

    downloadFile = (url: any) => {
        if (url != "") {
            const element = document.createElement("a");
            element.href = url;
            document.body.appendChild(element);
            element.click();
            element.remove();
        }
    }
    public render() {
        return (
            <React.Fragment>
                {(this.props.isLoading) &&
                    <Loader fullPage={true} loading={this.props.isLoading} />
                }
                <br />
                <div className="hide-from-mobile">
                    {this.props.isGetStarted && this.renderInboundItems()}
                </div>
                <div className="hide-in-big-screen show-in-mobile">
                    {this.props.isGetStarted && this.renderInboundItemsInMobile()}
                </div>
                <div className="hide-from-mobile">
                    {this.renderMDDRrequests()}
                </div>
                <div className="hide-in-big-screen show-in-mobile">
                    {this.renderMDDRrequestsInMobile()}
                </div>
                <div className="hide-from-mobile hide-from-tablet">
                    {this.renderMxrequests()}
                </div>
                <div className="hide-in-big-screen hide-from-mobile show-in-tablet">
                    {this.renderMxrequestsInTablet()}
                </div>
                <div className="hide-in-big-screen show-in-mobile">
                    {this.renderMxrequestsInMobile()}
                </div>
                <DeleteModal
                    message={'This record will be deleted'}
                    isOpen={this.props.showDeleteMxrConfirmation}
                    onConfirm={this.props.DeleteMxR}
                    onClose={this.props.CloseDeleteMxrConfirmation}
                />
                <Modal centered={true} className="partsModal mx-Mobile-Modal" isOpen={this.props.showAddMx}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseMxR(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        {this.props.selectedTail &&
                            <div className="header-title-popup text-align-center">
                                {this.props.selectedMxR.id === 0 ? "Add New MX Request" : "Edit MX Request"}
                            </div>
                        }

                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className={this.props.selectedMxR.id === 0 ? "col-12" : "col-8 mxrequest-form-div"}>
                                <Form
                                    onSubmit={this.props.SaveMxR}
                                    initialValues={this.props.selectedMxR}
                                    validate={values => {
                                        let errors = {} as any;
                                        if (!values.description) {
                                            errors.description = 'Required'
                                        }
                                        if (values.mxRequestTypesValues.id === 0 || values.mxRequestTypesValues.value === 0) {
                                            errors.mxRequestTypesValues = 'Required'
                                        }
                                        if (values.mxRequestStatusValues.id === 0 || values.mxRequestStatusValues.value === 0) {
                                            errors.mxRequestStatusValues = 'Required'
                                        }
                                        console.log('worksheetWIP', values);
                                        if (values.mxRequestPlanningstatusvalues.id === 0 || values.mxRequestPlanningstatusvalues.value === 0) {
                                            errors.mxRequestPlanningstatusvalues = 'Required'
                                        }
                                        if (values.mxRequestStatusValues.value === 3 && (!values.mxRequestDeferredReasonValues.value
                                            || values.mxRequestDeferredReasonValues.value === 0 || values.mxRequestDeferredReasonValues.value === undefined)) {
                                            errors.mxRequestDeferredReasonValues = 'Required'
                                        }

                                        return errors
                                    }}
                                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="partsForm">
                                                <div className="">
                                                    <div>
                                                        <label>Type<span>*</span></label>

                                                        <Field
                                                            name="mxRequestTypesValues"
                                                            component={ReactSelectAdapter}


                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <ReactSelectAdapter options={this.props.mxTypes.map((typ: WorksheetWIPStore.MxRequestTypes) => {
                                                                        return { value: typ.id, label: typ.requestText };
                                                                    }
                                                                    )}  {...input}
                                                                    />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>




                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Reference # (optional)</label>
                                                        <Field name="refNumber"
                                                            className="form-control w-100"
                                                            placeholder="Enter reference #"
                                                            component="input">
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Planning Status<span>*</span></label>
                                                        <Field
                                                            name="mxRequestPlanningstatusvalues"
                                                            component={ReactSelectAdapter}
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <ReactSelectAdapter options={this.props.mxPlanningStatus.map((typ: WorksheetWIPStore.MxRequestPlanningStatus) => {
                                                                        return { value: typ.id, label: typ.planningStatus };
                                                                    }
                                                                    )}  {...input}
                                                                    />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Status<span>*</span></label>

                                                        <Field
                                                            name="mxRequestStatusValues"
                                                            component={ReactSelectAdapter}


                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <ReactSelectAdapter options={this.props.mxStatus.map((typ: WorksheetWIPStore.DropDownType) => {
                                                                        return { value: typ.id, label: typ.label };
                                                                    }
                                                                    )}  {...input}
                                                                    />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                {values.mxRequestStatusValues != undefined && (values.mxRequestStatusValues.value == 3 ||
                                                    values.mxRequestStatusValues.id == 3)
                                                    &&
                                                    [
                                                        <div key="mxdeferred">
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <label>Deferred Reason<span>*</span></label>

                                                                    <Field
                                                                        name="mxRequestDeferredReasonValues"
                                                                        component={ReactSelectAdapter}


                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <ReactSelectAdapter options={this.props.mxDeferredReason.map((typ: WorksheetWIPStore.DropDownType) => {
                                                                                    return { value: typ.id, label: typ.label };
                                                                                }
                                                                                )}  {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    ]

                                                }

                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <Field
                                                            name="description"
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <label>Description<span>*</span></label>
                                                                    <textarea className="form-control" rows={5}  {...input}
                                                                        placeholder="Enter your description here..." />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Notes (optional)</label>
                                                        <Field
                                                            className="form-control w-100"
                                                            rows={1}
                                                            name="notes" component="textarea" placeholder="Enter your notes here..." />
                                                    </div>
                                                </div>
                                                <div>
                                                    {/*<FileField name="files" />*/}
                                                    <input accept=".jpg,.png,.pdf,.doc,.docx,.xls,.xlsx,.gif,.JPG,.PNG,.PDF,.DOC,.DOCX,.XLS,.XLSX,.GIF" type="file" multiple
                                                        onChange={this.onFileChange} onClick={this.onInputClick} className="custom-file-input" id="files" aria-describedby="fileHelp" />
                                                    <label className="custom-file-label" htmlFor="files">
                                                        Files (optional)&nbsp;&nbsp;

                                                    </label>
                                                </div>
                                                {this.renderThumbnails()}
                                                <div>&nbsp;</div>
                                                <div className="hide-in-big-screen show-in-mobile">
                                                    {this.props.selectedMxR.id > 0 && <div className={this.props.selectedMxR.id === 0 ? "" : ""}>
                                                        <div className="">
                                                            <div>
                                                                <label className="log-label">Log</label>
                                                            </div>
                                                            <div className="div-logs-worksheet">
                                                                {(this.props.selectedTail != null)
                                                                    && this.props.selectedTail.tail.mxrequests != undefined
                                                                    && this.props.selectedTail.tail.mxrequests.filter((x: any) => x.id == this.props.selectedMxR.id)[0] != undefined
                                                                    && this.props.selectedTail.tail.mxrequests.filter((x: any) => x.id == this.props.selectedMxR.id)[0].mxrequestsLogs.map((req: any) =>
                                                                        <div key={"mobile_mxcommentTime_" + req.mxrequestLogId}>
                                                                            <p className="hours-ago">{req.logTime}</p>
                                                                            <p className="logs-description">{req.description}</p>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <br />
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="text-right-align">

                                                    <button className="round-empty-button  cancel-popover-button" type="reset" onClick={() => { this.props.CloseMxR(); }}>CANCEL</button>
                                                    <span>&nbsp;</span>

                                                    <button className={this.props.selectedMxR.id === 0 ? "round-fill-button border-0 save-popover-button save-button-width-add" : "round-fill-button border-0 save-popover-button"}
                                                        type="submit" disabled={submitting}>
                                                        {this.props.selectedMxR.id === 0 ? "SAVE" : "SAVE CHANGES"}
                                                    </button>

                                                </div>

                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                            {this.props.selectedMxR.id > 0 && <div className={this.props.selectedMxR.id === 0 ? "" : "col-4  mxrequest-form-div"}>
                                <div className="hide-from-mobile">
                                    <div>
                                        <label className="log-label">Log</label>
                                    </div>
                                    <div className="div-logs-worksheet">
                                        {(this.props.selectedTail != null)
                                            && this.props.selectedTail.tail.mxrequests != undefined
                                            && this.props.selectedTail.tail.mxrequests.filter((x: any) => x.id == this.props.selectedMxR.id)[0] != undefined
                                            && this.props.selectedTail.tail.mxrequests.filter((x: any) => x.id == this.props.selectedMxR.id)[0].mxrequestsLogs.map((req: any) =>
                                                <div key={"mxcommentTime_" + req.mxrequestLogId}>
                                                    <span className="hours-ago">{req.logTime}</span>
                                                    <br />
                                                    <span className="logs-description">{req.description}</span>
                                                    <br />
                                                </div>
                                            )}
                                    </div>
                                    <br />
                                </div>
                            </div>
                            }
                        </div>

                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>

                <Modal centered={true} className="partsModal mx-Mobile-Modal" isOpen={this.props.showPlannedItem}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.ClosePlannedItem(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        {this.props.selectedTail &&
                            <div className="header-title-popup text-align-center">
                                {"Edit planned item"}
                            </div>
                        }

                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-8 mxrequest-form-div">
                                <Form key={"planned_" + this.props.selectedPlannedItem.id}
                                    onSubmit={this.props.SavePlannedItem}
                                    initialValues={this.props.selectedPlannedItem}
                                    validate={values => {
                                        let errors = {} as any;
                                        if (!values.defect_description) {
                                            errors.defect_description = 'Required'
                                        }

                                        if ((values.mddrstatusValues.value === 2 || values.mddrstatusValues.id === 2) &&
                                            !values.ataCode
                                        ) {
                                            errors.ataCode = 'Required'
                                        }
                                        if ((values.mddrstatusValues.value === 2 || values.mddrstatusValues.id === 2) &&
                                            !values.correctiveAction
                                        ) {
                                            errors.correctiveAction = 'Required'
                                        }

                                        if ((values.mddrstatusValues.value === 3 || values.mddrstatusValues.id === 3) &&
                                            !values.deferralReason
                                        ) {
                                            errors.deferralReason = 'Required'
                                        }

                                        if ((values.mddrstatusValues.value === 3 || values.mddrstatusValues.id === 3) &&
                                            (values.plannedDeferredTypeValues.value === 0 || values.plannedDeferredTypeValues.id === 0)
                                        ) {
                                            errors.plannedDeferredTypeValues = 'Required'
                                        }
                                        if ((values.mddrstatusValues.value === 3 || values.mddrstatusValues.id === 3) && (values.plannedDeferredTypeValues.value === 1 || values.plannedDeferredTypeValues.id === 1) &&
                                            !values.mel
                                        ) {
                                            errors.mel = 'Required'
                                        }

                                        return errors
                                    }}
                                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="partsForm">
                                                <div className="">
                                                    <div>
                                                        <Field
                                                            name="defect_description"
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <label>Description<span>*</span></label>
                                                                    <textarea className="form-control" rows={5}  {...input}
                                                                        placeholder="Enter your description here..." />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Planning<span>*</span></label>
                                                        <Field
                                                            className="form-control w-100"
                                                            rows={1}
                                                            name="status" component="textarea" disabled />
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Status<span>*</span></label>

                                                        <Field
                                                            name="mddrstatusValues"
                                                            component={ReactSelectAdapter}
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <ReactSelectAdapter options={this.props.mddrStatus.map((typ: WorksheetWIPStore.DropDownType) => {
                                                                        return { value: typ.id, label: typ.label };
                                                                    }
                                                                    )}  {...input}
                                                                    />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                {values.mddrstatusValues != undefined && (values.mddrstatusValues.value == 2 || values.mddrstatusValues.id == 2) &&
                                                    [
                                                        <div key="closedPlanning">
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="correctiveAction"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Corrective Action<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={3}  {...input} />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="ataCode"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>ATA Code<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={1} {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ]
                                                }

                                                {values.mddrstatusValues != undefined && (values.mddrstatusValues.value == 3 || values.mddrstatusValues.id == 3) &&
                                                    [
                                                        <div key="deferredPlanning">
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="deferralReason"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Deferral Reason<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={3} {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <label>Deferred Type<span>*</span></label>

                                                                    <Field
                                                                        name="plannedDeferredTypeValues"
                                                                        component={ReactSelectAdapter}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <ReactSelectAdapter options={this.props.deferredType.map((typ: WorksheetWIPStore.DropDownType) => {
                                                                                    return { value: typ.id, label: typ.label };
                                                                                }
                                                                                )}  {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ]}
                                                {(values.mddrstatusValues.value == 3 || values.mddrstatusValues.id == 3) &&
                                                    values.plannedDeferredTypeValues != undefined && (values.plannedDeferredTypeValues.value == 1 || values.plannedDeferredTypeValues.id == 1) &&
                                                    [
                                                        <div key="melPlanning">
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="mel"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>MEL<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={1} {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ]
                                                }


                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>MDDR<span>*</span></label>
                                                        <Field
                                                            className="form-control w-100"
                                                            rows={1}
                                                            name="mddr" component="textarea" disabled />
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Days</label>
                                                        <Field
                                                            className="form-control w-100"
                                                            rows={1}
                                                            name="days" component="textarea" disabled />
                                                    </div>
                                                </div>


                                                <div>&nbsp;</div>

                                                <div className="hide-in-big-screen show-in-mobile">
                                                    <div className="">
                                                        <div>
                                                            <label className="log-label">Log</label>
                                                        </div>
                                                        <div className="div-logs-worksheet">
                                                            {(this.props.selectedTail != null)
                                                                && this.props.selectedTail.defects != undefined
                                                                && this.props.selectedTail.defects.filter((x: any) => x.id == this.props.selectedPlannedItem.id)[0] != undefined
                                                                && this.props.selectedTail.defects.filter((x: any) => x.id == this.props.selectedPlannedItem.id)[0].mddrlogs.map((req: any) =>
                                                                    <div key={"commentTime_" + req.mddrlogId}>
                                                                        <p className="hours-ago">{req.logTime}</p>
                                                                        <p className="logs-description">{req.description}</p>
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>

                                                <div>&nbsp;</div>

                                                <div className="text-right-align">
                                                    <button className="round-empty-button cancel-popover-button" type="reset" onClick={() => { this.props.ClosePlannedItem(); }}>CANCEL</button>
                                                    <span>&nbsp;</span>
                                                    <button className={this.props.selectedPlannedItem.id === 0 ? "round-fill-button border-0 save-popover-button save-button-width-add" : "round-fill-button border-0 save-popover-button"}
                                                        type="submit" disabled={submitting}>
                                                        {this.props.selectedPlannedItem.id === 0 ? "SAVE" : "SAVE CHANGES"}
                                                    </button>
                                                </div>

                                                <div>



                                                </div>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                            <div className="col-4  mxrequest-form-div hide-from-mobile">
                                <div className="">
                                    <div>
                                        <label className="log-label">Log</label>
                                    </div>
                                    <div className="div-logs-worksheet">
                                        {(this.props.selectedTail != null)
                                            && this.props.selectedTail.defects != undefined
                                            && this.props.selectedTail.defects.filter((x: any) => x.id == this.props.selectedPlannedItem.id)[0] != undefined
                                            && this.props.selectedTail.defects.filter((x: any) => x.id == this.props.selectedPlannedItem.id)[0].mddrlogs.map((req: any) =>
                                                <div key={"commentTime_" + req.mddrlogId}>
                                                    <span className="hours-ago">{req.logTime}</span>
                                                    <br />
                                                    <span className="logs-description">{req.description}</span>
                                                    <br />
                                                </div>
                                            )}
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <DeleteModal
                    message={'This record will be deleted'}
                    isOpen={this.props.showDeleteInboundItemConfirmation}
                    onConfirm={this.props.DeleteInboundItem}
                    onClose={this.props.CloseDeleteIbItemConfirmation}
                />

                <Modal centered={true} className="partsModal mx-Mobile-Modal" isOpen={this.props.showInBound}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseInBound(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        {this.props.selectedTail &&
                            <div className="header-title-popup text-align-center">

                                {this.props.selectedInboundItem.id === 0 ? "Add New Inbound Item" : "Edit Inbound Item"}

                            </div>
                        }

                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className={this.props.selectedInboundItem.id === 0 ? "col-12" : "col-8 mxrequest-form-div"}>

                                <Form key={"addinbound_" + this.props.selectedInboundItem.id}
                                    onSubmit={this.props.SaveInBoundItem}
                                    initialValues={this.props.selectedInboundItem}
                                    validate={values => {
                                        let errors = {} as any;
                                        if (!values.description) {
                                            errors.description = 'Required'
                                        }

                                        if ((values.typeValues.value === 0 || values.typeValues.id === 0)
                                        ) {
                                            errors.typeValues = 'Required'
                                        }

                                        if ((values.inBoundStatusValues.value === 0 || values.inBoundStatusValues.id === 0)
                                        ) {
                                            errors.inBoundStatusValues = 'Required'
                                        }
                                        if ((values.inBoundStatusValues.value === 2 || values.inBoundStatusValues.id === 2) &&
                                            !values.ataCode
                                        ) {
                                            errors.ataCode = 'Required'
                                        }
                                        if ((values.inBoundStatusValues.value === 2 || values.inBoundStatusValues.id === 2) &&
                                            !values.correctiveAction
                                        ) {
                                            errors.correctiveAction = 'Required'
                                        }

                                        if ((values.inBoundStatusValues.value === 3 || values.inBoundStatusValues.id === 3) &&
                                            !values.deferralReason
                                        ) {
                                            errors.deferralReason = 'Required'
                                        }

                                        if (values.inBoundStatusValues != undefined && (values.inBoundStatusValues.value === 3 || values.inBoundStatusValues.id === 3) &&
                                            (!values.inBoundDeferredTypeValues || values.inBoundDeferredTypeValues.value === 0 || values.inBoundDeferredTypeValues.id === 0)
                                        ) {
                                            errors.inBoundDeferredTypeValues = 'Required'
                                        }
                                        if ((values.inBoundStatusValues.value === 3 || values.inBoundStatusValues.id === 3) && (values.inBoundDeferredTypeValues.value === 1 || values.inBoundDeferredTypeValues.id === 1) &&
                                            !values.mel
                                        ) {
                                            errors.mel = 'Required'
                                        }

                                        return errors
                                    }}
                                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="partsForm">
                                                <div className="">
                                                    <div>
                                                        <Field
                                                            name="description"
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <label>Description<span>*</span></label>
                                                                    <textarea className="form-control" rows={5}  {...input}
                                                                        placeholder="Enter your description here..." />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Type<span>*</span></label>

                                                        <Field
                                                            name="typeValues"
                                                            component={ReactSelectAdapter}
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <ReactSelectAdapter options={this.props.inBoundType.map((typ: WorksheetWIPStore.DropDownType) => {
                                                                        return { value: typ.id, label: typ.label };
                                                                    }
                                                                    )}  {...input}
                                                                    />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Status<span>*</span></label>

                                                        <Field
                                                            name="inBoundStatusValues"
                                                            component={ReactSelectAdapter}
                                                        >
                                                            {({ input, meta }) => (
                                                                <div>
                                                                    <ReactSelectAdapter options={this.props.mddrStatus.map((typ: WorksheetWIPStore.DropDownType) => {
                                                                        return { value: typ.id, label: typ.label };
                                                                    }
                                                                    )}  {...input}
                                                                    />
                                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                                {values.inBoundStatusValues != undefined && (values.inBoundStatusValues.value == 2 || values.inBoundStatusValues.id == 2) &&
                                                    [
                                                        <div key="closedtype">
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="correctiveAction"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Corrective Action<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={3}  {...input} />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="ataCode"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>ATA Code<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={1} {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ]
                                                }

                                                {values.inBoundStatusValues != undefined && (values.inBoundStatusValues.value == 3 || values.inBoundStatusValues.id == 3) &&
                                                    [
                                                        <div key="deferredtype">
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="deferralReason"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Deferral Reason<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={3}  {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <label>Deferred Type<span>*</span></label>

                                                                    <Field
                                                                        name="inBoundDeferredTypeValues"
                                                                        component={ReactSelectAdapter}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <ReactSelectAdapter options={this.props.deferredType.map((typ: WorksheetWIPStore.DropDownType) => {
                                                                                    return { value: typ.id, label: typ.label };
                                                                                }
                                                                                )}  {...input}
                                                                                />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ]}
                                                {(values.inBoundStatusValues.value == 3 || values.inBoundStatusValues.id == 3) &&
                                                    values.inBoundDeferredTypeValues != undefined && (values.inBoundDeferredTypeValues.value == 1 || values.inBoundDeferredTypeValues.id == 1) &&
                                                    [
                                                        <div key="mel">
                                                            <div>&nbsp;</div>
                                                            <div className="">
                                                                <div>
                                                                    <Field
                                                                        name="mel"
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>MEL<span>*</span></label>
                                                                                <textarea
                                                                                    className="form-control w-100"
                                                                                    rows={1}  {...input} />
                                                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ]
                                                }

                                                <div>&nbsp;</div>
                                                <div className="">
                                                    <div>
                                                        <label>Comments (optional)</label>
                                                        <Field
                                                            className="form-control w-100"
                                                            rows={1} placeholder="Enter your comments here..."
                                                            name="notes" component="textarea" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <PhotoField name="files" {...this.props.selectedInboundItem}
                                                        handleToUpdate={this.handleToUpdate}
                                                    />
                                                </div>
                                                <div>&nbsp;</div>
                                                <div className="hide-in-big-screen show-in-mobile">
                                                    {this.props.selectedInboundItem.id > 0 && <div className={this.props.selectedInboundItem.id === 0 ? "" : ""}>
                                                        <div className="">
                                                            <div>
                                                                <label className="log-label">Log</label>
                                                            </div>
                                                            <div className="div-logs-worksheet">
                                                                {(this.props.selectedTail != null)
                                                                    && this.props.selectedTail.tail.inboundItems != undefined
                                                                    && this.props.selectedTail.tail.inboundItems.filter((x: any) => x.id == this.props.selectedInboundItem.id)[0] != undefined
                                                                    && this.props.selectedTail.tail.inboundItems.filter((x: any) => x.id == this.props.selectedInboundItem.id)[0].inBoundLogs.map((req: any) =>
                                                                        <div key={"mobile_inboundTime_" + req.inBoundLogId}>
                                                                            <p className="hours-ago">{req.logTime}</p>
                                                                            <p className="logs-description">{req.description}</p>

                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <br />
                                                        </div>
                                                    </div>
                                                    }
                                                </div>

                                                <div className="text-right-align">
                                                    <button className="round-empty-button cancel-popover-button" type="reset" onClick={() => { this.props.CloseInBound(); }}>CANCEL</button>
                                                    <span>&nbsp;</span>
                                                    <button className={this.props.selectedInboundItem.id === 0 ? "round-fill-button border-0 save-popover-button save-button-width-add" : "round-fill-button border-0 save-popover-button"}
                                                        type="submit" disabled={submitting}>
                                                        {this.props.selectedInboundItem.id === 0 ? "SAVE" : "SAVE CHANGES"}
                                                    </button>
                                                </div>

                                                <div>



                                                </div>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                            {this.props.selectedInboundItem.id > 0 && <div className={this.props.selectedInboundItem.id === 0 ? "" : "col-4  mxrequest-form-div"}>
                                <div className="hide-from-mobile">
                                    <div>
                                        <label className="log-label">Log</label>
                                    </div>
                                    <div className="div-logs-worksheet">
                                        {(this.props.selectedTail != null)
                                            && this.props.selectedTail.tail.inboundItems != undefined
                                            && this.props.selectedTail.tail.inboundItems.filter((x: any) => x.id == this.props.selectedInboundItem.id)[0] != undefined
                                            && this.props.selectedTail.tail.inboundItems.filter((x: any) => x.id == this.props.selectedInboundItem.id)[0].inBoundLogs.map((req: any) =>
                                                <div key={"inboundTime_" + req.inBoundLogId}>
                                                    <span className="hours-ago">{req.logTime}</span>
                                                    <br />
                                                    <span className="logs-description">{req.description}</span>
                                                    <br />
                                                </div>
                                            )}
                                    </div>
                                    <br />
                                </div>
                            </div>
                            }
                        </div>
                    </ModalBody>
                </Modal>

                <Modal centered={true} className="smallModal diff-hours-modal" isOpen={this.props.showFileValidation}>
                    <ModalHeader className="modalHeader w-100">
                        <div className="flex flex-end w-100">
                            <div className="">
                                <div className="crossButton"
                                    onClick={() => { this.props.CloseImageValidError(); }}><IoCloseOutline /></div>

                            </div>
                        </div>
                        <div className="flex flex-center w-100">
                            <span>INVALID FILES</span>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="flex flex-center w-100">
                            <div className="flex flex-center w-100 text-center">{this.props.errorMessage}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex flex-center w-100">
                            <div className="round-fill-button border-0 diff-hours-ok" onClick={() => { this.props.CloseImageValidError(); }}>OK</div>
                        </div>

                    </ModalFooter>
                </Modal>
                
                    <Modal className="" centered={true} isOpen={this.props.imageOpen}>
                        <ModalHeader className="modalHeader w-100">
                            <div className="flex flex-end w-100">
                                <div className="">
                                    <div className="crossButton"
                                        onClick={() => { this.props.selectImage(null); }}
                                    ><IoCloseOutline /></div>

                                </div>
                            </div>

                            <div className="flex flex-center w-100">
                            {this.props.selectedImage && this.props.selectedImage.fileName}
                            </div>
                        </ModalHeader>
                    <ModalBody>
                        <div>
                            <img src={this.props.selectedImage && this.props.selectedImage.imageUri} width="400px" style={{ margin: 'auto', display: 'block' }}/>                            
                        </div>
                        </ModalBody>
                    <ModalFooter >
                        <br />
                            <div className="flex flex-button w-100">
                                <div className="round-empty-button-popup" onClick={() => { this.props.selectImage(null); }}   >
                                    CANCEL
                                </div>                                                          
                            </div>
                        </ModalFooter>
                    </Modal >
                
            </React.Fragment>
        );
    }
    private renderMxrequests() {
        return (
            <>
                <div className="outerDivMxR">
                    <div className="titleMxR">
                        MX Requests:
                    </div>
                    <div>
                        <div className="flex flex-space-between headerMxR">
                            <div className="planningSubTitle subHeading-wip-list w-20">Ref#</div>
                            <div className="planningSubTitle subHeading-wip-list w-30">Type</div>                            
                            <div className="planningSubTitle subHeading-wip-list w-20">Planning</div>
                            <div className="planningSubTitle subHeading-wip-list w-25">Status</div>
                            {this.props.isEditable &&
                                <div className="planningSubTitle subHeading-wip-list text-center w-8">Edit</div>
                            }
                            {this.props.isEditable &&
                                <div className="planningSubTitle subHeading-wip-list text-right-align w-8">Delete</div>
                            }
                        </div>
                    </div>
                    {this.props.selectedTail != null && this.props.selectedTail.tail.mxrequests.length === 0 &&
                        <div className="worksheetSubTitle text-align-center padding-top-15">
                            No mx request is available.
                            </div>
                    }
                    {this.props.selectedTail != null && this.props.selectedTail.tail.mxrequests.map((req: any) =>
                        <div key={req.id}>
                            <div className="flex flex-space-between rowMxR">
                                <div className="w-20 subheading-wip-data">{req.refNumber}</div>
                                <div className="w-30 subheading-wip-data">{req.mxRequestTypes.requestText}</div>                                
                                <div className="w-20 subheading-wip-data">
                                    <span className={`statusPad-auto ${req.mxRequestPlanningStatusId === 1 ? "statusPadPs" : req.mxRequestPlanningStatusId === 2 ? "statusPad4" : "statusPad2"}`}>
                                        {req.mxrequestPlanningStatus && req.mxrequestPlanningStatus.planningStatus.toUpperCase()}</span>
                                </div>
                                <div className="w-25 subheading-wip-data">
                                    {req.mxrequestStatus != null && req.mxrequestStatus.statusDescription}
                                    {req.mxrequestStatus == null && "Not Started"}
                                </div>
                                {this.props.isEditable &&
                                    <div className="w-8 text-center subheading-wip-data">
                                        <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditMxR(event, req) }} />
                                    </div>
                                }
                                {this.props.isEditable &&
                                    <div className="w-8 text-right-align subheading-wip-data">
                                    <IoTrashOutline className="cursor-pointer" onClick={event => { this.props.ShowDeleteMxrConfirmation(req) }} />
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                    {this.props.isEditable &&
                        <div className="fullFooterButton flex flex-center" onClick={(event) => { this.AddMXREQUEST() }}>
                            + ADD NEW MX REQUESTS</div>
                    }
                </div>
            </>
        );
    }

    private renderMxrequestsInTablet() {
        return (
            <>
                <div className="outerDivMxR">
                    <div className="titleMxR">
                        MX Requests:
                    </div>
                    <div>
                        <div className="flex headerMxR">
                            <div className="planningSubTitle subHeading-wip-list w-10">Ref#</div>
                            <div className="planningSubTitle subHeading-wip-list w-35">Type</div>
                            <div className="planningSubTitle subHeading-wip-list w-20">Planning</div>
                            <div className="planningSubTitle subHeading-wip-list w-15">Status</div>
                            {this.props.isEditable &&
                                <div className="planningSubTitle subHeading-wip-list text-center w-10">Edit</div>
                            }
                            {this.props.isEditable &&
                                <div className="planningSubTitle subHeading-wip-list text-right-align w-10">Delete</div>
                            }
                        </div>
                    </div>
                    {this.props.selectedTail != null && this.props.selectedTail.tail.mxrequests.length === 0 &&
                        <div className="worksheetSubTitle text-align-center padding-top-15">
                            No mx request is available.
                        </div>
                    }
                    {this.props.selectedTail != null && this.props.selectedTail.tail.mxrequests.map((req: any) =>
                        <div key={req.id}>
                            <div className="flex rowMxR">
                                <div className="w-10 subheading-wip-data">{req.refNumber}</div>
                                <div className="w-35 subheading-wip-data">{req.mxRequestTypes.requestText}</div>
                                <div className="w-20 subheading-wip-data">
                                    <span className={`statusPad-auto ${req.mxRequestPlanningStatusId === 1 ? "statusPadPs" : req.mxRequestPlanningStatusId === 2 ? "statusPad4" : "statusPad2"}`}>
                                        {req.mxrequestPlanningStatus && req.mxrequestPlanningStatus.planningStatus.toUpperCase()}</span>
                                </div>
                                <div className="w-15 subheading-wip-data">
                                    {req.mxrequestStatus != null && req.mxrequestStatus.statusDescription}
                                    {req.mxrequestStatus == null && "Not Started"}
                                </div>
                                {this.props.isEditable &&
                                    <div className="w-10 text-center subheading-wip-data">
                                        <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditMxR(event, req) }} />
                                    </div>
                                }
                                {this.props.isEditable &&
                                    <div className="w-10 text-center subheading-wip-data">
                                        <IoTrashOutline className="cursor-pointer" onClick={event => { this.props.ShowDeleteMxrConfirmation(req) }} />
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                    {this.props.isEditable &&
                        <div className="fullFooterButton flex flex-center" onClick={(event) => { this.AddMXREQUEST() }}>
                            + ADD NEW MX REQUESTS</div>
                    }
                </div>
            </>
        );
    }

    private renderMxrequestsInMobile() {
        return (
            <>
                <div className="outerDivMxR outerDivMxWip">
                    <div className="flex flex-space-between headerMxR heading-wip ">
                        MX Requests
                        </div>
                    {this.props.selectedTail != null && this.props.selectedTail.tail.mxrequests.length === 0 &&
                        <div className="planningSubTitle text-align-center padding-top-15">
                            No mx request is available.
                            </div>
                    }
                    {this.props.selectedTail != null && this.props.selectedTail.tail.mxrequests.map((req: any) =>
                        <div key={"mobile_mxrequest_" + req.id}>
                            <div className="">
                                <div className="inner-div-WIP flex"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx col-5">Ref#</label><label className="subheading-wip-data text-align-right text-right-align col-7">{req.refNumber}</label></div>
                                <div className="inner-div-WIP flex"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx col-5">Type</label> <label className="subheading-wip-data text-align-right text-right-align col-7">{req.mxRequestTypes.requestText}</label></div>
                                <div className="inner-div-WIP flex"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx col-5">Planning</label>
                                    <div className="col-7 text-align-right">
                                        <label className={`statusPad ${req.mxRequestPlanningStatusId === 1 ? "statusPad-Planned" : req.mxRequestPlanningStatusId === 2 ? "statusPad-Rescheduled" : "statusPad-Cancelled"}`}>{req.mxrequestPlanningStatus && req.mxrequestPlanningStatus.planningStatus.toUpperCase()}</label>
                                    </div>
                                </div>
                                <div className="inner-div-WIP flex"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx col-5">Status</label><label className="subheading-wip-data text-align-right text-right-align col-7">
                                    {req.mxrequestStatus != null && req.mxrequestStatus.statusDescription}</label>
                                </div>
                                {this.props.isEditable && <div className="inner-div-WIP flex"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx col-5">
                                    Edit</label><label className="subheading-wip-data text-align-right text-right-align col-7">
                                        <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditMxR(event, req) }} />
                                    </label>
                                </div>}
                                {this.props.isEditable && <div className="inner-div-WIP flex"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx col-5">Delete
                                </label><label className="subheading-wip-data text-align-right text-right-align col-7">
                                        <IoTrashOutline className="cursor-pointer" onClick={event => { this.props.ShowDeleteMxrConfirmation(req) }} />
                                    </label>
                                </div>}<br />

                            </div>
                        </div>
                    )}
                    {this.props.isEditable &&
                        <div className="fullFooterButton flex flex-center" onClick={(event) => { this.AddMXREQUEST() }}><span className="addmxrequest-button">+ ADD NEW MX REQUESTS</span></div>

                    }</div>
            </>
        );
    }

    private renderMDDRrequests() {
        return (
            <>
                <div className="outerDivMxR">
                    <div className="titleMxR">
                        Planned Items:
                    </div>
                    <div>
                        <div className="flex flex-space-between headerMxR">
                            <div className="planningSubTitle subHeading-wip-list w-10">MDDR</div>
                            <div className="planningSubTitle subHeading-wip-list w-35">Description</div>
                            <div className="planningSubTitle subHeading-wip-list w-30">Planning</div>
                            <div className="planningSubTitle subHeading-wip-list w-8">Days</div>                           
                            <div className="planningSubTitle subHeading-wip-list w-12">Status</div>
                            
                            {this.props.isEditable && <div className="planningSubTitle subHeading-wip-list w-5">Edit</div>}
                        </div>
                    </div>
                    {this.props.selectedTail != null && this.props.selectedTail.defects.length === 0 &&
                        <div className="worksheetSubTitle text-align-center padding-top-15">
                            No planned item is available.
                            </div>
                    }
                    {this.props.selectedTail != null && this.props.selectedTail.defects.map((mddr: any) =>
                        <div key={"defect_" + mddr.id}>
                            <div className="flex flex-space-between rowMxR height-auto">
                                <div className="subheading-wip-data w-10">{mddr.mddr}</div>
                                <div className="subheading-wip-data w-35">{mddr.defectDescription}</div>
                                <div className="subheading-wip-data w-30">
                                    <span className={"statusPad" + mddr.plannedStatusSubCategory.category.id + " planned-status-padding"}>
                                        {mddr.plannedStatusSubCategory.category.categoryName === "Not Planned to work - reason" ? "NOT PLANNED TO WORK" :
                                            mddr.plannedStatusSubCategory.category.categoryName === "Plan to work" ? "PLANNED TO WORK" : "NOT SCHEDULED"}
                                    </span>
                                </div>
                                <div className="subheading-wip-data w-8">
                                    {mddr.remainDays}                                   
                                </div>                                
                                <div className="subheading-wip-data w-12">
                                    {mddr.status}
                                </div>
                                {this.props.isEditable && <div className="subheading-wip-data w-5">
                                    <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditPlannedItem(event, mddr) }} />
                                </div>}

                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    private renderMDDRrequestsInMobile() {
        return (
            <>
                <div className="outerDivMxR outerDivMxWip">
                    <div className="flex flex-space-between headerMxR heading-wip">
                        Planned Items:
                        </div>
                    {this.props.selectedTail != null && this.props.selectedTail.defects.length === 0 &&
                        <div className="planningSubTitle text-align-center padding-top-15">
                            No planned item is available.
                            </div>
                    }
                    {this.props.selectedTail != null && this.props.selectedTail.defects.map((mddr: any) =>
                        <div key={"planned_" + mddr.id}>
                            <div className="">
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">MDDR</label>
                                    <label className="subheading-wip-data text-align-right text-right-align width-180-mx">{mddr.mddr}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Description</label><br />
                                    <label className=" width-300-mx">{mddr.defectDescription}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Planning</label>
                                    <label className="subheading-wip-data text-align-right text-right-align width-180-mx">
                                        <span className={"statusPad" + mddr.plannedStatusSubCategory.category.id + " planned-status-padding"}>
                                            {mddr.plannedStatusSubCategory.category.categoryName === "Not Planned to work - reason" ? "NOT PLANNED TO WORK" :
                                                mddr.plannedStatusSubCategory.category.categoryName === "Plan to work" ? "PLANNED TO WORK" : "NOT SCHEDULED"}
                                        </span>
                                    </label>
                                </div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Days</label>
                                    <label className="subheading-wip-data text-align-right text-right-align width-180-mx"> {mddr.remainDays}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Status</label>
                                    <label className="subheading-wip-data text-align-right text-right-align width-180-mx">{mddr.status}</label></div>
                                {this.props.isEditable && <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Edit</label><label className="subheading-wip-data text-align-right text-right-align width-180-mx">   <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditPlannedItem(event, mddr) }} />
                                </label></div>}
                            </div>
                            <br />
                        </div>
                    )}
                </div>
            </>
        );
    }

    private renderInboundItems() {
        return (
            <>
                <div className="outerDivMxR">
                    <div className="titleMxR">
                        Inbound Items:
                    </div>
                    <div>
                        <div className="flex flex-space-between headerMxR">
                            <div className="planningSubTitle subHeading-wip-list w-5">#</div>
                            <div className="planningSubTitle subHeading-wip-list w-11">Type</div>
                            <div className="planningSubTitle subHeading-wip-list w-11">Status</div>
                            <div className="planningSubTitle subHeading-wip-list w-26">Description</div>
                            <div className="planningSubTitle subHeading-wip-list w-11">Photo</div>
                            <div className="planningSubTitle subHeading-wip-list w-20">Comment</div>
                            {this.props.isEditable && <div className="planningSubTitle subHeading-wip-list text-center w-8">Edit</div>
                            }
                            {this.props.isEditable && <div className="planningSubTitle subHeading-wip-list text-right-align w-8">Delete</div>
                            }
                        </div>
                    </div>
                    {this.props.selectedTail != null && this.props.selectedTail.tail.inboundItems.length === 0 &&
                        <div className="worksheetSubTitle text-align-center padding-top-15">
                            No inbound item is available.
                            </div>
                    }
                    {this.props.selectedTail != null && this.props.selectedTail.tail.inboundItems.map((req: any) =>
                        <div key={"inbound_" + req.id}>
                            <div className="flex flex-space-between rowMxR">
                                <div className="w-5 subheading-wip-data">{req.ref}</div>
                                <div className="w-11 subheading-wip-data">{req.type != null && req.type.typeDescription}</div>
                                <div className="w-11 subheading-wip-data">
                                    {req.inBoundStatus != null && req.inBoundStatus.statusDescription}
                                </div>
                                <div className="w-26 subheading-wip-data padding-right-40">
                                    {req.description}
                                </div>
                                <div className="w-11 subheading-wip-data">
                                    {
                                        req.inBoundFiles.map((file: any) =>
                                            <div key={"file_" + file.id} onClick={() => { this.props.selectImage(file); }}>
                                                <img className="cursor-pointer" src={file.imageUri} width="42px" />
                                                {req.inBoundFiles.length > 1 && <br />}
                                            </div>
                                        )}
                                </div>
                                <div className="w-20 subheading-wip-data">{req.notes == null ? "" : req.notes}</div>
                                {this.props.isEditable && <div className="w-8 text-center subheading-wip-data">
                                    <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditInBound(event, req) }} />                                    
                                </div>}
                                {this.props.isEditable && <div className="w-8 text-right-align subheading-wip-data">
                                    <IoTrashOutline className="cursor-pointer" onClick={event => { this.props.ShowDeleteIbItemConfirmation(req) }} />
                                </div>}
                            </div>
                        </div>
                    )}
                    {this.props.isEditable &&
                        <div className="fullFooterButton flex flex-center" onClick={(event) => { this.AddInboundItem() }}>
                            + ADD INBOUND ITEM</div>
                    }
                </div>
            </>
        );
    }

    private renderInboundItemsInMobile() {
        return (
            <>
                <div className="outerDivMxR outerDivMxWip">
                    <div className="flex flex-space-between headerMxR heading-wip ">
                        Inbound Items:
                        </div>
                    {this.props.selectedTail != null && this.props.selectedTail.tail.inboundItems.length === 0 &&
                        <div className="planningSubTitle  text-align-center padding-top-15">
                            No inbound item is available.
                            </div>
                    }
                    {this.props.selectedTail != null && this.props.selectedTail.tail.inboundItems.map((req: any) =>
                        <div key={"mobile_inbound_" + req.id}>
                            <div className="">
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">#</label><label className="subheading-wip-data text-align-right text-right-align width-180-mx">{req.ref}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Type</label> <label className="subheading-wip-data text-align-right text-right-align width-180-mx">{req.type != null && req.type.typeDescription}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Status</label><label className=" subheading-wip-data text-align-right text-right-align width-180-mx">
                                    {req.inBoundStatus != null && req.inBoundStatus.statusDescription}</label>
                                </div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Description</label><label className="subheading-wip-data text-align-right text-right-align  width-180-mx">{req.description}</label></div>
                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Photo</label> <label className="subheading-wip-data text-align-right text-right-align width-180-mx">
                                    {
                                        req.inBoundFiles.map((file: any) =>
                                            <div key={"file_" + file.id}>
                                                <img src={file.imageUri} width="42px" />
                                                {req.inBoundFiles.length > 1 && <br />}
                                            </div>
                                        )}
                                </label></div>

                                <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Comment</label><label className="subheading-wip-data text-align-right text-right-align width-180-mx">{req.notes == null ? "" : req.notes}</label></div>
                                {this.props.isEditable && <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Edit</label> <label className="subheading-wip-data text-align-right text-right-align  width-180-mx">
                                    <IoCreateOutline className="cursor-pointer" onClick={event => { this.props.EditInBound(event, req) }} />
                                </label></div>}
                                {this.props.isEditable && <div className="inner-div-WIP"><label className="planningSubTitle subHeading-wip-list text-align-left width-120-mx">Delete
                                </label><label className="subheading-wip-data text-align-right text-right-align width-180-mx">
                                        <IoTrashOutline className="cursor-pointer" onClick={event => { this.props.ShowDeleteIbItemConfirmation(req) }} />
                                    </label>
                                </div>}<br />

                            </div>
                        </div>
                    )}
                    {this.props.isEditable &&
                        <div className="fullFooterButton flex flex-center" onClick={(event) => { this.AddInboundItem() }}><span className="addmxrequest-button">+ ADD INBOUND ITEM</span></div>
                    }
                </div>
            </>
        );
    }
    private renderThumbnails() {
        return (
            <>
                {this.props.mxRequestUploadedFiles.map((fileWrapper) => (
                <div>
                    
                    <div className="p-2 file-uploaded" key={fileWrapper.id}>
                        <AiOutlineFile className="file-uploaded-icon" />
                        {fileWrapper.uri}
                            {(fileWrapper.uri.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF|xls|XLS|xlsx|XLSX)$/i)) && fileWrapper.imageUri &&
                                <a className="viewButtonMx cursor-pointer" onClick={() => { this.openFile(fileWrapper); }}>
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg>

                            </a>
                            }
                            {(fileWrapper.uri.match(/.(pdf|PDF)$/i)) && fileWrapper.imageUri &&
                                <a className="viewButtonMx cursor-pointer" href={fileWrapper.imageUri} target="_blank">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg>

                                </a>
                            }
                            {(fileWrapper.uri.match(/.(doc|DOC|docx|DOCX)$/i)) && fileWrapper.imageUri &&
                                <a className="viewButtonMx cursor-pointer" href={"ms-word:ofe|u|" + fileWrapper.imageUri}>
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path><circle cx="256" cy="256" r="80" fill="none" stroke-miterlimit="10" stroke-width="32"></circle></svg>
                                </a>
                            }
                            <AiOutlineCloseCircle className="cursor-pointer file-delete-icon" onClick={() => { this.onDelete(fileWrapper.file, fileWrapper.fileName) }} />
                    </div>
                 </div>
                ))}
            </>
        );
    }
}


export default connect(
    (state: ApplicationState) => state.worksheetWIP,
    WorksheetWIPStore.actionCreators
)(WorksheetWIP as any);
